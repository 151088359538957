import React, { } from 'react';
import { Link } from 'react-router-dom';



function Bottom() {

    return (
        <>
            <div className="bottom section-padding">
                <div className="container" >
                    <div className="row">
                        
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                            <div className="bottom-widget">
                                <h4 className="widget-title">Company</h4>
                                <ul>
                                    <li><a href={'/terms-condition'}>Terms</a></li>
                                    <li><a href={'/privacy-policy'} >Privacy Policy</a></li>
                                    {/* <li><a href={'https://bullbox.zohodesk.in/portal/en/home'} target="_blank">Affiliate</a></li>
                                    <li><a href={'https://bullbox.zohodesk.in/portal/en/home'} target="_blank">Our Partner</a></li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                            <div className="bottom-widget">
                                <h4 className="widget-title">Support</h4>
                                <ul>
                                    {/* <li><a href={'https://bullbox.zohodesk.in/portal/en/home'} target="_blank">Ticket</a></li>
                                    <li><a href={'https://bullbox.zohodesk.in/portal/en/home'} target="_blank">FAQ</a></li>
                                    <li><a href={'https://bullbox.zohodesk.in/portal/en/home'} target="_blank">Blog</a></li> */}
                                    <li><a href={'https://bullbox.zohodesk.in/portal/en/home'} target="_blank">Helpdesk</a></li>
                                </ul>
                            </div>
                        </div>
                      
                    </div>
                    <div class="row">
                    <div class="col-lg-12">
                        <p></p>
                        <p></p>
                            <p>BullBox is only an order collection platform that collects orders on behalf of clients and places them on BSE StarMF for execution.
                             The Client expressly agrees that Bullbox is not liable or responsible and does not represent or warrant for any damages regarding non-execution of orders or any incorrect execution of orders with regard to the funds
                             chosen by the Client due to, but not being limited to, any link/system failure, sufficient/insufficient funds in the trading account which may occur at the end of the client,
                             Bullbox or the exchange platform.</p>
                        </div>
                        <div className="col-xl-6 col-md-6">
                            <div className="copy_right">
                                Copyright © 2022 Mango Leef Tech Labs PVT Ltd. All Rights Reserved.
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Bottom;