import React from "react";

import { ListGroup, Dropdown, Form, Button, Modal, Alert, Row, Col, Container, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import {
  loadOrderList,
  loadSipStatus,
  cancelSipOrder,
  pauseSipOrder,
  createSwpOrder,
  twoFactorAuthentication,
  cancelSwpOrder
} from "../../actions/orders";
import moment from 'moment';
import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";

import { TabPanel, a11yProps } from "../../shared/Tabs";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import Select from 'react-select';
import { Box, Card, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import OTPInput, { ResendOTP } from "otp-input-react";

class customerSupport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      tab_orders: 0,
      cardopen: false,
      list_stp_orders: [],
      _list_stp_orders: [],
      status_filter_list: [],
      status_filter_selected: null,

      nominee_list: [],
      pay_bank_mode_list: [],

      notification_html: ``,

      swp_order: {
        display_text: '',
        swp_no_of_installments: '',
        swp_amount: '',
        swp_date: ``, // new Date(new Date().setDate(new Date().getDate() + 8)),
        swp_date_value: ``,
        swp_date_text: ``,
        swp_frequency: '',
        is_otp_enabled: false,
        two_factor_authentication: {
          mobile_otp: '',
          email_otp: '',
          twofactor_token_id: ''
        }
      },
      swp_minimum_date: new Date(new Date().setDate(new Date().getDate() + 8)),
      swp_maximum_date: new Date().setDate(new Date().getDate() + 40),

      stp_order: {
        display_text: '',
        two_factor_authentication: {
          mobile_otp: '',
          email_otp: '',
          twofactor_token_id: ''
        }
      },

      display_mode: `order_list`,

      bank_information: {},
      is_verify_enabled: false,
      is_confirm_chk_enabled: false,
      is_verified_editable: false,
      esignkyc_information: {},
      esignkyc_attachment_list: {},
      is_esign_verified: false,
      is_esign_button_disabled: false,
      isloading: false,
      show_popup: false,
      popup_headerText: '',
      popup_messageText: '',

      show_swp_modal: false,
      show_swp_cancel_modal: false,
      show_stp_cancel_modal: false,
      show_view_modal: false,
      modal_orderinfo: {},
      modal_header_text: '',
      show_modal_filter: false,

      show_modal_action: false,
      modal_action_name: ``,
      modal_action_order: {},

      show_modal_pin_confirm_dialog: false,
      modal_pin_confirm_action: '',
      modal_pin_confirm_value: '',
      modal_pin_confirm_dialog_messagetext: 'Enter your 4 digit pin to complete your action',

      show_swpdate_popup: false,
      popup_swp_date_list: [],

    };
  }

  componentDidMount() {

    window.addEventListener("scroll", () => {

      if (document.getElementById("navbar-orderlist-container") &&
        document.getElementById("navbar") &&
        document.getElementById("navbar").classList) {
        const topBorder = document
          .getElementById("navbar-orderlist-container")
          .getBoundingClientRect().top;

        topBorder >= 0
          ? document.getElementById("navbar").classList.remove("fixed-orderlist")
          : document.getElementById("navbar").classList.add("fixed-orderlist");
      }

    });

    if (this.props.location && this.props.location.state &&
      this.props.location.state.source_selected_order) {

      this.setState({
        selected_order: this.props.location.state.source_selected_order
      });

    }

    this.onLoadStpList();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', null);
  }

  handleConfirmCheck = (event) => {
    this.setState({
      is_confirm_chk_checked: !this.state.is_confirm_chk_checked,
    });
  };

  onLoadStpList = () => {

    this.setState({ isloading: true });

    this.props
      .loadOrderList({
        order_type: 'stp',
      })
      .then((response) => {

        if(this.props.order_result) 
        {
            if (this.props.order_result["code"] !== "000") {
              this.setState({
                display_mode: ``,
                ['list_stp_orders']: [], ['_list_stp_orders']: [],
                isloading: false
              });
              if (this.props.order_result["code"] !== "007")
                alert(this.props.order_result["messageText"]);
            }
            else {

              if (this.props.order_result["result"])
                this.setState({
                  bank_information: this.props.order_result["bank_information"],
                  ["list_stp_orders"]: this.props.order_result["result"],
                  ["_list_stp_orders"]: this.props.order_result["result"],
                  status_filter_list: this.props.order_result['status_filter_list'],
                  display_mode: `order_list`,
                  show_swp_modal: false,
                  show_swp_cancel_modal: false,
                  show_stp_cancel_modal: false,
                  show_view_modal: false,
                  modal_orderinfo: {},
                  isloading: false
                });
              else
                this.setState({
                  ["list_stp_orders"]: [],
                  isloading: false
                });

            }
        }
        else {
          this.setState({
            display_mode: ``,
            isloading: false
          });
        }

      })
      .catch((err) => {
        alert(err);
      });
  };

  handleChangeStatus = (selectedOption) => {

    this.setState(
      {
        status_filter_selected: selectedOption
      },
      () => {

        let _filtered_list_stp_orders = [];

        selectedOption.forEach(item => {
          _filtered_list_stp_orders = _filtered_list_stp_orders.concat(
            this.state.list_stp_orders.filter(el => {
              return el[item['value']] === true;
            })
          );
        });

        this.setState({
          _list_stp_orders: selectedOption.length > 0 ?
            _filtered_list_stp_orders : this.state.list_stp_orders
        });

      });

  };

  showChildInformation(order_type, order, request_type) {

    if (request_type === "view") {

      this.setState({ isloading: true });

      this.props
        .loadSipStatus({
          request_mode: "order_payment_status",
          order_type: order_type,
          order_number: order["mf_order_number"] || "",
          order_id: order["_id"],
          selected_order: order,
          check_mandate_status: true,
          is_mobile_version: true
        })
        .then((response) => {

          this.setState({ isloading: false });

          if (this.props.order_result["code"] !== "000")
            alert(this.props.order_result["messageText"]);
          else {
            let _result = this.props.order_result["result"];

            if (_result) {

              let _list = this.state['list_' + order_type + '_orders'];
              let _new_list = [];

              _list.forEach(el => {

                if (el['mf_order_registration_no'] === _result['mf_order_registration_no']) {
                  _result['show_child'] = true;
                  _new_list.push(_result);
                }
                else
                  _new_list.push(el);
              });

              let _filtered_list_stp_orders = (this.state.status_filter_selected || []).length > 0 ?
                [] : _new_list;


              (this.state.status_filter_selected || []).forEach(item => {
                _filtered_list_stp_orders = _filtered_list_stp_orders.concat(
                  _new_list.filter(el => {
                    return el[item['value']] === true;
                  })
                );
              });

              this.setState({
                modal_orderinfo: _result,
                show_view_modal: true,
                ["list_" + order_type + "_orders"]: _new_list,
                ["_list_" + order_type + "_orders"]: _filtered_list_stp_orders
              });

            }
          }
        })
        .catch((err) => {
          alert(err);
        });
    } else {

      let _list = this.state["list_" + order_type + "_orders"];

      _list.forEach((el) => {
        if (
          order["mf_order_registration_no"] === el["mf_order_registration_no"]
        )
          el["show_child"] = false; // !el['show_child'];
      });

      this.filterListBasedOnStatus(_list, order_type);

    }
  }

  showSwpInformation(order_type, order, request_type) {

    let _result = this.state["list_" + order_type + "_orders"]; //this.props.order_result["result"];

    if (_result) {

      let _list = _result;

      _list.forEach((el) => {

        // if (order["order_id"] === el["order_id"]) 
        if (order['mf_order_registration_no'] === el['mf_order_registration_no']) {

          this.setState({
            show_swp_modal: true,
            swp_order: {
              ...this.state.swp_order,
              is_otp_enabled: false,
              two_factor_authentication: {
                mobile_otp: '',
                email_otp: '',
                twofactor_token_id: ''
              }
            },
            modal_orderinfo: el
          });

        }
      });

      this.filterListBasedOnStatus(_list, order_type);

    }
  }

  resetFilter = (order_type) => {

    let _new_list = this.state['list_' + order_type + '_orders'] || [];

    let _state_parameters = {
      show_modal_filter: false,
      status_filter_selected: [],
      ['list_' + order_type + '_orders']: _new_list,
      ['_list_' + order_type + '_orders']: _new_list
    }

    this.setState(_state_parameters);

  }

  closePopup = () => {
    this.setState({
      show_pause_popup: false
    })
  }

  onCancelOrder = (order_type, order) => {

    this.setState({ isloading: true });

    this.props
      .cancelStpOrder({
        order_type: order_type,
        client_info: order,
      })
      .then((response) => {

        const _response = this.props.order_result;

        if (_response["code"] !== "000") {
          this.setState({ isloading: false });
        }
        else {

          if (_response['result']) {

            this.setState({
              show_modal_pin_confirm_dialog: true,
              modal_pin_confirm_action: '',
              modal_pin_confirm_value: '',
              display_mode: 'scheme_action_completed',
              notification_html: _response['notification_html'] || ``
            });

            this.filterListBasedOnStatus(_response['result'], order_type, 'bank_information');

          }
          else {

            this.setState({
              ["list_" + order_type + "_orders"]: [],
              ["_list_" + order_type + "_orders"]: []
            });

          }

        }

      })
      .catch((err) => {
        alert(err);
      });

  };

  onPauseOrder = (order_type, order, mode) => {

    if (mode === 'initiate') {

      let _selected_pause_order = order;
      _selected_pause_order['pause_installment_list'] = [];
      _selected_pause_order['pause_no_of_installments'] = '';
      let _list = [];
      if (order['mf_order_is_pause_allowed']) {

        for (let iRec = parseInt(order['mf_order_pause_minimum_installments']);
          iRec <= parseInt(order['mf_order_pause_maximum_installments']); iRec++) {
          _list.push({ value: iRec, label: iRec });
        }
        _selected_pause_order['pause_installment_list'] = _list;
      }

      this.setState({
        show_pause_popup: true, selected_pause_order: _selected_pause_order
      });

    }
    else {

      this.setState({ isloading: true });

      this.props
        .pauseSipOrder({
          order_type: order_type,
          client_info: order,
        })
        .then((response) => {

          const _response = this.props.order_result;

          if (_response["code"] !== "000") {
            alert(_response["messageText"]);
            this.setState({ isloading: false });
          }
          else {

            /*
            if (_response["result"]) 
            {*/
            this.setState({
              isloading: false,
              show_pause_popup: false,
              modal_pin_confirm_action: '',
              modal_pin_confirm_value: '',
              display_mode: 'scheme_action_completed',
              notification_html: _response['notification_html']
            });
            // this.filterListBasedOnStatus(_response['result'], order_type, 'bank_information');
            /*}
            else
              this.setState({
                isloading: false,
                ["list_" + order_type + "_orders"]: [],
                ["_list_" + order_type + "_orders"]: []
              });

            this.props.history.push({
              pathname: "/fyInformation/",
              state: { display_html: _response['display_html'] || '' },
            });
            */

          }

        })
        .catch((err) => {
          alert(err);
        });

    }

  };

  handleChangeSelect = (selectedOption, order, input, category) => {

    if (input === 'swp_frequency') {

      if (category === 'swp') {

        this.setState({
          swp_order: {
            ...this.state.swp_order,
            swp_frequency: selectedOption.value
          }
        });

      }

    }
    else if (input === 'swp_no_of_installments') {

      if (category === 'swp')
        this.setState({
          swp_order: {
            ...this.state.swp_order,
            swp_no_of_installments: selectedOption.value
          }
        });

    }

  }

  OnCreateSwpOrder = (order_type, order) => {

    this.props
      .createSwpOrder({
        order_type: order_type,
        client_info: Object.assign(
          { swp_order: this.state.swp_order }, order
        ),
      })
      .then((response) => {

        const _response = this.props.order_result;

        if (_response["code"] !== "000") {
          alert(_response["messageText"]);
          this.setState({
            isloading: false
          });
        }
        else {

          let _result = _response["result"];
          if (_result) {

            let _list = this.state['list_' + order_type + '_orders'];
            let _new_list = [];

            _list.forEach(el => {

              if (el['tab_stp_order_child'] === _result['tab_stp_order_child']) {
                _result['show_swp'] = false;
                _new_list.push(_result);
              }
              else
                _new_list.push(el);

            });

            this.setState({
              ["list_" + order_type + "_orders"]: _new_list,
              show_swp_modal: false,
              swp_order: {
                ...this.state.swp_order,
                is_otp_enabled: false,
                two_factor_authentication: {
                  mobile_otp: '',
                  email_otp: '',
                  twofactor_token_id: ''
                }
              },
              modal_orderinfo: {},
              display_mode: 'scheme_action_completed',
              notification_html: _response['notification_html']
            });

          }
          // else 
          //   this.setState({ ["list_" + order_type + "_orders"]: [] });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  OnCancelSwpOrder = (order_type, order) => {

    this.setState({ isloading: true });

    this.props
      .cancelSwpOrder({
        order_type: order_type,
        client_info: Object.assign(
          { swp_order: this.state.swp_order }, order
        ),
      })
      .then((response) => {

        const _response = this.props.order_result;

        if (_response["code"] !== "000") {
          alert(_response["messageText"]);
          this.setState({
            isloading: false
          });
        }
        else {

          let _result = _response["result"];
          if (_result) {

            let _list = this.state['list_' + order_type + '_orders'];
            let _new_list = [];

            _list.forEach(el => {

              _new_list.push(el);
              if (el['tab_stp_order_child'] === _result['tab_stp_order_child']) {
                _result['show_swp'] = false;
                _new_list.push(_result);
              }
            });

            this.filterListBasedOnStatus(_new_list, order_type);

            this.setState({
              isloading: false,
              display_mode: 'scheme_action_completed',
              notification_html: _response['notification_html'],
            });

          }

        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  filterListBasedOnStatus = (order_result, order_type, attribute_name) => {

    let _new_list = (order_result || []);

    let _filtered_list_stp_orders = (this.state.status_filter_selected || []).length > 0 ?
      [] : _new_list;

    (this.state.status_filter_selected || []).forEach(item => {
      _filtered_list_stp_orders = _filtered_list_stp_orders.concat(
        _new_list.filter(el => {
          return el[item['value']] === true;
        })
      );
    });

    let _state_parameters = {
      ['list_' + order_type + '_orders']: _new_list,
      ['_list_' + order_type + '_orders']: _filtered_list_stp_orders
    }

    if (attribute_name)
      _state_parameters[[attribute_name]] = order_result[attribute_name];

    this.setState(_state_parameters)

  }

  showTransactionHistory(item) {

    let _html = `<div>we will show you soon</div>`;

    this.setState({
      show_popup: true,
      popup_headerText: 'Transaction History',
      popup_messageText: item['modal_transaction_history_html'] || _html,
    })

  }

  closePopup = () => {
    this.setState({
      show_popup: false,
      popup_headerText: '',
      popup_messageText: ''
    });
  };

  createDefaultMarkup() {
    return {
      __html: this.state.notification_html,
    };
  }

  htmlDefaultMarkup(text) {
    return {
      __html: text,
    };
  }

  routeChange = (type, item, mode) => {

    /*
    this.props.history.push({
      pathname: "/consolidatedStatement/"
        + item['mf_order_registration_no'] + '/' + item['mf_order_number'] + '/'
        + item['mf_order_type'].toLowerCase()
        + '/' + localStorage.getItem('token')
    });
    */
    this.props.history.push({
      pathname: '/consolidatedStatement/',
      state: {
        param_order: {
          order_registration_no: item['mf_order_registration_no'],
          order_number: item['mf_order_number'],
          order_type: item['mf_order_type'].toLowerCase(),
          order_id: item['_id'].toString()
        }
      }
    });

    /*
    {

      const win = window.open("/consolidatedStatement/"
        + item['mf_order_registration_no'] + '/'
        + item['mf_order_number'] + '/' + item['mf_order_type'].toLowerCase()
        + '/' + localStorage.getItem('token'), "_blank");
      win.focus();

    }
    */

  };

  sendOTP = () => {

    this.setState({ isloading: true });

    this.props
      .twoFactorAuthentication({
        client_info: {
          two_factor_authentication: this.state.swp_order['two_factor_authentication'],
          request_action: 'stp registration',
          is_otp_email_required: false
        },
      })
      .then((response) => {

        if (this.props.order_result["code"] !== "000") {
          this.setState({ isloading: false });
          alert(this.props.order_result["messageText"]);
        }
        else {

          let _result = this.props.order_result; //["result"];

          if (_result && _result['twofactor_id']) {

            this.setState({
              isloading: false,
              swp_order: {
                ...this.state.swp_order,
                is_otp_enabled: true,
                twofactor_token_id: {
                  mobile_otp: '',
                  email_otp: '',
                  twofactor_token_id: _result['twofactor_id']
                }
              }
            });

          }

        }
      })
      .catch((err) => {
        alert(err);
      });

  }

  showStpInformation(order_type, order, request_type) {

    let _result = this.state["list_" + order_type + "_orders"]; //this.props.order_result["result"];

    if (_result) {

      let _list = _result; //this.state["list_" + order_type + "_orders"];
      _list.forEach((el) => {

        if (order["order_id"] === el["order_id"]) {

          this.setState({
            [request_type]: true,
            // show_swp_modal: true,
            stp_order: {
              ...this.state.stp_order,
              is_otp_enabled: false,
              two_factor_authentication: {
                mobile_otp: '',
                email_otp: '',
                twofactor_token_id: ''
              }
            },
            modal_orderinfo: el
          });

        }
        else
          el['show_stp'] = false;
      });

      this.filterListBasedOnStatus(_list, order_type);

    }
  }

  render() {

    const renderButton = buttonProps => {
      return (
        <div className="row">
          <div class="col">
            <button onClick={this.sendOTP} {...buttonProps}
              class={buttonProps.remainingTime !== 0 ? `btn btn-outline-secondary` : `btn btn-outline-success`}>
              {`Resend OTP`}
            </button>
          </div>
          <div class="col-6" >
            {buttonProps.remainingTime !== 0
              ? <span>
                You can resend otp in
                <span style={{ fontWeight: 'bold', color: 'green' }}>{' '}{buttonProps.remainingTime}</span> secs.
              </span>
              : ``}
          </div>
        </div>
      );
    };
    const renderTime = () => React.Fragment;

    return (
      <>
        <Header1 />
        <Sidebar />
        <div className="content-body">

          {this.state.isloading && (
            <Spinner
              animation="border"
              variant="dark"
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                zIndex: 899,
              }}
            />
          )}

<div className="content-body">
                <div className="container">
                    <div className="row">
                        
                       

                        <div className="col-xxl-12" style={{marginTop:100}}>
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Support </h4>
                                    
                                </div>
                                <div className="card-body">
                                    <form className="row">
                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                                            <div className="user-info">
                                                <b style={{fontSize:16}}>Phone No</b>
                                                <br/>
                                                <button style={{fontSize:16 }} onClick={() => window.location.href = `tel:${'7349109891'}`}>7349109891</button>
                                                <p>Available from 9:am to 6:00 pm(MON to Fri)</p>
                                            </div>
                                        </div>
                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                                            <div className="user-info">
                                                <b style={{fontSize:16}}>EMAIL ADDRESS</b>
                                                <br/>
                                                <button style={{fontSize:16 }} onClick={() => window.location.href = 'mailto:Support@bullbox.in'}>Support@bullbox.in</button>
                                            </div>
                                        </div>
                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                                            <div className="user-info">
                                                <b style={{fontSize:16}}>Bullbox Office Address</b>
                                                <h4>
                                                    Above Renault Showroom,3rd floor
                                                    Upper Palace Orchards, 210/2, Bellary Rd, Sadashiva Nagar, Armane Nagar,
                                                     Bengaluru, Karnataka 560080</h4>
                                            </div>
                                        </div>
                                        
                                    </form>

                                </div>
                            </div>
                        </div>

                       
                      
                    </div>
                </div>
            </div>
        </div>
        </>
    );
  }

}

// StpList.propTypes = {
//     validateOtp: PropTypes.func.isRequired,
//     isAuthenticated: PropTypes.bool
// };

/*
const mapStateToProps = state => ({
    loading: state.order.loading,
    order_result: state.order.order_result
});
*/

const mapStateToProps = ({ order }) => {
  const { loading, order_result } = order;
  return {
    loading,
    order_result,
  };
};

export default connect(mapStateToProps, {
  loadOrderList,
  loadSipStatus,
  cancelSipOrder,
  pauseSipOrder,
  createSwpOrder,
  twoFactorAuthentication,
  cancelSwpOrder
})(customerSupport);
