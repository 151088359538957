import api from '../utils/api';
import { setAlert } from './alert';
import {
  LIST_REPORT_SUCCESS,
  LIST_REPORT_FAIL
} from './types';
import { API_END_POINTS } from '../constants/constants';

export const reportGeneric = (body_parameters) => async dispatch => {
   
  const body = body_parameters;

  try {
    
    const res = await api.post(
      API_END_POINTS.REPORT_GENERIC + `/` + body_parameters['method_attributes'], body);
    
    dispatch({
      type: LIST_REPORT_SUCCESS,
      payload: res.data
    });    
   
  } catch (err) {

    console.log(err)
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: LIST_REPORT_FAIL,
      list_result: {
        code: '-1',
        messageText: ''
      }
    });

  }
};

export const reportAllotment = (body_parameters) => async dispatch => {
   
  const body = body_parameters;

  try {

    const res = await api.post(API_END_POINTS.REPORT_ALLOTMENT, body);
    
    dispatch({
      type: LIST_REPORT_SUCCESS,
      payload: res.data
    });
    
   
  //  dispatch(loadUser());
  } catch (err) {

    console.log(err)
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: LIST_REPORT_FAIL,
      list_result: {
        code: '-1',
        messageText: ''
      }
    });

  }
};