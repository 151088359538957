import React from "react";
import { Link, Redirect } from "react-router-dom";
import { login, validateSession } from "../../jsx/actions/auth";
import { Button, Spinner, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { TabPanel, a11yProps } from "../shared/Tabs";
import Box from "@mui/material/Box";
import Backgroundlogin from '../../images/background/bg-23.jpg';
import Emitter from "../utils/emitter";
class Signin extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      value: 0,
      login_mobile_number: "",
      signup_mobile_number: "",
      otp_button: false,
      show_popup: false,
      show_signup_popup: false,
      isloading: false,
      otp_validation: ""
    };

  }

  componentDidMount() {

    //localStorage.removeItem('token');
    if (localStorage.getItem('token')) {

      if ((this.props.location.pathname || ``).toLowerCase().indexOf('/signin/loggedout') === -1) {
        this.props.history.push({
          pathname: '/portfolio/',
          state: {}
        });
      }

    }
    else 
    {

      if ( 
        (this.props.location.pathname || ``).toLowerCase().indexOf('/signin/loggedout') !== -1 
        && 
        (window.localStorage.getItem('mobile_device_code') || ``) === `SaiMuruga-01-SEP-2023-247774c0-075f-455a-b7fd-6ad5105f1a4c`
      ) 
      {

        this.setState({ isloading: true });
        this.props
          .validateSession({
            window_navigator_user_agent: window.navigator.userAgent
          })
          .then((response) => {

            this.setState({ isloading: false });
            // console.log((this.props.login_result));

            if (this.props.login_result["code"] === "000")
              this.props.history.push({
                pathname: '/' + this.props.login_result['navigateScreen'] + '/',
                state: {}
              });
            else 
            {
              
              Emitter.emit("HEADER1", {});
              const reset_info = this.props.location.state;
              if (reset_info && reset_info['reset_info']) 
              {
                this.setState({ value: 1 });
                this.props.history.replace('', null);
              }

            }

          })
          .catch((err) => {
            // alert(err);
          });

      }
      else 
      {
        localStorage.removeItem('token');
        localStorage.removeItem('profileData');
        localStorage.removeItem('masterList');
        this.props.history.replace('', null);
      }

    }

    /*
    sessionStorage.removeItem('personal_info_step');
    sessionStorage.removeItem('consolidated_statement_data');
    sessionStorage.removeItem('client_documents_data');
    */
    // sessionStorage.clear();

    /*
    const reset_info = this.props.location.state;
    if(reset_info && reset_info['reset_info'])
    {
      this.setState({ value: 1 });
      this.props.history.replace('', null);
    }
    */

  }

  closePopup = () => {
    this.setState({
      show_popup: false
    })
  }

  onSubmit = (login_request_from_value, event) => {

    const { email, password, login_mobile_number, signup_mobile_number } = this.state;
    
    // if(login_mobile_number === '7010815334')
    //    alert(window.localStorage.getItem('mobile_device_id'));

    this.setState({ isloading: true });

    let body_parameters = {
      login_mobile_number: this.state.show_signup_popup ? signup_mobile_number : login_mobile_number,
      login_email: email,
      login_role: "customer",
      login_hint: password,
      login_request_from: login_request_from_value,
      screen_name: 'signin',
      device_information: navigator.userAgent,
      device_id: localStorage.getItem(`mobile_device_id`) || new window.DeviceUUID().get()
    };

    this.props
      .login(body_parameters)
      .then((response) => {
        // console.log(response);
      //  console.log(this.props.login_result)
        this.setState({ isloading: false });

        
        if (this.props.login_result["code"] !== "000")
          this.setState({
            show_popup: true
          });
        else
          this.props.history.push({
            pathname: '/' + this.props.login_result['navigateScreen'] + '/',
            state: {
              route_parameters: {
                login_mobile_number: body_parameters['login_mobile_number'],
                login_email: email,
                login_role: "customer",
                login_request_from: login_request_from_value,
                token_id: this.props.login_result['token_id']
              }
            }

          });

      })
      .catch((err) => {
        alert(err);
      });
  };


  otpvalidation = (event) => {

    this.setState({ otp_validation: "" })
    const mobile = /^[6-9]\d{9}$/gm;

    const targetValue = event.target.value.replace(/\s/g, '');

    if (this.state.show_signup_popup)
      this.setState({ signup_mobile_number: targetValue });
    else
      this.setState({ login_mobile_number: targetValue });

    if (mobile.test(targetValue)) {
      if (targetValue.length >= 10)
        this.setState({ otp_button: true });
      this.setState({ otp_validation: "" })
    }
    else {
      this.setState({ otp_button: false });
    }

  };

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };
  handleClick() {
    // console.log('this is:', this);
  }
  Cancelregistration = () => {
    this.setState({ show_signup_popup: false })
  }

  render() {

    // if (this.props.login_result && this.props.login_result["code"] === '000' && 
    //     this.props.login_result['navigateScreen']) {
    //   return <Redirect to={this.props.login_result["navigateScreen"]} />;
    // }

    return (
      <>

        <div style={{
          background: `url(${Backgroundlogin})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          position: 'relative',
          zIndex: 0
        }}>

{/* {this.state.isloading &&
                              <Spinner animation="border" variant="dark"
                                style={{ position: "fixed", top: "50%", left: "50%" }} />
                            } */}

          <div className="authincation section-padding" >
            <div className="container h-100">
              <div className="row justify-content-center h-100">
                <div className="col-xl-5 col-md-6">
                  <div className="mini-logo text-center my-1">
                    <Link to={"#"}>
                      <img src={require("./../../images/logo.png")} alt="" width="200px" />
                    </Link>
                  </div>
                  <div className="auth-form card" 
                    style={{marginTop: 60, backgroundColor: "#edfbfc", width: 330}}>
                    {/* <div className="card justify-content-center"> */}
                      <h4>
                        Mutual Fund Investment App
                      </h4>
                    {/* </div> */}



                    {this.state.show_popup && this.props.login_result &&
                      this.props.login_result["messageText"] &&
                      <Modal show={this.state.show_popup} onHide={() => this.closePopup()}>
                        <Modal.Header>

                        </Modal.Header>
                        <Modal.Body>
                          <p>  {this.props.login_result["messageText"]}</p>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="primary" onClick={() => this.closePopup()}>
                            Close
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    }

                    <Modal show={this.state.show_signup_popup} onHide={() => this.Cancelregistration()}>
                      <Modal.Header closeButton>
                        <Modal.Title>Sign Up</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="auth-form card">
                          <div className="card-body">
                            
                            <div className="form-group">
                              <label>Enter your mobile number</label>
                              <input
                                className="form-control"
                                type="number"
                                placeholder=""
                                pattern="[0-9]*"
                                maxLength="10"
                                name="signup_mobile_number"
                                value={this.state.signup_mobile_number}
                                onChange={(event) => {

                                  if (event.target.value.trim().length === 0 ||
                                    event.target.value.match(/^[0-9]+$/)) {

                                    this.otpvalidation(event)
                                  }

                                }
                                }
                              />
                            </div>
                            {this.state.otp_button && (
                              <div className="text-center" style={{ marginTop: 20 }}>
                                <Button
                                  className="btn btn-success btn-block"
                                  variant="success"
                                  onClick={() => this.onSubmit("mobile")}
                                >
                                  Submit
                                </Button>
                              </div>
                            )}
                          </div>

                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="primary" onClick={() => this.Cancelregistration()}
                        >
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                  <div style={{ marginTop: 0 }}>
                    <div className="form-group">

                      <div class="auth-form card">
                        <div class="card justify-content-center">
                          <h4>Enter mobile number</h4>
                        </div>

                        <input
                          className="form-control"
                          pattern="[0-9]*"
                          maxLength="10"
                          type="tel"
                          name="login_mobile_number"
                          value={this.state.login_mobile_number}
                          // placeholder="Your mobile number"
                          onChange={(event) => {

                            if (event.target.value.trim().length === 0 ||
                              event.target.value.match(/^[0-9]+$/)) {

                              this.otpvalidation(event)
                            }

                          }
                          }
                          onKeyPress={(event) => {
                            if (this.state.otp_button && event.key === 'Enter') {
                              this.onSubmit("mobile");
                            }
                          }}
                          style={{ letterSpacing: 7 }}
                        />

                        {this.state.isloading &&
                          <Spinner animation="border" variant="dark"
                            style={{ position: "fixed", top: "50%", left: "50%" }} />
                        }
                        {(this.state.login_mobile_number.length > 1 || '') &&
                          <div className="form-group">
                            <label className="text-danger">{this.state.otp_validation}</label></div>}
                        {(
                          <div className="text-center">
                            <p></p>

                            <Button
                              disabled={!this.state.otp_button}
                              className="btn btn-info btn-block"
                              variant="success"
                              onKeyPress={(event) => this.onSubmit("mobile", event)}
                              onClick={(event) => this.onSubmit("mobile", event)}
                            >
                              <span style={{ fontSize: 16 }}>
                                Login with OTP
                              </span>
                            </Button>

                            
                          </div>
                        )}
<div className="mini-logo text-center my-4" >
                <p style={{color:'#1C2E91',fontWeight:'bold'}}>CashBack: -
                <br/>
                First Investment of Rs.500  is on us.
                <br/>
                Invest Rs.500 on Lumpsum and get Rs.500 CashBack*
                </p>
                
              </div>
                      </div>

                      
                    </div>

                  </div>
                  <hr />
                  

                </div>
                
              </div>
             
            </div>
          </div>
          <div className="form-group text-center" style={{backgroundColor:'#1C2E91',height:100}}>
          <label  style={{paddingTop:0,color:'white'}}>ARN-183190</label>
          <br/>
            <label  style={{paddingTop:0,color:'white'}}>V.24.1.0</label>
            <br/>
            <label  style={{paddingTop:0,color:'white'}}>Customer Support : +91-7349109891</label>
            <br/>
            <label  style={{paddingTop:0,color:'white'}}>Mon to Fri(9:00 Am to 6:00 pm)</label>
          </div>
        </div>
      </>
    );
  }
}
Signin.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,

};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  login_result: state.auth.login_result,
  loading: state.master.loading,
});

export default connect(mapStateToProps, { login, validateSession })(Signin);
