import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getOrderStatement
} from "../../actions/orders";
import { downloadStatement } from "../../actions/orders";

import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";
import axios from 'axios';
import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';

import {
  Table,
  Collapse,
  Modal,
  Form,
  Row,
  Col,
  Button,
  Label, Spinner
} from "react-bootstrap";
import Select from "react-select";
import { saveAs } from "file-saver";
import Popup from "../popup";

class ConsolidatedStatement extends React.Component {

  constructor(props) {

    super(props);
    this.state = {
      selected_order: {},
      sip_order: {},
      list_bank_account: [],
      list_mandate: [],
      list_pending_mandate: [],
      list_mandate_debit: [],
      src_nach_form: "",
      selected_mandate_id: "",
      is_mandate_selected: false,
      is_mandate_registered: false,
      is_mandate_uploaded: false,
      is_debit_max_limit: false,
      mandate_amount: 25000,
      mandate_id: "",
      mandate_date_validation: {
        message_text: "",
      },
      is_first_order_today: false,
      loading: false,
      is_nach: true,
      nach_option: "existing",
      nach_option_new_processed: false,
      nach_option_new_processed_message_text: "",
      sip_information_text: "You need to make payment physically on ",
      render_statement_pdf: null,
      show_popup: false,
      popup_messageText: "",
      invest_more_amount: 0.0,
      redeemable_amount: 0.0,
      is_redeem_full_checked: false,
      is_stage_verified: false,
      is_verify_enabled: false,
      is_verified_editable: false,
      esignkyc_information: {},
      esignkyc_attachment_list: {},
      is_esign_verified: false,
      is_esign_button_disabled: false,
      isloading: false,
      received_data: {},
      attachment_name: ``,
      download_base64: ``,
      download_statement_url: ``,
      download_mode: ``,
      download_timeout: ``,
      download_order_info: {},
      render_html: ``
    };
  }

  componentDidMount() {

    window.scrollTo(0, 0);
    window.addEventListener("scroll", () => {

      if (document.getElementById("navbar-orderlist-container") &&
        document.getElementById("navbar") &&
        document.getElementById("navbar").classList) {
        const topBorder = document
          .getElementById("navbar-orderlist-container")
          .getBoundingClientRect().top;

        topBorder >= 0
          ? document.getElementById("navbar").classList.remove("fixed-orderlist")
          : document.getElementById("navbar").classList.add("fixed-orderlist");
      }

    });

    // let isMobile = (window.matchMedia && window.matchMedia("(max-width: 480px)").matches);

    let received_data = (/*isMobile &&*/ this.props.location.state && this.props.location.state['param_order']) ?
      this.props.location.state['param_order'] : this.props.match.params;

    if (received_data) {
      sessionStorage.setItem('consolidated_statement_data', JSON.stringify(received_data));
      // this.setState({ scheme_name: received_data["scheme_name"] });
    }
    else
      received_data = JSON.parse(sessionStorage.getItem('consolidated_statement_data') || JSON.stringify({}));

    this.onLoadStatement(received_data);

  }

  onLoadStatement = (parameters) => {

    this.setState({ isloading: true, received_data: parameters });

    this.props
      .getOrderStatement(Object.assign({
        is_external_bb_access_token: true,
        bb_access_token: parameters['token_value'] || ``,
        is_mobile: true
      }, parameters))
      .then((response) => {

        if (this.props.order_result["code"] !== "000") {
          this.setState({ isloading: false });
          alert(this.props.order_result["messageText"]);
        }
        else {
          let _result = this.props.order_result["result"];
          this.setState({
            isloading: false,
            // download_base64: _result['statement_base64'] || ``,
            download_statement_url: _result['statement_url'] || ``,
            download_mode: _result['download_mode'] || ``,
            download_timeout: _result['download_timeout'] || 4500,
            // download_order_info: _result['order_info'],
            render_html: _result['render_html'] || ``,
            attachment_name: _result['attachment_name'] || new Date().toISOString() + `.pdf`,
            received_data: parameters
          });
        }
      })
      .catch((err) => {
        // console.log('line 146 :');
        console.log(err);
        alert(err);
      });
  };

  downloadStatement = (download_url) => {

    this.setState({ isloading: true });

    axios({
      url: download_url,
      method: 'GET',
      responseType: 'blob'
    })
      .then((response) => {

        this.setState({ isloading: false });
        const blob = new Blob([response.data], { type: "application/pdf" });
        saveAs(blob, this.state.attachment_name);

        /*
        const url = window.URL
          .createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', this.state.attachment_name);
        document.body.appendChild(link);
        link.click();
        this.setState({ isloading: false });
        */

      }).catch((err) => {
        this.setState({ isloading: false });
      });

    // this.props
    //   .downloadStatement({
    //     order_info: this.state.download_order_info,
    //     is_mobile: true
    // })
    // .then((response) => {

    //   this.setState({ isloading: false });
    //   if (this.props.order_result["code"] !== "000") {
    //     alert(this.props.order_result["messageText"]);
    //   }
    // })
    // .catch((err) => {
    //   console.log('line 146 :');
    //   console.log(err);
    //   alert(err);
    // });

  };

  /*
  async downloadStatement() {

    if (this.state.render_statement_pdf) {

      const byteCharacters = this.state.render_statement_pdf; //atob(this.state.src_nach_form);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      // const { data } = await getTicketsPdf()
      const blob = new Blob([byteArray], { type: "application/pdf" });
      saveAs(blob, this.state.received_data['order_number'] + ".pdf");

    }
  }
  */

  createDefaultMarkup(text) {
    return {
      __html: this.state.render_html,
    }
  };

  render() {

    // if(this.props.loading)
    //   return <Spinner />;

    // if(this.props.order_result && this.props.order_result['esignkyc_stage_verified'])
    //    return <Redirect to={this.props.aadhaar_result['navigateScreen']} />;

    return (
      <>

        <Header1 />
        <Sidebar />

        <div className="content-body" >
          <div className="container" style={{ marginBottom: 100 }}>
            <div className="row">

              <div id="navbar-orderlist-container">
                <div id="navbar" >
                  <div className="card" style={{ backgroundColor: '#f8f8fb' }}>
                    <div className="card-header">
                      <div class="row">
                        <table style={{ width: '100%' }}>
                          <tr>
                            <td style={{ height: 30, textAlign: 'center' }}>
                              <span>
                                <span style={{ fontSize: 16 }}>Account Statement</span>
                              </span>
                            </td>
                            <td style={{ width: 20 }}>

                            </td>
                            {this.state.download_mode !== 'url' &&
                              <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'right' }}>
                                <a
                                  className="btn-sm btn-success"
                                  download={
                                    this.state.attachment_name
                                  }
                                  href={this.state.download_statement_url}
                                  onClick={() => {
                                    this.setState({ isloading: true });
                                    setTimeout(() => { this.setState({ isloading: false }); }, 4500)
                                  }
                                  }
                                >
                                  <i style={{ color: 'white' }} className="fa fa-download  fa-lg" ></i>
                                  <span style={{ fontSize: 14, marginLeft: 5, padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4 }}>{` Download `}</span>
                                </a>
                              </td>}
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div class="row" style={{ marginTop: '-10px' }}>
                      <hr />
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ marginTop: 70 }}>

                {this.state.isloading &&
                  <Spinner animation="border" variant="dark"
                    style={{ position: "fixed", top: "50%", left: "50%" }} />
                }

                {this.state.render_html.length > 0 &&
                  <div dangerouslySetInnerHTML={this.createDefaultMarkup()} />
                }

                {/* {this.state.render_statement_pdf !== null && (
                    <PDFReader data={this.state.render_statement_pdf} />
                  )} */}
                {/* {
                  this.state.download_statement_url
                  && this.state.download_mode === 'url' &&
                  <div>
                    <hr />
                    <a
                      className="btn btn-success btn-block"
                      variant="success"
                      download={this.state.attachment_name}
                      href={ this.state.download_statement_url
                      }
                      disabled={this.state.isloading}
                      onClick={() => {
                        this.setState({ isloading: true });
                        setTimeout(() => { this.setState({ isloading: false }); }, 4500)
                      }
                      }
                    >
                      Download Statement
                    </a>
                  </div>
                } */}
                {/* {
                  (this.state.download_statement_url || '').length > 16 &&
                  this.state.download_mode === 'blob' &&
                  <div>
                    <hr />
                    <div class="row">
                      <div class="col-2">
                      </div>
                      <div class="col-8">

                        <button type="button"
                          className="btn btn-success btn-block"
                          style={{ marginTop: '-15px', color: 'white', textAlign: 'center' }}
                          onClick={() => {
                            {
                              this.downloadStatement(this.state.download_statement_url);
                            }
                          }}
                        >
                          Download Statement
                        </button>
                      </div>
                      <div class="col-2">
                      </div>
                    </div>
                  </div>
                } */}

                {(this.state.download_statement_url || '').length > 16 &&
                  this.state.download_mode === 'url' &&
                  <Fab
                    style={{ bottom: 25, right: -18, transform: 'scale(0.8)' }}
                    alwaysShowTitle={true}
                    mainButtonStyles={{
                      backgroundColor: "#2ea34d"
                    }}
                    icon={<i style={{ color: 'white' }} className="fa fa-download  fa-lg" ></i>}
                    onClick={() => {
                      // if (this.state.download_mode === 'url') {
                      const win = window.open(this.state.download_statement_url, "_blank");
                      // }
                      // else
                      //   this.downloadStatement(this.state.download_statement_url);
                    }}
                  >
                  </Fab>
                }

              </div>

            </div>

          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ order, general }) => {
  const { loading, order_result } = order;
  return {
    loading,
    order_result
  };
};

export default connect(mapStateToProps, {
  getOrderStatement,
  downloadStatement
})(ConsolidatedStatement);
