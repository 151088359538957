import React from "react";
import { Link } from "react-router-dom";
import { Button, Spinner,Modal } from "react-bootstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { validateOtp, verifyEmail, resetPassword } from "../../jsx/actions/auth";
import { Redirect } from "react-router-dom";
import Popup from "./popup";
import Background from "../../images/background/bg-14.jpg";
import OTPInput, { ResendOTP } from "otp-input-react";

class Reset extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      otp: "",
      password: "",
      confirm_password: "",
      data: "",
      verify_otp: false,
      verify_password: false,
      show_popup: false,
      popup_message: "",
      isloading: false,
      emailButtonDisabled: true
    };
  }

  componentDidMount() {
    /*
        let _state_parameters = localStorage.getItem('state_parameters');
        if(_state_parameters && typeof _state_parameters === 'string') {
            this.setState(JSON.parse(_state_parameters));
        }
        */
    // this.setState(this.props.location.state.otp_result["state_parameters"]);
  }

  closePopup = () => {
    this.setState({
      show_popup: false,
    });
  };

  onSubmit = () => {

    const { email } = this.state;
    this.setState({ 
      isloading: true,
      otp: '' 
    });
    let body_parameters = {
      login_email: email,
      login_role: "customer",
      login_request_from: "email",
      login_request_mode: "sendotp",
    };

    this.props
      .resetPassword(body_parameters)
      .then((response) => {
        this.setState({ isloading: false });
        if (this.props.email_otp_result["code"] === "000") {
          if (this.props.email_otp_result["state_parameters"])
            this.setState(this.props.email_otp_result["state_parameters"]);
          else
            this.setState({
              verify_otp: this.props.email_otp_result["verify_otp"],
              verify_password: this.props.email_otp_result["verify_password"],
            });
        }

        this.setState({
          show_popup: true,
          popup_message: this.props.email_otp_result["messageText"],
        });
      })
      .catch((err) => {
        alert(err);
      });
  };

  validEmail(event) {

    this.setState({
      email: event.target.value,
      emailButtonDisabled: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(event.target.value) === false
    });

  }

  onVerify = (request_mode) => {
    // const {  otp } = this.state;
    this.setState({ isloading: true });
    let body_parameters = {
      login_otp: this.state.otp,
      login_role: "customer",
      login_request_from: "email",
      login_request_mode: request_mode,
      is_password_reset: true
    };

    this.props
      .validateOtp(body_parameters)
      .then((response) => {
        this.setState({ isloading: false });
        if (this.props.login_otp_result["code"] === "000") {
          if (this.props.login_otp_result["state_parameters"])
            this.setState(this.props.login_otp_result["state_parameters"]);
          else
            this.setState({
              verify_otp: this.props.login_otp_result["verify_otp"],
              verify_password: this.props.login_otp_result["verify_password"],
            });
        }
        this.setState({
          show_popup: true,
          popup_message: this.props.login_otp_result["messageText"],
        });
      })
      .catch((err) => {
        alert(err);
      });
  };

  onSetPassword = (request_mode) => {

    this.setState({ isloading: true });
    let body_parameters = {
      login_email: this.state.email.toLowerCase(),
      login_role: "customer",
      login_request_from: "email",
      login_request_mode: request_mode,
      login_hint: this.state.password,
    };

    this.props
      .verifyEmail(body_parameters)
      .then((response) => {
        this.setState({ isloading: false });
        this.setState({
          show_popup: true,
          popup_message: this.props.email_otp_result["messageText"],
        });
        // this.props.history.push('/' + this.props.email_otp_result['navigateScreen'] + '/');
        this.props.history.push({
          pathname: "/" + this.props.email_otp_result["navigateScreen"] + "/",
          state: { reset_info: this.props.email_otp_result },
        });
      })
      .catch((err) => {
        console.log("errrrrrr");
        console.log(err);
        //alert(err)
      });
  };

  render() {
    // if(this.props.email_otp_result && this.props.email_otp_result['code'] === '000' &&
    //    this.props.email_otp_result['navigateScreen']) {

    //     return <Redirect to = {this.props.email_otp_result['navigateScreen']} />;
    // }

    const renderButton = buttonProps => {
            return (
            <button onClick={this.onSubmit} {...buttonProps}>
                {buttonProps.remainingTime !== 0
                ? `Kindly wait for ${buttonProps.remainingTime} seconds to resend otp`
                : "Resend"}
            </button>
            );
        };
        const renderTime = () => React.Fragment;

    return (
      <>
        <div
          style={{
            background: `url(${Background})`,
            backgroundRepeat: "repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <div className="authincation section-padding">
            <div className="container h-100">
              <div className="row justify-content-center h-100 align-items-center">
                <div className="col-xl-5 col-md-6">
                  <div className="mini-logo text-center my-5">
                    <Link to={"./"}>
                      <img
                        src={require("./../../images/logo.png")}
                        alt=""
                    
                        width="200px"
                      />
                    </Link>
                  </div>
                  <h3 className="text-center">Reset Password</h3>
                  <div className="auth-form card">
                    <div className="card-body">
                      {this.state.isloading && (
                        <Spinner
                          animation="border"
                          variant="dark"
                          style={{ position: "fixed", top: "50%", left: "50%" }}
                        />
                      )}

                      <p className="text-left">Enter your email address</p>
                      {/* <p className="text-center mb-5">We have sent one time code to your registered mobile number</p> */}
                      <form action="#">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control font-weight-bold"
                            value={this.state.email}
                            disabled={
                              this.state.verify_otp ||
                              this.state.verify_password
                            }
                            maxLength="35"
                            onChange={(event) =>  this.validEmail(event)}
                          />
                        </div>
                        {!this.state.verify_otp && !this.state.verify_password && (
                          <div className="text-center" style={{marginTop:20}}>
                            <Button
                              className="btn btn-success btn-block"
                              variant="success"
                              onClick={() => this.onSubmit()}
                              disabled={this.state.emailButtonDisabled}
                            >
                              Next
                            </Button>
                          </div>
                        )}
                      </form>
                    </div>

                    {this.state.verify_otp && (
                      <div className="card-body">
                        <p className="text-center">
                          Kindly enter the otp (one time password) sent to your
                          email id
                        </p>
                        {/* <p className="text-center mb-5">We have sent one time code to your registered mobile number</p> */}
                        <form action="#">
                          <div className="form-group">
                            {/* <input
                              type="password"
                              className="form-control text-center font-weight-bold"
                              value={this.state.otp}
                              onChange={(event) =>
                                this.setState({ otp: event.target.value })
                              }
                            /> */}
                            <OTPInput
                                value={this.state.otp}  
                                onChange={event => this.setState({ otp: event })}
                                autoFocus
                                OTPLength={6}
                                otpType="number"
                                disabled={false}
                                secure
                            />
                            <br />
                            <div className="text-center">
                              <ResendOTP renderButton={renderButton} renderTime={renderTime} style={{color:'black',marginTop:20}}
                                  onResendClick={(e) => { this.onSubmit() }}
                                  // handelResendClick={() => this.sendOTP()} 
                                  />
                              <hr/>
                            </div>
                          </div>
                          <div className="text-center">
                            <Button  style={{marginTop:20}}
                              className="btn btn-success btn-block"
                              variant="success"
                              onClick={() => this.onVerify("verifyemailotp")}
                              disabled={this.state.otp.length < 6}
                            >
                              Verify
                            </Button>
                          </div>
                        </form>
                      </div>
                    )}

                    {this.state.verify_password && (
                      <div className="card-body">
                        <p className="text-center">Kindly set your password</p>
                        {/* <p className="text-center mb-5">We have sent one time code to your registered mobile number</p> */}
                        <form action="#">
                          <div className="form-group">
                            <p className="text-center">New Password</p>
                            <input
                              type="password"
                              className="form-control text-center font-weight-bold"
                              value={this.state.password}
                              onChange={(event) =>
                                {
                                  const targetValue = event.target.value.replace(/\s/g, '');
                                  if ((targetValue.match(/^[0-9a-zA-Z(\@)]+$/) 
                                    || targetValue === "") && targetValue.toString().length < 22) 
                                  {
                                    this.setState({ 
                                      password: targetValue
                                     });
                                  }

                                }
                              }
                            />
                          </div>
                          <div className="form-group">
                            <p className="text-center">Confirm New Password</p>
                            <input
                              type="text"
                              className="form-control text-center font-weight-bold"
                              value={this.state.confirm_password}
                              onChange={(event) =>
                                {
                                  const targetValue = event.target.value.replace(/\s/g, '');
                                  if ((targetValue.match(/^[0-9a-zA-Z(\@)]+$/) 
                                    || targetValue === "") && targetValue.toString().length < 22) 
                                  {
                                    this.setState({ 
                                      confirm_password: targetValue
                                     });
                                  }

                                }
                              }
                            />
                          </div>
                          
                          {this.state.confirm_password.length > 0  && this.state.confirm_password !==
                            this.state.password && (
                            <div className="form-group">
                              <p className="text-center text-danger">
                                Password does not match
                              </p>
                            </div>
                          )}
                          <div className="text-center" style={{marginTop:20}}>
                            <Button
                              className="btn btn-success btn-block"
                              variant="success"
                              disabled={
                                this.state.password.length < 7 ||
                                this.state.password.trim() !==
                                  this.state.confirm_password.trim()
                              }
                              onClick={() =>
                                this.onSetPassword("resetpassword")
                              }
                            >
                              Submit
                            </Button>
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                  {this.state.show_popup && (
                  <Modal show={this.state.show_popup} onHide={() => this.closePopup()}>
                   <Modal.Header>
                      
                   </Modal.Header>
                   <Modal.Body>
                   <p>{this.state.popup_message}</p>
                   </Modal.Body>
                   <Modal.Footer>
                       <Button variant="primary" onClick={() => this.closePopup()}>
                           Close
                       </Button>
                   </Modal.Footer>
               </Modal> 
               )}
                  {/* {this.state.show_popup && (
                    <Popup
                      content={
                        <>
                          <p>{this.state.popup_message}</p>
                        </>
                      }
                      handleClose={() => this.closePopup()}
                    /> */}
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

Reset.propTypes = {
  validateOtp: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  email_otp_result: state.auth.email_otp_result,
  login_otp_result: state.auth.login_otp_result,
});
export default connect(mapStateToProps, { verifyEmail, validateOtp, resetPassword })(
  Reset
);
