import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './css/style.css';
import Index from './jsx';
import { Ripple } from 'react-preloaders';
import { LOGOUT } from './jsx/actions/types';
import setAuthToken from './jsx/utils/setAuthToken';
// Redux
import { Redirect, Switch, Route, withRouter } from "react-router";

import Spinner from "./jsx/layout/spinner";
import { Provider } from 'react-redux';
import store from './store';
import Background from './images/background/bg-16.jpg';

class App extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {

    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    // Emitter.on('APPJS', (data) => {
    //     alert(data)
    // });

    window.dispatchEvent(new CustomEvent("navigationhandler"));

    // store.dispatch(loadUser());

    // log user out from all tabs if they log out in one tab
    window.addEventListener('storage', () => {
      if (!localStorage.token) store.dispatch({ type: LOGOUT });
    });


    const script = document.createElement("script");
    script.src = "https://app.digio.in/sdk/v9/digio.js";
    script.async = true;
    document.head.appendChild(script);

    const script_one = document.createElement("script");
    script_one.src = "https://checkout.razorpay.com/v1/checkout.js";
    script_one.async = true;
    document.head.appendChild(script_one);

    const script_filesaver1 = document.createElement("script");
    script_filesaver1.src = "https://cdn.rawgit.com/eligrey/Blob.js/0cef2746414269b16834878a8abc52eb9d53e6bd/Blob.js";
    script_filesaver1.async = true;
    document.head.appendChild(script_filesaver1);

    const script_filesaver2 = document.createElement("script");
    script_filesaver2.src = "https://cdn.rawgit.com/eligrey/canvas-toBlob.js/f1a01896135ab378aa5c0118eadd81da55e698d8/canvas-toBlob.js";
    script_filesaver2.async = true;
    document.head.appendChild(script_filesaver2);

    const script_filesaver3 = document.createElement("script");
    script_filesaver3.src = "https://cdn.rawgit.com/eligrey/FileSaver.js/e9d941381475b5df8b7d7691013401e171014e89/FileSaver.min.js";
    script_filesaver3.async = true;
    document.head.appendChild(script_filesaver3);

  }





  render() {

    return (
      <div>
        <div className="App">
          {/* {this.props.isloading && <Spinner />} */}
          <Provider store={store}>
            <Index />
            <Ripple />
          </Provider>
        </div>
      </div>
    );
  }
}
export default App;
