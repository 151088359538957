import api from '../utils/api';
import { setAlert } from './alert';
import {
  CHART_LIST,
  CHART_FAIL
} from './types';
import { API_END_POINTS } from '../constants/constants';

export const listChartSchemes = (body_parameters) => async dispatch => {
   
  const body = body_parameters;

  try {
    
    const res = await api.post(API_END_POINTS.STATISTICS, body);
    dispatch({
      type: CHART_LIST,
      payload: res.data
    });
    
  //  console.log("listChartSchemes", res.data)
  //  dispatch(loadUser());
  } catch (err) {

    console.log(err)
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: CHART_FAIL,
      list_result: {
        code: '-1',
        messageText: ''
      }
    });

  }
};
