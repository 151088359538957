import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";
import { Form, Modal, ListGroup, Row, Col, Spinner } from "react-bootstrap";

import Select, { components } from "react-select";
import {
  listWatchSchemes,
  addWatchList,
  modifyWatchList,
} from "../../../jsx/actions/master";
import { createRazorPayOrder } from "../../../jsx/actions/payments";
import { loadSchemeAdviseInfo } from "../../../jsx/actions/general";
import ReactPaginate from "react-paginate";

import { Fab, Action } from "react-tiny-fab";
import moment from "moment";
import { Button, Alert } from "react-bootstrap";

const filter_conditional_parameters = [
  "growth",
  "minimum_investment",
  "investment_method",
];

class SchemeWatchList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      masterList: JSON.parse(
        localStorage.getItem("masterList") || JSON.stringify({})
      ),
      error: null,
      isLoaded: false,
      mutualFundList: [],
      pageIndex: 1,
      openCollapse: false,
      pageIndexAvailability: true,
      selected_order: {},
      selected_scheme: {},
      stp_selected_scheme_list: [],
      stp_order: {
        stp_frequency: "",
        stp_scheme: {},
        stp_scheme_code: {},
        stp_installments: "",
        stp_amount: "",
        stp_date: "",
        stp_date_value: "",
        validation_message_text: "",
        mfapi_scheme_code: "",
        selected_stp_frequency: [],
        selected_stp_scheme_code: [],
        selected_stp_installments: [],
      },
      show_targetscheme_popup: false,
      show_stpdate_popup: false,
      popup_stp_date_list: [],
      filterOption: {
        scheme_amc: /*selectedOptionAmc*/ [],
        category: [],
        risk: [],
        sub_category: [],
        growth: [],
        minimum_investment: [],
        investment_method: [],
      },
      stp_frequency_list: [],
      stp_scheme_specification_list: [],
      stp_installment_list: [],
      sub_category_list: [],
      selectedOptionAmc: [],
      selectedOptionCategory: [],
      selectedOptionRisk: [],
      selectedOptionSubCategory: [],
      selectedOptionGrowth: [],
      selectedOptionMinInvestment: [],
      selectedOptionInvestmentMethod: [],
      options: [],
      newValue: 0,
      scheme_list: [],
      initial_scheme_list: [],
      scheme_watch_list: [],
      isSelectedForRemoval: {},
      initial_scheme_watch_list: [],
      watch_list: [],
      perPage: 30,
      page: 1,
      pages: 0,
      isloading: false,

      notification_html: ``,

      list_pricing: [],
      selected_pricing_id: "",

      list_scheme_advisors: [],
      selected_scheme_advisor_id: "",
      selected_scheme_advisor_info: {},

      submit_watchlist_enabled: false,
      total_watchlist_schemes: 0,
      total_allowed_schemes_in_watchlist: 0,

      selected_watchlist: "",
      display_mode: "view watchlist",
      show_modal_add_watchlist: false,
      modal_pin_confirm_action: "",
      modal_watchlist_name: "",
      modal_watchlist_confirm_enabled: false,

      show_modal_advise_pricing: false,
      show_modal_scheme_advisor: false,
      show_modal_scheme_advise: false,
      modal_scheme_advise_information: {},

      show_modal_information: false,
      modal_information_dialog_messagetext: "",

      modal_pin_confirm_dialog_messagetext:
        "Enter your 4 digit pin to complete your action",
      modal_pin_confirm_dialog_error_messagetext: "",
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", () => {
      if (
        document.getElementById("navbar-watchlist-container") &&
        document.getElementById("navbar") &&
        document.getElementById("navbar").classList
      ) {
        const topBorder = document
          .getElementById("navbar-watchlist-container")
          .getBoundingClientRect().top;

        topBorder >= 0
          ? document.getElementById("navbar").classList.remove("fixed")
          : document.getElementById("navbar").classList.add("fixed");
      }
    });

    this.loadSchemeList();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", null);
  }

  handleChangeAmc = (selectedOption) => {
    this.setState(
      {
        filterOption: {
          ...this.state.filterOption,
          scheme_amc: selectedOption,
        },
        selectedOptionAmc: selectedOption,
        page: 0,
      },
      () => this.filterGridList(/*selectedOption, 'scheme_amc'*/)
    );
  };

  handleChangeCategory = (selectedOption) => {
    this.setState(
      {
        filterOption: { ...this.state.filterOption, category: selectedOption },
        selectedOptionCategory: selectedOption,
        // sub_category_list: sub_category_list
      },
      () => this.filterGridList(/*selectedOption, 'category'*/)
    );
  };

  handleChangeSubCategory = (selectedOption) => {
    this.setState({ selectedOptionSubCategory: selectedOption });
    this.filterGridList(/*selectedOption, 'sub_category'*/);
  };

  handleChangeRisk = (selectedOption) => {
    this.setState(
      {
        filterOption: { ...this.state.filterOption, risk: selectedOption },
        selectedOptionRisk: selectedOption,
      },
      () => this.filterGridList(/*selectedOption, 'risk'*/)
    );
  };

  handleChangeGrowth = (selectedOption) => {
    this.setState(
      {
        filterOption: { ...this.state.filterOption, growth: selectedOption },
        selectedOptionGrowth: selectedOption,
      },
      () => this.filterGridList(/*selectedOption, 'growth'*/)
    );

    // this.filterGrowthoption(selectedOption);
    // this.setState({ selectedOptionGrowth: selectedOption });
  };

  handleChangeMinInvestment = (selectedOption) => {
    this.setState(
      {
        filterOption: {
          ...this.state.filterOption,
          minimum_investment: selectedOption,
        },
        selectedOptionMinInvestment: selectedOption,
      },
      () => this.filterGridList(/*selectedOption, 'minimum_investment'*/)
    );
  };

  handleChangeInvestmentMode = (selectedOption) => {
    this.setState(
      {
        filterOption: {
          ...this.state.filterOption,
          investment_method: selectedOption,
        },
        selectedOptionInvestmentMode: selectedOption,
      },
      () => this.filterGridList(/*selectedOption, 'investment_method'*/)
    );
  };

  routeChange = (item) => 
  {
    if (window.matchMedia && window.matchMedia("(max-width: 480px)").matches) {
      this.props.history.push({
        pathname: "/chartsmobile",
        state: {
          param_data: {
            scheme_isin: item["scheme_isin"],
            scheme_code: item["scheme_code"],
            scheme_name: item["scheme_name"],
          },
        },
      });
    } else {
      const win = window.open(
        "/charts/" +
          item["scheme_isin"] +
          "/" +
          item["scheme_code"] +
          "/" +
          item["scheme_name"],
        "_blank"
      );
      win.focus();
    }
  };

  filterGridList = (amclistFilter) => {
    let _filtered_list = this.props.result["result"]["scheme_list"] || []; //this.state.scheme_list || [];
    let _filterOption = amclistFilter ? amclistFilter : this.state.filterOption;

    if (amclistFilter) {
      this.setState({
        filterOption: {
          ...this.state.filterOption,
          scheme_amc: _filterOption["scheme_amc"],
          category: _filterOption["category"],
          risk: _filterOption["risk"],
          growth: _filterOption["growth"],
          minimum_investment: _filterOption["minimum_investment"],
          investment_method: _filterOption["investment_method"],
        },
        selectedOptionGrowth: _filterOption["growth"],
        selectedOptionMinInvestment: _filterOption["minimum_investment"],
        selectedOptionInvestmentMode: _filterOption["investment_method"],
        /*
        selectedOptionAmc: _filterOption['scheme_amc'],
        selectedOptionCategory: _filterOption['category'],
        selectedOptionRisk: _filterOption['risk'],
        selectedOptionSubCategory: _filterOption['sub_category']
        */
      });
    }

    let _selected_list = [];

    Object.keys(_filterOption).forEach((key) => {
      /*if(key === 'risk' ||  key === 'category') {
        if(_filterOption[key]['value']) {
          _selected_list = _filterOption[key]['value'] === 'all' ? [] : [_filterOption[key]] ;
        }
        else
          _selected_list = [];
      }
      else*/
      _selected_list = _filterOption[key];

      if (_selected_list.length > 0) {
        if (
          filter_conditional_parameters.filter((__el) => {
            return __el === key;
          }).length > 0
        ) {
          switch (key) {
            case "growth":
              let _filter = _filterOption[key];
              let _arr_values = [];
              let boolTrue = false;

              let __filtered_list = [];

              _filtered_list.forEach((el) => {
                boolTrue = false;

                _filter.forEach((el_) => {
                  if (!boolTrue) {
                    _arr_values = el_.value.split("-");

                    boolTrue =
                      parseFloat(el["one_year_percent"] || 0) >=
                        parseFloat(_arr_values[0]) &&
                      parseFloat(el["one_year_percent"] || 0) <=
                        parseFloat(_arr_values[1]);

                    if (!boolTrue)
                      boolTrue =
                        parseFloat(el["three_year_percent"] || 0) >=
                          parseFloat(_arr_values[0]) &&
                        parseFloat(el["three_year_percent"] || 0) <=
                          parseFloat(_arr_values[1]);

                    if (!boolTrue)
                      boolTrue =
                        parseFloat(el["five_year_percent"] || 0) >=
                          parseFloat(_arr_values[0]) &&
                        parseFloat(el["five_year_percent"] || 0) <=
                          parseFloat(_arr_values[1]);
                  }

                  if (boolTrue) __filtered_list.push(el);
                });

                // return boolTrue;

                /*
                return _filter.filter(el_ =>  {
                  _arr_values = el_.value.split('-');
                  return (parseFloat(el['one_year_percent'] || 0) >= parseFloat(_arr_values[0]) && 
                    parseFloat(el['one_year_percent'] || 0) <= parseFloat(_arr_values[1])) 
                    || 
                    (parseFloat(el['three_year_percent'] || 0) >= parseFloat(_arr_values[0]) && 
                    parseFloat(el['three_year_percent'] || 0) <= parseFloat(_arr_values[1]))
                    ||
                    (parseFloat(el['five_year_percent'] || 0) >= parseFloat(_arr_values[0]) && 
                    parseFloat(el['five_year_percent'] || 0) <= parseFloat(_arr_values[1])) ;
                });*/ //.length > 0;
              });

              _filtered_list = __filtered_list;

              // _filtered_list = [...new Set(_filtered_list)];

              break;
            case "minimum_investment":
              _filtered_list = _filtered_list.filter((el) => {
                return (
                  _filterOption[key].filter((el_) => {
                    return (
                      parseFloat(el["scheme_minimum_investment_amount"] || 0) >=
                        parseFloat(el_.value) &&
                      parseFloat(el["scheme_minimum_investment_amount"] || 0) <=
                        parseFloat(el_.value) + 10
                    );
                  }).length > 0
                );
              });
              break;
            case "investment_method":
              let _condition = _filterOption[key].map((el) => {
                return el["value"];
              });

              _filtered_list = _filtered_list.filter((el) => {
                if (_condition.length > 1)
                  return el[_condition[0]] === "Y" || el[_condition[1]] === "Y";
                else return el[_condition[0]] === "N";
              });
              break;

            case "category":
              let condition_category = _filterOption[key].map((el) => {
                return el["value"];
              });

              _filtered_list = _filtered_list.filter((el) => {
                if (condition_category.length > 1)
                  return el[condition_category[0]];
              });
              break;

            default:
              break;
          }
        } else {
          let _loc = "";
          let _condition = _selected_list; /*.map(el => {
               return el.value.toLowerCase();
             });*/

          _filtered_list = _filtered_list.filter((el) => {
            // return _condition.indexOf((el[key] || '').toLowerCase());
            _loc = (el[key] || "").toLowerCase();
            return (
              _condition.filter((el_) => {
                return _loc === el_["value"].toLowerCase();
              }).length > 0
            );
          });
        }
      }
    });

    sessionStorage.setItem("amclistFilter", JSON.stringify(_filterOption));

    this.setState({
      scheme_list: _filtered_list,
      pages: Math.round(
        /*floor*/ _filtered_list.length / this.props.result["per_page_count"] ||
          this.state.perPage
      ),
    });
  };

  filterGrowthoption = (selectedOption) => {
    let _filtered_list = this.state.scheme_list || [];

    let text = selectedOption[0].value || "";
    let filtered_list = _filtered_list.filter((item) => {
      return text.length > 1
        ? (
            item.three_year_percent.toString() ||
            item.one_month_percent.toString() ||
            item.one_year_percent.toString()
          ).match(text)
        : true;
    });

    filtered_list = [...new Set(filtered_list)];

    this.setState({
      scheme_list: filtered_list,
      pages: Math.round(
        /*floor*/ filtered_list.length / this.props.result["per_page_count"] ||
          this.state.perPage
      ),
    });
  };

  filterMininvestmentoption = (selectedOption) => {
    let _filtered_list = this.state.scheme_list || [];

    let text = selectedOption[0].value || "";
    let filtered_list = _filtered_list.filter((item) => {
      return text.length > 1
        ? item.minimum_investment_amount.toString().match(text)
        : true;
    });

    this.setState({
      scheme_list: filtered_list,
      pages: Math.round(
        /*floor*/ filtered_list.length / this.props.result["per_page_count"] ||
          this.state.perPage
      ),
    });
  };

  loadSchemeList = () => {
    this.setState({ isloading: true });

    this.props
      .listWatchSchemes({
        file_index: this.state.pageIndex,
      })
      .then((response) => {
        const { perPage } = this.state;

        if (this.props.result["code"] === "000") {
          this.setState({
            show_targetscheme_popup: true,
            scheme_list: this.props.result["result"]["scheme_list"] || [],
            scheme_watch_list: [],
            initial_scheme_watch_list:
              this.props.result["result"]["scheme_watch_list"] || [],
            watch_list: this.props.result["result"]["watch_list"] || [],
            initial_scheme_list:
              this.props.result["result"]["scheme_list"] || [],
            list_pricing: this.props.result["result"]["list_pricing"] || [],
            list_scheme_advisors:
              this.props.result["result"]["list_scheme_advisors"] || [],
            total_allowed_schemes_in_watchlist:
              this.props.result["result"][
                "total_allowed_schemes_in_watchlist"
              ] || 10,

            pages: Math.round(
              /*floor*/ (this.props.result["result"]["scheme_list"] || [])
                .length / this.props.result["per_page_count"] || perPage
            ),
            isloading: false,
            perPage: this.props.result["per_page_count"] || this.state.perPage,
          });

          this.filterGridList(
            sessionStorage.getItem("amclistFilter")
              ? JSON.parse(sessionStorage.getItem("amclistFilter"))
              : null
          );

          if (sessionStorage.getItem("amclistFilter"))
            sessionStorage.removeItem("amclistFilter");
        } else {
          alert(this.props.result["messageText"]);
          this.setState({ isloading: false });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleSchemeCheck = (/*e,*/ item) => {
    // let _list = this.state.initial_scheme_list;

    // _list.forEach((el) => {
    //   el["is_checked"] = false;

    //   if (el["_id"] === item["_id"])
    //     el["is_checked"] = true; //e.target.checked;
    // });

    let _watch_scheme_filtered = this.state.scheme_watch_list.filter((el) => {
      return el["scheme_isin"] === item["scheme_isin"];
    });

    _watch_scheme_filtered = _watch_scheme_filtered.map((el) => {
      return {
        label:
          "Min ₹" +
          el["stp_in_minimum_installment_amount"] +
          " - Max ₹" +
          (parseInt(el["stp_in_maximum_installment_amount"]) < 1
            ? 99999999.0
            : el["stp_in_maximum_installment_amount"]) +
          // + '| SETTLEMENT TYPE :' + el['param_settlement_type']
          (el["scheme_name"].split("-").length < 3
            ? ""
            : "|" +
              el["scheme_name"].split("-")[
                el["scheme_name"].split("-").length - 1
              ]),
        // + '| REDEEM ' + (el['param_redemption_allowed'] === 'N' ? 'NOT ' : '') + 'ALLOWED',
        value: el["scheme_code"],
        frequency: el["stp_frequency"],
      };
    });

    this.setState({
      stp_frequency_list: this.props.result["stp_frequency_list"] || [],
      stp_installment_list: this.props.result["stp_installment_list"] || [],
      stp_order: {
        ...this.state.stp_order,
        stp_frequency: "",
        stp_scheme: {},
        stp_scheme_code: {},
        stp_installments: "",
        stp_amount: "",
        stp_date: "",
        stp_date_value: "",
        validation_message_text: "",
        selected_stp_frequency: [],
        selected_stp_scheme_code: [],
        selected_stp_installments: [],
      },
      // scheme_list: _list,
      stp_scheme_specification_list: _watch_scheme_filtered,
      selected_scheme: item,
      show_targetscheme_popup: false,
    });
    // window.scrollTo(0, document.body.scrollHeight);
  };

  handleChangeWatchList = (selectedOption) => {
    this.setState(
      {
        selected_watchlist: selectedOption,
      },
      () => {
        let _scheme_watch_list = this.state.initial_scheme_watch_list.filter(
          (el) => {
            return el["id"] === selectedOption["value"];
          }
        );

        _scheme_watch_list =
          _scheme_watch_list.length > 0
            ? _scheme_watch_list[0]["scheme_list"]
            : [];

        this.setState({
          scheme_watch_list: this.myFilter(
            this.state.initial_scheme_list,
            _scheme_watch_list
              .map((el) => {
                return el["scheme_code"];
              })
              .join(",")
          ),
        });
      }
    );
  };

  handleRadioChange = (value, item, key, key_1) => {
    let json = {
      // selected_pricing_id: item['id']
      [key]: item["id"],
    };
    if (key_1) json[key_1] = item;

    this.setState(json);
  };

  myFilter(base, query) {
    const queries = query.split(",");
    return base.filter((a) => {
      let doReturn = false;
      queries.forEach((q) => {
        if (a.scheme_code.includes(q)) {
          doReturn = true;
        }
      });
      return doReturn;
    });
  }

  loadScript = async (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  displayRazorpay = async () => {
    // const res = await this.loadScript(
    //   "https://checkout.razorpay.com/v1/checkout.js"
    // );

    // if (!res) {
    //   alert("Razorpay SDK failed to load. Are you online?");
    //   return;
    // }
    // else
    {
      // const _selected_scheme_list = this.state.scheme_list.filter(el => {
      //   return el['is_checked'] === true;
      // });

      const list_pricing = this.state.list_pricing.filter((el) => {
        return el["id"] === this.state.selected_pricing_id;
      });

      const list_scheme_advisors = this.state.list_scheme_advisors.filter(
        (el) => {
          return el["id"] === this.state.selected_scheme_advisor_id;
        }
      );

      const _props = this.props;
      const _state = this.state;
      const _this = this;

      this.setState({ isloading: true });

      this.props
        .createRazorPayOrder(
          {
            selected_watchlist: this.state.selected_watchlist,
            request_mode: "create order",
            pricing_plan: list_pricing[0],
            scheme_advisor: list_scheme_advisors[0],
            watchlist_name: this.state.modal_watchlist_name,
            // }).then((response) => {
          },
          (response) => {
            this.setState({ isloading: false });

            if (_props.payment_result) response = _props.payment_result;

            if (response["code"] === "000") {
              let _result = response["result"];
              const {
                key,
                amount,
                id: order_id,
                currency,
                name,
                email,
                mobile,
                address,
                description,
                color,
                logo,
              } = _result["razorpay_orderinfo"];

              const options = {
                key: key,
                amount: amount,
                currency: currency,
                name: name,
                description: description,
                image: { logo },
                order_id: order_id,
                handler: async function (response) {
                  /*
                const data = {
                  orderCreationId: order_id,
                  razorpayPaymentId: response.razorpay_payment_id,
                  razorpayOrderId: response.razorpay_order_id,
                  razorpaySignature: response.razorpay_signature,
                };
                */

                  let payment_order_response = response;
                  payment_order_response["order_id"] = order_id;

                  _props.createRazorPayOrder(
                    {
                      selected_watchlist: _state.selected_watchlist["value"],
                      request_mode: "confirm order",
                      pricing_plan: list_pricing[0],
                      scheme_advisor: list_scheme_advisors[0],
                      // watchlist_name: this.state.modal_watchlist_name,
                      payment_order_response: payment_order_response,
                      // }).then((response) => {
                    },
                    (_response) => {
                      if (_props.payment_result)
                        _response = _props.payment_result;

                      if (_response["code"] === "000") {
                        const initial_scheme_watch_list =
                          _response["result"]["scheme_watch_list"] || [];

                        let _scheme_watch_list =
                          initial_scheme_watch_list.filter((el) => {
                            return (
                              el["id"] === _state.selected_watchlist["value"]
                            );
                          });

                        _scheme_watch_list =
                          _scheme_watch_list.length > 0
                            ? _scheme_watch_list[0]["scheme_list"]
                            : [];

                        const queries = _scheme_watch_list.map((el) => {
                          return el["scheme_code"];
                        });
                        const _loc_scheme_watch_list = (
                          _response["result"]["scheme_list"] || []
                        ).filter((a) => {
                          let doReturn = false;
                          queries.forEach((q) => {
                            if (a.scheme_code.includes(q)) {
                              doReturn = true;
                            }
                          });
                          return doReturn;
                        });

                        _this.setState({
                          selected_pricing_id: "",
                          selected_scheme_advisor_id: "",

                          submit_watchlist_enabled: false,
                          total_watchlist_schemes: 0,

                          selected_watchlist: "",
                          display_mode: "view watchlist",
                          show_modal_add_watchlist: false,
                          modal_watchlist_name: "",
                          modal_watchlist_confirm_enabled: false,

                          show_modal_advise_pricing: false,
                          show_modal_scheme_advisor: false,
                          show_modal_scheme_advise: false,

                          show_modal_information: true,
                          modal_information_dialog_messagetext:
                            _response["messageText"],

                          watch_list: _response["result"]["watch_list"] || [],
                          initial_scheme_watch_list: initial_scheme_watch_list,
                          scheme_watch_list:
                            _loc_scheme_watch_list /*this.myFilter(_response['result']["scheme_list"] || [],
                        _scheme_watch_list.map(el => { return el['scheme_code'] }).join(','))*/,
                        });
                      }
                    }
                  );

                  // const result = await axios.post("http://localhost:5000/payment/success", data);

                  // alert(result.data.msg);
                },
                prefill: {
                  name: name,
                  email: email,
                  contact: mobile,
                },
                notes: {
                  address: address,
                },
                theme: {
                  color: color, //"#61dafb",
                },
              };

              const paymentObject = new window.Razorpay(options);

              paymentObject.on("payment.failed", function (response) {
                /*
              alert(response.error.code);
              alert(response.error.description);
              alert(response.error.source);
              alert(response.error.step);
              alert(response.error.reason);
              alert(response.error.metadata.order_id);
              alert(response.error.metadata.payment_id);
              */
              });

              paymentObject.open();
            } else {
            }
          }
        )
        .catch((err) => {
          alert(err);
        });
    }
  };

  loadSchemeAdviseInfo = (scheme) => {
    this.setState({ isloading: true });

    this.props
      .loadSchemeAdviseInfo({
        scheme_advisor_id:
          this.state.selected_watchlist["value"] /*['scheme_advisor_id']*/,
        scheme_code: scheme["scheme_code"],
      })
      .then((response) => {
        if (this.props.scheme_advise["code"] === "000") {
          this.setState({
            //show_modal_scheme_advise: true,
            display_mode: "read scheme advise",
            modal_scheme_advise_information: Object.assign(
              this.props.scheme_advise["result"]["scheme_advise_information"],
              { scheme_info: scheme }
            ),
            isloading: false,
          });
        } else {
          alert(this.props.scheme_advise["messageText"]);
          this.setState({ isloading: false });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleChangeSelect = (selectedOption, input) => {
    if (input === "stp_frequency") {
      this.setState({
        stp_order: {
          ...this.state.stp_order,
          [input]: selectedOption.value,
          selected_stp_frequency: selectedOption,
        },
        stp_selected_scheme_list:
          this.state.stp_scheme_specification_list.filter((el) => {
            return (
              el["frequency"].toLowerCase() ===
              selectedOption.value.toLowerCase()
            );
          }),
      });
    } else if (input === "stp_scheme_code") {
      let _selected_scheme = this.state.scheme_watch_list.filter((el) => {
        return el["scheme_code"] === selectedOption.value;
      });

      if (_selected_scheme.length > 0) {
        _selected_scheme = _selected_scheme[0];

        let _loc = [];
        for (
          var _rec = parseInt(
            _selected_scheme["stp_minimum_installment_numbers"]
          );
          _rec <= parseInt(_selected_scheme["stp_maximum_installment_numbers"]);
          _rec++
        ) {
          _loc.push({ value: _rec, label: _rec });
        }

        let _mfapi_scheme_code = this.state.scheme_list.filter((el) => {
          return (
            el["scheme_isin"] === _selected_scheme["scheme_isin"] ||
            el["scheme_isin_1"] === _selected_scheme["scheme_isin"]
          );
        });

        _selected_scheme["mfapi_scheme_code"] =
          _mfapi_scheme_code.legnth > 0
            ? _mfapi_scheme_code[0]["scheme_code"]
            : "";

        this.setState({
          stp_order: {
            ...this.state.stp_order,
            [input]: _selected_scheme["scheme_code"],
            mfapi_scheme_code:
              _mfapi_scheme_code.legnth > 0
                ? _mfapi_scheme_code[0]["scheme_code"]
                : "",
            stp_scheme: _selected_scheme,
            selected_stp_scheme_code: selectedOption,
          },
          stp_installment_list: _loc,
          popup_stp_date_list: _selected_scheme["stp_dates"].split(","),
        });
      }
    } else if (input === "stp_installments") {
      this.setState({
        stp_order: {
          ...this.state.stp_order,
          [input]: selectedOption.value,
          selected_stp_installments: selectedOption,
        },
      });
    }
    // else if (input === 'stp_amount') {

    // }
  };

  handlePageClick = (event) => {
    let page = event.selected;
    this.setState({ page });
    window.scrollTo(0, 0);
  };

  onNextPrevious = (count) => {
    // this.setState({pageIndex: this.state.pageIndex + count});
  };

  getBase64Image = (imgUrl) => {
    var img = new Image();

    // onload fires when the image is fully loadded, and has width and height

    img.onload = function () {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      var dataURL = canvas.toDataURL("image/png"),
        dataURL = dataURL.replace(/^data:image\/(png|jpg);base64,/, "");

      return dataURL; // the base64 string
    };

    // set attributes and src
    img.setAttribute("crossOrigin", "anonymous"); //
    img.src = imgUrl;
  };

  stpParameterValidation() {
    let _stp_order = this.state.stp_order;

    var isValid =
      (_stp_order["stp_frequency"] || "").length > 4 &&
      parseInt(_stp_order["stp_installments"] || 0) >= 1 &&
      !isNaN(this.state.stp_order["stp_amount"] || "") &&
      parseInt(this.state.stp_order["stp_amount"]) >=
        parseInt(
          _stp_order["stp_scheme"]["stp_in_minimum_installment_amount"]
        ) &&
      parseInt(this.state.stp_order["stp_amount"]) <=
        parseInt(_stp_order["stp_scheme"]["stp_in_maximum_installment_amount"]);

    return isValid;

    /*
    return parseFloat(_sip_order['sip_amount']) >= parseFloat(this.state.selected_sip_order['minimum_installment_amount']) &&
      parseFloat(_sip_order['sip_amount']) <= parseFloat(this.state.selected_sip_order['maximum_installment_amount']) &&
      _sip_order['sip_frequency'].length > 2 && _sip_order['sip_installment_numbers'] > 0;
    */
  }

  handleSchemeSelect = (item) => {
    var _limit_reached = this.state.total_watchlist_schemes >= 10;

    if (_limit_reached) {
      alert("You can save only 10 schemes in an single watchlist");
    }
    {
      let _list_scheme = this.state.initial_scheme_list;
      _list_scheme.forEach((el) => {
        if (el["_id"] === item["_id"] && !_limit_reached) {
          item["is_checked"] = !item["is_checked"];
        }
      });

      const submit_watchlist_enabled = _list_scheme.filter((el) => {
        return el["is_checked"] === true;
      }).length;

      this.setState({
        scheme_list: _list_scheme,
        submit_watchlist_enabled: submit_watchlist_enabled > 0,
        total_watchlist_schemes: submit_watchlist_enabled,
      });
    }
  };
// The submitWatchList function is responsible for submitting the selected schemes to the watchlist. 
  submitWatchList = (request_mode) => {
    // if (isValid)
    {
      this.setState({ isloading: true });

      const _selected_scheme_list = this.state.scheme_list.filter((el) => {
        return el["is_checked"] === true;
      });
      this.props
        .addWatchList({
          scheme_watchlist: _selected_scheme_list,
          request_mode: request_mode,
          watchlist_name: this.state.modal_watchlist_name,
        })
        .then((response) => {
          if (this.props.result["code"] !== "000") {
            this.setState({
              isloading: false,
            });
          } else {
            let _result = this.props.result["result"];

            const initial_scheme_watch_list =
              _result["scheme_watch_list"] || [];

            let selected_scheme_watch_list = initial_scheme_watch_list.filter(
              (el) => {
                return el["id"] === _result["added_watchlist_id"];
              }
            );

            let _scheme_watch_list =
              selected_scheme_watch_list.length > 0
                ? selected_scheme_watch_list[0]["scheme_list"]
                : [];

            this.setState({
              isloading: false,

              selected_pricing_id: "",
              selected_scheme_advisor_id: "",

              submit_watchlist_enabled: false,
              total_watchlist_schemes: 0,

              selected_watchlist: selected_scheme_watch_list[0],
              display_mode: "view watchlist",
              show_modal_add_watchlist: false,
              modal_watchlist_name: "",
              modal_watchlist_confirm_enabled: false,

              show_modal_advise_pricing: false,
              show_modal_scheme_advisor: false,
              show_modal_scheme_advise: false,

              show_modal_information: true,
              modal_information_dialog_messagetext: _result["messageText"],

              watch_list: _result["watch_list"] || [],
              initial_scheme_watch_list: initial_scheme_watch_list,
              scheme_watch_list: this.myFilter(
                this.state.initial_scheme_list,
                _scheme_watch_list
                  .map((el) => {
                    return el["scheme_code"];
                  })
                  .join(",")
              ),
            });
          }
        })
        .catch((err) => {
          alert(err);
        });
    }
    // else
    //   alert('Your inputs are not valid');
  };

  createDefaultMarkup() {
    return {
      __html: this.state.modal_information_dialog_messagetext,
    };
  }

  handleRemoveScheme = (schemeId) => {
    // Show confirmation dialog before removing the scheme
    
      // Filter out the scheme to be removed from the watchlist
      const updatedSchemeWatchList = this.state.scheme_watch_list.filter(
        (scheme) => scheme["_id"] !== schemeId
      );
  
      // Update the state with the modified watchlist
      this.setState({
        scheme_watch_list: updatedSchemeWatchList,
        total_watchlist_schemes: updatedSchemeWatchList.length,
      });
  
      // Optionally, you can also make an API call here to update the watchlist on the server-side.
    
    //   fetch(AppConstants.API_BASE_URL + API_END_POINTS.REMOVE_SCHEME, {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         // Include any necessary authorization headers if required
    //     },
    //     body: JSON.stringify({ schemeId }),
    // })
    // .then(response => {
    //     if (!response.ok) {
    //         throw new Error('Failed to update watchlist on the server');
    //     }
    //     // Optionally, you can handle the response here if needed
    //     // console.log('Watchlist updated successfully');
    // })
    // .catch(error => {
    //     console.error('Error updating watchlist on the server:', error);
    //     // You can handle errors or display an error message to the user
    // });
  };
  
  handleToggleRemove = (schemeId) => {
    // Toggle the selection status for the scheme ID
    const updatedSelection = { ...this.state.isSelectedForRemoval };
    updatedSelection[schemeId] = !updatedSelection[schemeId];
    
    // Update the state with the modified isSelectedForRemoval object
    this.setState({
      isSelectedForRemoval: updatedSelection,
    });
  };
  render() {
    const { page, perPage, pages, scheme_list, scheme_watch_list,isSelectedForRemoval } = this.state;

    let _scheme_watch_list = scheme_watch_list;

    let _list_scheme =
      scheme_list.length > perPage
        ? scheme_list.slice(page * perPage, (page + 1) * perPage)
        : scheme_list;

    return (
      <>
        <Header1 />
        <Sidebar />

        <div>
          <div id="navbar-watchlist-container">
            <div id="navbar">
              <div className="card" style={{ backgroundColor: "#f8f8fb" }}>
                <Row>
                  <Col>
                    <h4 className="text-left">
                      {this.state.display_mode === "add watchlist"
                        ? `Add New Scheme WatchList`
                        : this.state.display_mode === "read scheme advise"
                        ? `Scheme Advise`
                        : "WatchList"}
                    </h4>
                  </Col>
                </Row>
                {this.state.display_mode === "view watchlist" &&
                  !this.state.isloading && (
                    <div className="row">
                      <div className="col-8">
                        <div style={{ width: "190px" }}>
                          <Select
                            placeholder={`-Select WatchList-`}
                            value={this.state.selected_watchlist}
                            onChange={this.handleChangeWatchList}
                            options={this.state.watch_list}
                          />
                        </div>
                      </div>
                      <div className="col-4">
                        <button
                          type="button"
                          className="btn-sm btn-primary"
                          style={{ color: "white", width: "70px" }}
                          onClick={() => {
                            this.setState({
                              display_mode: "add watchlist",
                            });
                          }}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  )}
                {this.state.display_mode === "view watchlist" &&
                  (this.state.selected_watchlist["value"] || "").length > 3 && (
                    <div className="row">
                      <div className="col" style={{ marginTop: 10 }}>
                        {!this.state.selected_watchlist[
                          "subscription_enabled"
                        ] ? (
                          <button
                            type="button"
                            className="btn-sm btn-success"
                            style={{ color: "white", width: "120px" }}
                            onClick={() => {
                              this.setState({
                                display_mode: "show scheme advisorlist",
                              });
                            }}
                          >
                            Ask Expert Advise
                          </button>
                        ) : (
                          <p>
                            <span
                              style={{
                                fontSize: 12,
                                color: "InfoText",
                                backgroundColor: "#99f0c5",
                                padding: 4,
                                paddingLeft: 8,
                                paddingRight: 8,
                                margin: 4,
                                borderRadius: 4,
                              }}
                            >
                              You are subscribed to expert advise
                              <span>{`  `}from</span>
                            </span>{" "}
                            <br />
                            <span
                              style={{
                                fontSize: 12,
                                color: "InfoText",
                                backgroundColor: "#99f0c5",
                                padding: 4,
                                paddingLeft: 8,
                                paddingRight: 8,
                                margin: 4,
                                borderRadius: 4,
                              }}
                            >
                              {
                                this.state.selected_watchlist[
                                  "scheme_advisor_name"
                                ]
                              }
                            </span>
                          </p>
                        )}
                      </div>
                    </div>
                  )}

                {this.state.display_mode === "add watchlist" && (
                  <div>
                    {_list_scheme && scheme_list.length >= perPage && (
                      <ReactPaginate
                        breakLabel="..."
                        previousLabel={"←"}
                        nextLabel={"→"}
                        pageCount={pages}
                        onPageChange={this.handlePageClick}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="container">
            {this.state.display_mode === "view watchlist" &&
              (this.state.scheme_watch_list.length || []).length < 1 &&
              (this.state.isloading || this.state.watch_list.length == 0) && (
                <Alert variant="secondary text-center">
                  <Alert.Heading>
                    {this.state.isloading
                      ? "We are fetching your watchlist schemes"
                      : `Your watchlist is empty`}
                  </Alert.Heading>
                </Alert>
              )}
            <div class="row">
              <div
                class="col-xl-12"
                style={{
                  marginTop:
                    this.state.display_mode === "view watchlist"
                      ? (this.state.selected_watchlist["value"] || "").length >
                        3
                        ? this.state.selected_watchlist["subscription_enabled"]
                          ? 60
                          : 40
                        : 500
                      : this.state.display_mode === "read scheme advise"
                      ? -22
                      : -5,
                }}
              >
                <div class="transaction-widget">
                  {this.state.isloading && (
                    <Spinner
                      animation="border"
                      variant="dark"
                      style={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        zIndex: 899,
                      }}
                    />
                  )}

                  {this.state.display_mode === "view watchlist" && (
                    <div>
                      <ListGroup>
                        {_scheme_watch_list.map((item) => (
                          <ListGroup.Item
                            key={item["_id"]}
                            style={{ marginBottom: 5 }}
                            action
                            variant="light"
                            className="wallet-address text-dark"
                          >
                            <div class="row">
                              <div class="col">
                                {/* <p> */}
                                <input
                          type="checkbox"
                          checked={isSelectedForRemoval[item["_id"]]}
                          onChange={() => this.handleToggleRemove(item["_id"])}
                        />

{` `}
{item["scheme_name_display_1"]}
{item["scheme_name_display_2"].length > 2 && (
  <span>
    {` `} {item["scheme_name_display_2"]}
  </span>
)}
{/* </p> */}
</div>
</div>

<Row>
<p>
<img
  className="fundlogo"
  src={item["logo_url"]}
/>
{"   "}
<span
  style={{
    fontSize: 12,
    backgroundColor: "#F6F3F1",
    padding: 4,
    paddingLeft: 8,
    paddingRight: 8,
    margin: 4,
    borderRadius: 4,
  }}
>
  {item["scheme_category"]}
</span>
<br/>
{isSelectedForRemoval[item["_id"]] && (
  <div className="col-auto ml-auto">
    <button
      type="button"
      className="btn btn-danger btn-sm"
      onClick={() => this.handleRemoveScheme(item["_id"])}
    >
      Remove
    </button>
  </div>
)}
</p>

</Row>

                            {item["one_year_percent"] &&
                              !isNaN(parseInt(item["one_year_percent"])) && (
                                <Row>
                                  <Row>
                                    <Col>
                                      <div class="row">
                                        <div class="col">
                                          <div
                                            style={{
                                              borderBottom: "1px solid",
                                              borderBottomColor: "lightgray",
                                              fontSize: 10,
                                              fontWeight: "bold",
                                              marginTop: 10,
                                              textAlign: "center",
                                            }}
                                          >
                                            Lumpsum (%)
                                          </div>
                                          <div class="row">
                                            {item["one_year_percent"] &&
                                              !isNaN(
                                                parseInt(
                                                  item["one_year_percent"]
                                                )
                                              ) && (
                                                <div
                                                  style={{
                                                    width: "30px",
                                                    textAlign: "center",
                                                  }}
                                                  class="col"
                                                >
                                                  <div
                                                    style={{
                                                      fontSize: 12,
                                                      color: "#7d7777",
                                                    }}
                                                  >
                                                    1Y
                                                  </div>
                                                  <span
                                                    style={{
                                                      fontSize: 13,
                                                      color:
                                                        item[
                                                          "one_year_percent"
                                                        ].substring(0, 1) ===
                                                          "-" || ""
                                                          ? "#f57362"
                                                          : "#03b45e",
                                                    }}
                                                  >
                                                    {item["one_year_percent"]}
                                                  </span>
                                                </div>
                                              )}
                                            {item["three_year_percent"] &&
                                              !isNaN(
                                                parseInt(
                                                  item["three_year_percent"]
                                                )
                                              ) && (
                                                <div
                                                  style={{
                                                    width: "30px",
                                                    textAlign: "center",
                                                  }}
                                                  class="col"
                                                >
                                                  <div
                                                    style={{
                                                      fontSize: 12,
                                                      color: "#7d7777",
                                                    }}
                                                  >
                                                    3Y
                                                  </div>
                                                  <span
                                                    style={{
                                                      fontSize: 13,
                                                      color:
                                                        item[
                                                          "three_year_percent"
                                                        ].substring(0, 1) ===
                                                          "-" || ""
                                                          ? "#f57362"
                                                          : "#03b45e",
                                                    }}
                                                  >
                                                    {item["three_year_percent"]}
                                                  </span>
                                                </div>
                                              )}
                                            {item["five_year_percent"] &&
                                              !isNaN(
                                                parseInt(
                                                  item["five_year_percent"]
                                                )
                                              ) && (
                                                <div
                                                  style={{
                                                    width: "30px",
                                                    textAlign: "center",
                                                  }}
                                                  class="col"
                                                >
                                                  <div
                                                    style={{
                                                      fontSize: 12,
                                                      color: "#7d7777",
                                                    }}
                                                  >
                                                    5Y
                                                  </div>
                                                  <span
                                                    style={{
                                                      fontSize: 13,
                                                      color:
                                                        item[
                                                          "five_year_percent"
                                                        ].substring(0, 1) ===
                                                          "-" || ""
                                                          ? "#f57362"
                                                          : "#03b45e",
                                                    }}
                                                  >
                                                    {item["five_year_percent"]}
                                                  </span>
                                                </div>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </Col>

                                    <Col>
                                      <div class="col">
                                        <div
                                          style={{
                                            fontFamily: "sans-serif",
                                            borderBottom: "1px solid",
                                            borderBottomColor: "lightgray",
                                            fontSize: 10,
                                            fontWeight: "bold",
                                            marginTop: 10,
                                            textAlign: "center",
                                          }}
                                        >
                                          {/* <span>
                                    SIP (Eg. &#8377;1000 monthly)
                                  </span> */}
                                          SIP (%)
                                        </div>
                                        <div class="row">
                                          {item["sip_returns_data"] &&
                                            item["sip_returns_data"][
                                              "1y_percent"
                                            ] &&
                                            !isNaN(
                                              parseInt(
                                                item["sip_returns_data"][
                                                  "1y_percent"
                                                ]
                                              )
                                            ) && (
                                              <div
                                                style={{
                                                  width: "30px",
                                                  textAlign: "center",
                                                }}
                                                class="col"
                                              >
                                                <div
                                                  style={{
                                                    fontSize: 12,
                                                    color: "#7d7777",
                                                  }}
                                                >
                                                  1Y
                                                </div>
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    color:
                                                      item["sip_returns_data"][
                                                        "1y_percent"
                                                      ].substring(0, 1) ===
                                                        "-" || ""
                                                        ? "#f57362"
                                                        : "#03b45e",
                                                  }}
                                                >
                                                  {
                                                    item["sip_returns_data"][
                                                      "1y_percent"
                                                    ]
                                                  }
                                                </span>
                                              </div>
                                            )}
                                          {item["sip_returns_data"] &&
                                            item["sip_returns_data"][
                                              "3y_percent"
                                            ] &&
                                            !isNaN(
                                              parseInt(
                                                item["sip_returns_data"][
                                                  "3y_percent"
                                                ]
                                              )
                                            ) && (
                                              <div
                                                style={{
                                                  width: "30px",
                                                  textAlign: "center",
                                                }}
                                                class="col"
                                              >
                                                <div
                                                  style={{
                                                    fontSize: 12,
                                                    color: "#7d7777",
                                                  }}
                                                >
                                                  3Y
                                                </div>
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    color:
                                                      item["sip_returns_data"][
                                                        "3y_percent"
                                                      ].substring(0, 1) ===
                                                        "-" || ""
                                                        ? "#f57362"
                                                        : "#03b45e",
                                                  }}
                                                >
                                                  {
                                                    item["sip_returns_data"][
                                                      "3y_percent"
                                                    ]
                                                  }
                                                </span>
                                              </div>
                                            )}
                                          {item["sip_returns_data"] &&
                                            item["sip_returns_data"][
                                              "5y_percent"
                                            ] &&
                                            !isNaN(
                                              parseInt(
                                                item["sip_returns_data"][
                                                  "5y_percent"
                                                ]
                                              )
                                            ) && (
                                              <div
                                                style={{
                                                  width: "30px",
                                                  textAlign: "center",
                                                }}
                                                class="col"
                                              >
                                                <div
                                                  style={{
                                                    fontSize: 12,
                                                    color: "#7d7777",
                                                  }}
                                                >
                                                  5Y
                                                </div>
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    color:
                                                      item["sip_returns_data"][
                                                        "5y_percent"
                                                      ].substring(0, 1) ===
                                                        "-" || ""
                                                        ? "#f57362"
                                                        : "#03b45e",
                                                  }}
                                                >
                                                  {
                                                    item["sip_returns_data"][
                                                      "5y_percent"
                                                    ]
                                                  }
                                                </span>
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </Row>
                              )}

                            <br />
                            <Row>
                              <Col xs={9}>
                                <span>
                                  <span
                                    style={{ fontSize: 15 }}
                                    class="text-dark"
                                  >
                                    {`  `}
                                    {item["one_month_percent"] ? (
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color:
                                            item["one_month_percent"].substring(
                                              0,
                                              1
                                            ) === "-" || ""
                                              ? "red"
                                              : "#03b45e",
                                        }}
                                      >
                                        {item["one_month_percent"]}%
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                  {`  `}
                                  <span style={{ fontSize: 15 }}>1M</span>
                                  {`   `}
                                </span>
                                {` `}
                                <span
                                  style={{
                                    fontSize: 12,
                                    color: "InfoText",
                                    backgroundColor:
                                      item["risk"]
                                        .toLowerCase()
                                        .indexOf("low") > -1
                                        ? "lightgreen"
                                        : item["risk"]
                                            .toLowerCase()
                                            .indexOf("high") > -1
                                        ? "#d96f76"
                                        : "#81a6d6",
                                    padding: 4,
                                    paddingLeft: 8,
                                    paddingRight: 8,
                                    margin: 4,
                                    borderRadius: 4,
                                  }}
                                >
                                  {item["risk"]}
                                </span>
                              </Col>
                              <Col>
                                {this.state.selected_watchlist[
                                  "subscription_enabled"
                                ] && (
                                  <button
                                    type="button"
                                    className="btn-sm btn-success"
                                    style={{
                                      marginTop: -5,
                                      color: "white",
                                      textAlign: "right",
                                    }}
                                    onClick={() => {
                                      // console.log(this.state.selected_watchlist);
                                      this.loadSchemeAdviseInfo(item);
                                    }}
                                  >
                                    Read
                                  </button>
                                )}
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    </div>
                  )}

                  {this.state.display_mode === "add watchlist" && (
                    <div>
                      <ListGroup>
                        {_list_scheme.map((item) => (
                          <ListGroup.Item
                            key={item["_id"]}
                            style={{ marginBottom: 5 }}
                            action
                            variant="light"
                            className="wallet-address text-dark"
                          >
                            {/* <Container fluid="md"> */}

                            <div class="row">
                              <div class="col">
                                {/* <p> */}
                                {` `}
                                {item["scheme_name_display_1"]}
                                {item["scheme_name_display_2"].length > 2 && (
                                  <span>
                                    {` `} {item["scheme_name_display_2"]}
                                  </span>
                                )}
                                {/* </p> */}
                              </div>
                            </div>

                            <Row>
                              <p>
                                <img
                                  className="fundlogo"
                                  src={item["logo_url"]}
                                />
                                {"   "}
                                <span
                                  style={{
                                    fontSize: 12,
                                    backgroundColor: "#F6F3F1",
                                    padding: 4,
                                    paddingLeft: 8,
                                    paddingRight: 8,
                                    margin: 4,
                                    borderRadius: 4,
                                  }}
                                >
                                  {item["scheme_category"]}
                                </span>
                              </p>
                            </Row>
                            <Row>
                              <Col>
                                <div class="row">
                                  <div class="col-8">
                                    <span
                                      style={{
                                        fontSize: 12,
                                        color: "InfoText",
                                        backgroundColor:
                                          item["risk"]
                                            .toLowerCase()
                                            .indexOf("low") > -1
                                            ? "lightgreen"
                                            : item["risk"]
                                                .toLowerCase()
                                                .indexOf("high") > -1
                                            ? "#d96f76"
                                            : "#81a6d6",
                                        padding: 4,
                                        paddingLeft: 8,
                                        paddingRight: 8,
                                        margin: 4,
                                        borderRadius: 4,
                                      }}
                                    >
                                      {item["risk"]}
                                    </span>
                                    {/* </div>
                                  <div class="col"> */}
                                    <span>
                                      <span
                                        style={{ fontSize: 15 }}
                                        class="text-dark"
                                      >
                                        {`  `}
                                        {item["one_month_percent"] ? (
                                          <span
                                            style={{
                                              fontSize: 15,
                                              color:
                                                item[
                                                  "one_month_percent"
                                                ].substring(0, 1) === "-" || ""
                                                  ? "red"
                                                  : "#03b45e",
                                            }}
                                          >
                                            {item["one_month_percent"]}%
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </span>
                                      {`  `}
                                      <span style={{ fontSize: 15 }}>1M</span>
                                    </span>
                                  </div>
                                  <div class="col">
                                    <label
                                      style={{ fontSize: 17, color: "#5e72e4" }}
                                    >
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicCheckbox"
                                      >
                                        <Form.Check
                                          type="checkbox"
                                          label="Select"
                                          onChange={(event) => {
                                            this.handleSchemeSelect(item);
                                          }}
                                          defaultChecked={false}
                                          // disabled={this.state.is_redeem_button_clicked}
                                        />
                                      </Form.Group>
                                    </label>
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            {item["one_year_percent"] &&
                              !isNaN(parseInt(item["one_year_percent"])) && (
                                <Row>
                                  <Row>
                                    <Col>
                                      <div class="row">
                                        <div class="col">
                                          <div
                                            style={{
                                              borderBottom: "1px solid",
                                              borderBottomColor: "lightgray",
                                              fontSize: 10,
                                              fontWeight: "bold",
                                              marginTop: 10,
                                              textAlign: "center",
                                            }}
                                          >
                                            Lumpsum (%)
                                          </div>
                                          <div class="row">
                                            {item["one_year_percent"] &&
                                              !isNaN(
                                                parseInt(
                                                  item["one_year_percent"]
                                                )
                                              ) && (
                                                <div
                                                  style={{
                                                    width: "30px",
                                                    textAlign: "center",
                                                  }}
                                                  class="col"
                                                >
                                                  <div
                                                    style={{
                                                      fontSize: 12,
                                                      color: "#7d7777",
                                                    }}
                                                  >
                                                    1Y
                                                  </div>
                                                  <span
                                                    style={{
                                                      fontSize: 13,
                                                      color:
                                                        item[
                                                          "one_year_percent"
                                                        ].substring(0, 1) ===
                                                          "-" || ""
                                                          ? "#f57362"
                                                          : "#03b45e",
                                                    }}
                                                  >
                                                    {item["one_year_percent"]}
                                                  </span>
                                                </div>
                                              )}
                                            {item["three_year_percent"] &&
                                              !isNaN(
                                                parseInt(
                                                  item["three_year_percent"]
                                                )
                                              ) && (
                                                <div
                                                  style={{
                                                    width: "30px",
                                                    textAlign: "center",
                                                  }}
                                                  class="col"
                                                >
                                                  <div
                                                    style={{
                                                      fontSize: 12,
                                                      color: "#7d7777",
                                                    }}
                                                  >
                                                    3Y
                                                  </div>
                                                  <span
                                                    style={{
                                                      fontSize: 13,
                                                      color:
                                                        item[
                                                          "three_year_percent"
                                                        ].substring(0, 1) ===
                                                          "-" || ""
                                                          ? "#f57362"
                                                          : "#03b45e",
                                                    }}
                                                  >
                                                    {item["three_year_percent"]}
                                                  </span>
                                                </div>
                                              )}
                                            {item["five_year_percent"] &&
                                              !isNaN(
                                                parseInt(
                                                  item["five_year_percent"]
                                                )
                                              ) && (
                                                <div
                                                  style={{
                                                    width: "30px",
                                                    textAlign: "center",
                                                  }}
                                                  class="col"
                                                >
                                                  <div
                                                    style={{
                                                      fontSize: 12,
                                                      color: "#7d7777",
                                                    }}
                                                  >
                                                    5Y
                                                  </div>
                                                  <span
                                                    style={{
                                                      fontSize: 13,
                                                      color:
                                                        item[
                                                          "five_year_percent"
                                                        ].substring(0, 1) ===
                                                          "-" || ""
                                                          ? "#f57362"
                                                          : "#03b45e",
                                                    }}
                                                  >
                                                    {item["five_year_percent"]}
                                                  </span>
                                                </div>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </Col>

                                    <Col>
                                      <div class="col">
                                        <div
                                          style={{
                                            fontFamily: "sans-serif",
                                            borderBottom: "1px solid",
                                            borderBottomColor: "lightgray",
                                            fontSize: 10,
                                            fontWeight: "bold",
                                            marginTop: 10,
                                            textAlign: "center",
                                          }}
                                        >
                                          {/* <span>
                                    SIP (Eg. &#8377;1000 monthly)
                                  </span> */}
                                          SIP (%)
                                        </div>
                                        <div class="row">
                                          {item["sip_returns_data"] &&
                                            item["sip_returns_data"][
                                              "1y_percent"
                                            ] &&
                                            !isNaN(
                                              parseInt(
                                                item["sip_returns_data"][
                                                  "1y_percent"
                                                ]
                                              )
                                            ) && (
                                              <div
                                                style={{
                                                  width: "30px",
                                                  textAlign: "center",
                                                }}
                                                class="col"
                                              >
                                                <div
                                                  style={{
                                                    fontSize: 12,
                                                    color: "#7d7777",
                                                  }}
                                                >
                                                  1Y
                                                </div>
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    color:
                                                      item["sip_returns_data"][
                                                        "1y_percent"
                                                      ].substring(0, 1) ===
                                                        "-" || ""
                                                        ? "#f57362"
                                                        : "#03b45e",
                                                  }}
                                                >
                                                  {
                                                    item["sip_returns_data"][
                                                      "1y_percent"
                                                    ]
                                                  }
                                                </span>
                                              </div>
                                            )}
                                          {item["sip_returns_data"] &&
                                            item["sip_returns_data"][
                                              "3y_percent"
                                            ] &&
                                            !isNaN(
                                              parseInt(
                                                item["sip_returns_data"][
                                                  "3y_percent"
                                                ]
                                              )
                                            ) && (
                                              <div
                                                style={{
                                                  width: "30px",
                                                  textAlign: "center",
                                                }}
                                                class="col"
                                              >
                                                <div
                                                  style={{
                                                    fontSize: 12,
                                                    color: "#7d7777",
                                                  }}
                                                >
                                                  3Y
                                                </div>
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    color:
                                                      item["sip_returns_data"][
                                                        "3y_percent"
                                                      ].substring(0, 1) ===
                                                        "-" || ""
                                                        ? "#f57362"
                                                        : "#03b45e",
                                                  }}
                                                >
                                                  {
                                                    item["sip_returns_data"][
                                                      "3y_percent"
                                                    ]
                                                  }
                                                </span>
                                              </div>
                                            )}
                                          {item["sip_returns_data"] &&
                                            item["sip_returns_data"][
                                              "5y_percent"
                                            ] &&
                                            !isNaN(
                                              parseInt(
                                                item["sip_returns_data"][
                                                  "5y_percent"
                                                ]
                                              )
                                            ) && (
                                              <div
                                                style={{
                                                  width: "30px",
                                                  textAlign: "center",
                                                }}
                                                class="col"
                                              >
                                                <div
                                                  style={{
                                                    fontSize: 12,
                                                    color: "#7d7777",
                                                  }}
                                                >
                                                  5Y
                                                </div>
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    color:
                                                      item["sip_returns_data"][
                                                        "5y_percent"
                                                      ].substring(0, 1) ===
                                                        "-" || ""
                                                        ? "#f57362"
                                                        : "#03b45e",
                                                  }}
                                                >
                                                  {
                                                    item["sip_returns_data"][
                                                      "5y_percent"
                                                    ]
                                                  }
                                                </span>
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </Row>
                              )}
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    </div>
                  )}

                  {this.state.display_mode === "show scheme advisorlist" && (
                    <div className="form-group">
                      {this.state.list_scheme_advisors.map((item, index) => (
                        <div style={{ marginTop: 10 }}>
                          <Row>
                            <Col xs={9}>
                              <Form.Check
                                type="radio"
                                label={
                                  <span style={{ fontFamily: "sans-serif" }}>
                                    {item["label"]}
                                  </span>
                                }
                                name="scheme_advisor"
                                id={item["_id"]}
                                value={item["value"]}
                                checked={
                                  this.state.selected_scheme_advisor_id ===
                                  item["id"]
                                }
                                onChange={(e) =>
                                  this.handleRadioChange(
                                    e,
                                    item,
                                    "selected_scheme_advisor_id",
                                    "selected_scheme_advisor_info"
                                  )
                                }
                                key={`advisor_` + index}
                              />
                              <hr />
                            </Col>
                            <Col xs={3}>
                              <button
                                type="button"
                                className="btn-sm btn-primary"
                                style={{ marginTop: "-15px", color: "white" }}
                                onClick={() => {
                                  this.setState({
                                    selected_scheme_advisor_id: item["id"],
                                    selected_scheme_advisor_info: item,
                                    show_modal_scheme_advisor: true,
                                  });
                                }}
                              >
                                Details
                              </button>
                            </Col>
                          </Row>
                        </div>
                      ))}
                    </div>
                  )}

                  {this.state.display_mode === "read scheme advise" &&
                    this.state.modal_scheme_advise_information["risk"] && (
                      <div className="row">
                        <Row>
                          <Col xs={3}>Advisor</Col>
                          <Col>
                            {`: `}
                            {
                              this.state.selected_watchlist[
                                "scheme_advisor_name"
                              ]
                            }
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col xs={3}>Updated</Col>
                          <Col>
                            {`: `}
                            {moment(
                              this.state.modal_scheme_advise_information[
                                "modified_date"
                              ]
                            ).format("MMMM Do YYYY, h:mm:ss a")}
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col>
                            {
                              this.state.modal_scheme_advise_information[
                                "scheme_info"
                              ]["scheme_name"]
                            }
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            {
                              this.state.modal_scheme_advise_information[
                                "scheme_info"
                              ]["scheme_category"]
                            }
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col xs={6}>Risk</Col>
                          <Col>
                            {`: `}
                            {this.state.modal_scheme_advise_information["risk"]}
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col xs={6}>Ratings</Col>
                          <Col>
                            {`: `}
                            {
                              this.state.modal_scheme_advise_information[
                                "ratings"
                              ]
                            }
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col xs={6}>Investment Option</Col>
                          <Col>
                            {`: `}
                            {
                              this.state.modal_scheme_advise_information[
                                "invest_option"
                              ]
                            }
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col xs={6}>Investment Period</Col>
                          <Col>
                            {`: `}
                            {
                              this.state.modal_scheme_advise_information[
                                "invest_period"
                              ]
                            }{" "}
                            {` Months`}
                          </Col>
                        </Row>
                        <br />
                        <p></p>
                        <Row>
                          <Col
                            xs={5}
                            style={{
                              borderBottom: "1px solid black",
                              borderRadius: "2px",
                            }}
                          >
                            Opinion/Advise
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col>
                            {
                              this.state.modal_scheme_advise_information[
                                "recommendations"
                              ]
                            }
                          </Col>
                        </Row>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>

          <div className="content-body" style={{ marginTop: 90 }}>
            {this.state.show_modal_add_watchlist && (
              <Modal
                show={this.state.show_modal_add_watchlist}
                onHide={() =>
                  this.setState({
                    show_modal_add_watchlist: false,
                  })
                }
                centered
                keyboard={false}
                fullscreen={false}
                backdrop="static"
              >
                <Modal.Header>
                  <p>ADD NEW WATCH LIST</p>
                </Modal.Header>
                <Modal.Body>
                  <p>Type Your Preferred Name</p>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.modal_watchlist_name}
                      // disabled={

                      // }
                      maxLength="30"
                      onChange={(event) =>
                        // this.validEmail(event)
                        {
                          const targetValue = event.target.value; //.replace(/\s/g, '');
                          this.setState({
                            modal_watchlist_name: targetValue,
                            // modal_watchlist_confirm_enabled: !new RegExp(/\w([-_+.']*\w+)+@(\w(-*\w+)+\.)+[a-zA-Z]{2,4}$/gm).test(targetValue)
                          });
                        }
                      }
                    />
                  </div>
                  <p style={{ color: "red" }}>
                    {this.state["modal_pin_confirm_dialog_error_messagetext"]}
                  </p>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="primary"
                    disabled={this.state.modal_watchlist_confirm_enabled}
                    onClick={() => {
                      this.submitWatchList("add");
                    }}
                  >
                    Submit
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() =>
                      this.setState({
                        show_modal_add_watchlist: false,
                        modal_watchlist_name: "",
                      })
                    }
                  >
                    Cancel
                  </Button>
                </Modal.Footer>
              </Modal>
            )}

            {this.state.show_modal_scheme_advisor && (
              <Modal
                show={this.state.show_modal_scheme_advisor}
                onHide={() =>
                  this.setState({
                    show_modal_scheme_advisor: false,
                  })
                }
                centered
                keyboard={false}
                fullscreen={true}
                backdrop="static"
              >
                <Modal.Header>
                  <p>{this.state.selected_scheme_advisor_info["label"]}</p>
                </Modal.Header>
                <Modal.Body>
                  <div className="form-group">
                    <div class="row">
                      <div class="col">
                        {this.state.selected_scheme_advisor_info["description"]}
                      </div>
                    </div>
                  </div>
                  <p style={{ color: "red" }}>
                    {this.state["modal_pin_confirm_dialog_error_messagetext"]}
                  </p>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="primary"
                    disabled={this.state.selected_scheme_advisor_id.length < 1}
                    onClick={() => {
                      this.setState({
                        show_modal_scheme_advisor: false,
                        show_modal_advise_pricing: true,
                      });
                    }}
                  >
                    Continue
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() =>
                      this.setState({
                        show_modal_scheme_advisor: false,
                        modal_watchlist_name: "",
                      })
                    }
                  >
                    Cancel
                  </Button>
                </Modal.Footer>
              </Modal>
            )}

            {this.state.show_modal_advise_pricing && (
              <Modal
                show={this.state.show_modal_advise_pricing}
                onHide={() =>
                  this.setState({
                    show_modal_advise_pricing: false,
                  })
                }
                centered
                keyboard={false}
                fullscreen={false}
                backdrop="static"
              >
                <Modal.Header>
                  <p>Choose your price plan</p>
                </Modal.Header>
                <Modal.Body>
                  {this.state.isloading && (
                    <Spinner
                      animation="border"
                      variant="dark"
                      style={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        zIndex: 899,
                      }}
                    />
                  )}

                  <div className="form-group">
                    {this.state.list_pricing.map((item, index) => (
                      <div class="row">
                        <div class="col">
                          <Form.Check
                            type="radio"
                            label={
                              <span style={{ fontFamily: "sans-serif" }}>
                                {item["label"]}
                              </span>
                            }
                            name="pricing_plan"
                            id={item["id"]}
                            value={item["value"]}
                            checked={
                              this.state.selected_pricing_id === item["id"]
                            }
                            onChange={(e) =>
                              this.handleRadioChange(
                                e,
                                item,
                                "selected_pricing_id",
                                null
                              )
                            }
                            key={`pricing_` + index}
                          />
                          <hr />
                        </div>
                      </div>
                    ))}
                  </div>
                  <p style={{ color: "red" }}>
                    {this.state["modal_pin_confirm_dialog_error_messagetext"]}
                  </p>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="primary"
                    disabled={this.state.selected_pricing_id.length < 1}
                    onClick={() => {
                      this.displayRazorpay();
                    }}
                  >
                    Continue
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() =>
                      this.setState({
                        // show_modal_scheme_advisor: true,
                        show_modal_advise_pricing: false,
                      })
                    }
                  >
                    Cancel
                  </Button>
                </Modal.Footer>
              </Modal>
            )}

            {this.state.show_modal_scheme_advise && (
              <Modal
                show={this.state.show_modal_scheme_advise}
                onHide={() =>
                  this.setState({
                    show_modal_scheme_advise: false,
                  })
                }
                centered
                keyboard={false}
                fullscreen={true}
                backdrop="static"
              >
                <Modal.Header>
                  <u>Expert Advise</u>
                  <br />
                </Modal.Header>
                <Modal.Body>
                  {this.state.modal_scheme_advise_information["risk"] && (
                    <div>
                      <Row>
                        <Col>
                          <p>
                            {
                              this.state.modal_scheme_advise_information[
                                "scheme_info"
                              ]["scheme_name"]
                            }
                          </p>
                          <p>
                            {
                              this.state.modal_scheme_advise_information[
                                "scheme_info"
                              ]["scheme_category"]
                            }
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={2}>Risk :</Col>
                        <Col>
                          {this.state.modal_scheme_advise_information["risk"]}
                        </Col>
                      </Row>
                    </div>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="primary"
                    onClick={() =>
                      this.setState({
                        modal_scheme_advise_information: {},
                        show_modal_scheme_advise: false,
                      })
                    }
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            )}

            {!this.state.loading &&
              this.state.submit_watchlist_enabled &&
              this.state.display_mode === "add watchlist" && (
                <Fab
                  style={{ bottom: 27, left: 275, transform: "scale(0.82)" }}
                  alwaysShowTitle={true}
                  mainButtonStyles={{
                    backgroundColor: "#5e72e4",
                  }}
                  icon={<i className="fa fa-floppy-o fa-lg"></i>}
                  onClick={() => {
                    this.setState({
                      show_modal_add_watchlist: true,
                    });
                  }}
                ></Fab>
              )}

            {!this.state.isloading &&
              this.state.display_mode === "add watchlist" && (
                <Fab
                  style={{ bottom: 22, left: -15, transform: "scale(0.82)" }}
                  alwaysShowTitle={true}
                  mainButtonStyles={{
                    backgroundColor: "#d45950",
                  }}
                  icon={<i className="fa fa-undo fa-lg"></i>}
                  onClick={() => {
                    this.setState({
                      display_mode: "view watchlist",
                    });
                  }}
                ></Fab>
              )}

            {!this.state.loading &&
              this.state.selected_scheme_advisor_id.length > 7 &&
              this.state.display_mode === "show scheme advisorlist" && (
                <Fab
                  style={{ bottom: 22, left: -15, transform: "scale(0.8)" }}
                  alwaysShowTitle={true}
                  mainButtonStyles={{
                    backgroundColor: "#d45950",
                  }}
                  icon={<i className="fa fa-arrow-left  fa-lg"></i>}
                  onClick={() =>
                    this.setState({
                      display_mode: "view watchlist",
                    })
                  }
                ></Fab>
              )}

            {!this.state.loading &&
              this.state.selected_scheme_advisor_id.length > 7 &&
              this.state.display_mode === "show scheme advisorlist" && (
                <Fab
                  style={{ bottom: 22, right: -18, transform: "scale(0.8)" }}
                  alwaysShowTitle={true}
                  mainButtonStyles={{
                    backgroundColor: "#2ea34d",
                  }}
                  icon={<i className="fa fa-arrow-right  fa-lg"></i>}
                  onClick={() => {
                    this.setState({
                      show_modal_scheme_advisor: false,
                      show_modal_advise_pricing: true,
                    });
                  }}
                ></Fab>
              )}

            {this.state.display_mode === "read scheme advise" && (
              <Fab
                style={{ top: 50, left: -10, transform: "scale(0.8)" }}
                alwaysShowTitle={true}
                mainButtonStyles={{
                  backgroundColor: "#d45950",
                }}
                icon={<i className="fa fa-times fa-lg"></i>}
                onClick={() =>
                  this.setState({ display_mode: "view watchlist" })
                }
              ></Fab>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  result: state.master.result,
  scheme_advise: state.general.general_result,
  order_result: state.order.order_result,
  payment_result: state.payment.payment_result,
});

export default connect(mapStateToProps, {
  listWatchSchemes,
  addWatchList,
  modifyWatchList,
  createRazorPayOrder,
  loadSchemeAdviseInfo,
})(SchemeWatchList);
