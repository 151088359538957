import React, { } from 'react';
import Header3 from '../layout/header3';
import Bottom from '../element/bottom';
import Footer1 from '../layout/footer1';



function PrivacyPolicy() {

    return (
        <>
            <Header3 />
            <div className="terms_condition" style={{marginTop:180}}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-9">
                            <div className="terms_condition-content">
                                <div className="terms_condition-text">
                              
                                    <h3>Your privacy is important to us: </h3>
                                    <p>Therefore, we guarantee that:</p>
                                    <ul>
                                    <li  style={{display:'flex',listStyle:'none'}}>
                                    <i className="fa fa-circle"  style={{marginRight:10,fontSize:10}}></i>
                                            <p style={{marginTop:-5}}>
If you do provide information about yourself, such as Address, E-mail, Telephone and Fax numbers etc.

</p>
                                        </li>
                                        <li  style={{display:'flex',listStyle:'none'}}>
                                        <i className="fa fa-circle"  style={{marginRight:10,fontSize:10}}></i>
                                            <p style={{marginTop:-5}}>We will not Share, Sell or Divulge it to external agencies unless we have informed you, been Authorized by you, or are required to do so by law.
</p>
                                        </li>
                                        <li  style={{display:'flex',listStyle:'none'}}>
                                        <i className="fa fa-circle"  style={{marginRight:10,fontSize:10}}></i>
                                            <p style={{marginTop:-5}}>
We will maintain this information, as well as your transactions, Strictly Confidential.</p>
                                        </li>
                                    </ul>
                                </div>


                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Bottom />
            {/* <Footer1 /> */}
        </>
    )
}

export default PrivacyPolicy;