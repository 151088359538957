import React, { } from 'react';
import { Link } from 'react-router-dom';
import { Button, Spinner, Modal } from 'react-bootstrap';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { verifyMpin, send2FactorOtp } from '../../../jsx/actions/auth';
import { Redirect } from 'react-router-dom';
// import Background from '../../../images/background/bg-14.jpg';
import OTPInput, { ResendOTP } from "otp-input-react";

import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";

class MpinInformation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            otp: '',
            pin: '',
            confirm_pin: '',
            data: '',
            verify_pin: true,
            is_otp_enabled: false,
            mobile_otp: '',
            twofactor_token_id: '',
            isloading: this.props.loading,
            show_popup: false,
            popup_messageText: '',
            errorMessage: ''
        };

    }

    componentDidMount() {

    }

    onSetPin = (request_mode) => {

        this.setState({ isloading: true })
        let body_parameters = {
            "login_role": "customer",
            "request_from": "pin",
            "request_mode": request_mode,
            "pin_hint": this.state.confirm_pin,
            "mobile_otp": this.state.mobile_otp,
            "email_otp": '',
            twofactor_token_id: this.state.twofactor_token_id
        }

        this.props.verifyMpin(body_parameters).then(response => {

            if(this.props.email_otp_result && this.props.email_otp_result['code'] === '000') 
            {                
                this.setState({
                    isloading: false,
                    show_popup: true,
                    popup_messageText:  this.props.email_otp_result['messageText'],
                    pin: '',
                    confirm_pin: '',
                    is_otp_enabled: false,
                    mobile_otp: '',
                    twofactor_token_id: '',
                });
            }
            else                
                this.setState({
                    isloading: false,
                    show_popup: true,
                    popup_messageText:  this.props.email_otp_result['messageText']
                });

            // this.props.history.push('/' + this.props.email_otp_result['navigateScreen'] + '/');
        })
            .catch(err => {
                console.log('errrrrrr')
                console.log(err);
            });


    };

    send2FactorOtp = () => {

        this.setState({ isloading: true })
        let body_parameters = {
            login_role: 'customer',
            client_info: {
                "request_action": 'mpin change'
            }
        }

        this.props.send2FactorOtp(body_parameters).then(response => {

            var _response = this.props.login_otp_result || {};

            if (_response['code'] === '000') {

                this.setState({
                    isloading: false,
                    is_otp_enabled: true,
                    twofactor_token_id: _response['twofactor_id']
                });

            }
            else
                alert(_response['messageText']);
        })
            .catch(err => {
                console.log(err);
            });


    };

    render() {

        const renderButton = buttonProps => {
            return (
                <div>
                    <button onClick={send2FactorOtp} {...buttonProps}
                        class={buttonProps.remainingTime !== 0 ? `btn btn-outline-secondary` : `btn btn-outline-success`}>
                        {`Resend OTP`}
                    </button>
                    <div style={{ textAlign: 'left' }}>
                        {buttonProps.remainingTime !== 0
                            ? <span>Didn't receive the otp ? You can resend otp in
                                <span style={{ fontWeight: 'bold', color: 'green' }}>{' '}{buttonProps.remainingTime}</span> secs.</span>
                            : ``}
                    </div>
                </div>
            );
        };
        const renderTime = () => React.Fragment;

        return (
            <>
                <Header1 />
                <Sidebar />

                <div className="content-body" style={{ marginTop: 105 }}>
                    <div className="container">
                        <div className="row">
                            <p>Set up your 4 digit mpin for two factor security</p>
                            <div className="col-lg-12">
                                <div className="card">
                                    {this.state.isloading && (
                                        <Spinner
                                            animation="border"
                                            variant="dark"
                                            style={{ position: "fixed", top: "50%", left: "50%" }}
                                        />
                                    )}

                                    <div className="card-body">

                                        <form action="#">

                                            <div class="row">
                                                <div class="col">
                                                    <div className="form-group">
                                                        <p /*className="text-center"*/>Enter New PIN</p>
                                                        <OTPInput
                                                            value={this.state.pin}
                                                            onChange={event => this.setState({ pin: event })}
                                                            autoFocus
                                                            OTPLength={4}
                                                            otpType="number"
                                                            disabled={this.state.is_otp_enabled || this.state.isloading}
                                                            secure
                                                            isInputNum={true}
                                                        />

                                                    </div>
                                                    <div className="form-group">
                                                        <label className="text-danger">{this.state.errorMessage}</label>
                                                    </div>
                                                    <div className="form-group">
                                                        <p /*className="text-center"*/>Re-Enter New PIN</p>
                                                        <OTPInput
                                                            value={this.state.confirm_pin}
                                                            onChange={event => this.setState({ confirm_pin: event })}
                                                            OTPLength={4}
                                                            otpType="number"
                                                            disabled={this.state.is_otp_enabled || this.state.isloading}
                                                            // secure
                                                            isInputNum={true}
                                                        />
                                                    </div>
                                                    <br />
                                                    <div >
                                                        <Button
                                                            className="btn btn-success btn-block"
                                                            variant="success"
                                                            disabled={
                                                                this.state.pin.length < 4 || this.state.pin.length > 6 ||
                                                                (this.state.pin.trim() !== this.state.confirm_pin.trim()) ||
                                                                this.state.is_otp_enabled ||
                                                                this.state.isloading
                                                            }
                                                            onClick={() => {
                                                                this.send2FactorOtp()
                                                            }}
                                                            style={{ width: 200 }}
                                                        >Submit</Button>
                                                    </div>
                                                </div>                                                
                                            </div>
                                            {this.state.is_otp_enabled &&
                                                <div class="row">
                                                    <div class="col">
                                                        <div className="form-group">
                                                            <p /*className="text-center"*/>Enter OTP received on your registered mobile number</p>
                                                            <OTPInput
                                                                value={this.state.mobile_otp}
                                                                onChange={event => this.setState({ mobile_otp: event })}
                                                                autoFocus
                                                                OTPLength={6}
                                                                otpType="number"
                                                                // secure
                                                                isInputNum={true}
                                                                disabled={this.state.isloading}
                                                            />
                                                        </div>
                                                        <br />
                                                        <div className="text-left">
                                                            <ResendOTP maxTime={120} renderButton={renderButton} style={{ color: 'black', marginTop: 20 }} renderTime={renderTime}
                                                                onResendClick={send2FactorOtp}
                                                            />
                                                            <hr />
                                                        </div>
                                                        <br />
                                                        <div className="form-group">
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <Button
                                                                        className="btn btn-success btn-block"
                                                                        variant="success"
                                                                        disabled={
                                                                            this.state.pin.length !== 4 || this.state.confirm_pin.length !== 4 ||
                                                                            (this.state.pin.trim() !== this.state.confirm_pin.trim()) ||
                                                                            this.state.mobile_otp.length !== 6 ||
                                                                            this.state.isloading
                                                                        }
                                                                        onClick={() => {
                                                                            this.onSetPin("verifypin")
                                                                        }}>
                                                                        Confirm
                                                                    </Button>
                                                                </div>
                                                                <div class="col-6">
                                                                    <Button
                                                                        className="btn btn-success btn-block"
                                                                        variant="success"
                                                                        disabled={this.state.isloading}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                isloading: false,
                                                                                pin: '',
                                                                                confirm_pin: '',
                                                                                is_otp_enabled: false,
                                                                                mobile_otp: '',
                                                                                twofactor_token_id: ''
                                                                            });
                                                                        }}>Reset</Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                        </form>

                                    </div>

                                    {this.state.show_popup && (
                                        <Modal
                                        show={
                                            this.state.show_popup
                                        }
                                        onHide={() =>
                                            this.setState({
                                                show_popup: false,
                                                popup_messageText: ''
                                            })
                                        }
                                        >
                                        <Modal.Header></Modal.Header>
                                        <Modal.Body>
                                            <p>
                                            {this.state.popup_messageText}
                                            </p>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button
                                            variant="primary"
                                            onClick={() =>
                                                this.setState({
                                                    show_popup: false,
                                                    popup_messageText: ''
                                                })
                                            }
                                            >
                                            Close
                                            </Button>
                                        </Modal.Footer>
                                        </Modal>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );

    }

}

MpinInformation.propTypes = {
    verifyMpin: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,

};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    email_otp_result: state.auth.email_otp_result,
    login_otp_result: state.auth.login_otp_result
});
export default connect(mapStateToProps, { send2FactorOtp, verifyMpin })(MpinInformation);
