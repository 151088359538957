import React from "react";
import { Button, Form, Spinner, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import Header1 from "../../layout/header1";
import { v4 as uuidv4 } from "uuid";
import Webcam from "react-webcam";
import { verifyAccount, loadStageInfo } from "../../actions/verify";
import Sidebar from "../../layout/sidebar";
import { Link, Redirect } from "react-router-dom";
import { Camera } from "react-camera-pro";
import { height } from "@mui/system";

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "environment",
  screenshotQuality: 1,
};

class ImageVerification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file_photograph: null,
      src_file_photograph: null,
      file_photograph_tiff: null,
      is_confirm_chk_checked: false,
      isloading: false,
    };

    // Camera.requestCameraPermissionsAsync();
  }

  setRef = (webcam) => {
    this.webcam = webcam;
  };

  setCameraRef = (cam) => {
    this.cam = cam;
  };

  capture = () => {
    const imageSrc = this.webcam.getScreenshot();
    let file_photograph = this.dataURLtoFile(
      imageSrc,
      "photograph" /*uuidv4()*/
    );

    this.setState({
      file_photograph: file_photograph,
      src_file_photograph: imageSrc,
    });
  };

  Recapture = () => {
    this.setState({ src_file_photograph: null });
  };

  captureCam = () => {

    const imageSrc = this.cam.takePhoto()
    let file_photograph = this.dataURLtoFile(
      imageSrc,
      "photograph" /*uuidv4()*/
    );

    this.setState({
      file_photograph: file_photograph,
      src_file_photograph: imageSrc,
    });
  };

  recaptureCam = () => {
    this.setState({ src_file_photograph: null });
  };

 dataURLtoFile (dataurl, filename) {
    this.setState({ isloading: true });
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    this.setState({
      file_photograph_tiff: new File([u8arr], filename + "_photograph.tiff", {
        type: mime.split("/")[0] + "/tiff",
      })
    });
    this.setState({ isloading: false });
    return new File([u8arr], filename + "." + mime.split("/")[1], {
      type: mime,
    });
  }

  handleConfirmCheck = (event) => {
    this.setState({
      is_confirm_chk_checked: !this.state.is_confirm_chk_checked,
    });
  };

  verifyAccount = () => {

    this.setState({ isloading: true });
    let formData = new FormData();

    formData.append("upload_facephoto", this.state.file_photograph);
    formData.append("upload_facephoto", this.state.file_photograph_tiff);

    let parameters = this.props.values;

    let _nomineeUpload =
      !parameters["nominee_information"] ||
      !parameters["nominee_information"]["attachment_id"] ||
      parameters["nominee_information"]["attachment_id"].length < 4;
    
    const arrMandatoryParameters = [
      'nominee_information', 'address_information',
      "gender", "marital_status", "email", "occupation", "source_of_wealth", "annual_income",
      "is_income_outside_india", "is_political_india", "is_political_foreign"
    ];

    for (var key of Object.keys(parameters)) {

      if (
        (!_nomineeUpload && key === "file_nominee") ||
        key === "file_photograph"
      ) {
        if (parameters[key])
          formData.append("upload_file", parameters[key]);
      } else {

        if (arrMandatoryParameters.includes(key.toLocaleLowerCase())) {

          if (typeof parameters[key] === "object")
            formData.append(key, JSON.stringify(parameters[key]));
          else {
            if (parameters[key])
              formData.append(key, parameters[key]);
          }
        }
      }

    }

    formData.append("id_type", "personal");
    formData.append("folder_name", "personal");
    formData.append("attachment_category", "personal");
    formData.append("verify_stage", "personal");

    // this.props.verifyAccount(formData, (response) => {
    this.props.verifyAccount(formData)
    .then(response => {

      this.setState({ isloading: false });
      let _personal_result = this.props.personal_result;

      if (_personal_result['code'] === '000') {

        if (
          _personal_result && _personal_result["personal_stage_verified"]
        ) {

          this.props._props.history.push({
            pathname: "/" + _personal_result["navigateScreen"]
          });
          // this.props._props.nextStep();

        }
        else {
          alert(_personal_result['messageText']);
        }

      }
      else
        alert(_personal_result['messageText']);
    })
    .catch(err => {
      alert(err);
    });
    
  };

  render() {

    // if (
    //   this.props.personal_result &&
    //   this.props.personal_result["personal_stage_verified"]
    // )
    //   return <Redirect to={this.props.personal_result["navigateScreen"]} />;

    return (
      <>
        <Header1 />
        <Sidebar />
        <div id="navbar-personalinfo-container">
          <div id="navbar" >
            <div className="card" style={{ backgroundColor: '#f8f8fb' }}>
              <div className="row">
                <h5 style={{ textAlign: 'center', marginTop: 5 }}>
                  Verify Your Identity
                </h5>
              </div>
            </div>
          </div>
        </div>

        <div
          className="authincation section-padding"
          style={{ marginTop: -5 }}
        >
          <div className="content-body">
            <div className="container">
              <div class="row">
                <div class="col-xl-12">

                  <div className="card" style={{ marginTop: -15 }}>

                    {this.state.isloading && (
                      <Spinner
                        animation="border"
                        variant="dark"
                        style={{
                          position: "fixed",
                          top: "50%",
                          left: "50%",
                        }}
                      />
                    )}

                    {(this.state.src_file_photograph || '').length > 77 ?
                      <div class="row" >
                        <img src={this.state.src_file_photograph}
                          style={{ height: 320, width: 360 }} />
                      </div>
                      :
                      <div class="row" style={{ marginBottom: 260 }}>
                        <Camera ref={this.setCameraRef} height={600} width={600} />
                      </div>
                    }
                  </div>

                  {/* <div className="card" >
                    <div>
                      <Row>
                        <Col >
                          {this.state.src_file_photograph ? (
                            <img src={this.state.src_file_photograph} style={{ marginBottom: 20 }} />
                          ) : (
                            <Webcam
                              audio={false}
                              height={500}
                              ref={this.setRef}
                              screenshotFormat="image/jpeg"
                              width={500}
                              videoConstraints={videoConstraints}
                            />
                          )}{" "}
                        </Col>
                      </Row>


                    </div>

                    <div class="row">
                      <div
                        class="col-4"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          onClick={this.props.prevStep}
                          type="submit"
                          variant="secondary"
                          color="primary"
                        >
                          Previous
                        </Button>

                      </div>
                      <div class="col" style={{textAlign: 'right'}}>
                        {this.state.src_file_photograph &&
                          this.state.src_file_photograph.length > 77 ? (
                          <button
                            onClick={this.Recapture}
                            className="btn btn-success pl-5 pr-5"
                          >
                            Re-take
                          </button>
                        ) : (
                          <button
                            onClick={this.capture}
                            className="btn btn-success pl-5 pr-5"
                          >
                            Capture photo
                          </button>
                        )}

                      </div>
                      
                    </div>

                  </div> */}
                  <hr />
                  <Row>
                    <Col xs={5}>
                      <Button
                        onClick={this.props.prevStep}
                        type="submit"
                        variant="secondary"
                        color="primary"
                      >
                        Previous
                      </Button>
                    </Col>
                    <Col style={{ textAlign: 'right' }}>
                      {!this.state.src_file_photograph ?
                        <button onClick={this.captureCam}
                          className="btn btn-success pl-5 pr-5">
                          Take photo
                        </button>
                        :
                        <button
                          onClick={this.recaptureCam}
                          className="btn btn-success pl-5 pr-5"
                        >
                          Retake
                        </button>
                      }
                    </Col>
                  </Row>

                  <div style={{ height: 20 }}></div>

                  {this.state.src_file_photograph &&
                    this.state.src_file_photograph.length > 77 &&
                    <div class="row" >
                      <div class="col-lg-3"></div>
                      <div class="col-lg-7">
                        <div>
                          <Row>
                            <Col className="mb-3">

                              <Form.Group
                                className="mb-3"
                                controlId="formBasicCheckbox"
                              >
                                <Form.Check size="lg"
                                  type="checkbox"

                                  label="I here by agree to submit my KYC information to the exchange"
                                  value={this.state.is_confirm_chk_checked}
                                  onChange={this.handleConfirmCheck}
                                  defaultChecked={
                                    this.state.is_confirm_chk_checked
                                  }
                                />
                              </Form.Group>
                              {this.state.is_confirm_chk_checked && (
                                <div className="text-center">
                                  <Button
                                    className="btn btn-success btn-block"
                                    variant="success"
                                    onClick={this.verifyAccount}
                                    disabled={this.state.isloading}

                                  >
                                    Confirm
                                  </Button>
                                </div>
                              )}
                              {this.state.is_confirm_chk_checked &&
                                this.props.personal_result['code'] &&
                                this.props.personal_result['code'] !== '000' && (
                                  <div >
                                    <br />
                                    <h5 style={{
                                      textAlign: 'left',
                                      padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4,
                                      color: "#f57362",
                                    }}>
                                      {this.props.personal_result['messageText']}
                                    </h5>
                                  </div>
                                )}
                            </Col>
                          </Row>

                        </div>
                      </div>
                    </div>
                  }

                  <div style={{ height: 10 }}></div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  personal_result: state.verify.personal_result,
});

export default connect(mapStateToProps, { verifyAccount, loadStageInfo })(
  ImageVerification
);
