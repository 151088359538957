import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { connect } from "react-redux";
import { Button, Spinner, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { verifyAadhaar, verifyStage, loadStageInfo } from '../../../jsx/actions/verify';
import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";
import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import Select from 'react-select';

var selectedFrontFile = false;
var selectedBackFile = false;
var selectedSingleFile = false;

class AadhaarVerification extends Component {

    constructor(props) {

        super(props);
        this.state = {
            is_stage_verified: false,
            is_verify_enabled: false,
            is_confirm_chk_enabled: false,
            is_confirm_chk_checked: false,
            is_verified_editable: false,
            selectedFrontFile: null,
            selectedBackFile: null,
            selectedSingleFile: null,
            src_selectedFrontFile: null,
            src_selectedBackFile: null,
            src_selectedSingleFile: null,
            aadhaar_information: [],
            aadhaar_attachment_list: [],
            show_popup: false,
            pop_messagetext: '',
            isloading: false,
            is_aadhaar_front: true,
            is_aadhaar_back: true,
            message_text: '',

            aadhaar_upload_part: "separate",
            wizard_step: 1,

            show_modal_confirm_dialog: false,
            modal_confirm_dialog_messagetext: 'You are above to submit the aadhaar proof and once submitted you cannot undone. Are you sure to submit this ?',
            previous_stage_verified: false,

            popup_dob_messageText: 'Confirm your date of birth as per the aadhaar card',
            show_aadhaar_popup: false,
            aadhaar_dob_month: '',
            aadhaar_dob_date: '',
            aadhaar_dob_year: new Date().getFullYear() - 18,
            aadhaar_dob_date_list: [],
            aadhaar_dob_month_list: [
                { label: 'January', value: '01' }, { label: 'February', value: '02' },
                { label: 'March', value: '03' }, { label: 'April', value: '04' },
                { label: 'May', value: '05' }, { label: 'June', value: '06' },
                { label: 'July', value: '07' }, { label: 'August', value: '08' },
                { label: 'September', value: '09' }, { label: 'October', value: '10' },
                { label: 'November', value: '11' }, { label: 'December', value: '12' },
            ],
            aadhaar_dob_year_list: [],
            aadhaar_dob: '',
            is_aadhaar_dob_valid: true

        };

    }

    componentDidMount() {
        this.onLoadStageInfo();
    }

    closePopup = () => {
        this.setState({
            show_popup: false,
            pop_messagetext: ''
        })
    }

    handleRadioChange = (value, attribute) => {
        this.setState({ [attribute]: value });
    };

    handleConfirmCheck = (event) => {
        this.setState({ is_confirm_chk_checked: !this.state.is_confirm_chk_checked })
    }

    changeFileHandler = (selectedFile) => (event) => {

        if (event.target.files[0] && (event.target.files[0].type === 'image/png' ||
            event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/jpg')) {
            this.setState({
                [selectedFile]: event.target.files[0],
                ['src_' + selectedFile]: URL.createObjectURL(event.target.files[0])
            });

            if (selectedFile === 'selectedFrontFile')
                selectedFrontFile = true;
            else if (selectedFile === 'selectedBackFile')
                selectedBackFile = true;
            else if (selectedFile === 'selectedSingleFile')
                selectedSingleFile = true;

            if (this.state.aadhaar_upload_part === "separate") {
                if (selectedFrontFile && selectedBackFile) {
                    window.scrollTo(0, 1000);
                }
            }

        }
        else {

            if (event.target.files.length > 0)
                this.setState({
                    show_popup: true,
                    pop_messagetext: 'Upload file format should be of PNG, JPEG, JPG only'
                });
        }
    }

    onLoadStageInfo = () => {

        this.setState({ isloading: true });

        this.props.loadStageInfo({ stage_name: 'aadhaar' }).then(response => {

            this.setState({ isloading: false });

            let aadhaar_dob_date_list = [];
            for (var index = 1; index <= 31; index++) {
                aadhaar_dob_date_list.push({
                    label: (index < 10 ? '0' : '') + index,
                    value: (index < 10 ? '0' : '') + index
                })
            }

            let dob_minimum_year = new Date().getFullYear() - 18;
            let aadhaar_dob_year_list = [];
            for (var index = dob_minimum_year; index >= dob_minimum_year - 72; index--) {
                aadhaar_dob_year_list.push({
                    label: index,
                    value: index.toString()
                })
            }

            if (this.props.aadhaar_result['code'] !== '000')
                this.setState({
                    isloading: false,
                    show_popup: true,
                    pop_messagetext: this.props.aadhaar_result['messageText'],
                });
            else {

                let _state_paramters = this.props.aadhaar_result['state_parameters'];

                if (_state_paramters && _state_paramters['aadhaar_information'].length > 0)
                    _state_paramters['wizard_step'] = 2;

                if (this.props.aadhaar_result['state_parameters'])
                    this.setState(
                        Object.assign(this.props.aadhaar_result['state_parameters'], {
                            aadhaar_dob_date_list: aadhaar_dob_date_list,
                            aadhaar_dob_year_list: aadhaar_dob_year_list,
                        }));
                else
                    this.setState({
                        is_stage_verified: this.props.aadhaar_result['is_stage_verified'],
                        is_verify_enabled: this.props.aadhaar_result['is_verify_enabled'],
                        is_confirm_chk_enabled: this.props.aadhaar_result['is_confirm_chk_enabled'],
                        is_verified_editable: this.props.aadhaar_result['is_verified_editable'],
                        aadhaar_information: this.props.aadhaar_result['id_information'],
                        aadhaar_attachment_list: this.props.aadhaar_result['attachment_list'],
                        aadhaar_dob_date_list: aadhaar_dob_date_list,
                        aadhaar_dob_year_list: aadhaar_dob_year_list
                    });
            }

        })
            .catch(err => {
                alert(err)
            });


    };

    onVerify = () => {

        this.setState({ show_aadhaar_popup: false, isloading: true });

        const { otp } = this.state;

        let formData = new FormData();

        if (this.state.aadhaar_upload_part === 'separate') {
            formData.append("upload_aadhaar", this.state.selectedFrontFile);
            formData.append("upload_aadhaar", this.state.selectedBackFile);
        }
        else
            formData.append("upload_aadhaar", this.state.selectedSingleFile);

        formData.append("id_type", 'aadhaar');
        formData.append("no_of_attachment", this.state.aadhaar_upload_part === 'separate' ? 2 : 1);
        formData.append("folder_name", 'aadhaar');
        formData.append("attachment_category", 'aadhaar');
        formData.append("attachment_mode", this.state.aadhaar_upload_part);
        formData.append("aadhaar_dob", this.state.aadhaar_dob);

        this.props.verifyAadhaar(formData).then(response => {

            if (this.props.aadhaar_result['code'] !== '000') {
                // this.setState(this.props.aadhaar_result['state_parameters']);
                this.setState({
                    message_text: this.props.aadhaar_result['messageText'],
                    state_parameters: this.props.aadhaar_result['state_parameters'],
                    isloading: false
                });
                this.setState({
                    isloading: false,
                    show_popup: true,
                    pop_messagetext: this.props.aadhaar_result['messageText'],
                });
            }
            else {

                let _state_parameters = this.props.aadhaar_result['state_parameters'];
                _state_parameters['isloading'] = false;
                _state_parameters['wizard_step'] = 2;

                if (_state_parameters)
                    this.setState(_state_parameters);
                else
                    this.setState({
                        isloading: false,
                        is_stage_verified: this.props.aadhaar_result['is_stage_verified'],
                        is_verify_enabled: this.props.aadhaar_result['is_verify_enabled'],
                        is_confirm_chk_enabled: this.props.aadhaar_result['is_confirm_chk_enabled'],
                        is_verified_editable: this.props.aadhaar_result['is_verified_editable'],
                        aadhaar_information: this.props.aadhaar_result['id_information'],
                        aadhaar_attachment_list: this.props.aadhaar_result['attachment_list'],
                    });
            }

        })
            .catch(err => {
                alert(err)
            });


    };

    onVerifyStage = () => {

        this.setState({ show_modal_confirm_dialog: false, isloading: true });

        this.props.verifyStage({
            verify_stage: 'aadhaar', aadhaar_dob: this.state.aadhaar_dob
        }, 'aadhaar', response => {

            this.setState({ isloading: false })
            this.props.history.push({
                pathname: "/" + this.props.aadhaar_result["navigateScreen"] + "/",
            });

        });

    };

    handleDobChange = (selectedOption, attribute) => {

        let _aadhaar_dob = '';
        if (attribute === 'aadhaar_dob_date')
            _aadhaar_dob = selectedOption.value + '/' + this.state.aadhaar_dob_month + '/' + this.state.aadhaar_dob_year;
        else if (attribute === 'aadhaar_dob_month')
            _aadhaar_dob = this.state.aadhaar_dob_date + '/' + selectedOption.value + '/' + this.state.aadhaar_dob_year;
        else if (attribute === 'aadhaar_dob_year')
            _aadhaar_dob = this.state.aadhaar_dob_date + '/' + this.state.aadhaar_dob_month + '/' + selectedOption.value;

        let _format_aadhaar_dob = _aadhaar_dob.split('/');

        if (this.isValidDate(_format_aadhaar_dob[2], _format_aadhaar_dob[1], _format_aadhaar_dob[0])) {
            this.setState({
                [attribute]: selectedOption.value,
                aadhaar_dob: _aadhaar_dob
            });
        }
        else {
            this.setState({
                [attribute]: selectedOption.value,
                aadhaar_dob: ``
            });
        }

    }

    isValidDate = (year, month, day) => {
        var d = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
        return month == d.getMonth() + 1;
    }

    render() {

        // if(this.props.aadhaar_result && this.props.aadhaar_result['aadhaar_stage_verified'])
        //    return <Redirect to={this.props.aadhaar_result['navigateScreen']} />; 

        return (
            <>
                <Header1 />
                <Sidebar />
                <div className="authincation section-padding" style={{ marginTop: 50 }}>
                    <div className="content-body">

                        {!this.state.isloading &&
                            !this.state.previous_stage_verified &&
                            <Card sx={{ backgroundColor: '#edf3f0', p: 2, borderRadius: 2 }}>
                                <CardContent  >
                                    <Stack direction="row">
                                        <Typography flex={1} whiteSpace="pre-line" >
                                            Sorry the information you are looking for could not be found
                                        </Typography>
                                    </Stack>
                                </CardContent>
                            </Card>
                        }

                        { this.state.previous_stage_verified &&
                            <div className="container">

                                <div class="row">
                                    <div class="col-xl-12">
                                        <h4 className="text-center">
                                            {this.state.is_stage_verified ? `Your Aadhaar Information` : `Verify Aadhaar`}
                                        </h4>
                                        {
                                            this.state.wizard_step === 1 &&
                                            <span style={{ textAlign: 'center', textDecorationLine: 'underline' }}>
                                                <span style={{ color: 'green', fontSize: 14, fontWeight: 'bold' }}>Note : </span>
                                                File format should be png or jpeg or jpg
                                            </span>
                                        }

                                        {this.state.wizard_step === 1 &&
                                            <hr />
                                        }

                                        {!this.state.is_stage_verified && this.state.wizard_step === 1 &&
                                            <div className="row">
                                                <div className="col-8">
                                                    <Form.Check
                                                        type="radio"
                                                        label="Front & Back Separately"
                                                        name="aadhaar_upload_part"
                                                        id="separate"
                                                        value="separate"
                                                        checked={
                                                            this.state.aadhaar_upload_part === "separate"
                                                        }
                                                        onChange={(e) =>
                                                            this.handleRadioChange(
                                                                "separate",
                                                                "aadhaar_upload_part"
                                                            )
                                                        }
                                                    />
                                                </div>
                                             
                                            </div>
                                        }
                                        {!this.state.is_stage_verified &&
                                            <hr />
                                        }

                                        {(this.state.is_verify_enabled || this.state.is_verified_editable) &&
                                            this.state.wizard_step === 1 && this.state.aadhaar_upload_part.length > 5 &&
                                            <div className="card" style={{ marginTop: 10 }}>
                                                <div>
                                                    <Form>
                                                        <Form.Group controlId="formFile" className="mb-3">

                                                            {this.state.aadhaar_upload_part === "separate" &&

                                                                <div>
                                                                    <span style={{ backgroundColor: '#DCE4F7', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4 }} >
                                                                        Upload front side of your aadhaar card
                                                                    </span>
                                                                    <div style={{ height: 7 }} />
                                                                    <Form.Control type="file" onChange={this.changeFileHandler('selectedFrontFile')} />
                                                                    {this.state['src_selectedFrontFile'] &&
                                                                        <div style={{
                                                                            textAlign: 'center'
                                                                        }}>
                                                                            <img src={this.state['src_selectedFrontFile']}                                                                            
                                                                                style={{ width: 330, height: 250 }} />
                                                                        </div>
                                                                    }
                                                                </div>
                                                            }
                                                            {/* {this.state.aadhaar_upload_part === "both" &&

                                                                <div class="row">
                                                                    <div class="col">
                                                                        <span style={{ color: 'InfoText', backgroundColor: '#DCE4F7', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4 }} >
                                                                            Upload front & back side of your aadhaar card
                                                                        </span>
                                                                        <div style={{ height: 7 }} />
                                                                        <Form.Control type="file" onChange={this.changeFileHandler('selectedSingleFile')} />
                                                                        {this.state['src_selectedSingleFile'] &&
                                                                            <div style={{
                                                                                textAlign: 'center'
                                                                            }}>
                                                                                <img src={this.state['src_selectedSingleFile']}
                                                                                    style={{ width: 330, height: 250 }} />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            } */}

                                                        </Form.Group>

                                                    </Form>
                                                </div>
                                            </div>
                                        }
                                        {(this.state.is_verify_enabled || this.state.is_verified_editable) &&
                                            this.state.wizard_step === 1 && this.state.aadhaar_upload_part === "separate" &&
                                            <div className="card" style={{ marginTop: 10 }}>
                                                <div /*className="card-body"*/>
                                                    <Form>
                                                        <Form.Group controlId="formFile" className="mb-3">

                                                            <div class="row">
                                                                <div class="col">

                                                                    <span style={{ color: 'InfoText', backgroundColor: '#DCE4F7', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4 }} >
                                                                        Upload back side of your aadhaar card
                                                                    </span>
                                                                    <div style={{ height: 7 }} />
                                                                    <Form.Control type="file" onChange={this.changeFileHandler('selectedBackFile')} />
                                                                    {this.state['src_selectedBackFile'] &&
                                                                        <div style={{
                                                                            textAlign: 'center'
                                                                        }}>
                                                                            <img src={this.state['src_selectedBackFile']}
                                                                                style={{ width: 330, height: 250 }} />
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>

                                                        </Form.Group>

                                                    </Form>
                                                </div>
                                            </div>
                                        }

                                        {
                                            (this.state.is_verify_enabled || this.state.is_verified_editable) &&
                                            (
                                                (this.state.aadhaar_upload_part === "separate" && this.state['src_selectedFrontFile'] && this.state['src_selectedBackFile'])
                                                
                                            ) &&
                                            this.state.wizard_step === 1 &&
                                            <div className="text-center" style={{ marginTop: 10 }}>
                                                <Button
                                                    className="btn btn-success btn-block"
                                                    variant="success"
                                                    onClick={() => {
                                                        // console.log("Verifying Aadhaar...");
                                                        // console.log("Current state before verification:", this.state);
                                                        let _format_aadhaar_dob = this.state.aadhaar_dob.split('/');

                                                        let _dob_from_aadhaar = this.state.aadhaar_information.filter(el => {
                                                            return el['attribute'] === 'dob'
                                                        });

                                                        if (_dob_from_aadhaar.length > 0) {
                                                            if (_format_aadhaar_dob.length <= 1)
                                                                _format_aadhaar_dob = _dob_from_aadhaar[0]['text'].split('/');
                                                        }

                                                        if (_format_aadhaar_dob.length === 3) 
                              {
                                this.setState({
                                    show_aadhaar_popup: true,
                                    aadhaar_dob_date: _format_aadhaar_dob[0],
                                    aadhaar_dob_month: _format_aadhaar_dob[1],
                                    aadhaar_dob_year: _format_aadhaar_dob[2],
                                    aadhaar_dob: _format_aadhaar_dob[0] + '/' +
                                        _format_aadhaar_dob[1] + '/' + _format_aadhaar_dob[2]
                                }, () => {
                                    // console.log("State updated after verification:", this.state);
                                });
                            } 
                            else {
                                this.setState({
                                    show_aadhaar_popup: true,
                                    aadhaar_dob: ``
                                }, () => {
                                    // console.log("State updated after verification:", this.state);
                                });
                            }
                                                    }}
                                                    disabled={this.state.isloading}

                                                >Verify</Button>
                                            </div>
                                        }
                                        {
                                            this.state.wizard_step === 1 && this.state.message_text.length > 5 &&
                                            <p className="text-center mb-5" style={{ color: '#8c4bd1' }}>{
                                                this.state.message_text
                                            }</p>
                                        }

                                        {
                                            !this.state.is_stage_verified &&
                                            this.state.wizard_step > 1 &&
                                            this.state.aadhaar_information.length > 0 &&
                                            <h5 style={{ textAlign: 'center' }}>
                                                Recently uploaded information
                                            </h5>
                                        }

                                        {this.state.wizard_step > 1 && this.state.aadhaar_information.length > 0 &&
                                           <div>

                                                <table  style={{ width: '100%' }}>
                                                    {this.state.aadhaar_information.map((el, i) =>

                                                        el['attribute'] !== 'address_information' && el['text'].length < 400 &&
                                                        <tr key={i} >
                                                            <td style={{ width: 120, height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                                                                <span>
                                                                    {el['attribute']}
                                                                </span>
                                                            </td>

                                                            <td style={{ wordWrap: 'break-word', border: '1px solid #e2e0e4', textAlign: 'left' }}>
                                                                {el['text'] && el['text'].length < 20 ?
                                                                    <label>
                                                                        {el['text']}
                                                                    </label> :
                                                                    <label style={{ paddingBottom: 20 }}>
                                                                        {el['text']}
                                                                    </label>
                                                                }
                                                            </td>
                                                        </tr>
                                                    )}
                                                </table>
                                                <div style={{ height: 10 }} />
                                                <table style={{ width: '100%' }}>
                                                    {(this.state.aadhaar_attachment_list || []).map((el, i) =>
                                                        <tr>
                                                            <td >
                                                                <img key={i} src={el} style={{ width: 330, height: 250 }} />
                                                            </td>
                                                        </tr>
                                                    )
                                                    }
                                                </table>

                                                {this.state.show_popup && (
                                                    <Modal show={this.state.show_popup} onHide={() => this.closePopup()}>
                                                        <Modal.Header>

                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            <p>
                                                                {this.state["pop_messagetext"]}
                                                            </p>
                                                        </Modal.Body>
                                                        <Modal.Footer>
                                                            <Button variant="primary" onClick={() => this.closePopup()}>
                                                                Close
                                                            </Button>
                                                        </Modal.Footer>
                                                    </Modal>
                                                )}

                                            </div>
                                        }

                                        {this.state.isloading &&
                                            <Spinner animation="border" variant="dark"
                                                style={{ position: "fixed", top: "50%", left: "50%" }} />
                                        }{
                                            this.state.is_stage_verified &&
                                            <div style={{ height: 20 }} />
                                        }


                                        {
                                            this.state.is_stage_verified &&
                                            <table style={{ width: '100%' }}>
                                                <tr>
                                                    <td style={{
                                                        height: 30,
                                                        textAlign: 'left'
                                                    }}>
                                                        <Link to={'/panVerification'} type="submit"
                                                            className="btn btn-warning pl-5 pr-5">Previous</Link>
                                                    </td>
                                                    <td style={{ height: 30, textAlign: 'right' }}>
                                                        <Link to={'/bankVerification'} type="submit"
                                                            className="btn btn-success pl-5 pr-5">Next</Link>
                                                    </td>
                                                </tr>
                                            </table>
                                        }

                                        {this.state.wizard_step > 1 && this.state.aadhaar_information.length > 0 &&
                                            !this.state.is_stage_verified &&
                                            this.state.is_aadhaar_front && this.state.is_aadhaar_back &&
                                            <div>
                                                <div style={{ height: 7 }} />

                                                {

                                                    this.state.is_confirm_chk_enabled &&
                                                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                                        <Form.Check type="checkbox"
                                                            label="I agree and confirm to verify my aadhaar information."
                                                            value={this.state.is_confirm_chk_checked}
                                                            onChange={this.handleConfirmCheck} defaultChecked={this.state.is_confirm_chk_checked}
                                                        />
                                                    </Form.Group>
                                                }

                                                {
                                                    this.state.is_confirm_chk_checked &&
                                                    <div className="text-center">
                                                        <Button
                                                            className="btn btn-success btn-block"
                                                            variant="success"
                                                            // onClick={this.onVerifyStage}
                                                            onClick={() => {
                                                                this.setState({ show_modal_confirm_dialog: true })
                                                            }}
                                                            disabled={this.state.isloading}
                                                        >
                                                            Confirm
                                                        </Button>
                                                    </div>
                                                }
                                            </div>

                                        }
                                    </div>
                                </div>

                                {!this.state.is_stage_verified && this.state.wizard_step > 1 &&
                                    <Fab
                                        style={{ bottom: 22, left: -15, transform: 'scale(0.8)' }}
                                        // event="hover"
                                        alwaysShowTitle={true}
                                        mainButtonStyles={{
                                            backgroundColor: "#8165d6"
                                        }}
                                        icon={<i className="fa fa-arrow-left  fa-lg" ></i>}
                                        onClick={() => this.setState({
                                            wizard_step: this.state.wizard_step - 1
                                        })}
                                    >
                                    </Fab>
                                }
                                {!this.state.is_stage_verified && this.state.wizard_step < 2 &&
                                    this.state.aadhaar_information.length > 0 &&
                                    <Fab
                                        style={{ bottom: 22, right: -18, transform: 'scale(0.8)' }}
                                        // event="hover"
                                        alwaysShowTitle={true}
                                        mainButtonStyles={{
                                            backgroundColor: "#00b5ad"
                                        }}
                                        icon={<i className="fa fa-arrow-right  fa-lg" ></i>}
                                        onClick={() => this.setState({
                                            wizard_step: this.state.wizard_step + 1
                                        })}
                                    >
                                    </Fab> 
                                }

                                {this.state.show_modal_confirm_dialog && (
                                    <Modal show={this.state.show_modal_confirm_dialog}
                                        // onHide={() => this.setState({
                                        //   show_modal_confirm_dialog: false
                                        // })}
                                        centered
                                        keyboard={false}
                                        // fullscreen={true}
                                        backdrop="static"
                                    >
                                        <Modal.Header>

                                        </Modal.Header>
                                        <Modal.Body>

                                            <p>{this.state["modal_confirm_dialog_messagetext"]}</p>

                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="primary"
                                                onClick={() => this.onVerifyStage()}>
                                                Yes
                                            </Button>
                                            <Button variant="primary"
                                                onClick={() => this.setState({
                                                    show_modal_confirm_dialog: false
                                                })}>
                                                No
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                )}

                                <Modal show={this.state.show_aadhaar_popup} centered>
                                    <Modal.Header>
                                        <p>{this.state.popup_dob_messageText}</p>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="row">
                                            <div className="col-5">
                                                Month
                                            </div>
                                            <div className="col">
                                                <Select placeholder={`select`}
                                                    value={this.state.aadhaar_dob_month_list.find(object => object.value === this.state.aadhaar_dob_month || '')}
                                                    onChange={(e) => { this.handleDobChange(e, 'aadhaar_dob_month') }}
                                                    options={this.state.aadhaar_dob_month_list}
                                                    isSearchable={false} //
                                                />
                                            </div>
                                        </div>
                                        <p></p>
                                        <div className="row">
                                            <div className="col-5">
                                                Date
                                            </div>
                                            <div className="col">
                                                <Select placeholder={`select`}
                                                    value={this.state.aadhaar_dob_date_list.find(object => object.value === this.state.aadhaar_dob_date || '')}
                                                    onChange={(e) => { this.handleDobChange(e, 'aadhaar_dob_date') }}
                                                    options={this.state.aadhaar_dob_date_list}
                                                    isSearchable={false}
                                                />
                                            </div>
                                        </div>
                                        <p></p>
                                        <div className="row">
                                            <div className="col-5">
                                                Year
                                            </div>
                                            <div className="col">
                                                <Select placeholder={`select`}
                                                    value={this.state.aadhaar_dob_year_list.find(object => object.value === this.state.aadhaar_dob_year || '')}
                                                    onChange={(e) => { this.handleDobChange(e, 'aadhaar_dob_year') }}
                                                    options={this.state.aadhaar_dob_year_list}
                                                    isSearchable={false}
                                                />
                                            </div>
                                        </div>
                                        <hr />
                                        {!this.state.is_aadhaar_dob_valid &&
                                            <div className="row">
                                                <span style={{ color: 'red' }}>
                                                    Entered Date Of Birth is not valid
                                                </span>
                                            </div>
                                        }
                                        {this.state.is_aadhaar_dob_valid && this.state.aadhaar_dob.length === 10 &&
                                            <div className="row">
                                                <div class="col">
                                                    Your Date Of Birth
                                                    {/* </div>
                            <div class="col"> */}
                                                    {` `}
                                                    <span style={{ fontWeight: 'bold', color: 'green' }}>
                                                        {this.state.aadhaar_dob}
                                                    </span>
                                                </div>
                                            </div>
                                        }
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="primary"
                                            disabled={this.state.aadhaar_dob.length < 10}
                                            onClick={() => {
                                                this.onVerify();
                                            }}>
                                            Submit
                                        </Button>
                                        {`      `}
                                        <Button
                                            variant="primary"
                                            onClick={() => {
                                                this.setState({
                                                    show_aadhaar_popup: false,
                                                    aadhaar_dob: ``
                                                })
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Modal.Footer>
                                </Modal>

                            </div>
                        }
                    </div>
                </div>
            </>
        )
    }

}
// AadhaarVerification.propTypes = {
//     validateOtp: PropTypes.func.isRequired,
//     isAuthenticated: PropTypes.bool
// };

const mapStateToProps = state => ({
    aadhaar_result: state.verify.aadhaar_result
});

export default connect(mapStateToProps, { verifyAadhaar, verifyStage, loadStageInfo })
    (AadhaarVerification);