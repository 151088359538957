import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  downloadNominee
} from "../../actions/orders";
//import Spinner from "../../layout/spinner";
import { PDFReader } from "reactjs-pdf-reader";
// import { PDFViewer } from 'pdf-viewer-reactjs';

import {
  Button,
  Spinner
} from "react-bootstrap";
import { saveAs } from "file-saver";

class DownloadNominee extends React.Component {
  

  constructor(props) {

    super(props);
    this.state = {
      selected_order: {},
      sip_order: {},
      list_bank_account: [],
      list_mandate: [],
      list_pending_mandate: [],
      list_mandate_debit: [],
      src_nach_form: "",
      selected_mandate_id: "",
      is_mandate_selected: false,
      is_mandate_registered: false,
      is_mandate_uploaded: false,
      is_debit_max_limit: false,
      mandate_amount: 25000,
      mandate_id: "",
      mandate_date_validation: {
        message_text: "",
      },
      is_first_order_today: false,
      loading: false,
      is_nach: true,
      nach_option: "existing",
      nach_option_new_processed: false,
      nach_option_new_processed_message_text: "",
      sip_information_text: "You need to make payment physically on ",
      render_statement_pdf: null,
      nachmandateFile: null,
      src_nachmandateFile: null,
      byte_nachmandateFile: null,
      show_popup: false,
      popup_messageText: "",
      invest_more_amount: 0.0,
      redeemable_amount: 0.0,
      is_redeem_full_checked: false,
      is_stage_verified: false,
      is_verify_enabled: false,
      is_verified_editable: false,
      esignkyc_information: {},
      esignkyc_attachment_list: {},
      is_esign_verified: false,
      is_esign_button_disabled: false,
      isloading: false,
      received_data: {}
    };
  }

  componentDidMount() 
  {

    // this.setState(this.props.location.state);
    window.scrollTo(0, 0)

    let isMobile = (window.matchMedia && window.matchMedia("(max-width: 480px)").matches);

    let received_data = (isMobile && this.props.location.state && this.props.location.state['param_data']) ?
      this.props.location.state['param_data'] : this.props.match.params;

    if (received_data) {
      sessionStorage.setItem('download_nominee_data', JSON.stringify(received_data));
    }
    else
      received_data = JSON.parse(sessionStorage.getItem('download_nominee_data') || JSON.stringify({}));

    this.loadNomineeForm(received_data);

  }

  loadNomineeForm = (parameters) => {

    this.setState({ isloading: true, received_data: parameters });
    
    this.props
      .downloadNominee(
        { 
          is_external_bb_access_token: true,
          bb_access_token: parameters['token_value'], 
          scheme_code: parameters['scheme_code'],
          nominee_id: parameters['nominee_id'] 
        }
      )
      .then((response) => {

        if (this.props.order_result["code"] !== "000") {
          this.setState({ isloading: false });
          alert(this.props.order_result["messageText"]);
        }
        else {
          let _result = this.props.order_result["result"];
          this.setState({
            isloading: false,
            render_statement_pdf: atob(_result['statement_base64'])
          });
        }
      })
      .catch((err) => {
        // console.log('line 112 :');
        console.log(err);
        alert(err);
      });

  };

  async downloadStatement() {

    if (this.state.render_statement_pdf) {

      const byteCharacters = this.state.render_statement_pdf; //atob(this.state.src_nach_form);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      // const { data } = await getTicketsPdf()
      const blob = new Blob([byteArray], { type: "application/pdf" });
      saveAs(blob, 
        (this.state.received_data['nominee_type'] ? 
        'NOMINEE_OPTING_OUT_DECLARATION_' : ``) + 
        this.state.received_data['client_code'] + ".pdf");

    }
  }

  render() {

    // if(this.props.loading)
    //   return <Spinner />;

    // if(this.props.order_result && this.props.order_result['esignkyc_stage_verified'])
    //    return <Redirect to={this.props.aadhaar_result['navigateScreen']} />;

    return (
      <>


        <div className="content-body" >
          <div className="container">
            <div className="row">

              <div className="col-xl-12 col-md-8">
                {this.state.isloading &&
                  <Spinner animation="border" variant="dark"
                    style={{ position: "fixed", top: "50%", left: "50%" }} />
                }
                {this.state.render_statement_pdf !== null && 
                    <Button
                    className="btn btn-success btn-block"
                    variant="success"
                    onClick={() =>
                      this.downloadStatement()
                    }
                  >
                    Download
                  </Button>
                  }
                {this.state.render_statement_pdf !== null && (
                  <PDFReader
                    data={this.state.render_statement_pdf}
                  />
                )}


              </div>

            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ order }) => {
  const { loading, order_result } = order;
  return {
    loading,
    order_result,
  };
};

export default connect(mapStateToProps, {
  downloadNominee
})(DownloadNominee);
