import api from '../utils/api';
import { setAlert } from './alert';
import {
  LIST_SCHEME,
  LIST_FAIL
} from './types';
import { API_END_POINTS } from '../constants/constants';

export const listSchemes = (body_parameters) => async dispatch => {
   
  const body = body_parameters;

  try {

    const res = await api.post(API_END_POINTS.LIST_SCHEME, body);
    
    dispatch({
      type: LIST_SCHEME,
      payload: res.data
    });
    
   
  //  dispatch(loadUser());
  } catch (err) {

    console.log(err)
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: LIST_FAIL,
      list_result: {
        code: '-1',
        messageText: ''
      }
    });

  }
};

export const listStpSchemes = (body_parameters) => async dispatch => {
   
  const body = body_parameters;

  try {

    const res = await api.post(API_END_POINTS.LIST_STP_SCHEMES, body);
    
    dispatch({
      type: LIST_SCHEME,
      payload: res.data
    });
    
   
  //  dispatch(loadUser());
  } catch (err) {

    console.log(err)
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: LIST_FAIL,
      list_result: {
        code: '-1',
        messageText: ''
      }
    });

  }
};


export const listWatchSchemes = (body_parameters) => async dispatch => {
   
  const body = body_parameters;

  try {

    const res = await api.post(API_END_POINTS.LIST_WATCH_SCHEMES, body);
    
    dispatch({
      type: LIST_SCHEME,
      payload: res.data
    });

    dispatch({
      type: LIST_SCHEME,
      payload: res.data
    });
    
   
  //  dispatch(loadUser());
  } catch (err) {

    console.log(err)
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: LIST_FAIL,
      list_result: {
        code: '-1',
        messageText: ''
      }
    });

  }
};

export const addWatchList = (body_parameters) => async dispatch => {
   
  const body = body_parameters;

  try {

    const res = await api.post(API_END_POINTS.ADD_WATCHLIST, body);
    
    dispatch({
      type: LIST_SCHEME,
      payload: res.data
    });
    
   
  //  dispatch(loadUser());
  } catch (err) {

    console.log(err)
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: LIST_FAIL,
      list_result: {
        code: '-1',
        messageText: ''
      }
    });

  }
};

export const modifyWatchList = (body_parameters) => async dispatch => {
   
  const body = body_parameters;

  try {

    const res = await api.post(API_END_POINTS.MODIFY_WATCHLIST, body);
    
    dispatch({
      type: LIST_SCHEME,
      payload: res.data
    });
    
   
  //  dispatch(loadUser());
  } catch (err) {

    console.log(err)
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: LIST_FAIL,
      list_result: {
        code: '-1',
        messageText: ''
      }
    });

  }
};

export const addAdviseList = (body_parameters) => async dispatch => {
   
  const body = body_parameters;

  try {

    const res = await api.post(API_END_POINTS.ADD_WATCHLIST, body);
    
    dispatch({
      type: LIST_SCHEME,
      payload: res.data
    });
    
   
  //  dispatch(loadUser());
  } catch (err) {

    console.log(err)
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: LIST_FAIL,
      list_result: {
        code: '-1',
        messageText: ''
      }
    });

  }
};

export const modifyAdviseList = (body_parameters) => async dispatch => {
   
  const body = body_parameters;

  try {

    const res = await api.post(API_END_POINTS.MODIFY_WATCHLIST, body);
    
    dispatch({
      type: LIST_SCHEME,
      payload: res.data
    });
    
   
  //  dispatch(loadUser());
  } catch (err) {

    console.log(err)
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: LIST_FAIL,
      list_result: {
        code: '-1',
        messageText: ''
      }
    });

  }
};

