import React, { } from 'react';



function PageTitle() {

    return (
        <>
            <div className="page_title">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="page_title-content">
                            </div>
                          
                        </div>
                      
                    </div>
                </div>
            </div>

        </>
    )
}

export default PageTitle;