import React from "react";
import { Form, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { Button, Modal, CloseButton } from "react-bootstrap";
import {
  loadBankAccountList,
  saveBankAccount,
  validateBankAccount,
  verifyStage,
  verifyIdProof
} from "../../actions/verify";
import { Link } from 'react-router-dom';
import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";
import Box from "@mui/material/Box";
import { Table, Collapse, Spinner } from "react-bootstrap";
import Select from "react-select";
import { Card, CardContent } from "@mui/material";

class BankAccountList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isloading: false,
      messageText: '',
      is_primary_available: false,
      list_bank_account: [],
      activity_mode: "list",
      bank_account_number: "",
      bank_ifsc_code: "",
      bank_account_type: "",
      bank_name: {},
      is_valid_account: false,
      is_valid_idproof: false,
      selectedFile: null,
      src_selectedFile: null,
      bank_information: [],
      bank_attachment_list: [],
      file_bank_tiff: null,
      show_popup: false,
      pop_messagetext: "",
      list_bank: [],
      list_account_type: [],
      selectedAccountType: [],
      login_stage: '',
      modalshow: false,
      accountMatch: true,
      show_modal_confirm_dialog: false,
      modal_confirm_dialog_messagetext: 'You are above to submit the bank account details and once submitted you cannot undone. Are you sure to submit now ?',
      previous_stage_verified: false
    };
  }

  componentDidMount() {

    window.addEventListener("scroll", () => {

      if (document.getElementById("navbar-banklist-container") &&
        document.getElementById("navbar") &&
        document.getElementById("navbar").classList) {
        const topBorder = document
          .getElementById("navbar-banklist-container")
          .getBoundingClientRect().top;

        topBorder >= 0
          ? document.getElementById("navbar").classList.remove("fixed-banklist")
          : document.getElementById("navbar").classList.add("fixed-banklist");
      }

    });

    this.onLoadBankAccountList();

  }

  componentWillUnmount() {
    window.removeEventListener('scroll', null);
  }

  handleConfirmCheck = (e, item) => {
    const _list = this.state.list_bank_account;
    _list.forEach((el) => {
      if (el["beneficiary_account_no"] === item["beneficiary_account_no"])
        item["is_primary"] = !item["is_primary"];
      else item["is_primary"] = false;
    });

    this.setState({ list_bank_account: _list });
  };

  onLoadBankAccountList = () => {

    this.setState({ isloading: true });
    this.props
      .loadBankAccountList({})
      .then((response) => {

        if (this.props.bank_result["code"] !== "000")
          alert(this.props.bank_result["messageText"]);
        else {
          if (this.props.bank_result["result"]) {

            const bank_list = this.props.bank_result["result"]["customer_bank_list"];

            this.setState({
              isloading: false,
              messageText: bank_list.length > 0 ? '' : this.props.bank_result["messageText"],
              login_stage: this.props.bank_result["result"]['login_stage'],
              list_bank_account: bank_list,
              list_account_type:
                this.props.bank_result["master_list"]["bank_account_type"] ||
                [],
              list_bank:
                this.props.bank_result["master_list"]["bank_list"] || [],
              is_primary_available: bank_list.filter(el => {
                return el['is_primary']
              }).length > 0
            });
          }
          else this.setState({ list_bank_account: [] });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  onValidateBankAccount = () => {

    this.setState({ isloading: true });
    let body_parameters = {
      client_bank_account_number_1: this.state.bank_account_number,
      client_ifsc_code_1: this.state.bank_ifsc_code,
      client_bank_account_type_1: this.state.bank_account_type,
      client_bank: this.state.bank_name,
      request_mode: 'additional'
    };

    this.props
      .validateBankAccount(body_parameters)
      .then((response) => {
        this.setState({ isloading: false });
        if (this.props.bank_result["code"] !== "000")
          alert(this.props.bank_result["messageText"]);
        else {
          this.setState({
            is_valid_account: true,
            bank_information: this.props.bank_result["id_information"],
            bank_attachment_list: this.props.bank_result["attachment_list"],
          });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  onSaveBankAccount = (request_mode) => {

    this.setState({ isloading: true });

    let formData = new FormData();

    formData.append("id_type", "cheque");
    formData.append("folder_name", "bank");
    formData.append("attachment_category", "bank");
    formData.append("request_mode", request_mode);
    formData.append(
      "account_information",
      JSON.stringify(this.state.bank_information)
    );

    if (request_mode === "add") {
      if (this.state.selectedFile) {
        formData.append("upload_addbank", this.state.selectedFile);

        if (this.state.file_bank_tiff)
          formData.append("upload_addbank", this.state.file_bank_tiff);
      } else return;
    }

    this.props.saveBankAccount(formData, request_mode, (response) => {

      if (this.props.bank_result['code'] === '000') {

        const _list_bank_account = this.state.list_bank_account
          .concat([this.props.bank_result["result"]])

        this.setState({
          activity_mode: 'list',
          list_bank_account: _list_bank_account //this.props.bank_result["result"]["customer_bank_list"],
        });

      }
    });
  };
   

  handleChangeSelect = (selectedOption, attribute) => {

    // this.setState({ 'bank_account_type':  selectedOption['value'] });
    if (attribute)
      //    this.setState({ [attribute]:  {...this.state[attribute], [input]: selectedOption.value }  });
      // else
      this.setState({
        [attribute]: attribute === 'bank_name' ?
          selectedOption : selectedOption.value
      });

    // this.filteroption(selectedOption);
  };

  changeHandler = (event) => {

    this.setState({ isloading: true });

    if (event.target.files[0] && (event.target.files[0].type === 'image/png' ||
      event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/jpg')) {

      const uploaded_file = event.target.files[0];

      {
        const file = uploaded_file;

        const reader = new FileReader();
        reader.onloadend = () => {
          this.dataURLtoFile(reader.result, 'bse');
        };
        reader.readAsDataURL(file);
      }

      {

        let bank_information = {};

        this.state.bank_information.forEach(el => {
          bank_information[
            el['attribute'].replace(/ /gm, "_").toLowerCase()] = el['text'];
        });

        let formData = new FormData();

        formData.append("id_type", "cheque");
        formData.append("request_mode", 'save');
        formData.append("upload_addbank", uploaded_file);
        formData.append("folder_name", 'bank');
        formData.append("attachment_category", 'bank');
        formData.append('is_master_update_required', false);

        formData.append("bank_info", JSON.stringify({
          account_number: bank_information['account_no'] ||
            bank_information['account_number'],
          ifsc_code: bank_information['ifsc_code'],
          account_type: bank_information['account_type'],
          bank_name: bank_information['bank_name']
        }));

        formData.append('request_mode', 'additional');

        this.props.verifyIdProof(formData, { attachment_category: 'bank' }, response => {

          if (response['code'] === '000') {

            this.setState({
              isloading: false,
              selectedFile: uploaded_file,
              src_selectedFile: URL.createObjectURL(uploaded_file),
              is_valid_idproof: true,
              show_popup: false,
              pop_messagetext: ''
            });

          }
          else
            this.setState({
              isloading: false,
              is_valid_idproof: false,
              show_popup: true,
              pop_messagetext: response['messageText']
            });

        });

      }


    }
    else {

      if (event.target.files.length > 0)
        this.setState({
          isloading: false,
          show_popup: true,
          pop_messagetext: 'Upload file format should be of PNG, JPEG, JPG only'
        });
      else
        this.setState({ isloading: false });

    }

  }

  closePopup = () => {
    this.setState({
      show_popup: false,
      pop_messagetext: ''
    })
  }

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    this.setState({
      file_bank_tiff: new File([u8arr], filename + "_bank.tiff", {
        type: mime.split("/")[0] + "/tiff",
      }),
    });
  }

  handleClose = () => {
    this.setState({ bank_account_number: '', bank_ifsc_code: '' })
    this.setState({ activity_mode: "list", modalshow: false });
  }

  Showaccount = () => {
    this.setState({ activity_mode: "add", modalshow: false });
  }

  validateAccount = () => {
    const { bank_account_number, bank_account_number_confirm } = this.state;
    if (bank_account_number === bank_account_number_confirm) {
      this.setState({ accountMatch: true });
    } else {
      this.setState({ accountMatch: false });
    }
  };

  render() {

    return (
      <>
        <Header1 />
        <Sidebar />

        <div className="content-body" style={{ marginTop: 30 }}>
          <div className="container">
            <div className="row " >

              <div id="navbar-banklist-container">
                <div id="navbar" >
                  <div className="card" style={{ backgroundColor: '#f8f8fb' }}>
                    <div className="card-header">
                      <div class="row">
                        <div class="col">
                          <h5>Your Primary Bank Account Info</h5>
                        </div>
                      </div>
                    </div>

                    <div class="row" style={{ marginTop: '-10px' }}>
                      <hr />
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ marginTop: 60, marginBottom: 30 }}>

                  {this.state.activity_mode === "list" &&
                    (
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>

                        <div class="buy-sell-widget">
                          <div id="chart-timeline">
                            {this.state.isloading && (
                              <Spinner
                                animation="border"
                                variant="dark"
                                style={{
                                  position: "fixed",
                                  top: "50%",
                                  left: "50%",

                                }}
                              />
                            )}

                            {this.state.list_bank_account.length < 1 && (
                              <Alert variant="secondary text-center">
                                <Alert.Heading>
                                  {this.state.isloading
                                    ? "We are fetching your bank account list"
                                    : "No bank account information found"}
                                </Alert.Heading>
                              </Alert>
                            )}



                            {(this.state.list_bank_account || []).map(
                              (item, index) => (
                                <Card key={index}>

                                  <CardContent>

                                    <table style={{ width: '100%' }}>
                                      <tr>
                                        <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                                          <span>
                                            Bank Name
                                          </span>
                                        </td>
                                        <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                          {item["beneficiary_bank_name"]}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                                          <span>
                                            Account Number
                                          </span>
                                        </td>
                                        <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                          {item["beneficiary_account_no"]}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                                          <span>
                                            Name
                                          </span>
                                        </td>
                                        <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                          {item["beneficiary_account_holder_name"]}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                                          <span>
                                            Account Type
                                          </span>
                                        </td>
                                        <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                          {item["beneficiary_account_type"]}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                                          <span>
                                            IFSC
                                          </span>
                                        </td>
                                        <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                          {item["beneficiary_ifsc"]}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                                          <span>
                                            Branch
                                          </span>
                                        </td>
                                        <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                          {item["beneficiary_bank_branch_name"]}
                                        </td>
                                      </tr>
                                    </table>
                                    <br />
                                    <p>
                                      <u>ADDRESS</u>
                                      <br />
                                      {item['beneficiary_bank_branch_address']}
                                    </p>
                                    {item["is_primary"] &&
                                      <span style={{
                                        fontSize: 16, backgroundColor: '#d9b998', padding: 4, paddingLeft: 8,
                                        paddingRight: 8, margin: 4, borderRadius: 4
                                      }}>
                                        PRIMARY ACCOUNT
                                      </span>
                                    }


                                    {/* <p>
                                      <label>
                                        <Form.Group
                                          className="mb-3"
                                          controlId="formBasicCheckbox"
                                        >
                                          <Form.Check
                                            type="checkbox"
                                            label="Primary?"
                                            onChange={(e) => {
                                              this.handleConfirmCheck(
                                                e,
                                                item
                                              );
                                            }}
                                            defaultChecked={
                                              item["is_primary"]
                                            }
                                            disabled={true}
                                          />
                                        </Form.Group>
                                      </label>
                                    </p> */}
                                  </CardContent>

                                </Card>
                              ))}

                            {
                              this.state.list_bank_account.length === 0 &&
                              <div class="col">
                                <p className="text-center">
                                  {this.state.messageText}
                                </p>
                              </div>

                            }
                          </div>
                        </div>
                      </Box>
                    )}

                  {this.state.activity_mode === "add" && (
                      <form action="#">

                        { //(this.state.is_verify_enabled || this.state.is_verified_editable) &&

                          <div class="form-group">

                            <div class="row">

                              <label class="mr-sm-2">Choose Your Bank</label>

                              <Select
                                value={this.state.list_bank.find(obj => obj.value === this.state.bank_name['value'] || '')}
                                onChange={(e) => this.handleChangeSelect(e, 'bank_name')}
                                options={this.state.list_bank}
                              />

                            </div>
                            <br />
                            <div class="row">
                              <label class="mr-sm-2">Choose Account Type</label>

                              <Select
                                value={this.state.list_account_type.find(obj => obj.value === this.state.bank_account_type)}
                                onChange={(e) => this.handleChangeSelect(e, 'bank_account_type')}
                                options={this.state.list_account_type}
                              />

                            </div>
                            <br />
                            <div class="row">
                              <div class="col">
                                <label>Enter Account Number</label>
                                <input type="text" className="form-control"
                                  value={this.state.bank_account_number}
                                  maxlength="18"
                                  style={{ letterSpacing: 5 }}
                                  onChange={event => {
                                    const targetValue = event.target.value.replace(/\s/g, '');
                                    if ((targetValue.match(/^[0-9]+$/)
                                      || targetValue === "") && targetValue.toString().length <= 18) {
                                      this.setState({
                                        bank_account_number: targetValue
                                      }, this.validateAccount);
                                    }
                                  }
                                  }

                                />
                              </div>

                            </div>
                            <br />
                            <div class="row">
                              <div class="col">
                                <label>Confirm Account Number</label>
                                <input type="password" className="form-control"
                                  value={this.state.bank_account_number_confirm}
                                  maxlength="18"
                                  style={{ letterSpacing: 5 }}
                                  onChange={event => {
                                    const targetValue = event.target.value.replace(/\s/g, '');
                                    if ((targetValue.match(/^[0-9]+$/)
                                      || targetValue === "") && targetValue.toString().length <= 18) {
                                      this.setState({
                                        bank_account_number_confirm: targetValue
                                      }, this.validateAccount);
                                    }
                                  }
                                  }
                                />
                              </div>
                            </div>
                            <br />
                            {this.state.accountMatch ? null : (
                              <p style={{ color: 'red' }}>Account Number do not match</p>
                            )}
                            <br />
                            <div class="row">
                              <div class="col">
                                <label>IFSC Code</label>
                                <input value={this.state.bank_ifsc_code}
                                  onChange={event => {
                                    const targetValue = event.target.value.replace(/\s/g, '');
                                    if ((targetValue.match(/^[0-9a-zA-Z]+$/)
                                      || targetValue === "") && targetValue.toString().length <= 15) {
                                      this.setState({
                                        bank_ifsc_code: targetValue.toUpperCase()
                                      });
                                    }
                                  }
                                  }
                                  maxLength={15}
                                  className="form-control"
                                  type="text" />
                              </div>
                            </div>
                            <br />
                            <div class="row">
                              <div class="col">
                                { /*this.state.bank_information.length === 0*/
                                  (this.state.is_verify_enabled || this.state.is_verified_editable) &&
                                  (this.state.bank_account_number.length > 4 &&
                                    this.state.bank_ifsc_code.length > 4 &&
                                    this.state.bank_account_type.length > 1 &&
                                    this.state.bank_name['value']) &&
                                  this.state.bank_account_number === this.state.bank_account_number_confirm &&
                                  <div className="text-center">
                                    <Button
                                      className="btn btn-success btn-block"
                                      variant="success"
                                      // onClick={this.onVerify}
                                      onClick={() => {
                                        this.setState({ show_modal_confirm_dialog: true })
                                      }}
                                      disabled={this.state.isloading}

                                    >Verify</Button>
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                        }
                        {this.state.isloading &&

                          <Spinner animation="border" variant="dark"
                            style={{ position: "fixed", top: "50%", left: "50%" }} />
                        }

                        {this.state.bank_information.length > 0 &&
                          this.state.bank_information[0]['text'] &&
                          <div className="card">

                            <div class="row">

                              {(this.state.bank_information || []).map((el, i) =>
                                <div key={i}>
                                  <label className="text-dark">
                                    {el["attribute"]}{` : `}
                                    <label className="text-success">
                                      {el["text"]}
                                    </label>
                                  </label>
                                  <p></p>
                                </div>
                              )
                              }

                            </div>

                            <div class="row">
                              <div class="col">
                                {!this.state.is_stage_verified &&

                                  <div className="text-left">
                                    <p><span style={{ color: 'green', fontWeight: 'bold' }}>Note : </span>Kindly upload your valid cheque (File format should be png or jpeg or jpg)</p>
                                    <Form.Control type="file"
                                      onClick={(event) => { const { target = {} } = event || {}; target.value = ""; }}
                                      onChange={this.changeHandler} />
                                    {this.state.isloading &&
                                      <Spinner animation="border" variant="dark"
                                        style={{ position: "fixed", top: "50%", left: "50%" }} />
                                    }
                                  </div>
                                }


                                {//(this.state.is_verify_enabled || this.state.is_verified_editable) &&
                                  this.state['src_selectedFile'] &&
                                  <div>
                                    <br />
                                    <img src={this.state['src_selectedFile']}
                                      style={{ width: 340, height: 220 }} />
                                  </div>
                                }

                              </div>
                            </div>
                          </div>
                        }

                        {<div className="card" style={{
                          textAlign: 'center'
                        }}>
                          {(this.state.bank_attachment_list || []).map((el, i) =>
                            <div key={i} className="form-group">
                              {el.length > 5 ?
                                <img className="photo" src={el} />
                                : null}
                            </div>
                          )
                          }
                        </div>
                        }



                        {
                          this.state.show_popup &&
                          this.state.pop_messagetext.length > 4 &&
                          <span style={{ color: 'InfoText', backgroundColor: '#e3e0de', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4 }} >
                            {this.state.pop_messagetext}
                          </span>
                        }
                        <br />
                        {
                          // !this.state.is_stage_verified && 
                          this.state.bank_information.length > 0 &&
                          this.state.bank_information[0]['text'] &&
                          this.state.is_confirm_chk_enabled &&
                          !this.state.show_popup &&
                          ((this.state.bank_attachment_list || []).length > 0 ||
                            this.state.selectedFile) &&
                          <div className="text-center">
                            <Button
                              className="btn btn-success btn-block"
                              variant="success"
                              onClick={this.onVerifyStage}
                              disabled={this.state.isloading}
                            >
                              Confirm
                            </Button>
                          </div>
                        }

                        {
                          this.state.is_stage_verified &&
                          <table style={{ width: '100%' }}>
                            <tr>
                              <td style={{
                                height: 30,
                                textAlign: this.state.is_stage_verified ? 'left' : 'center'
                              }}>
                                <Link to={'/aadhaarVerification'} type="submit"
                                  className="btn btn-warning pl-5 pr-5">Previous</Link>
                              </td>
                              <td style={{ height: 30, textAlign: 'right' }}>

                                <Link to={'/personalVerification'} type="submit"
                                  className="btn btn-success pl-5 pr-5">Next</Link>
                              </td>
                            </tr>
                          </table>
                        }

                      </form>
                  )}

                  {this.state.show_popup && (
                    <Modal show={this.state.show_popup} onHide={() => this.closePopup()}>
                      <Modal.Header>

                      </Modal.Header>
                      <Modal.Body>

                        <p>{this.state["pop_messagetext"]}</p>

                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="primary" onClick={() => this.closePopup()}>
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  )}

                  {this.state.isloading && (
                    <Spinner
                      animation="border"
                      variant="dark"
                      style={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        zIndex: 899,
                      }}
                    />
                  )}


                  <Modal show={this.state.modalshow} onHide={() => this.handleClose()}>
                    <Modal.Header>
                      <Modal.Title>Add bank Account</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Do you want to cancel the process?</Modal.Body>
                    <Modal.Footer>
                      <Button variant="primary" onClick={() => this.handleClose()}>
                        Yes
                      </Button>
                      <Button variant="secondary" onClick={() => this.Showaccount()}>
                        No
                      </Button>

                    </Modal.Footer>
                  </Modal>
                  
              </div>



              {!this.state.isloading &&
                <div class="card" style={{ alignSelf: 'center' }}>

                  {this.state.activity_mode === "list" &&
                      (this.state.list_bank_account || []).length > 0 && (
                        <Button
                          className="btn btn-success btn-block"
                          variant="success"
                          // disabled={this.state.is_primary_available}
                          onClick={() => {
                            this.setState({ activity_mode: "add" });
                          }}
                        >
                          Add Account
                      </Button>
                    )}

                  {/* <Button type="button"
                        className="btn btn-sm btn-primary"
                        style={{ color: 'white' }}
                        onClick={() => {
                          this.props.history.push({
                            pathname: "/mandateList",
                          })
                        }}
                        disabled={(this.state.list_bank_account || []).length < 1}
                      >
                        Go to Mandate List
                      </Button> */}
                </div>
              }

            </div>
          </div>
        </div>

      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.verify.loading,
  bank_result: state.verify.bank_result,
});


export default connect(mapStateToProps, {
  loadBankAccountList,
  saveBankAccount,
  validateBankAccount,
  verifyStage,
  verifyIdProof
})(BankAccountList);
