import {
    CHART_LIST

  } from '../actions/types';
  
  const initialState = {
    token: localStorage.getItem('token'),
    isAuthenticated: null,
    loading: true,
    chart_result: null

  };
  
  function chartReducer(state = initialState, action) {

    const { type, payload } = action;

    switch (type) {
      
      case CHART_LIST:
          return {
            ...state,
            chart_result: payload,
            isAuthenticated: true,
            loading: false
          };
      default:
        return state;
       
    }
   
  }
  
  export default chartReducer;
  