import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button, Spinner, Modal } from 'react-bootstrap';
import Select from 'react-select';
import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";
import Popup from '../popup';
import OTPInput, { ResendOTP } from "otp-input-react";

const Nominee2factorAuth = ({ prevStep, nextStep, on2FactorAuthentication,
  handle2FactorChange, values, errors, loading }) => {

  const renderButton = buttonProps => {
    return (
      <div>
        <button onClick={on2FactorAuthentication} {...buttonProps}
          class={buttonProps.remainingTime !== 0 ? `btn btn-outline-secondary` : `btn btn-outline-success`}>
          {`Resend OTP`}
        </button>
        <br />
        <div style={{ textAlign: 'left' }}>
          {buttonProps.remainingTime !== 0
            ? <span>Didn't receive the otp ? You can resend otp in
              <span style={{ fontWeight: 'bold', color: 'green' }}>{' '}{buttonProps.remainingTime}</span> secs.</span>
            : ``}
        </div>
      </div>
    );
  };
  const renderTime = () => React.Fragment;

  const Continue = e => {
    e.preventDefault();
    nextStep();
  }

  const Previous = e => {
    e.preventDefault();
    prevStep();
  }

  const sendOTP = e => {
    e.preventDefault();
    on2FactorAuthentication();
  }

  return (


    <>
      <Header1 />
      <Sidebar />
      <div className="authincation section-padding" style={{ marginTop: 70 }}>
        <div className="content-body">
          <div className="container">
            <div class="row">
              <div class="col-xl-12">

                <h6 className="text-center mb-5">Two Factor Authentication <br />
                  <span style={{ textAlign: 'center', fontSize: 12 }}>
                    (As per SEBI guidelines two factor authentication is mandatory for adding your nominee information)
                  </span>
                </h6>

                <div className="card" style={{ marginTop: -30 }}>
                  <div className="card-body">

                    <Form style={{ marginTop: -10 }}>

                      {values['is_bse_nominee_verified'] &&
                        <div class="row">
                          <div class="col">
                            OTP verification on your mobile number & email id were successfull.
                            Kindly click "Next" button to submit your identity
                          </div>
                        </div>
                      }
                      {!values['is_bse_nominee_verified'] &&
                        <div class="row">
                          <div class="col">

                            <Form.Group className="mb-3">
                              <Form.Label>Enter OTP received on your registered mobile</Form.Label>
                              <OTPInput
                                value={values['two_factor_authentication']['mobile_otp']}
                                onChange={handle2FactorChange('mobile_otp', 'two_factor_authentication')}
                                autoFocus
                                OTPLength={6}
                                otpType="number"
                                secure
                                isInputNum={true}
                              />
                            </Form.Group>
                            <br />
                            <Form.Group
                              className="mb-3"
                            >
                              <Form.Label>Enter OTP received on your registered email</Form.Label>
                              <OTPInput
                                value={values['two_factor_authentication']['email_otp']}
                                onChange={handle2FactorChange('email_otp', 'two_factor_authentication')}
                                OTPLength={6}
                                otpType="number"
                                secure
                                isInputNum={true}
                              />
                            </Form.Group>
                            <div className="text-left">
                              <ResendOTP maxTime={120} renderButton={renderButton} style={{ color: 'black', marginTop: 20 }} renderTime={renderTime}
                                onResendClick={on2FactorAuthentication}
                              />
                              <hr />
                            </div>
                          </div>

                        </div>
                      }

                    </Form>

                    {loading &&
                      <Spinner animation="border" variant="dark"
                        style={{ position: "fixed", top: "50%", left: "50%" }} />
                    }

                  </div>
                </div>


                <div className="text-center mb-4">
                  <Button
                    onClick={Previous}
                    type="submit"
                    variant="secondary"
                    color="primary"
                    style={{ 'width': '120px' }}
                  >
                    Previous
                  </Button>
                  <span style={{ marginRight: 70 }}>
                  </span>
                  <Button
                    disabled={
                      ((values['two_factor_authentication']['mobile_otp'] || '').length < 6 ||
                        (values['two_factor_authentication']['email_otp'] || '').length < 6 ||
                        loading)
                      &&
                      !values['is_bse_nominee_verified']
                    }
                    onClick={Continue}
                    type="submit"
                    variant="success"
                    color="primary"
                    style={{ 'width': '120px' }}
                  >
                    Next
                  </Button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Nominee2factorAuth;