import React from "react";
import { Link } from "react-router-dom";
import { Button, Spinner, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { resendOtp, validateOtp } from "../../jsx/actions/auth";
import { Redirect } from "react-router-dom";
import Popup from "./popup";
import Background from "../../images/background/bg-14.jpg";
import OTPInput, { ResendOTP } from "otp-input-react";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";

class Otp2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: "",
      password: "",
      data: "",
      show_popup: false,
      popup_message: "",
      isloading: false,
      route_parameters: null,
      is_resendotp_clicked: false,
      after_resendotp_token_id: ''
    };
  }

  componentDidMount()
   {
    document.addEventListener("keydown", this.handleKeyDown);

    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.route_parameters
    ) {
      this.setState({
        isloading: false,
        route_parameters: this.props.location.state.route_parameters,
      });
      window.history.replaceState(null, "");
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown = (event) => {
    if (event.key === "Enter") {
      this.onSubmit();
    }
  };

  closePopup = () => {
    this.setState({
      show_popup: false,
    });
  };

  resendOtp = () => {
    this.setState({
      isloading: true,
      otp: "",
    });

    let body_parameters = this.state.is_resendotp_clicked ? 
    {
      is_external_bb_access_token: true,
      bb_access_token: this.state.after_resendotp_token_id,
      login_mobile_number: this.state.route_parameters['login_mobile_number'],
      login_email: this.state.route_parameters['login_email'],
      login_role: this.state.route_parameters['login_role'],
      login_request_from: this.state.route_parameters['login_request_from'],
    } : this.state.route_parameters ;

    this.props
      .resendOtp(body_parameters)
      .then((response) => {

        if (this.props.login_otp_result["code"] !== "000")
          this.setState({
            isloading: false,
            show_popup: true,
          });
        else 
          this.setState({ 
            isloading: false,
            is_resendotp_clicked: true,
            after_resendotp_token_id: this.props.login_otp_result['token_id']
          });
      })
      .catch((err) => {
        alert(err);
      });
  };

  onSubmit = () => {
    const { otp } = this.state;
    this.setState({
      isloading: true,
    });

    this.props
      .validateOtp({
        login_otp: otp,
        login_role: "customer",
        device_id:
          localStorage.getItem(`mobile_device_id`) ||
          new window.DeviceUUID().get(),
        is_external_bb_access_token: true,
        bb_access_token: this.state.is_resendotp_clicked ? 
          this.state.after_resendotp_token_id : this.state.route_parameters["token_id"],
      })
      .then((response) => {
        if (this.props.login_otp_result["code"] === "000") {
          this.setState({
            isloading: false,
          });
          this.props.history.push({
            pathname: "/" + this.props.login_otp_result["navigateScreen"] + "/",
            state: { otp_result: this.props.login_otp_result },
          });
        } else
          this.setState({
            isloading: false,
            otp: "",
            show_popup: true,
            popup_message: this.props.login_otp_result["messageText"],
          });
      })
      .catch((err) => {
        alert(err);
      });
  };


   maskeMobileNumber =(mobileNumber)=>{
    if(mobileNumber && mobileNumber.length === 10){
      const firstTwoDigits = mobileNumber.substring(0,2);
       const lastFourDigits = mobileNumber.substring(6);
       const maskedDigits = '*'.repeat(6);

       return '+91'+ " " +firstTwoDigits + maskedDigits + lastFourDigits;
    }

    else {
      return "";
    }
  }
  render() {
    // if(!this.state.route_parameters)
    //    localStorage.removeItem('token');

    const renderButton = (buttonProps) => {
      return (
        <div class="row">
          <div class="col-6">
            <button
              style={{ width: 140 }}
              onClick={this.resendOtp}
              {...buttonProps}
              class={
                buttonProps.remainingTime !== 0
                  ? `btn btn-outline-secondary`
                  : `btn btn-outline-success`
              }
            >
              {`Resend OTP`}
            </button>
          </div>
          <div class="col">
            {buttonProps.remainingTime !== 0 ? (
              <span>
                <p>
                  You can resend otp in
                  <span style={{ fontWeight: "bold", color: "green" }}>
                    {" "}
                    {buttonProps.remainingTime}
                  </span>{" "}
                  secs.
                </p>
              </span>
            ) : (
              ``
            )}
          </div>
        </div>
      );
    };
    const renderTime = () => React.Fragment;

    return (
      <>
        <div
          style={{
            background: `url(${Background})`,
            backgroundRepeat: "repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <div className="authincation section-padding">
            <div className="container h-100">
              <div className="row justify-content-center h-100 align-items-center">
                <div>
                  <div className="mini-logo text-center my-1">
                    <Link>
                      <img
                        src={require("./../../images/logo.png")}
                        alt=""
                        width="200px"
                      />
                    </Link>
                  </div>

                  <div>
                    <div className="card-body">
                      {this.state.isloading && (
                        <Spinner
                          animation="border"
                          variant="dark"
                          style={{ position: "fixed", top: "50%", left: "50%" }}
                        />
                      )}
                      {/* <Link className="page-back text-muted" to={'/signin'}><span><i
                                                className="fa fa-angle-left"></i></span> Back</Link> */}

                      {!this.state.route_parameters && (
                        <Card
                          sx={{
                            backgroundColor: "#edf3f0",
                            p: 2,
                            borderRadius: 2,
                          }}
                        >
                          <CardContent>
                            <Stack direction="row">
                              <Typography flex={1} whiteSpace="pre-line">
                                Sorry the information you are looking for could
                                not be found
                              </Typography>
                            </Stack>
                          </CardContent>
                          <CardContent>
                            <p>
                              <span>
                                <Link to={"/signin"} title="OrderList">
                                  Click here
                                </Link>{" "}
                                to Sign-In again.
                              </span>
                            </p>
                          </CardContent>
                        </Card>
                      )}

                      {this.state.route_parameters && (
                        <form action="#">
                          <h4 className="text-center">OTP Verification</h4>
                          <p className="text-center mb-5">
                            You will receive an OTP on your registered mobile
                            number :  <strong>{this.maskeMobileNumber(this.state.route_parameters.login_mobile_number)}</strong>
                          </p>

                          <div className="form-group">
                            <label style={{ marginBottom: 10 }}>
                              Enter the OTP
                            </label>
                            <br />
                            <OTPInput
                              value={this.state.otp}
                              onChange={(event) => {
                                this.setState({ otp: event });
                              }}
                              shouldAutoFocus={true}
                              OTPLength={6}
                              otpType="number"
                              disabled={false}
                              isInputSecure={true}
                              isInputNum={true}
                            />
                          </div>

                          <div class="row">
                            <ResendOTP
                              maxTime={90}
                              renderButton={renderButton}
                              style={{ color: "black", marginTop: 20 }}
                              renderTime={renderTime}
                              onResendClick={(e) => {
                                this.resendOtp();
                              }}
                              // handelResendClick={() => this.resendOtp()}
                            />
                            <hr />
                          </div>
                          <br />

                          <div className="text-center">
                            <div class="row">
                              <div class="col-3">
                                <Button
                                  variant="primary"
                                  size="sm"
                                  onClick={() => {
                                    localStorage.removeItem("token");
                                    this.props.history.push({
                                      pathname: "/signin/",
                                      state: {},
                                    });
                                  }}
                                >
                                  Back
                                </Button>
                              </div>
                              <div class="col-8">
                                <Button
                                  className="btn btn-success btn-block"
                                  variant="success"
                                  onClick={this.onSubmit}
                                  disabled={this.state.otp.length < 6}
                                >
                                  Verify
                                </Button>
                              </div>
                            </div>
                          </div>
                        </form>
                      )}
                    </div>
                  </div>

                  {this.state.show_popup && (
                    <Modal
                      show={this.state.show_popup}
                      onHide={() => this.closePopup()}
                    >
                      <Modal.Header></Modal.Header>
                      <Modal.Body>
                        <p> {this.state.popup_message}</p>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="primary"
                          onClick={() => this.closePopup()}
                        >
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

Otp2.propTypes = {
  validateOtp: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  login_otp_result: state.auth.login_otp_result,
});
export default connect(mapStateToProps, { resendOtp, validateOtp })(Otp2);