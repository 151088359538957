import {
    LIST_REPORT_SUCCESS, LIST_REPORT_FAIL

  } from '../actions/types';
  
  const initialState = {
    token: localStorage.getItem('token'),
    isAuthenticated: null,
    loading: true,
    result: null

  };
  
  function reportReducer(state = initialState, action) {

    const { type, payload } = action;

    switch (type) {
      
      case LIST_REPORT_SUCCESS:
          return {
            ...state,
            result: payload,
            isAuthenticated: true,
            loading: false
          };
      case LIST_REPORT_FAIL:
        return {
          ...state,
          result: {},
          isAuthenticated: true,
          loading: false
        };
      default:
        return state;
       
    }
   
  }
  
  export default reportReducer;
  