import api from './api';

const setAuthToken = token => {

  if (token) {

    // if('g6Eg1l0aId3yzoosDaSfxeblOy2lXoUgXgFeHdnoYuUtIDIeIQ') {
    //   delete api.defaults.headers.common['bb-access-token'];
    //   localStorage.removeItem('token');
    // }
    // else {

    //   if(token.length > 106) {
        
    //     api.defaults.headers.common['bb-access-token'] = token;
    //     localStorage.setItem('token', token); 
    //   }

    // }

  } else {
    
    /*
    delete api.defaults.headers.common['bb-access-token'];
    localStorage.removeItem('token');
    */

  }

};

export default setAuthToken;
