import {
  VERIFY_PAN_SUCCESS,
  VERIFY_AADHAAR_SUCCESS,
  VERIFY_BANK_SUCCESS,
  VERIFY_PERSONAL_SUCCESS,
  VERIFY_CLIENT_LIST_SUCCESS,
  VERIFY_FAIL,
  DAILY_UPLOAD_SUCCESS,
  DAILY_UPLOAD_FAIL,

  } from '../actions/types';
  
  const initialState = {

    token: localStorage.getItem('token'),
    loading: true,
    user: null,
    pan_result: null,
    pan_stage_verified: false,
    aadhaar_result: null,
    aadhaar_stage_verified: false,
    bank_result: null,
    bank_stage_verified: false,
    personal_result: null,
    personal_stage_verified: false,
    client_result: null,
    daily_upload_result: null

  };
  
  function verifyReducer(state = initialState, action) {

    const { type, payload } = action;

    switch (type) {

      case VERIFY_PAN_SUCCESS:
        return {
          ...state,
          pan_result: payload,
          loading: false
        };
      
      case VERIFY_AADHAAR_SUCCESS:
        return {
          ...state,
          aadhaar_result: payload,
          loading: false
        };
      
      case VERIFY_BANK_SUCCESS:
        return {
          ...state,
          bank_result: payload,
          loading: false
        };
      
      case VERIFY_PERSONAL_SUCCESS:
        return {
          ...state,
          personal_result: payload,
          loading: false
        };

      case VERIFY_CLIENT_LIST_SUCCESS:
        return {
          ...state,
          client_result: payload,
          loading: false
        };

      case DAILY_UPLOAD_SUCCESS:
        return {
          ...state,
          daily_upload_result: payload,
          loading: false
        };        

      default:
        return state;
       
    }
   
  }
  
  export default verifyReducer;
  