
import React from "react";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";
import { Form, Modal, ListGroup, Row, Col, Container, Spinner } from "react-bootstrap";

import Select, { components } from "react-select";
import DatePicker from "react-datepicker";
import { listStpSchemes } from "../../../jsx/actions/master";
import { registerStpOrder, twoFactorAuthentication } from "../../../jsx/actions/orders";

import { Button, Badge, Collapse, Alert } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import moment from 'moment';
import OTPInput, { ResendOTP } from "otp-input-react";

import Emitter from '../../utils/emitter';

const filter_conditional_parameters = ['growth', 'minimum_investment', 'investment_method'];

class StpSchemeList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      masterList: JSON.parse(localStorage.getItem("masterList") || JSON.stringify({})),
      error: null,
      isLoaded: false,
      mutualFundList: [],
      pageIndex: 1,
      openCollapse: false,
      pageIndexAvailability: true,
      selected_order: {},
      selected_scheme: {},
      stp_selected_scheme_list: [],
      stp_order: {
        stp_frequency: '',
        stp_scheme: {},
        stp_scheme_code: {},
        stp_installments: '',
        stp_amount: '',
        stp_date: '',
        stp_date_value: '',
        stp_date_text: ``,
        validation_message_text: '',

        mfapi_scheme_code: '',
        selected_stp_frequency: [],
        selected_stp_scheme_code: [],
        selected_stp_installments: [],
        is_otp_enabled: false,
        two_factor_authentication: {
          mobile_otp: '',
          email_otp: '',
          twofactor_token_id: ''
        }
      },
      show_targetscheme_popup: false,
      show_stpdate_popup: false,
      popup_stp_date_list: [],
      filterOption: {
        scheme_amc: /*selectedOptionAmc*/[],
        category: [],
        risk: [],
        sub_category: [],
        growth: [],
        minimum_investment: [],
        investment_method: [],
      },
      stp_frequency_list: [],
      stp_scheme_specification_list: [],
      stp_installment_list: [],
      sub_category_list: [],
      selectedOptionAmc: [],
      selectedOptionCategory: [],
      selectedOptionRisk: [],
      selectedOptionSubCategory: [],
      selectedOptionGrowth: [],
      selectedOptionMinInvestment: [],
      selectedOptionInvestmentMethod: [],
      options: [],
      newValue: 0,
      scheme_list: [],
      stp_scheme_list: [],
      initial_scheme_list: [],
      perPage: 30,
      page: 1,
      pages: 0,
      isloading: false,

      notification_html: ``,

      display_mode: "scheme_transfer_from",
      modal_validation_message_text: ``,
      show_modal_pin_confirm_dialog: false,
      modal_pin_confirm_action: '',
      modal_pin_confirm_value: '',
      modal_pin_confirm_dialog_messagetext: 'Enter your 4 digit pin to complete your action',
      modal_pin_confirm_dialog_error_messagetext: ''
    };
  }

  componentDidMount() {

    window.addEventListener("scroll", () => {

      if (document.getElementById("navbar-portfolio-container") &&
        document.getElementById("navbar") &&
        document.getElementById("navbar").classList) {
        const topBorder = document
          .getElementById("navbar-portfolio-container")
          .getBoundingClientRect().top;

        topBorder >= 0
          ? document.getElementById("navbar").classList.remove("fixed-portfolio")
          : document.getElementById("navbar").classList.add("fixed-portfolio");
      }

    });

    if (this.props.location.state.selected_order) {

      this.setState({
        selected_order: this.props.location.state.selected_order
      });

      // this.loadSchemeList(this.props.location.state.selected_order);

    }

  }

  componentWillUnmount() {
    window.removeEventListener('scroll', null);
  }

  handleChangeAmc = (selectedOption) => {

    this.setState(
      {
        filterOption: { ...this.state.filterOption, scheme_amc: selectedOption },
        selectedOptionAmc: selectedOption,
        page: 0
      },
      () => this.filterGridList(/*selectedOption, 'scheme_amc'*/)

    );
  };

  handleChangeCategory = (selectedOption) => {

    this.setState(
      {
        filterOption: { ...this.state.filterOption, category: selectedOption },
        selectedOptionCategory: selectedOption,
        // sub_category_list: sub_category_list 
      },
      () => this.filterGridList(/*selectedOption, 'category'*/)

    );

  };

  handleChangeSubCategory = (selectedOption) => {

    this.setState({ selectedOptionSubCategory: selectedOption });
    this.filterGridList(/*selectedOption, 'sub_category'*/);

  };

  handleChangeRisk = (selectedOption) => {

    this.setState(
      {
        filterOption: { ...this.state.filterOption, risk: selectedOption },
        selectedOptionRisk: selectedOption
      },
      () => this.filterGridList(/*selectedOption, 'risk'*/)

    );

  };

  handleChangeGrowth = (selectedOption) => {

    this.setState(
      {
        filterOption: { ...this.state.filterOption, growth: selectedOption },
        selectedOptionGrowth: selectedOption
      },
      () => this.filterGridList(/*selectedOption, 'growth'*/)

    );

    // this.filterGrowthoption(selectedOption);
    // this.setState({ selectedOptionGrowth: selectedOption });

  };

  handleChangeMinInvestment = (selectedOption) => {

    this.setState(
      {
        filterOption: { ...this.state.filterOption, minimum_investment: selectedOption },
        selectedOptionMinInvestment: selectedOption
      },
      () => this.filterGridList(/*selectedOption, 'minimum_investment'*/)

    );

  };

  handleChangeInvestmentMode = (selectedOption) => {

    this.setState(
      {
        filterOption: { ...this.state.filterOption, investment_method: selectedOption },
        selectedOptionInvestmentMode: selectedOption
      },
      () => this.filterGridList(/*selectedOption, 'investment_method'*/)

    );

  };

  routeChange = (item) => {

    if ((window.matchMedia && window.matchMedia("(max-width: 480px)").matches)) {
      this.props.history.push({
        pathname: "/chartsmobile",
        state: {
          param_data: {
            scheme_isin: item['scheme_isin'],
            scheme_code: item['scheme_code'],
            scheme_name: item['scheme_name']
          }
        }
      });
    }
    else {

      const win = window.open("/charts/" + item['scheme_isin'] + '/' + item['scheme_code'] + '/' + item['scheme_name'], "_blank");
      win.focus();

    }

  };

  filterGridList = (amclistFilter) => {

    let _filtered_list = this.props.result['result']["scheme_list"] || []; //this.state.scheme_list || [];
    let _filterOption = amclistFilter ? amclistFilter : this.state.filterOption;

    if (amclistFilter) {

      this.setState({
        filterOption: {
          ...this.state.filterOption,
          scheme_amc: _filterOption['scheme_amc'],
          category: _filterOption['category'],
          risk: _filterOption['risk'],
          growth: _filterOption['growth'],
          minimum_investment: _filterOption['minimum_investment'],
          investment_method: _filterOption['investment_method']
        },
        selectedOptionGrowth: _filterOption['growth'],
        selectedOptionMinInvestment: _filterOption['minimum_investment'],
        selectedOptionInvestmentMode: _filterOption['investment_method']
        /*
        selectedOptionAmc: _filterOption['scheme_amc'],
        selectedOptionCategory: _filterOption['category'],
        selectedOptionRisk: _filterOption['risk'],
        selectedOptionSubCategory: _filterOption['sub_category']
        */
      });

    }

    let _selected_list = [];

    Object.keys(_filterOption).forEach(key => {

      /*if(key === 'risk' ||  key === 'category') {
        if(_filterOption[key]['value']) {
          _selected_list = _filterOption[key]['value'] === 'all' ? [] : [_filterOption[key]] ;
        }
        else
          _selected_list = [];
      }
      else*/
      _selected_list = _filterOption[key];

      if (_selected_list.length > 0) {

        if (filter_conditional_parameters
          .filter(__el => { return __el === key; }).length > 0) {

          switch (key) {

            case 'growth':

              let _filter = _filterOption[key];
              let _arr_values = [];
              let boolTrue = false;

              let __filtered_list = [];

              _filtered_list.forEach(el => {

                boolTrue = false;

                _filter.forEach(el_ => {

                  if (!boolTrue) {

                    _arr_values = el_.value.split('-');

                    boolTrue = (parseFloat(el['one_year_percent'] || 0) >= parseFloat(_arr_values[0]) &&
                      parseFloat(el['one_year_percent'] || 0) <= parseFloat(_arr_values[1]));

                    if (!boolTrue)
                      boolTrue = (parseFloat(el['three_year_percent'] || 0) >= parseFloat(_arr_values[0]) &&
                        parseFloat(el['three_year_percent'] || 0) <= parseFloat(_arr_values[1]));

                    if (!boolTrue)
                      boolTrue = (parseFloat(el['five_year_percent'] || 0) >= parseFloat(_arr_values[0]) &&
                        parseFloat(el['five_year_percent'] || 0) <= parseFloat(_arr_values[1]));

                  }

                  if (boolTrue)
                    __filtered_list.push(el);

                });

                // return boolTrue;

                /*
                return _filter.filter(el_ =>  {
                  _arr_values = el_.value.split('-');
                  return (parseFloat(el['one_year_percent'] || 0) >= parseFloat(_arr_values[0]) && 
                    parseFloat(el['one_year_percent'] || 0) <= parseFloat(_arr_values[1])) 
                    || 
                    (parseFloat(el['three_year_percent'] || 0) >= parseFloat(_arr_values[0]) && 
                    parseFloat(el['three_year_percent'] || 0) <= parseFloat(_arr_values[1]))
                    ||
                    (parseFloat(el['five_year_percent'] || 0) >= parseFloat(_arr_values[0]) && 
                    parseFloat(el['five_year_percent'] || 0) <= parseFloat(_arr_values[1])) ;
                });*/ //.length > 0;

              });

              _filtered_list = __filtered_list;

              // _filtered_list = [...new Set(_filtered_list)];

              break;
            case 'minimum_investment':
              _filtered_list = _filtered_list.filter(el => {

                return _filterOption[key].filter(el_ => {
                  return parseFloat(el['scheme_minimum_investment_amount'] || 0) >= parseFloat(el_.value) &&
                    parseFloat(el['scheme_minimum_investment_amount'] || 0) <= parseFloat(el_.value) + 10;
                }).length > 0;

              });
              break;
            case 'investment_method':

              let _condition = _filterOption[key].map(el => {
                return el['value'];
              });

              _filtered_list = _filtered_list.filter(el => {

                if (_condition.length > 1)
                  return (el[_condition[0]] === 'Y' || el[_condition[1]] === 'Y');
                else
                  return el[_condition[0]] === 'N';

              });
              break;

            case 'category':

              let condition_category = _filterOption[key].map(el => {
                return el['value'];
              });

              _filtered_list = _filtered_list.filter(el => {

                if (condition_category.length > 1)
                  return (el[condition_category[0]]);


              });
              break;

            default:
              break;
          }

        }
        else {

          let _loc = '';
          let _condition = _selected_list; /*.map(el => {
               return el.value.toLowerCase();
             });*/

          _filtered_list = _filtered_list.filter(el => {

            // return _condition.indexOf((el[key] || '').toLowerCase());
            _loc = (el[key] || '').toLowerCase();
            return _condition.filter(el_ => {
              return _loc === el_['value'].toLowerCase();
            }).length > 0;

          });

        }
      }

    });

    sessionStorage.setItem('amclistFilter', JSON.stringify(_filterOption));

    this.setState({
      scheme_list: _filtered_list,
      pages: Math.round/*floor*/(_filtered_list.length /
        this.props.result['per_page_count'] || this.state.perPage)
    });

  };

  filterGrowthoption = (selectedOption) => {

    let _filtered_list = this.state.scheme_list || [];

    let text = selectedOption[0].value || "";
    let filtered_list = _filtered_list.filter((item) => {
      return text.length > 1
        ? (
          item.three_year_percent.toString() ||
          item.one_month_percent.toString() ||
          item.one_year_percent.toString()
        ).match(text)
        : true;
    });

    filtered_list = [...new Set(filtered_list)];

    this.setState({
      scheme_list: filtered_list,
      pages: Math.round/*floor*/(filtered_list.length /
        this.props.result['per_page_count'] || this.state.perPage)
    });

  };

  filterMininvestmentoption = (selectedOption) => {

    let _filtered_list = this.state.scheme_list || [];

    let text = selectedOption[0].value || "";
    let filtered_list = _filtered_list.filter((item) => {
      return text.length > 1
        ? item.minimum_investment_amount.toString().match(text)
        : true;
    });

    this.setState({
      scheme_list: filtered_list,
      pages: Math.round/*floor*/(filtered_list.length /
        this.props.result['per_page_count'] || this.state.perPage)
    });

  };

  loadSchemeList = (selected_order) => {

    this.setState({ isloading: true });

    this.props
      .listStpSchemes({
        file_index: this.state.pageIndex,
        scheme_filter: {
          scheme_amc: selected_order['scheme_information']['param_amc_code'],
          request_for: 'stp schemes',
          selected_order: selected_order
        }
      })
      .then((response) => {

        const { perPage } = this.state;

        if (this.props.result["code"] === "000") {

          this.setState({
            show_targetscheme_popup: true,
            scheme_list: this.props.result['result']["scheme_list"] || [],
            stp_scheme_list: this.props.result['result']["stp_scheme_list"] || [],
            initial_scheme_list: this.props.result['result']["scheme_list"] || [],

            // stp_frequency_list: this.props.result["stp_frequency_list"] || [],
            // stp_installment_list: this.props.result["stp_installment_list"] || [],

            pages:
              Math.round/*floor*/(
                (this.props.result['result']["scheme_list"] || []).length /
                this.props.result['per_page_count'] || perPage
              ),
            isloading: false,
            perPage: this.props.result['per_page_count'] || this.state.perPage
          });

          this.filterGridList(
            sessionStorage.getItem('amclistFilter') ? JSON.parse(sessionStorage.getItem('amclistFilter')) : null);

          if (sessionStorage.getItem('amclistFilter'))
            sessionStorage.removeItem('amclistFilter');

        } else {
          alert(this.props.result["messageText"]);
          this.setState({ isloading: false });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleToSchemeCheck = (/*e,*/ item) => {

    // let _list = this.state.initial_scheme_list;

    // _list.forEach((el) => {
    //   el["is_checked"] = false;

    //   if (el["_id"] === item["_id"])
    //     el["is_checked"] = true; //e.target.checked;
    // });

    let _stp_scheme_filtered = this.state.stp_scheme_list.filter(el => {
      return el['scheme_isin'] === item['scheme_isin'];
    });

    _stp_scheme_filtered = _stp_scheme_filtered.map(el => {
      return {
        label: 'Min ₹' + el['stp_in_minimum_installment_amount']
          + ' - Max ₹' + (parseInt(el['stp_in_maximum_installment_amount']) < 1
            ? 99999999.000 : el['stp_in_maximum_installment_amount'])
          + '| SETTLEMENT TYPE :' + el['param_settlement_type']
          + (el['scheme_name'].split('-').length < 3 ?
            '' :
            ('|' + el['scheme_name'].split('-')[el['scheme_name'].split('-').length - 1]))
          + '| REDEEM ' + (el['param_redemption_allowed'] === 'N' ? 'NOT ' : '') + 'ALLOWED',
        value: el['scheme_code'],
        frequency: el['stp_frequency']
      }
    });

    this.setState({
      stp_frequency_list: this.props.result["stp_frequency_list"] || [],
      stp_installment_list: this.props.result["stp_installment_list"] || [],
      stp_order: {
        ...this.state.stp_order,
        stp_frequency: '',
        stp_scheme: {},
        stp_scheme_code: {},
        stp_installments: '',
        stp_amount: '',
        stp_date: '',
        stp_date_value: '',
        validation_message_text: '',
        selected_stp_frequency: [],
        selected_stp_scheme_code: [],
        selected_stp_installments: [],
      },
      // scheme_list: _list,
      stp_scheme_specification_list: _stp_scheme_filtered,
      selected_scheme: item,
      show_targetscheme_popup: false
    });
    // window.scrollTo(0, document.body.scrollHeight);

  };

  handleChangeSelect = (selectedOption, input) => {

    if (input === 'stp_frequency') {

      this.setState({
        stp_order: {
          ...this.state.stp_order,
          [input]: selectedOption.value,
          selected_stp_frequency: selectedOption
        },
        stp_selected_scheme_list: this.state.stp_scheme_specification_list.filter(el => {
          return el['frequency'].toLowerCase() === selectedOption.value.toLowerCase();
        })
      });

    }
    else if (input === 'stp_scheme_code') {

      let _selected_scheme = this.state.stp_scheme_list.filter(el => {
        return el['scheme_code'] === selectedOption.value;
      });

      if (_selected_scheme.length > 0) {

        _selected_scheme = _selected_scheme[0];

        let _loc = [];
        for (var _rec = parseInt(_selected_scheme['stp_minimum_installment_numbers']);
          _rec <= parseInt(_selected_scheme['stp_maximum_installment_numbers']); _rec++) {
          _loc.push({ value: _rec, label: _rec });
        }

        let _mfapi_scheme_code = this.state.scheme_list.filter(el => {
          return el['scheme_isin'] === _selected_scheme['scheme_isin'] ||
            el['scheme_isin_1'] === _selected_scheme['scheme_isin'];
        });

        _selected_scheme['mfapi_scheme_code'] = _mfapi_scheme_code.legnth > 0 ?
          _mfapi_scheme_code[0]['scheme_code'] : '';

        this.setState({

          stp_order: {
            ...this.state.stp_order,
            [input]: _selected_scheme['scheme_code'],
            mfapi_scheme_code: _mfapi_scheme_code.legnth > 0 ?
              _mfapi_scheme_code[0]['scheme_code'] : '',
            stp_scheme: _selected_scheme,
            selected_stp_scheme_code: selectedOption
          },
          stp_installment_list: _loc,
          popup_stp_date_list: _selected_scheme['stp_dates'].split(',')

        });

      }

    }
    else if (input === 'stp_installments') {

      this.setState({
        stp_order: {
          ...this.state.stp_order,
          [input]: selectedOption.value,
          selected_stp_installments: selectedOption
        }
      });

    }
    // else if (input === 'stp_amount') {

    // }

  };

  handlePageClick = (event) => {

    let page = event.selected;
    this.setState({ page });
    window.scrollTo(0, 0);

  };

  onNextPrevious = (count) => {
    // this.setState({pageIndex: this.state.pageIndex + count});
  };

  getBase64Image = (imgUrl) => {

    var img = new Image();

    // onload fires when the image is fully loadded, and has width and height

    img.onload = function () {

      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      var dataURL = canvas.toDataURL("image/png"),
        dataURL = dataURL.replace(/^data:image\/(png|jpg);base64,/, "");

      return (dataURL); // the base64 string

    };

    // set attributes and src 
    img.setAttribute('crossOrigin', 'anonymous'); //
    img.src = imgUrl;

  }

  stpParameterValidation() {

    let _stp_order = this.state.stp_order;

    var isValid = (_stp_order['stp_frequency'] || '').length > 4 && parseInt(_stp_order['stp_installments'] || 0) >= 1
      && !isNaN(this.state.stp_order['stp_amount'] || '')
      && parseInt(this.state.stp_order['stp_amount']) >= parseInt(_stp_order['stp_scheme']['stp_in_minimum_installment_amount'])
      && parseInt(this.state.stp_order['stp_amount']) <= parseInt(_stp_order['stp_scheme']['stp_in_maximum_installment_amount']);

    return isValid;

    /*
    return parseFloat(_sip_order['sip_amount']) >= parseFloat(this.state.selected_sip_order['minimum_installment_amount']) &&
      parseFloat(_sip_order['sip_amount']) <= parseFloat(this.state.selected_sip_order['maximum_installment_amount']) &&
      _sip_order['sip_frequency'].length > 2 && _sip_order['sip_installment_numbers'] > 0;
    */
  }

  startSTP = () => {

    let _stp_order = this.state.stp_order;
    let isValid = (parseInt(_stp_order['stp_installments']) * parseInt(this.state.stp_order['stp_amount']))
      <= parseInt(this.state.selected_order['stp_available_transfer_amount']);

    this.setState({
      stp_order: {
        ...this.state.stp_order,
        validation_message_text: isValid ?
          '' :
          'Your Monthly Installment Amount shall not exceed Rs.' +
          (parseInt(this.state.selected_order['stp_available_transfer_amount']) / parseInt(_stp_order['stp_installments'])).toFixed(2)
      }
    });

    if (isValid) {

      this.setState({
        show_modal_pin_confirm_dialog: true,
        modal_pin_confirm_action: 'register stp',
        modal_pin_confirm_value: ''
      });

    }

  }

  confirmSTP = () => {

    /*
    let _stp_order = this.state.stp_order;
    let isValid = (parseInt(_stp_order['stp_installments']) * parseInt(this.state.stp_order['stp_amount']))
      <= parseInt(this.state.selected_order['stp_available_transfer_amount']);

    this.setState({
      stp_order: {
        ...this.state.stp_order,
        modal_validation_message_text: isValid ?
          '' :
          'Your Monthly Installment Amount shall not exceed Rs.' +
          (parseInt(this.state.selected_order['stp_available_transfer_amount']) / parseInt(_stp_order['stp_installments']))
      }
    });

    if (isValid) 
    */
    {

      this.setState({ isloading: true });

      let _target_scheme = [this.state.selected_scheme]; /*this.state.scheme_list.filter(el => {
        return el['scheme_isin'] === this.state.stp_order['stp_scheme']['scheme_isin'] ||
          el['scheme_isin_1'] === this.state.stp_order['stp_scheme']['scheme_isin']
      });

      if (_target_scheme.length > 0) */
      {

        /*
        _target_scheme = _target_scheme[0]['scheme_master'].filter(el => {
          return el['param_scheme_code'] === this.state.stp_order['stp_scheme_code']
        });
        */

        if (_target_scheme.length > 0) {

          _target_scheme = _target_scheme[0];

          if ((_target_scheme['mfapi_scheme_code'] || '').length < 3) {

            let _mfapi_scheme_code = this.state.scheme_list.filter(el => {
              return el['scheme_isin'] === this.state.stp_order['stp_scheme']['scheme_isin'] ||
                el['scheme_isin_1'] === this.state.stp_order['stp_scheme']['scheme_isin'];
            });

            _target_scheme['mfapi_scheme_code'] = _mfapi_scheme_code.length > 0 ?
              _mfapi_scheme_code[0]['scheme_code'] : '';

          }

          this.props
            .registerStpOrder(Object.assign({
              source_scheme: this.state.selected_order,
              target_scheme: _target_scheme,
              stp_order: this.state.stp_order
            },
              { pin_verification_value: this.state.modal_pin_confirm_value })
            ).then((response) => {

              if (this.props.order_result["code"] !== "000") {
                this.setState({
                  isloading: false,
                  modal_pin_confirm_dialog_error_messagetext: this.props.order_result["messageText"]
                });
              }
              else {

                let _result = this.props.order_result["result"];

                this.setState({
                  isloading: false,
                  stp_frequency_list: [],
                  stp_installment_list: [],
                  stp_order: {
                    ...this.state.stp_order,
                    stp_frequency: '',
                    stp_scheme: {},
                    stp_scheme_code: {},
                    stp_installments: '',
                    stp_amount: '',
                    stp_date: '',
                    stp_date_value: '',
                    validation_message_text: '',
                    selected_stp_frequency: [],
                    selected_stp_scheme_code: [],
                    selected_stp_installments: [],
                  },
                  display_mode: 'scheme_transfer_completed',
                  notification_html: _result['notification_html'],
                  show_modal_pin_confirm_dialog: false
                });

                alert(this.props.order_result["messageText"]);

              }
            })
            .catch((err) => {
              alert(err);
            });
        }
        else
          alert('Your inputs are not valid');
      }
      /*else
        alert('Your inputs are not valid');*/

    }

  }

  createDefaultMarkup() {
    return {
      __html: this.state.notification_html,
    }
  };

  sendOTP = () => {

    /*
    let _stp_order = this.state.stp_order;
    let isValid = (parseInt(_stp_order['stp_installments']) * parseInt(this.state.stp_order['stp_amount']))
      <= parseInt(this.state.selected_order['stp_available_transfer_amount']);

    this.setState({
      stp_order: {
        ...this.state.stp_order,
        validation_message_text: isValid ?
          '' :
          'Your Monthly Installment Amount shall not exceed Rs.' +
          (parseInt(this.state.selected_order['stp_available_transfer_amount']) / parseInt(_stp_order['stp_installments'])).toFixed(2)
      }
    });

    if (isValid)
    */
    {

      this.setState({ isloading: true });

      this.props
        .twoFactorAuthentication({
          client_info: {
            two_factor_authentication: this.state.stp_order['two_factor_authentication'],
            request_action: 'stp registration',
            is_otp_email_required: true
          },
        })
        .then((response) => {

          if (this.props.order_result["code"] !== "000") {
            this.setState({ isloading: false });
            alert(this.props.order_result["messageText"]);
          }
          else {

            let _result = this.props.order_result; //["result"];
            
            if (_result && _result['twofactor_id']) {

              this.setState({
                isloading: false,
                show_modal_pin_confirm_dialog: true,
                modal_pin_confirm_action: 'register stp',
                modal_pin_confirm_value: '',
                stp_order: {
                  ...this.state.stp_order,
                  is_otp_enabled: true,
                  two_factor_authentication: {
                    mobile_otp: '',
                    email_otp: '',
                    twofactor_token_id: _result['twofactor_id']
                  }
                }
              });

            }

          }
        })
        .catch((err) => {
          alert(err);
        });

    }

  }

  handle2FactorChange = (input, attribute, event) => e => {

    if (e)
      this.setState({
        [event]: {
          ...this.state[event],
          [attribute]: {
            ...this.state[event][attribute],
            [input]: e
          }
        }
      });
    else
      this.setState({ [input]: e });

  }

  render() {

    const {
      filterOption,
      selectedOptionGrowth,
      selectedOptionMinInvestment,
      selectedOptionInvestmentMode
    } = this.state;
    const { page, perPage, pages, scheme_list } = this.state;

    let _list_scheme = scheme_list.length > perPage ?
      scheme_list.slice(page * perPage, (page + 1) * perPage) : scheme_list;

    const renderButton = buttonProps => {
      return (
        <div className="row">
          <div class="col">
            <button onClick={this.sendOTP} {...buttonProps}
              class={buttonProps.remainingTime !== 0 ? `btn btn-outline-secondary` : `btn btn-outline-success`}>
              {`Resend OTP`}
            </button>
          </div>
          <div class="col-6" >
            {buttonProps.remainingTime !== 0
              ? <span>
                You can resend otp in
                <span style={{ fontWeight: 'bold', color: 'green' }}>{' '}{buttonProps.remainingTime}</span> secs.
              </span>
              : ``}
          </div>
        </div>
      );
    };
    const renderTime = () => React.Fragment;

    return (
      <>
        <Header1 />
        <Sidebar />

        <div className="content-body" style={{ marginBottom: 100 }}>
          <div className="container">


            {this.state.selected_order &&

              <div className="row" >

                <div id="navbar-portfolio-container">
                  <div id="navbar" >
                    <div className="card" style={{ backgroundColor: '#f8f8fb' }}>
                      <div className="card-header">
                        <div class="row">
                          <div class={this.state.display_mode !== 'scheme_transfer_completed' ?
                            "col" : "col-8"}>
                            <h5 >Sytematic Transfer Plan</h5>
                          </div>
                          {
                            this.state.display_mode === 'scheme_transfer_completed' &&
                            <div class="col-4" >
                              <button type="button"
                                className="btn-sm btn-primary"
                                style={{ marginLeft: -1, marginTop: '-15px', color: 'white', width: '110px' }}
                                onClick={() => {
                                  this.props.history.push({
                                    pathname: "/stplist",
                                  })
                                }}
                              >
                                Go to STP List
                              </button>
                            </div>
                          }
                        </div>
                      </div>

                      <div class="row" style={{ marginTop: '-10px' }}>
                        <hr />
                      </div>
                    </div>
                  </div>
                </div>

                <div style={{ marginTop: 60, marginBottom: 30 }}>

                  {this.state.isloading && (
                    <Spinner
                      animation="border"
                      variant="dark"
                      style={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        zIndex: 899,
                      }}
                    />
                  )}

                  {this.state.display_mode === 'scheme_transfer_from' &&
                    <div>
                      <div className="row">
                        <span
                          style={{
                            fontSize: 14
                          }}
                        >
                          {this.state.selected_order["mf_order_scheme_name"] || ""}
                        </span>
                      </div>
                      {/* <div class="row">

                        <div class="col-6">

                          <div class="row">
                            <div class="col-4">
                              Invested
                            </div>
                            <div class="col-8">
                              <span style={{
                                color: 'InfoText',
                                backgroundColor: '#e6c8c8',
                                fontWeight: 500,
                                padding: 4, margin: 4, borderRadius: 4
                              }}>
                                <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                {this.state.selected_order['mf_order_overall_investment_amount']}
                              </span>
                            </div>
                            <p></p>
                            <div class="row">
                              <div class="col-4">
                                Returns
                              </div>
                              <div class="col-8">
                                <span style={{
                                  color: 'InfoText',
                                  backgroundColor: '#f8f2f2',
                                  padding: 4, paddingLeft: 12, paddingRight: 8, margin: 4, borderRadius: 4,
                                  color: parseFloat(this.state.selected_order['total_returns_amount'] || 0) -
                                    parseFloat(this.state.selected_order['mf_order_overall_investment_amount']) < 0
                                    ? "#f57362"
                                    : (parseFloat(this.state.selected_order['total_returns_amount'] || 0) -
                                      parseFloat(this.state.selected_order['mf_order_overall_investment_amount'])) === 0 ? '' : 'green',
                                }}>
                                  <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                  {this.state.selected_order['total_returns_amount']}
                                </span>

                              </div>
                            </div>
                          </div>

                        </div>

                        <div class="col-6">
                          <div class="row">
                            <div class="col-4">
                              {parseFloat(this.state.selected_order['returns_difference_amount'] || 0) < 0 ? `Loss` : 'Profit'}
                            </div>
                            <div class="col-8">
                              <span style={{
                                color: 'InfoText',
                                padding: 4, margin: 4, borderRadius: 4,
                                color:
                                  parseFloat(this.state.selected_order['returns_difference_amount'] || 0) < 0
                                    ? "#f57362"
                                    : parseFloat(this.state.selected_order['returns_difference_amount'] || 0) === 0 ? '' : 'green',
                              }}>
                                <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                {
                                  this.state.selected_order['returns_difference_amount'] || 0
                                }
                              </span>
                            </div>
                            <p></p>
                            <div class="row">
                              <div class="col-4">
                                Returns%
                              </div>
                              <div class="col-8">
                                <span style={{
                                  fontFamily: 'Verdana, sans-serif',
                                  padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4,
                                  color:
                                    parseFloat(this.state.selected_order['returns_difference_amount']) < 0
                                      ? "#f57362"
                                      : (parseFloat(this.state.selected_order['returns_difference_amount']) === 0 ? '' : 'green'),
                                }}>
                                  {parseFloat(this.state.selected_order['returns_difference_amount']) > 0 ? `+` : ``}
                                  {this.state.selected_order['total_returns_percent']}</span>

                              </div>
                            </div>
                          </div>
                        </div>

                      </div> */}
                      <table style={{ width: '100%' }}>
                        <tr>
                          <td style={{ height: 30, width: '50px', backgroundColor: '#f7f5f5', border: '1px solid #b9bdc4', textAlign: 'left' }}>
                            <span style={{
                              margin: 4
                            }}>
                              Invested
                            </span>
                          </td>
                          <td style={{ height: 30, border: '1px solid #b9bdc4', textAlign: 'center' }}>
                            <span style={{
                              margin: 4
                            }}>
                              <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                              {this.state.selected_order['mf_order_overall_investment_amount']}
                            </span>
                          </td>
                          <td style={{ height: 30, width: '50px', backgroundColor: '#f7f5f5', border: '1px solid #b9bdc4', textAlign: 'left' }}>
                            <span style={{
                              margin: 4
                            }}>
                              {parseFloat(this.state.selected_order['returns_difference_amount'] || 0) < 0 ? `Loss    ` : `Profit  `}
                            </span>
                          </td>
                          <td style={{ height: 30, border: '1px solid #b9bdc4', textAlign: 'center' }}>
                            <span style={{
                              margin: 4,
                              color:
                                parseFloat(this.state.selected_order['returns_difference_amount'] || 0) < 0
                                  ? "#f57362"
                                  : parseFloat(this.state.selected_order['returns_difference_amount'] || 0) === 0 ? '' : 'green',
                            }}>
                              <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                              {
                                this.state.selected_order['returns_difference_amount'] || 0
                              }
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ height: 30, width: '50px', backgroundColor: '#f7f5f5', border: '1px solid #b9bdc4', textAlign: 'right' }}>

                            <span style={{
                              margin: 4
                            }}>
                              Returns
                            </span>
                          </td>
                          <td style={{ height: 30, border: '1px solid #b9bdc4', textAlign: 'center' }}>
                            <span style={{
                              margin: 4,
                              color: parseFloat(this.state.selected_order['total_returns_amount'] || 0) -
                                parseFloat(this.state.selected_order['mf_order_overall_investment_amount']) < 0
                                ? "#f57362"
                                : (parseFloat(this.state.selected_order['total_returns_amount'] || 0) -
                                  parseFloat(this.state.selected_order['mf_order_overall_investment_amount'])) === 0 ? '' : 'green',
                            }}>
                              <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                              {this.state.selected_order['total_returns_amount']}
                            </span>
                          </td>
                          <td style={{ height: 30, width: '50px', backgroundColor: '#f7f5f5', border: '1px solid #b9bdc4', textAlign: 'right' }}>
                            <span style={{
                              margin: 4,
                            }}>
                              Percent
                            </span>
                          </td>
                          <td style={{ height: 30, width: '50px', border: '1px solid #b9bdc4', textAlign: 'right' }}>
                            <span style={{
                              fontFamily: 'Verdana, sans-serif',
                              margin: 4,
                              fontWeight: 500,
                              color:
                                parseFloat(this.state.selected_order['returns_difference_amount']) < 0
                                  ? "#f57362"
                                  : (parseFloat(this.state.selected_order['returns_difference_amount']) === 0 ? '' : 'green'),
                            }}>
                              {parseFloat(this.state.selected_order['returns_difference_amount']) > 0 ? `+` : ``}
                              {this.state.selected_order['total_returns_percent']}%</span>
                          </td>
                        </tr>
                      </table>

                      <div className="row">
                        <div className="col">
                          <span style={{ fontSize: 14 }}>
                            Available Amount To Transfer{' '}
                          </span> {`: `}
                          <span style={{ fontsize: 16, fontWeight: 'bold' }}>
                            <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                            {this.state.selected_order['stp_available_transfer_amount'] || 0.000}
                          </span>
                        </div>
                      </div>

                      <hr />
                      <div className="row">
                        <div className="col">
                          {/* <h6 style={{ textDecorationLine: "underline" }}>
                              SCHEME TRANSFER TO
                            </h6> */}
                          <button type="button"
                            className="btn-sm btn-primary"
                            style={{ color: 'white', width: '210px' }}
                            onClick={() => {
                              this.loadSchemeList(this.state.selected_order);
                            }}
                          >
                            Choose a Scheme (to transfer)
                          </button>

                        </div>
                      </div>
                    </div>
                  }

                  {this.state.display_mode === 'scheme_transfer_completed' &&
                    <div>

                      <div className="row">
                        <div className="col-lg-12">
                          <div dangerouslySetInnerHTML={this.createDefaultMarkup()} />
                        </div>
                      </div>
                    </div>
                  }

                </div>

                {this.state.display_mode === 'scheme_transfer_from' &&
                  this.state.selected_scheme['_id'] &&
                  <div style={{ marginTop: -20 }}>
                    <div className="row">
                      <div className="col">
                        <h6 style={{ textDecorationLine: "underline" }}>
                          Transfer to Scheme
                        </h6>
                      </div>
                    </div>
                  </div>
                }
                {this.state.display_mode === 'scheme_transfer_from' &&
                  this.state.selected_scheme['_id'] &&
                  <div className="row" >
                    <Row>
                      <p>{
                        this.state.selected_scheme["scheme_name"]
                      }
                        {'   '}
                        <span style={{
                          fontSize: 12,
                          color: 'InfoText',
                          backgroundColor: this.state.selected_scheme["risk"].toLowerCase().indexOf('low') > -1 ? 'lightgreen' :
                            (this.state.selected_scheme["risk"].toLowerCase().indexOf('high') > -1 ? '#d96f76' : '#81a6d6'), padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                        }} >
                          {this.state.selected_scheme["risk"]}
                        </span>
                      </p>
                    </Row>
                    <Row>
                      <p>
                        <img className="fundlogo" src={this.state.selected_scheme["logo_url"]} />
                        {'   '}
                        <span
                          style={{
                            fontSize: 12,
                            color: "InfoText",
                            backgroundColor: "#F6F3F1",
                            padding: 4,
                            paddingLeft: 8,
                            paddingRight: 8,
                            margin: 4,
                            borderRadius: 4,
                          }}
                        >
                          {this.state.selected_scheme["scheme_category"]}
                        </span>
                      </p>
                    </Row>

                    {/* {this.state.selected_scheme["three_month_percent"] && !isNaN(parseInt(this.state.selected_scheme["three_month_percent"])) &&
                      <Row>
                        <Col>
                          <div className="row">
                            <div className="col">
                              <div style={{ borderBottom: '1px solid', borderBottomColor: 'lightgray', textAlign: 'center' }}>
                                Lumpsum (%)
                              </div>
                              <div className="row">
                                {!this.state.selected_scheme["three_year_percent"] && this.state.selected_scheme["three_month_percent"] && <div style={{ width: '30px', textAlign: 'center' }} className="col">
                                  <div style={{ fontSize: 12, color: '#7d7777' }}>3M</div>
                                  <span style={{ fontSize: 13, color: (this.state.selected_scheme["three_month_percent"].substring(0, 1) === '-' || '') ? '#f57362' : '#03b45e' }}>
                                    {this.state.selected_scheme["three_month_percent"]}</span>
                                </div>
                                }
                                {!this.state.selected_scheme["five_year_percent"] && this.state.selected_scheme["six_month_percent"] &&
                                  <div style={{ width: '30px', textAlign: 'center' }} className="col">
                                    <div style={{ fontSize: 12, color: '#7d7777' }}>6M</div>
                                    <span style={{ fontSize: 13, color: (this.state.selected_scheme["six_month_percent"].substring(0, 1) === '-' || '') ? '#f57362' : '#03b45e' }}>
                                      {this.state.selected_scheme["six_month_percent"]}</span>
                                  </div>
                                }
                                {this.state.selected_scheme["one_year_percent"] && <div style={{ width: '30px', textAlign: 'center' }} className="col">
                                  <div style={{ fontSize: 12, color: '#7d7777' }}>1Y</div>
                                  <span style={{ fontSize: 13, color: (this.state.selected_scheme["one_year_percent"].substring(0, 1) === '-' || '') ? '#f57362' : '#03b45e' }}>
                                    {this.state.selected_scheme["one_year_percent"]}</span>

                                </div>
                                }
                                {this.state.selected_scheme["three_year_percent"] && <div style={{ width: '30px', textAlign: 'center' }} className="col">
                                  <div style={{ fontSize: 12, color: '#7d7777' }}>3Y</div>
                                  <span style={{ fontSize: 13, color: (this.state.selected_scheme["three_year_percent"].substring(0, 1) === '-' || '') ? '#f57362' : '#03b45e' }}>
                                    {this.state.selected_scheme["three_year_percent"]}</span>

                                </div>
                                }
                                {this.state.selected_scheme["five_year_percent"] && <div style={{ width: '30px', textAlign: 'center' }} className="col">
                                  <div style={{ fontSize: 12, color: '#7d7777' }}>5Y</div>
                                  <span style={{ fontSize: 13, color: (this.state.selected_scheme["five_year_percent"].substring(0, 1) === '-' || '') ? '#f57362' : '#03b45e' }}>
                                    {this.state.selected_scheme["five_year_percent"]}</span>

                                </div>
                                }
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <div className="row">
                            <div className="col">
                              <div style={{ fontFamily: 'sans-serif', borderBottom: '1px solid', borderBottomColor: 'lightgray', textAlign: 'center' }}>
                                SIP (Annualized %)
                              </div>
                              <div className="row">
                                {['1y', '3y', '5y'].map((el, _index) => (
                                  this.state.selected_scheme['sip_returns_data'] && this.state.selected_scheme['sip_returns_data'][el + '_percent_annualized'] && !isNaN(parseInt(this.state.selected_scheme['sip_returns_data'][el + '_percent_annualized'])) &&
                                  <div style={{ width: '30px', textAlign: 'center' }} className="col" key={_index}>
                                    <div style={{ fontSize: 12, color: '#7d7777' }}>{el.toUpperCase()}</div>
                                    <span style={{ fontSize: 13, color: (this.state.selected_scheme['sip_returns_data'][el + '_percent_annualized'].substring(0, 1) === '-' || '') ? '#f57362' : '#03b45e' }}>
                                      {this.state.selected_scheme['sip_returns_data'][el + '_percent_annualized']}</span>
                                  </div>
                                )
                                )}

                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    } */}
                    <hr />
                    <div className="row">

                      <span style={{ color: 'red' }}>
                        {this.state.stp_order['validation_message_text']}
                      </span>
                    </div>
                  </div>
                }

                {this.state.display_mode === 'scheme_transfer_from' &&
                  this.state.selected_scheme['_id'] &&
                  <div className="card">


                    <div className="row">
                      <div className="col">
                        <div className="row">
                          <div className="col-6">
                            <div class="form-group">
                              Choose Frequency
                              <Select placeholder={`-Select-`}
                                // value={this.state.stp_frequency_list.find(object => object.value === this.state.stp_order['stp_frequency'] || '')}
                                value={this.state.stp_order['selected_stp_frequency']}
                                onChange={(e) => this.handleChangeSelect(e, 'stp_frequency')}
                                options={this.state.stp_frequency_list}
                                isSearchable={false}
                              />
                            </div>
                          </div>
                          <div className="col-6">
                            <div class="form-group">
                              Choose Installments
                              <Select placeholder={`-Select-`}
                                // value={this.state.stp_installment_list.find(object => object.value === this.state.stp_order['stp_installments'] || '')}
                                value={this.state.stp_order['selected_stp_installments']}
                                onChange={(e) => this.handleChangeSelect(e, 'stp_installments')}
                                options={this.state.stp_installment_list}
                                isSearchable={false}
                              />
                            </div>
                          </div>

                        </div>
                        <br />
                        <div class="row">

                          <div className="col">
                            <div class="form-group">
                              Investment Range
                              <Select placeholder={`-Select-`}
                                // value={this.state.stp_selected_scheme_list.find(object =>
                                //   object.value === this.state.stp_order['stp_scheme_code'] || '')
                                // }
                                value={this.state.stp_order['selected_stp_scheme_code']}
                                onChange={(e) => this.handleChangeSelect(e, 'stp_scheme_code')}
                                options={this.state.stp_scheme_specification_list}
                                isSearchable={false}
                              />
                            </div>
                          </div>
                        </div>
                        <br/>
                        <div className="row">
                          <div className="col-6">
                            <span style={{ fontFamily: 'sans-serif' }}>Enter STP Amount &#8377;
                            </span>
                            <input
                              type="tel"
                              pattern="[0-9]*"
                              maxLength="8"
                              style={{ letterSpacing: 2 }}
                              name="stp_amount"
                              class="form-control"
                              placeholder=""
                              value={this.state.stp_order['stp_amount']}
                              onKeyPress={event => {
                                if (event.target.key === 'Enter') {
                                  event.preventDefault();
                                }
                              }}
                              onChange={event => {
                                const targetValue = event.target.value.replace(/\s/g, '');

                                if (targetValue.length === 0)
                                  this.setState({
                                    stp_order: { ...this.state.stp_order, stp_amount: targetValue }
                                  });
                                else {

                                  if (targetValue.match(/^[0-9]+$/))
                                    // if (parseFloat(targetValue) <= parseFloat(this.state.selected_sip_order['maximum_installment_amount']))
                                    this.setState({ stp_order: { ...this.state.stp_order, stp_amount: targetValue } });

                                }

                              }

                              }

                            />
                          </div>
                          <div className="col-6">
                            {/* <span>
                              STP Date (every {this.state.stp_order['stp_frequency'].toLowerCase().replace('ly', '')})
                            </span> */}
                            <span>
                              STP Date
                            </span>
                            <input
                              type="text"
                              name="sip_amount"
                              class="form-control"
                              placeholder=""
                              maxLength="2"
                              readOnly={true}
                              value={this.state.stp_order['stp_date_value']}
                              onClick={() => {
                                // if (event.target.key === 'Enter') {
                                //   event.preventDefault();
                                // }
                                this.setState({
                                  show_stpdate_popup: true
                                })
                              }}
                              disabled={(this.state.stp_order['stp_frequency'] || '').length < 4}
                            />
                            {/* <DatePicker className="form-control"
                                    selected={this.state.stp_order.stp_date}
                                    onChangeRaw={(e) => e.preventDefault()}
                                    // readOnly={true}
                                    onChange={(date) => {
                                      this.setState({
                                        sip_order: { ...this.state.stp_order, stp_date: date }
                                      })
                                    }
                                    }
                                    // minDate={this.state.sip_minimum_date}
                                    // maxDate={this.state.sip_maximum_date}
                                    shouldCloseOnSelect={true}
                                  // excludeDates={this.state.sip_exclude_date_list}
                                  /> */}

                          </div>
                        </div>
                        <div className="row">


                          <div className="col-6">
                            <br />
                            <button
                              type="submit"
                              name="submit"
                              class="btn btn-success btn-block"
                              onClick={() => {
                                // this.startSTP()
                                /*if(( parseInt(this.state.stp_order['stp_installments']) *  parseInt(this.state.stp_order['stp_amount']) ) 
                                    > parseInt(this.state.selected_order['stp_information']['maximum_amount']))
                                    alert(`Sorry, Your number of withdrawals exceeds your available withdrawal amount`);
                                  else*/
                                this.sendOTP();

                              }}
                              disabled={!this.stpParameterValidation()}
                            >
                              Start STP
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }

              </div>

            }

          </div>
          {this.state
            .show_targetscheme_popup && (
              <Modal
                show={
                  this.state
                    .show_targetscheme_popup
                }
                onHide={() =>
                  this.setState({ show_targetscheme_popup: false })
                }

                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header>Choose a scheme from the below list</Modal.Header>
                <Modal.Body>

                  {_list_scheme.map((item, index) => (
                    <div className="row" key={index} style={{ marginBottom: 5 }}>
                      <Row>
                        <p>{
                          item["scheme_name"]
                        }
                        </p>
                      </Row>
                      <Row>
                        <p>
                          <img className="fundlogo" src={item["logo_url"]} />
                          {'   '}
                          <span
                            style={{
                              fontSize: 12,
                              color: "InfoText",
                              backgroundColor: "#F6F3F1",
                              padding: 4,
                              paddingLeft: 8,
                              paddingRight: 8,
                              margin: 4,
                              borderRadius: 4,
                            }}
                          >
                            {item["scheme_category"]}
                          </span>
                        </p>
                      </Row>
                      {item["three_month_percent"] && !isNaN(parseInt(item["three_month_percent"])) &&
                        <Row>
                          <Col>
                            <div className="row">
                              <div className="col">
                                <div style={{ borderBottom: '1px solid', borderBottomColor: 'lightgray', textAlign: 'center' }}>
                                  Lumpsum (%)
                                </div>
                                <div className="row">
                                  {!item["three_year_percent"] && item["three_month_percent"] && <div style={{ width: '30px', textAlign: 'center' }} className="col">
                                    <div style={{ fontSize: 12, color: '#7d7777' }}>3M</div>
                                    <span style={{ fontSize: 13, color: (item["three_month_percent"].substring(0, 1) === '-' || '') ? '#f57362' : '#03b45e' }}>
                                      {item["three_month_percent"]}</span>
                                  </div>
                                  }
                                  {!item["five_year_percent"] && item["six_month_percent"] &&
                                    <div style={{ width: '30px', textAlign: 'center' }} className="col">
                                      <div style={{ fontSize: 12, color: '#7d7777' }}>6M</div>
                                      <span style={{ fontSize: 13, color: (item["six_month_percent"].substring(0, 1) === '-' || '') ? '#f57362' : '#03b45e' }}>
                                        {item["six_month_percent"]}</span>
                                    </div>
                                  }
                                  {item["one_year_percent"] && <div style={{ width: '30px', textAlign: 'center' }} className="col">
                                    <div style={{ fontSize: 12, color: '#7d7777' }}>1Y</div>
                                    <span style={{ fontSize: 13, color: (item["one_year_percent"].substring(0, 1) === '-' || '') ? '#f57362' : '#03b45e' }}>
                                      {item["one_year_percent"]}</span>

                                  </div>
                                  }
                                  {item["three_year_percent"] && <div style={{ width: '30px', textAlign: 'center' }} className="col">
                                    <div style={{ fontSize: 12, color: '#7d7777' }}>3Y</div>
                                    <span style={{ fontSize: 13, color: (item["three_year_percent"].substring(0, 1) === '-' || '') ? '#f57362' : '#03b45e' }}>
                                      {item["three_year_percent"]}</span>

                                  </div>
                                  }
                                  {item["five_year_percent"] && <div style={{ width: '30px', textAlign: 'center' }} className="col">
                                    <div style={{ fontSize: 12, color: '#7d7777' }}>5Y</div>
                                    <span style={{ fontSize: 13, color: (item["five_year_percent"].substring(0, 1) === '-' || '') ? '#f57362' : '#03b45e' }}>
                                      {item["five_year_percent"]}</span>

                                  </div>
                                  }
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col>
                            <div className="row">
                              <div className="col">
                                <div style={{ fontFamily: 'sans-serif', borderBottom: '1px solid', borderBottomColor: 'lightgray', textAlign: 'center' }}>
                                  SIP (Annualized %)
                                </div>
                                <div className="row">
                                  {['1y', '3y', '5y'].map((el, _index) => (
                                    item['sip_returns_data'] && item['sip_returns_data'][el + '_percent_annualized'] && !isNaN(parseInt(item['sip_returns_data'][el + '_percent_annualized'])) &&
                                    <div style={{ width: '30px', textAlign: 'center' }} className="col" key={_index}>
                                      <div style={{ fontSize: 12, color: '#7d7777' }}>{el.toUpperCase()}</div>
                                      <span style={{ fontSize: 13, color: (item['sip_returns_data'][el + '_percent_annualized'].substring(0, 1) === '-' || '') ? '#f57362' : '#03b45e' }}>
                                        {item['sip_returns_data'][el + '_percent_annualized']}</span>
                                    </div>
                                  )
                                  )}

                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      }
                      <Row>
                        <Col>
                          <br />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={3}>
                          <button type="button"
                            className="btn-sm btn-primary"
                            style={{ color: 'white', width: '60px', marginTop: -5 }}
                            onClick={() => {
                              this.handleToSchemeCheck(
                                //e,
                                item
                              );
                            }}
                          >
                            Select
                          </button>
                        </Col>
                        <Col>
                          <span >
                            <span
                              style={{ fontSize: 14 }}
                              class="text-dark"
                            >
                              {`  `}
                              {item["one_month_percent"] ?
                                <span style={{ fontSize: 13, color: (item["one_month_percent"].substring(0, 1) === '-' || '') ? 'red' : '#03b45e' }}>
                                  {item["one_month_percent"]}%
                                </span> : ''}
                            </span>
                          </span>

                          <span style={{ marginLeft: 8, fontSize: 14 }} >
                            1M
                          </span>
                          {/* </Col>
                        <Col xs={6}> */}
                          {` `}
                          <span style={{
                            fontSize: 12,
                            color: 'InfoText',
                            backgroundColor: item["risk"].toLowerCase().indexOf('low') > -1 ? 'lightgreen' :
                              (item["risk"].toLowerCase().indexOf('high') > -1 ? '#d96f76' : '#81a6d6'), padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                          }} >
                            {item["risk"]}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <hr />
                        </Col>
                      </Row>

                    </div>
                  ))}

                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="primary"
                    onClick={() =>
                      this.setState({ show_targetscheme_popup: false })
                    }
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            )}

          {this.state
            .show_stpdate_popup && (
              <Modal

                show={
                  this.state
                    .show_stpdate_popup
                }
                onHide={() =>
                  this.setState({ show_stpdate_popup: false })
                }

                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header closeButton>Choose a monthly STP date</Modal.Header>
                <Modal.Body>
                  <div>
                    {[1, 6, 11, 16, 21, 26].map((item, index) => (
                      <div className="row" key={index}>
                        <br />
                        {[0, 1, 2, 3, 4].map((increment, _index) => (

                          <div className="col-2" key={_index}>
                            {
                              this.state.popup_stp_date_list.indexOf((item + increment).toString()) > -1 ?
                                <button type="button"
                                  className="btn-sm btn-success"
                                  style={{ color: 'white', width: '30px', marginTop: '3px', marginLeft: '3px' }}
                                  onClick={() => {

                                    var firstSwpDate = new Date();
                                    firstSwpDate = firstSwpDate.setMonth(firstSwpDate.getMonth() + 1);

                                    this.setState({
                                      show_stpdate_popup: false,
                                      stp_order: {
                                        ...this.state.stp_order,
                                        stp_date: (item + increment),
                                        stp_date_value: moment('1982-11-' +
                                          ((item + increment) < 10 ? `0` : ``) + (item + increment)).format('Do '),
                                          // + ' of every ' + this.state.stp_order['stp_frequency'].toLowerCase().replace('ly', ''),
                                        stp_date_text: moment(new Date(firstSwpDate).setDate(parseInt((item + increment))))
                                      }
                                    })
                                  }}
                                  disabled={this.state.popup_stp_date_list.indexOf((item + increment).toString()) === -1}
                                >
                                  {item + increment}
                                </button>
                                :
                                <div>
                                  {(item + increment <= 28) &&
                                    <p
                                      style={{ width: '30px', textAlign: 'center', backgroundColor: '#dbcec5', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4 }}>
                                      {item + increment}
                                    </p>
                                  }
                                </div>
                            }
                            {/* <a
                          role="button"
                          style={{
                            width: 60,
                            backgroundColor: '#dae3e6',
                            fontWeight: 300,
                            fontSize: 18,
                            textAlign: 'center',
                            textDecoration: 'underline',
                            padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                          }}
                          onClick={() => {
                            this.setState({
                              show_stpdate_popup: false,
                              stp_order: {
                                ...this.state.stp_order,
                                stp_date: item,
                                stp_date_value: moment('1982-11-' + item).format('Do ')
                                  + ' of every ' + this.state.stp_order['stp_frequency'].toLowerCase().replace('ly', '')
                              }
                            })
                          }}
                          disabled={this.state.popup_stp_date_list.indexOf(item.toString()) === -1}
                        >
                          {item}
                        </a> */}
                          </div>

                        ))}
                      </div>
                    ))}
                  </div>
                  {/* <div className="row">
                    {[1, 2, 3, 4, 5
                    ].map((item, index) => (
                      <div className="col-2" key={index}>
                        
                        <a
                          role="button"
                          style={{
                            width: 60,
                            backgroundColor: '#dae3e6',
                            fontWeight: 300,
                            fontSize: 18,
                            textAlign: 'center',
                            textDecoration: 'underline',
                            padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                          }}
                          onClick={() => {
                            this.setState({
                              show_stpdate_popup: false,
                              stp_order: {
                                ...this.state.stp_order,
                                stp_date: item,
                                stp_date_value: moment('1982-11-' + item).format('Do ')
                                  + ' of every ' + this.state.stp_order['stp_frequency'].toLowerCase().replace('ly', '')
                              }
                            })
                          }}
                          disabled={this.state.popup_stp_date_list.indexOf(item.toString()) === -1}
                        >
                          {item}
                        </a>
                      </div>)
                    )}
                  </div> */}

                </Modal.Body>
                {/* <Modal.Footer>
                  <Button
                    variant="primary"
                    onClick={() =>
                      this.setState({ show_stpdate_popup: false })
                    }
                  >
                    Close
                  </Button>
                </Modal.Footer> */}
              </Modal>
            )}

          {this.state.show_modal_pin_confirm_dialog && (
            <Modal show={this.state.show_modal_pin_confirm_dialog}
              onHide={() => this.setState({
                show_modal_pin_confirm_dialog: false,
                modal_pin_confirm_action: '',
                modal_pin_confirm_value: ''
              })}
              centered
              keyboard={false}
              fullscreen={false}
              backdrop="static"
            >
              <Modal.Header>
                <p>Two Factor Authentication - Systematic Transfer Plan</p>
                {
                  this.state.modal_validation_message_text.length > 3 &&
                  <p>
                    {this.state.modal_validation_message_text}
                  </p>
                }
              </Modal.Header>
              <Modal.Body>

                {/* <p>{this.state["modal_pin_confirm_dialog_messagetext"]}</p>
                <OTPInput
                  value={this.state.modal_pin_confirm_value}
                  onChange={event => this.setState({ modal_pin_confirm_value: event })}
                  autoFocus
                  OTPLength={4}
                  otpType="number"
                  disabled={false}
                  secure
                  isInputNum={true}
                /> */}
                <div>
                  <div className="row">
                    <div class="col">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Enter OTP received on your registered mobile</Form.Label>
                        <OTPInput
                          value={this.state.stp_order['two_factor_authentication']['mobile_otp']}
                          onChange={this.handle2FactorChange('mobile_otp', 'two_factor_authentication', 'stp_order')}
                          autoFocus
                          OTPLength={6}
                          otpType="number"
                          secure
                          isInputNum={true}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <br />
                  <Form.Group
                    className="mb-3"
                  >
                    <Form.Label>Enter OTP received on your registered email</Form.Label>
                    <OTPInput
                      value={this.state.stp_order['two_factor_authentication']['email_otp']}
                      onChange={this.handle2FactorChange('email_otp', 'two_factor_authentication', 'stp_order')}
                      OTPLength={6}
                      otpType="number"
                      secure
                      isInputNum={true}
                    />
                  </Form.Group>
                  <br />
                  <div className="row">
                    <ResendOTP maxTime={120} renderButton={renderButton} style={{ color: 'black', marginTop: 20 }} renderTime={renderTime}
                      onResendClick={(e) => { this.sendOTP() }}
                    />
                  </div>
                </div>
                <p style={{ color: 'red' }}>{this.state["modal_pin_confirm_dialog_error_messagetext"]}</p>

                {this.state.stp_order['stp_date_text'].toString().length > 10 &&
                  <div className="row">
                    <p style={{ color: `#733dd1`, marginBottom: 0 }} >
                      Your 1st transfer will be placed on {new Date(this.state.stp_order['stp_date_text']).toLocaleString("en-IN", { year: 'numeric', month: 'short', day: 'numeric' })}
                    </p>
                  </div>
                }
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary"
                  // disabled={this.state.modal_pin_confirm_value.length !== 4}
                  disabled={
                    isNaN(this.state.stp_order['stp_installments']) ||
                    isNaN(this.state.stp_order['stp_amount']) ||
                    this.state.stp_order['stp_installments'].length < 1 ||
                    this.state.stp_order['stp_amount'].length < 1 ||
                    this.state.stp_order['stp_frequency'].length < 5 ||
                    parseInt(this.state.stp_order['stp_installments']) < 1 ||
                    parseInt(this.state.stp_order['stp_amount']) < parseInt(this.state.selected_order['minimum_stp_amount'])
                  }
                  onClick={() => {
                    this.confirmSTP()
                  }}>
                  Confirm
                </Button>
                <Button variant="primary"
                  onClick={() => this.setState({
                    show_modal_pin_confirm_dialog: false,
                    modal_pin_confirm_action: '',
                    modal_pin_confirm_value: ''
                  })}>
                  Cancel
                </Button>
              </Modal.Footer>
              {this.state.isloading && (
                <Spinner
                  animation="border"
                  variant="dark"
                  style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    zIndex: 899,
                  }}
                />
              )}
            </Modal>
          )}

        </div>
      </>
    );
  }

}

const mapStateToProps = (state) => ({
  result: state.master.result,
  order_result: state.order.order_result,
  loading: state.master.loading,
});
export default connect(mapStateToProps,
  { listStpSchemes, registerStpOrder, twoFactorAuthentication })
  (StpSchemeList);

