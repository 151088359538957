import React, { } from 'react';
import { Link } from 'react-router-dom';

class Sidebar extends React.Component {
    constructor() {
        super();
        this.state = {
            profileData: {}
        }
    }

    componentDidMount() {

        this.setState({
            profileData: JSON.parse(localStorage.getItem("profileData") || JSON.stringify({}))
        });
    }

    render() {
        return (
            <>
                <div className="sidebar">
                    <Link className="brand-logo" to={'#'} style={{ marginTop: 30 }}>


                    </Link>
                    <div className="menu">
                        <ul>
                            <li>
                                <Link to={"/portfolio"} title="Portfolio">
                                    <span><i className="mdi mdi-briefcase"></i></span>
                                </Link>
                            </li>
                            <li>
                                <Link to={"/schemeList"} title="SchemeList">
                                    <span><i className="fa fa-bar-chart"></i></span>
                                </Link>
                            </li>
                            {/* <li><Link to={"./buy-sell"}>
                                <span><i class="mdi mdi-receipt"></i></span>
                                <span class="nav-text">Import Funds</span>
                            </Link>
                            </li> */}

                            {this.state.profileData['config_details'] &&
                                this.state.profileData['config_details']['is_watchlist_allowed'] &&
                                <li><Link to={"/schemeWatchList"} title="Watchlist">
                                    <span><i class="mdi mdi-alarm"></i></span>
                                </Link>
                                </li>
                            }

                            <li><Link to={"/orderList"} title="Single Orders">
                                <span><i className="fa fa-list-ul" ></i></span>

                            </Link>
                            </li>
                            <li><Link to={"/sipList"} title="SIP">
                                <span><i className="mdi mdi-calendar-today"></i></span>

                            </Link>
                            </li>
                            <li><Link to={"/stpList"} title="STP">
                                <span><i className="mdi mdi-transfer"></i></span>

                            </Link>
                            </li>
                            
                            {/* <li><Link to={"./settings"}>
                            <span><i class="mdi mdi-settings"></i></span>
                            <span class="nav-text">Setting</span>
                        </Link>
                        </li> */}
                        </ul>
                    </div>
                    {/* <div class="sidebar-footer">
                    <div class="social">
                        <Link to={"#"}><i class="fa fa-youtube-play"></i></Link>
                        <Link to={"#"}><i class="fa fa-instagram"></i></Link>
                        <Link to={"#"}><i class="fa fa-twitter"></i></Link>
                        <Link to={"#"}><i class="fa fa-facebook"></i></Link>
                    </div>
                    <div class="copy_right">
                        © 2021 Mango Leef Tech Labs Pvt Ltd
                </div>
                </div> */}
                </div>
            </>
        )
    }
}

export default Sidebar;