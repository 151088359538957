import React, { } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap'
import logo from '../../images/logo.png';

function Header3() {
    
    return (
        <>
 <div class="header landing">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <nav class="navbar">
                                <div class="brand-logo">
                                    <Link to={"/"}>
                                        <img src={logo} alt=""  />
                                      
                                    </Link>
                                </div>
                                <Link to={"/signin"} class="btn btn-success">Sign in</Link>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
 {/*
            <div className="header landing_page">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="navigation">
                                <Navbar expand="lg">
                                    <Link className="navbar-brand" to={'/'}>
                                        <img src={require('./../../images/logo.png')} alt="" />
                                       
                                    </Link>
                                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                    <Navbar.Collapse>
                                        <Nav className="mr-auto">
                                            <Nav.Item><a className="nav-link" href="#intro">Home</a></Nav.Item>
                                            <Nav.Item><a className="nav-link" href="#market">Market</a></Nav.Item>
                                            <Nav.Item><a className="nav-link" href="#portfolio">Portfolio</a></Nav.Item>
                                            <Nav.Item><a className="nav-link" href="#features">Features</a></Nav.Item>
                                            <Nav.Item><a className="nav-link" href="#testimonial">Testimonial</a></Nav.Item>
                                            <Nav.Item><a className="nav-link" href="#contact">Contact</a></Nav.Item> 
                                        </Nav>

                                    </Navbar.Collapse>

                                    <div className="header_auth">
                                        <Link to={'./signin'} className="btn btn-success" >Signin</Link>
                                    </div>
                                </Navbar>
                            </div>
                        </div>
                    </div>
                </div>
            </div>*/}
        </>
    )
}

export default Header3;