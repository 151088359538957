import React from "react";
import { connect } from "react-redux";
import {
  downloadNominee
} from "../../actions/orders";
import { PDFReader } from "reactjs-pdf-reader";
// import { PDFViewer } from 'pdf-viewer-reactjs';

import {
  Button,
  Spinner
} from "react-bootstrap";
import { saveAs } from "file-saver";
import axios from 'axios';
import { AppConstants } from '../../constants/constants';
import fileDownload from 'js-file-download';

class DownloadDocument extends React.Component {
  

  constructor(props) {

    super(props);
    this.state = {
      selected_order: {},
      sip_order: {},
      list_bank_account: [],
      list_mandate: [],
      list_pending_mandate: [],
      list_mandate_debit: [],
      src_nach_form: "",
      selected_mandate_id: "",
      is_mandate_selected: false,
      is_mandate_registered: false,
      is_mandate_uploaded: false,
      is_debit_max_limit: false,
      mandate_amount: 25000,
      mandate_id: "",
      mandate_date_validation: {
        message_text: "",
      },
      is_first_order_today: false,
      loading: false,
      is_nach: true,
      nach_option: "existing",
      nach_option_new_processed: false,
      nach_option_new_processed_message_text: "",
      sip_information_text: "You need to make payment physically on ",
      render_statement_pdf: null,
      nachmandateFile: null,
      src_nachmandateFile: null,
      byte_nachmandateFile: null,
      show_popup: false,
      popup_messageText: "",
      invest_more_amount: 0.0,
      redeemable_amount: 0.0,
      is_redeem_full_checked: false,
      is_stage_verified: false,
      is_verify_enabled: false,
      is_verified_editable: false,
      esignkyc_information: {},
      esignkyc_attachment_list: {},
      is_esign_verified: false,
      is_esign_button_disabled: false,
      isloading: false,
      received_data: {}
    };
  }

  componentDidMount() 
  {
      // console.log(AppConstants.API_BASE_URL + `/validation/` + this.props.match.params.document_type + `/download/` + this.props.match.params.token_value + '/' + this.props.match.params.document_id 
      // + '/' + this.props.match.params.scheme_code + `/true`);

      // axios({
      //   url: AppConstants.API_BASE_URL + `/validation/` + this.props.match.params.document_type + `/download/` + this.props.match.params.token_value + '/' + this.props.match.params.document_id 
      //     + '/' + this.props.match.params.scheme_code + `/true`,
      //   // url: `https://html.spec.whatwg.org/print.pdf`,
      //   method: 'GET',
      //   // responseType: 'blob'
      // })
      // .then((blob) => {
      //   fileDownload(blob, 'NONOMINEE_DECLARATION.pdf')
      // })
      // .catch((error) => {
      //   console.error('Error:', error);
      // }); 

      // window.location = AppConstants.API_BASE_URL + `/validation/` + this.props.match.params.document_type + `/download/` + this.props.match.params.token_value + '/' + this.props.match.params.document_id 
      //     + '/' + this.props.match.params.scheme_code + `/true`;

      saveAs(AppConstants.API_BASE_URL + `/validation/` + this.props.match.params.document_type + `/download/` + this.props.match.params.token_value + '/' + this.props.match.params.document_id 
          + '/' + this.props.match.params.scheme_code + `/true`);

  }

  render() {

    return (
      <>

        <div className="content-body" >
          <div className="container">
            <div className="row">

              <div className="col-xl-12 col-md-8">
                
              </div>

            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ order }) => {
  const { loading, order_result } = order;
  return {
    loading,
    order_result,
  };
};

export default connect(mapStateToProps, {
  downloadNominee
})(DownloadDocument);
