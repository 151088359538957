import React, { Fragment } from 'react';
import spinner from './spinner.gif';

const Spinner = () => (
  <Fragment>
    <img
      src={spinner}
      style={{ position:'absolute', top: "50%", left: "50%", transform: "translate(-30%, -30%)"}}    
      alt="Loading..."
    />
  </Fragment>
);

export default Spinner;

// style={{left: '50%',
// marginLeft: '-4em',position: 'absolute',width:'200px' }}