import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
// import Dashboard from './pages/index';
import BuySell from './pages/buy-sell';
import Accounts from './pages/accounts';
import Settings from './pages/settings';
import Preferences from './pages/settings-preferences';
import SettingsSecurity from './pages/settings-security';
import SettingsAccount from './pages/settings-account';
import AddBankAccount from './pages/add-bank-acc';
import AddDebitCard from './pages/add-debit-card';
import PrivateRoute from './pages/privateroute/privateroute';
import Locked from './pages/lock';
import Otp2 from './pages/otp-2';
import PrivacyPolicy from './pages/privacy-policy';
import Reset from './pages/reset';
import Signin from './pages/signin';
import Signup from './pages/auth/register/signup';
import About from './pages/about';
import TermsCondition from './pages/terms-condition';

import SchemeList from './pages/scheme/schemeList';
import StpList from './pages/mf/stpList';
import StpSchemeList from './pages/scheme/stpSchemeList';
import SchemeWatchList from './pages/scheme/schemeWatchList';
import AdvisorAdviseList from './pages/scheme/advisorAdviseList';
import customerSupport from './pages/personal/customerSupport';
import TwoFABseStarMFPage from './pages/payment/twoFABseStarMF';

import VerifyEmail from './pages/verify-email';
import VerifyPin from './pages/verify-pin';
import VerifyStep1 from './pages/verify-step-1';
import VerifyStep2 from './pages/verify-step-2';
import VerifyStep3 from './pages/verify-step-3';
import VerifyStep4 from './pages/verify-step-4';
import VerifyStep5 from './pages/verify-step-5';
import VerifyStep6 from './pages/verify-step-6';

import MpinInformation from './pages/personal/mpinInformation';

import PersonalDetails from './pages/personaldetails/personaldetails';
import DocumentsUpload  from './pages/documentsupload/documentsupload';
import IncomeDetails from './pages/incomedetails/incomedetails';
import History from './pages/history';
import PanVerification from './pages/pan/panVerification';
import AadhaarVerification from './pages/aadhaar/aadhaarVerification';
import PortfolioInformation from './pages/mf/portfolioInformation';

import PaymentPage from './pages/payment/paymentPage';
import PaymentResponse from './pages/payment/paymentResponse';

import Landing from './pages/landing';
import PersonalVerification from './pages/personal/personalVerification';
import AccountActivation from './pages/accountActivation/accountActivation';
import BankVerification from './pages/bank/bankVerification';
import BankAutopay from './pages/bank/bankAutopay';

import BankAccountList from './pages/bank/bankaccountList';
// import MandateList from './pages/bank/mandateList';
import MandateList from './pages/mf/mandateList';
import DownloadMandate from './pages/mf/downloadMandate';
import DownloadNominee from './pages/mf/downloadNominee';
import DownloadDocument from './pages/mf/downloadDocument';

import OrderList from './pages/mf/orderList';
import OrderCheckout from './pages/mf/orderCheckout';
import SipList from './pages/mf/sipList';
import OrderEditCheckout from './pages/mf/orderEditCheckout';
import SipCheckout from './pages/mf/sipCheckout';
import SipEditCheckout from './pages/mf/sipEditCheckout';
import OrderResponse from './pages/mf/orderResponse';
import SipResponse from './pages/mf/sipResponse';
import ConsolidatedStatement from './pages/mf/consolidatedStatement';

import ImageVerification from './pages/personaldetails/imageVerification';
import EsignkycVerification from './pages/esignkyc/esignkycVerification';
import Faq from './pages/faq';
import Contact from './pages/contact';
import Charts from './pages/chartDisplay/chartDisplay';
import SipCalculator from './pages/sipCalculator/sipcalculator';

import FYInformation from './pages/fyInformation';

import ClientList from './pages/admin/clientList';
import ClientDocuments from './pages/admin/clientDocuments';

import  ENachMandatePage from './pages/payment/enachMandatePage';
import DailyUpload from './pages/daily/dailyUpload';

class Index extends Component {
  

  //   componentDidMount(){
  //       const { history } = this.props;

  // history.listen((newLocation, action) => {
  //   if (action === "PUSH") {
  //     if (
  //       newLocation.pathname !== this.currentPathname ||
  //       newLocation.search !== this.currentSearch
  //     ) {
  //       this.currentPathname = newLocation.pathname;
  //       this.currentSearch = newLocation.search;

  //       history.push({
  //         pathname: newLocation.pathname,
  //         search: newLocation.search
  //       });
  //     }
  //   } else {
  //     history.go(1);
  //   }
  // });
  //   }
    
  //   componentWillUnmount() {
  //       window.onpopstate = null;
  //     }
    render() {
      
        return (
            <>
                <BrowserRouter basename='/'>
                    <div id="main-wrapper">
                        <Switch>
                            <Route path='/' exact component={Signin} />
                            <Route path='/landing' component={Landing} />

                            <Route path='/buy-sell' component={BuySell} />
                            <Route path='/accounts' component={Accounts} />
                            <PrivateRoute path='/settings' component={Settings} />
                            <PrivateRoute path='/settings-preferences' component={Preferences} />
                            <PrivateRoute path='/settings-security' component={SettingsSecurity} />
                            <PrivateRoute path='/settings-account' component={SettingsAccount} />
                            <Route path='/add-bank-acc' component={AddBankAccount} />
                            <Route path='/add-debit-card' component={AddDebitCard} />
                            <Route path='/lock' component={Locked} />
                            <Route path='/otp-2' component={Otp2} />
                            <Route path='/verify-email' component={VerifyEmail} />
                            <Route path='/verify-pin' component={VerifyPin} />
                            <Route path='/about' component={About} />
                            <Route path='/privacy-policy' component={PrivacyPolicy} />
                            <Route path='/reset' component={Reset} />
                            
                            <Route path='/signin' component={Signin} />
                            <Route path='/signin/:action_mode/' component={Signin} />
                            
                            <Route path='/signup' component={Signup} />
                            <Route path='/terms-condition' component={TermsCondition} />
                            <Route path='/faq' component={Faq} />
                            <Route path='/contact' component={Contact} />   
                            <Route path='/verify-step-1' component={VerifyStep1} />
                            <Route path='/verify-step-2' component={VerifyStep2} />
                            <Route path='/verify-step-3' component={VerifyStep3} />
                            <Route path='/verify-step-4' component={VerifyStep4} />
                            <Route path='/verify-step-5' component={VerifyStep5} />
                            <Route path='/verify-step-6' component={VerifyStep6} />
                            <Route path='/history' component={History} />
                            {/* <Route path='/home' component={Dashboard} /> */}
                            <Route path='/home' component={SchemeList} />

                            <Route path='/portfolio' component={PortfolioInformation} />
                            <Route path='/schemeList' component={SchemeList} />
                            
                            <Route path='/accountactivation/:id' render={(props) => <AccountActivation {...props} />}/> 
                            <Route path='/panVerification' component={PanVerification} />
                            <Route path='/aadhaarVerification' component={AadhaarVerification} />
                            <Route path='/imageverification' component={ImageVerification} />
                            <Route path='/bankVerification' component={BankVerification} />
                            <Route path='/bankAutopay' component={BankAutopay} />

                            <Route path='/bankAccountList' component={BankAccountList} />
                            <Route path='/mandateList' component={MandateList} />
                            <Route path='/downloadMandate/:token_value/:mandate_id/' component={DownloadMandate} />
                            <Route path='/downloadNominee/:token_value/:scheme_code/:nominee_id/' component={DownloadNominee} />
                            <Route path='/downloadNominee/:token_value/:scheme_code/:nominee_id/:file_type' component={DownloadNominee} />
                            
                            <Route path='/downloadDocument/:token_value/:document_id/:scheme_code/:document_type/' component={DownloadDocument} />
                            <Route path='/orderList' component={OrderList} />
                            <Route path='/orderCheckout' component={OrderCheckout} />
                            <Route path='/sipList' component={SipList} />
                            <Route path='/stpList' component={StpList} />
                            <Route path='/stpSchemeList' component={StpSchemeList} />

                            <Route path='/schemeWatchList' component={SchemeWatchList} />
                            <Route path='/advisorAdviseList' component={AdvisorAdviseList} />
                                                        
                            <Route path='/orderEditCheckout' component={OrderEditCheckout} />
                            <Route path='/order2factorCheckout/:redeem_id' component={OrderEditCheckout} />

                            <Route path='/sipCheckout' component={SipCheckout} />
                            <Route path='/sipEditCheckout' component={SipEditCheckout} />
                            {/* <Route path='/consolidatedStatement/:order_registration_no/:order_number/:order_type/:token_value/' 
                                component={ConsolidatedStatement} /> */}
                            <Route path='/consolidatedStatement' component={ConsolidatedStatement} />
                            
                            <Route path='/sipResponse' component={SipResponse} />
                            <Route path='/orderResponse' component={OrderResponse} />
                            
                            <Route path='/personaldetails' component={PersonalDetails} />
                            <Route path='/charts/:scheme_isin/:scheme_code/:scheme_name' component={Charts} />
                            <Route path='/chartsmobile' component={Charts} />

                            <Route path='/incomedetails' component={IncomeDetails} />
                            <Route path='/documentsupload' component={DocumentsUpload} />
                            <Route path='/personalVerification' component={PersonalVerification} />
                            <Route path='/esignkycVerification' component={EsignkycVerification} />
                                                        
                            <Route path='/mpinInformation' component={MpinInformation} />
                            <Route path='/sipcalculator' component={SipCalculator} />
                            <Route path='/customerSupport' component={customerSupport} />
                            <Route path='/paymentPage' component={PaymentPage} />
                            <Route path='/paymentResponse' component={PaymentResponse} />
                            
                            <Route path='/fyInformation' component={FYInformation} />

                            <Route path='/2FABseStarMF' component={TwoFABseStarMFPage} />
                            
                            
                            <Route path='/clientDocuments' component={ClientDocuments} />
                            <Route path='/clientDocuments/:customer_id' component={ClientDocuments} />
                            
                            <Route path='/clientList' component={ClientList} />
                            
                            {/* <Route path='/enachMandatePage' component={ENachMandatePage} /> */}
                            <Route path='/enachMandatePage?' component={ENachMandatePage} />
                            <Route path='/enachMandatePage/:enach_response' component={ENachMandatePage} />

                            <Route path='/dailyupload' component={DailyUpload} />
                            
                            
                        </Switch>
                    </div>
                </BrowserRouter>

            </>
        );
    }
}

export default Index;