import React from "react";

import { ListGroup, Dropdown, Form, Button, Modal, Alert, Row, Col, Container, Spinner } from "react-bootstrap";
import { connect } from "react-redux";

import {
  loadOrderList,
  loadSipStatus,
  cancelSipOrder,
  pauseSipOrder,
  createSwpOrder,
  twoFactorAuthentication,
  cancelSwpOrder
} from "../../actions/orders";
import moment from 'moment';
import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";

import { TabPanel, a11yProps } from "../../shared/Tabs";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import Select from 'react-select';
import { Box, Card, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import OTPInput, { ResendOTP } from "otp-input-react";

class StpList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      tab_orders: 0,
      cardopen: false,
      list_stp_orders: [],
      _list_stp_orders: [],
      status_filter_list: [],
      status_filter_selected: null,

      nominee_list: [],
      pay_bank_mode_list: [],

      notification_html: ``,

      swp_order: {
        display_text: '',
        swp_no_of_installments: '',
        swp_amount: '',
        swp_date: ``, // new Date(new Date().setDate(new Date().getDate() + 8)),
        swp_date_value: ``,
        swp_date_text: ``,
        swp_frequency: '',
        is_otp_enabled: false,
        two_factor_authentication: {
          mobile_otp: '',
          email_otp: '',
          twofactor_token_id: ''
        }
      },
      swp_minimum_date: new Date(new Date().setDate(new Date().getDate() + 8)),
      swp_maximum_date: new Date().setDate(new Date().getDate() + 40),

      stp_order: {
        display_text: '',
        two_factor_authentication: {
          mobile_otp: '',
          email_otp: '',
          twofactor_token_id: ''
        }
      },

      display_mode: `order_list`,

      bank_information: {},
      is_verify_enabled: false,
      is_confirm_chk_enabled: false,
      is_verified_editable: false,
      esignkyc_information: {},
      esignkyc_attachment_list: {},
      is_esign_verified: false,
      is_esign_button_disabled: false,
      isloading: false,
      show_popup: false,
      popup_headerText: '',
      popup_messageText: '',

      show_swp_modal: false,
      show_swp_cancel_modal: false,
      show_stp_cancel_modal: false,
      show_view_modal: false,
      modal_orderinfo: {},
      modal_header_text: '',
      show_modal_filter: false,

      show_modal_action: false,
      modal_action_name: ``,
      modal_action_order: {},

      show_modal_pin_confirm_dialog: false,
      modal_pin_confirm_action: '',
      modal_pin_confirm_value: '',
      modal_pin_confirm_dialog_messagetext: 'Enter your 4 digit pin to complete your action',

      show_swpdate_popup: false,
      popup_swp_date_list: [],

    };
  }

  componentDidMount() {

    window.addEventListener("scroll", () => {

      if (document.getElementById("navbar-orderlist-container") &&
        document.getElementById("navbar") &&
        document.getElementById("navbar").classList) {
        const topBorder = document
          .getElementById("navbar-orderlist-container")
          .getBoundingClientRect().top;

        topBorder >= 0
          ? document.getElementById("navbar").classList.remove("fixed-orderlist")
          : document.getElementById("navbar").classList.add("fixed-orderlist");
      }

    });

    if (this.props.location && this.props.location.state &&
      this.props.location.state.source_selected_order) {

      this.setState({
        selected_order: this.props.location.state.source_selected_order
      });

    }

    this.onLoadStpList();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', null);
  }

  handleConfirmCheck = (event) => {
    this.setState({
      is_confirm_chk_checked: !this.state.is_confirm_chk_checked,
    });
  };

  onLoadStpList = () => {

    this.setState({ isloading: true });

    this.props
      .loadOrderList({
        order_type: 'stp',
      })
      .then((response) => {

        if(this.props.order_result) 
        {
            if (this.props.order_result["code"] !== "000") {
              this.setState({
                display_mode: ``,
                ['list_stp_orders']: [], ['_list_stp_orders']: [],
                isloading: false
              });
              if (this.props.order_result["code"] !== "007")
                alert(this.props.order_result["messageText"]);
            }
            else {

              if (this.props.order_result["result"])
                this.setState({
                  bank_information: this.props.order_result["bank_information"],
                  ["list_stp_orders"]: this.props.order_result["result"],
                  ["_list_stp_orders"]: this.props.order_result["result"],
                  status_filter_list: this.props.order_result['status_filter_list'],
                  display_mode: `order_list`,
                  show_swp_modal: false,
                  show_swp_cancel_modal: false,
                  show_stp_cancel_modal: false,
                  show_view_modal: false,
                  modal_orderinfo: {},
                  isloading: false
                });
              else
                this.setState({
                  ["list_stp_orders"]: [],
                  isloading: false
                });

            }
        }
        else {
          this.setState({
            display_mode: ``,
            isloading: false
          });
        }

      })
      .catch((err) => {
        alert(err);
      });
  };

  handleChangeStatus = (selectedOption) => {

    this.setState(
      {
        status_filter_selected: selectedOption
      },
      () => {

        let _filtered_list_stp_orders = [];

        selectedOption.forEach(item => {
          _filtered_list_stp_orders = _filtered_list_stp_orders.concat(
            this.state.list_stp_orders.filter(el => {
              return el[item['value']] === true;
            })
          );
        });

        this.setState({
          _list_stp_orders: selectedOption.length > 0 ?
            _filtered_list_stp_orders : this.state.list_stp_orders
        });

      });

  };

  showChildInformation(order_type, order, request_type) {

    if (request_type === "view") {

      this.setState({ isloading: true });

      this.props
        .loadSipStatus({
          request_mode: "order_payment_status",
          order_type: order_type,
          order_number: order["mf_order_number"] || "",
          order_id: order["_id"],
          selected_order: order,
          check_mandate_status: true,
          is_mobile_version: true
        })
        .then((response) => {

          this.setState({ isloading: false });

          if (this.props.order_result["code"] !== "000")
            alert(this.props.order_result["messageText"]);
          else {
            let _result = this.props.order_result["result"];

            if (_result) {

              let _list = this.state['list_' + order_type + '_orders'];
              let _new_list = [];

              _list.forEach(el => {

                if (el['mf_order_registration_no'] === _result['mf_order_registration_no']) {
                  _result['show_child'] = true;
                  _new_list.push(_result);
                }
                else
                  _new_list.push(el);
              });

              let _filtered_list_stp_orders = (this.state.status_filter_selected || []).length > 0 ?
                [] : _new_list;


              (this.state.status_filter_selected || []).forEach(item => {
                _filtered_list_stp_orders = _filtered_list_stp_orders.concat(
                  _new_list.filter(el => {
                    return el[item['value']] === true;
                  })
                );
              });

              this.setState({
                modal_orderinfo: _result,
                show_view_modal: true,
                ["list_" + order_type + "_orders"]: _new_list,
                ["_list_" + order_type + "_orders"]: _filtered_list_stp_orders
              });

            }
          }
        })
        .catch((err) => {
          alert(err);
        });
    } else {

      let _list = this.state["list_" + order_type + "_orders"];

      _list.forEach((el) => {
        if (
          order["mf_order_registration_no"] === el["mf_order_registration_no"]
        )
          el["show_child"] = false; // !el['show_child'];
      });

      this.filterListBasedOnStatus(_list, order_type);

    }
  }

  showSwpInformation(order_type, order, request_type) {

    let _result = this.state["list_" + order_type + "_orders"]; //this.props.order_result["result"];

    if (_result) {

      let _list = _result;

      _list.forEach((el) => {

        // if (order["order_id"] === el["order_id"]) 
        if (order['mf_order_registration_no'] === el['mf_order_registration_no']) {

          this.setState({
            show_swp_modal: true,
            swp_order: {
              ...this.state.swp_order,
              is_otp_enabled: false,
              two_factor_authentication: {
                mobile_otp: '',
                email_otp: '',
                twofactor_token_id: ''
              }
            },
            modal_orderinfo: el
          });

        }
      });

      this.filterListBasedOnStatus(_list, order_type);

    }
  }

  resetFilter = (order_type) => {

    let _new_list = this.state['list_' + order_type + '_orders'] || [];

    let _state_parameters = {
      show_modal_filter: false,
      status_filter_selected: [],
      ['list_' + order_type + '_orders']: _new_list,
      ['_list_' + order_type + '_orders']: _new_list
    }

    this.setState(_state_parameters);

  }

  closePopup = () => {
    this.setState({
      show_pause_popup: false
    })
  }

  onCancelOrder = (order_type, order) => {

    this.setState({ isloading: true });

    this.props
      .cancelStpOrder({
        order_type: order_type,
        client_info: order,
      })
      .then((response) => {

        const _response = this.props.order_result;

        if (_response["code"] !== "000") {
          this.setState({ isloading: false });
        }
        else {

          if (_response['result']) {

            this.setState({
              show_modal_pin_confirm_dialog: true,
              modal_pin_confirm_action: '',
              modal_pin_confirm_value: '',
              display_mode: 'scheme_action_completed',
              notification_html: _response['notification_html'] || ``
            });

            this.filterListBasedOnStatus(_response['result'], order_type, 'bank_information');

          }
          else {

            this.setState({
              ["list_" + order_type + "_orders"]: [],
              ["_list_" + order_type + "_orders"]: []
            });

          }

        }

      })
      .catch((err) => {
        alert(err);
      });

  };

  onPauseOrder = (order_type, order, mode) => {

    if (mode === 'initiate') {

      let _selected_pause_order = order;
      _selected_pause_order['pause_installment_list'] = [];
      _selected_pause_order['pause_no_of_installments'] = '';
      let _list = [];
      if (order['mf_order_is_pause_allowed']) {

        for (let iRec = parseInt(order['mf_order_pause_minimum_installments']);
          iRec <= parseInt(order['mf_order_pause_maximum_installments']); iRec++) {
          _list.push({ value: iRec, label: iRec });
        }
        _selected_pause_order['pause_installment_list'] = _list;
      }

      this.setState({
        show_pause_popup: true, selected_pause_order: _selected_pause_order
      });

    }
    else {

      this.setState({ isloading: true });

      this.props
        .pauseSipOrder({
          order_type: order_type,
          client_info: order,
        })
        .then((response) => {

          const _response = this.props.order_result;

          if (_response["code"] !== "000") {
            alert(_response["messageText"]);
            this.setState({ isloading: false });
          }
          else {

            /*
            if (_response["result"]) 
            {*/
            this.setState({
              isloading: false,
              show_pause_popup: false,
              modal_pin_confirm_action: '',
              modal_pin_confirm_value: '',
              display_mode: 'scheme_action_completed',
              notification_html: _response['notification_html']
            });
            // this.filterListBasedOnStatus(_response['result'], order_type, 'bank_information');
            /*}
            else
              this.setState({
                isloading: false,
                ["list_" + order_type + "_orders"]: [],
                ["_list_" + order_type + "_orders"]: []
              });

            this.props.history.push({
              pathname: "/fyInformation/",
              state: { display_html: _response['display_html'] || '' },
            });
            */

          }

        })
        .catch((err) => {
          alert(err);
        });

    }

  };

  handleChangeSelect = (selectedOption, order, input, category) => {

    if (input === 'swp_frequency') {

      if (category === 'swp') {

        this.setState({
          swp_order: {
            ...this.state.swp_order,
            swp_frequency: selectedOption.value
          }
        });

      }

    }
    else if (input === 'swp_no_of_installments') {

      if (category === 'swp')
        this.setState({
          swp_order: {
            ...this.state.swp_order,
            swp_no_of_installments: selectedOption.value
          }
        });

    }

  }

  OnCreateSwpOrder = (order_type, order) => {

    this.props
      .createSwpOrder({
        order_type: order_type,
        client_info: Object.assign(
          { swp_order: this.state.swp_order }, order
        ),
      })
      .then((response) => {

        const _response = this.props.order_result;

        if (_response["code"] !== "000") {
          alert(_response["messageText"]);
          this.setState({
            isloading: false
          });
        }
        else {

          let _result = _response["result"];
          if (_result) {

            let _list = this.state['list_' + order_type + '_orders'];
            let _new_list = [];

            _list.forEach(el => {

              if (el['tab_stp_order_child'] === _result['tab_stp_order_child']) {
                _result['show_swp'] = false;
                _new_list.push(_result);
              }
              else
                _new_list.push(el);

            });

            this.setState({
              ["list_" + order_type + "_orders"]: _new_list,
              show_swp_modal: false,
              swp_order: {
                ...this.state.swp_order,
                is_otp_enabled: false,
                two_factor_authentication: {
                  mobile_otp: '',
                  email_otp: '',
                  twofactor_token_id: ''
                }
              },
              modal_orderinfo: {},
              display_mode: 'scheme_action_completed',
              notification_html: _response['notification_html']
            });

          }
          // else 
          //   this.setState({ ["list_" + order_type + "_orders"]: [] });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  OnCancelSwpOrder = (order_type, order) => {

    this.setState({ isloading: true });

    this.props
      .cancelSwpOrder({
        order_type: order_type,
        client_info: Object.assign(
          { swp_order: this.state.swp_order }, order
        ),
      })
      .then((response) => {

        const _response = this.props.order_result;

        if (_response["code"] !== "000") {
          alert(_response["messageText"]);
          this.setState({
            isloading: false
          });
        }
        else {

          let _result = _response["result"];
          if (_result) {

            let _list = this.state['list_' + order_type + '_orders'];
            let _new_list = [];

            _list.forEach(el => {

              _new_list.push(el);
              if (el['tab_stp_order_child'] === _result['tab_stp_order_child']) {
                _result['show_swp'] = false;
                _new_list.push(_result);
              }
            });

            this.filterListBasedOnStatus(_new_list, order_type);

            this.setState({
              isloading: false,
              display_mode: 'scheme_action_completed',
              notification_html: _response['notification_html'],
            });

          }

        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  filterListBasedOnStatus = (order_result, order_type, attribute_name) => {

    let _new_list = (order_result || []);

    let _filtered_list_stp_orders = (this.state.status_filter_selected || []).length > 0 ?
      [] : _new_list;

    (this.state.status_filter_selected || []).forEach(item => {
      _filtered_list_stp_orders = _filtered_list_stp_orders.concat(
        _new_list.filter(el => {
          return el[item['value']] === true;
        })
      );
    });

    let _state_parameters = {
      ['list_' + order_type + '_orders']: _new_list,
      ['_list_' + order_type + '_orders']: _filtered_list_stp_orders
    }

    if (attribute_name)
      _state_parameters[[attribute_name]] = order_result[attribute_name];

    this.setState(_state_parameters)

  }

  showTransactionHistory(item) {

    let _html = `<div>we will show you soon</div>`;

    this.setState({
      show_popup: true,
      popup_headerText: 'Transaction History',
      popup_messageText: item['modal_transaction_history_html'] || _html,
    })

  }

  closePopup = () => {
    this.setState({
      show_popup: false,
      popup_headerText: '',
      popup_messageText: ''
    });
  };

  createDefaultMarkup() {
    return {
      __html: this.state.notification_html,
    };
  }

  htmlDefaultMarkup(text) {
    return {
      __html: text,
    };
  }

  routeChange = (type, item, mode) => {

    /*
    this.props.history.push({
      pathname: "/consolidatedStatement/"
        + item['mf_order_registration_no'] + '/' + item['mf_order_number'] + '/'
        + item['mf_order_type'].toLowerCase()
        + '/' + localStorage.getItem('token')
    });
    */
    this.props.history.push({
      pathname: '/consolidatedStatement/',
      state: {
        param_order: {
          order_registration_no: item['mf_order_registration_no'],
          order_number: item['mf_order_number'],
          order_type: item['mf_order_type'].toLowerCase(),
          order_id: item['_id'].toString()
        }
      }
    });

    /*
    {

      const win = window.open("/consolidatedStatement/"
        + item['mf_order_registration_no'] + '/'
        + item['mf_order_number'] + '/' + item['mf_order_type'].toLowerCase()
        + '/' + localStorage.getItem('token'), "_blank");
      win.focus();

    }
    */

  };

  sendOTP = () => {

    this.setState({ isloading: true });

    this.props
      .twoFactorAuthentication({
        client_info: {
          two_factor_authentication: this.state.swp_order['two_factor_authentication'],
          request_action: 'stp registration',
          is_otp_email_required: false
        },
      })
      .then((response) => {

        if (this.props.order_result["code"] !== "000") {
          this.setState({ isloading: false });
          alert(this.props.order_result["messageText"]);
        }
        else {

          let _result = this.props.order_result; //["result"];

          if (_result && _result['twofactor_id']) {

            this.setState({
              isloading: false,
              swp_order: {
                ...this.state.swp_order,
                is_otp_enabled: true,
                twofactor_token_id: {
                  mobile_otp: '',
                  email_otp: '',
                  twofactor_token_id: _result['twofactor_id']
                }
              }
            });

          }

        }
      })
      .catch((err) => {
        alert(err);
      });

  }

  showStpInformation(order_type, order, request_type) {

    let _result = this.state["list_" + order_type + "_orders"]; //this.props.order_result["result"];

    if (_result) {

      let _list = _result; //this.state["list_" + order_type + "_orders"];
      _list.forEach((el) => {

        if (order["order_id"] === el["order_id"]) {

          this.setState({
            [request_type]: true,
            // show_swp_modal: true,
            stp_order: {
              ...this.state.stp_order,
              is_otp_enabled: false,
              two_factor_authentication: {
                mobile_otp: '',
                email_otp: '',
                twofactor_token_id: ''
              }
            },
            modal_orderinfo: el
          });

        }
        else
          el['show_stp'] = false;
      });

      this.filterListBasedOnStatus(_list, order_type);

    }
  }

  render() {

    const renderButton = buttonProps => {
      return (
        <div className="row">
          <div class="col">
            <button onClick={this.sendOTP} {...buttonProps}
              class={buttonProps.remainingTime !== 0 ? `btn btn-outline-secondary` : `btn btn-outline-success`}>
              {`Resend OTP`}
            </button>
          </div>
          <div class="col-6" >
            {buttonProps.remainingTime !== 0
              ? <span>
                You can resend otp in
                <span style={{ fontWeight: 'bold', color: 'green' }}>{' '}{buttonProps.remainingTime}</span> secs.
              </span>
              : ``}
          </div>
        </div>
      );
    };
    const renderTime = () => React.Fragment;

    return (
      <>
        <Header1 />
        <Sidebar />
        <div className="content-body">

          {this.state.isloading && (
            <Spinner
              animation="border"
              variant="dark"
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                zIndex: 899,
              }}
            />
          )}

          <div className="container" style={{ marginBottom: 100 }}>

            <div className="row" >
              <div id="navbar-orderlist-container">
                <div id="navbar" >
                  <div className="card" style={{ backgroundColor: '#f8f8fb' }}>
                    <div className="card-header">
                      <div class="row">
                        <div class={this.state.display_mode === 'order_list' || 
                          this.state.display_mode === `` ?
                          "col" : "col-8"}>
                          <h5 >STP List</h5>
                        </div>
                        {
                          this.state.display_mode != 'order_list' &&
                          this.state.display_mode != `` &&
                          <div class="col-4" >
                            <button type="button"
                              className="btn-sm btn-primary"
                              style={{ marginLeft: -1, marginTop: '-15px', color: 'white', width: '110px' }}
                              onClick={() => {
                                this.onLoadStpList();
                              }}
                            >
                              Refresh List
                            </button>
                          </div>
                        }

                      </div>
                    </div>

                    <div class="row" style={{ marginTop: '-10px' }}>
                      <hr />
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ marginTop: 60, marginBottom: 30 }}>

                <div>

                  {(this.state.list_stp_orders || []).length < 1 && (
                    <Alert variant="secondary text-center">
                      <Alert.Heading>
                        {this.state.isloading
                          ? "We are fetching your orders"
                          : "No orders found"}
                      </Alert.Heading>
                    </Alert>
                  )}

                  {this.state.display_mode === 'order_list' &&
                    <ListGroup >
                      {(this.state._list_stp_orders || []).map((item, index) => (
                        <ListGroup.Item
                          key={index}
                          style={{ marginBottom: 15 }}
                          action
                          variant="light"
                          className="wallet-address text-dark"
                        >
                          <div class="row">
                            {item['is_order_cancelled'] &&
                              <div class="col-4">
                                <span style={{
                                  color: 'InfoText',
                                  backgroundColor: '#ec9fa4',
                                  fontWeight: 500,
                                  padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                                }}>
                                  {`CANCELLED`}
                                </span>
                              </div>
                            }

                            <div class="col">
                              {(item['mf_order_folio_no'] || '').length > 6 ?
                                <span
                                  style={{
                                    backgroundColor: item['mf_order_status'] === 'CXL' ? '#dae0e0' : '#e9f1f5',
                                    padding: 4, paddingLeft: 8, paddingRight: 8, margin: -4, borderRadius: 4
                                  }}
                                >
                                  Folio#  {item['mf_order_folio_no']}
                                </span>
                                :
                                ((item["mf_order_number"] || '').length < 4 ?
                                  <span style={{
                                    color: 'InfoText',
                                    backgroundColor: item['mf_order_status'] === 'CXL' ? '#ec9fa4' : '#a6c5ed',
                                    fontWeight: 500,
                                    padding: 4, paddingLeft: 8, paddingRight: 8, margin: -4, borderRadius: 4
                                  }}>
                                    Reg No:{" "} {item["mf_order_registration_no"]}
                                  </span>
                                  :
                                  (item['mf_order_folio_no'] || '').length < 4 &&
                                  <span style={{
                                    fontSize: 16
                                  }}>
                                    Order# {item["mf_order_number"]}
                                  </span>
                                )
                              }

                            </div>


                            {item['mf_order_swp_enabled'] &&
                              <div class="col-5">
                                <span
                                  onClick={() => {
                                    this.showSwpInformation(
                                      "stp",
                                      item,
                                      "show_swp_cancel_modal"
                                    );
                                  }}
                                  style={{
                                    // color: 'InfoText',
                                    backgroundColor: '#eab3f2',
                                    fontWeight: 500,
                                    padding: 4, paddingLeft: 8, paddingRight: 8, margin: -4, borderRadius: 4
                                  }} >
                                  {` `} SWP Enabled
                                  {` `}
                                  <i className="mdi mdi-arrow-right" ></i>
                                </span>
                              </div>
                            }

                          </div>
                          <Row>
                            <br />
                          </Row>
                          <div class="row">
                            <div class="col">

                              <span style={{
                                fontSize: 12, color: 'InfoText',
                                backgroundColor: '#c3d4bc',
                                padding: 4, paddingLeft: 8, paddingRight: 8, margin: -4, borderRadius: 4
                              }} >
                                {item["mf_order_installment_frequency"] || ``}
                              </span>
                              {``}<span style={{
                                fontSize: 12, color: 'InfoText',
                                backgroundColor: '#48DCA6',
                                padding: 4, paddingLeft: 8, paddingRight: 8, margin: 8, borderRadius: 4
                              }} >
                                <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                {item["mf_order_installment_amount"] || ``}
                              </span>
                              {/* </div>
                            <div class="col-6"> */}
                              <span style={{ fontSize: 16, marginLeft: 5 }}>{`Installments: `}</span>
                              <span style={{
                                fontSize: 16,
                                padding: 2, paddingLeft: 4, paddingRight: 1, margin: 4, borderRadius: 4
                              }} >
                                {item["mf_order_no_of_installments"] || ``}
                              </span>

                            </div>
                          </div>
                          <Row>
                            <br />
                          </Row>

                          <Row>
                            <Col>
                              <span
                                style={{
                                  fontSize: 14, marginBottom: 0
                                }}
                              >
                                {item["mf_order_scheme_name"] || ""}
                              </span>

                            </Col>

                          </Row>

                          {/* {item['mf_order_swp_enabled'] &&
                            <Row>
                              <span>
                                <span style={{
                                  // color: 'InfoText',
                                  backgroundColor: '#d1b66d',
                                  fontWeight: 500,
                                  padding: 4, paddingLeft: 8, paddingRight: 8, margin: -4, borderRadius: 4
                                }} >
                                  {` `} SWP Enabled
                                </span>
                                <span style={{ marginLeft: 10 }}>
                                  {item["mf_order_swp_information"]['frequency']} OF
                                  {` `}<span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                  {item["mf_order_swp_information"]['amount']}
                                </span>
                              </span>
                            </Row>
                          } */}


                          <Row>
                            {item['mf_order_stp_enabled'] ?
                              <span
                                onClick={() => {
                                  this.props.history.push({
                                    pathname: "/stpList/",
                                    state: {
                                      request_mode: 'STP LIST',
                                      source_selected_order: item,
                                    },
                                  })
                                }}
                                style={{
                                  width: 110,
                                  backgroundColor: '#d1b66d',
                                  fontWeight: 500,
                                  padding: 4, margin: 11, borderRadius: 4
                                }} >
                                {` STP Enabled`}
                                {` `}
                                <i className="mdi mdi-arrow-right" ></i>
                              </span>
                              :
                              <br />
                            }
                          </Row>

                          {
                            !item['mf_order_2fa_bsestarmf_submitted'] &&
                            item['mf_order_status'] !== 'CXL' &&
                            !item['mf_order_is_allotted'] &&
                            <div class="row">

                              <div class="col">
                                {item["mf_order_message"]} {`  `}
                                <Button
                                  style={{ marginTop: -10, }}
                                  className="text-success"
                                  variant="link" onClick={() => {
                                    this.setState({
                                      show_popup: true,
                                      popup_headerText: item['modal_rta_validation_information']['header'],
                                      popup_messageText:
                                        item['modal_rta_validation_information']['body'],
                                    })
                                  }}>
                                  Why ? </Button>
                              </div>

                            </div>
                          }

                          {item['mf_order_2fa_bsestarmf_submitted'] &&
                            item['mf_order_message_is_showed'] &&
                            <div class="row">

                              <div class="col-8">
                                {item["mf_order_message"]} {`  `}
                                {
                                  !item['mf_order_is_allotted'] &&
                                  <Button
                                    style={{ marginTop: -10, marginLeft: -15 }}
                                    className="text-success"
                                    variant="link" onClick={() => {
                                      this.setState({
                                        show_popup: true,
                                        popup_headerText: item['modal_rta_validation_information']['header'],
                                        popup_messageText:
                                          item['modal_rta_validation_information']['body'],
                                      })
                                    }}>
                                    Why ? </Button>
                                }
                              </div>
                            </div>
                          }

                          <div class="row">
                            { //item['mf_order_is_allotted'] &&
                              parseFloat(item['mf_order_overall_investment_amount']) > 0 ?
                                <div class="col-6">

                                  <div class="row">
                                    <div class="col-5">
                                      Invested
                                    </div>
                                    <div class="col-7">
                                      {item['mf_order_is_allotted'] ?
                                        <span style={{
                                          // color: 'InfoText',
                                          backgroundColor: '#f2f7fa',
                                          fontWeight: 500,
                                          padding: 4, margin: 4, borderRadius: 4
                                        }}>
                                          <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                          {item['mf_order_overall_investment_amount']}
                                        </span>
                                        :
                                        <span style={{
                                          fontFamily: 'Verdana, sans-serif'
                                        }}>
                                          {`-`}
                                        </span>
                                      }
                                    </div>
                                    <div style={{ height: 5 }} />
                                    <div class="row">
                                      <div class="col-5">
                                        Returns
                                      </div>
                                      <div class="col-7">
                                        {item['mf_order_is_allotted'] ?
                                          <span style={{
                                            // color: 'InfoText',
                                            backgroundColor: '#f2f7fa',
                                            padding: 4, paddingLeft: 12, paddingRight: 8, margin: 4, borderRadius: 4,
                                            color: parseFloat(item['total_returns_amount'] || 0) -
                                              parseFloat(item['mf_order_overall_investment_amount']) < 0
                                              ? "#f57362"
                                              : (parseFloat(item['total_returns_amount'] || 0) -
                                                parseFloat(item['mf_order_overall_investment_amount'])) === 0 ? '' : 'green',
                                          }}>
                                            <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                            {item['total_returns_amount']}
                                          </span>
                                          :
                                          <span style={{
                                            fontFamily: 'Verdana, sans-serif'
                                          }}>
                                            {`-`}
                                          </span>
                                        }
                                      </div>
                                    </div>
                                  </div>

                                </div>
                                :
                                <div class="col-6">
                                  {/* {
                                  !item['mf_order_2fa_bsestarmf_submitted'] &&
                                  (item['mf_order_status_id'] === 1 || item['mf_order_status_id'] === 6) &&
                                  <Button className="btn btn-success btn-block"
                                    variant="success"
                                    onClick={() => {
                                      this.props.history.push({
                                        pathname: '/2FABseStarMF/',
                                        state: { selected_order: item }
                                      });
                                    }}
                                  >
                                    Approve
                                  </Button>
                                } */}
                                  {/* {
                                  !item['mf_order_2fa_bsestarmf_submitted'] &&
                                  (item['mf_order_status_id'] === 1 || item['mf_order_status_id'] === 6) &&
                                  <Button className="btn btn-warning btn-block"
                                    variant="success"
                                    onClick={() => {
                                      this.gotoOrderCheckoutPage(item)
                                    }}
                                  >
                                    Pay Now
                                  </Button>
                                } */}
                                </div>
                            }

                            {parseFloat(item['mf_order_overall_investment_amount']) > 0 &&
                              <div class="col-6">
                                <div class="row" >
                                  <div class="col-7">

                                    <div class="row">
                                      <div class="col-4">
                                        {parseFloat(item['returns_difference_amount'] || 0) < 0 ? `Loss` : 'Profit'}
                                      </div>
                                      <div class="col-8">
                                        {item['mf_order_is_allotted'] ?
                                          <span style={{
                                            color: 'InfoText',
                                            padding: 4, margin: 4, borderRadius: 4,
                                            color:
                                              parseFloat(item['returns_difference_amount'] || 0) < 0
                                                ? "#f57362"
                                                : parseFloat(item['returns_difference_amount'] || 0) === 0 ? '' : 'green',
                                          }}>
                                            <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                            {item['returns_difference_amount']}
                                          </span>
                                          :
                                          <span style={{
                                            fontFamily: 'Verdana, sans-serif',
                                            padding: 4, margin: 4, borderRadius: 4
                                          }}>
                                            {`-`}
                                          </span>
                                        }
                                      </div>
                                    </div>

                                    <div class="row">
                                      <div class="col-4">
                                        Returns%
                                      </div>
                                      <div class="col-8">
                                        {item['mf_order_is_allotted'] ?
                                          <span style={{
                                            fontFamily: 'Verdana, sans-serif',
                                            padding: 4, margin: 4, borderRadius: 4,
                                            color:
                                              parseFloat(item['returns_difference_amount']) < 0
                                                ? "#f57362"
                                                : (parseFloat(item['returns_difference_amount']) === 0 ? '' : 'green'),
                                          }}>
                                            {parseFloat(item['returns_difference_amount']) > 0 ? `+` : ``}
                                            {item['total_returns_percent']}</span>
                                          :
                                          <span style={{
                                            fontFamily: 'Verdana, sans-serif',
                                            padding: 4, margin: 4, borderRadius: 4
                                          }}>
                                            {`-`}
                                          </span>
                                        }
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            }
                          </div>

                          {parseFloat(item['mf_order_overall_investment_amount']) >= 0 &&
                            <div class="row" style={{ marginTop: 15, textAlign: 'right' }}>
                              <div class="col-11">
                                <button type="button" class="btn-sm btn-success"
                                  style={{ color: 'white' }}
                                  onClick={() => {
                                    this.setState({
                                      show_modal_action: true,
                                      modal_action_order: item
                                    });
                                  }}
                                  disabled={this.state.isloading}>
                                  Explore Options
                                </button>
                              </div>
                            </div>
                          }
                          {/* <hr /> */}
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  }

                  {this.state.display_mode === 'scheme_action_completed' &&
                    <div>

                      <div className="row">
                        <div className="col-lg-12">
                          <div dangerouslySetInnerHTML={this.createDefaultMarkup()} />
                        </div>
                      </div>
                    </div>
                  }

                  {this.state
                    .show_popup && (
                      <Modal
                        show={
                          this.state
                            .show_popup
                        }
                        onHide={() =>
                          this.closePopup()
                        }
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        keyboard={false}
                        fullscreen={true}
                        backdrop="static"
                      >
                        <Modal.Header>{this.state.popup_headerText}</Modal.Header>
                        <Modal.Body>
                          <div style={{ margin: 0, padding: 0, fontSize: 12 }}
                            dangerouslySetInnerHTML={this.htmlDefaultMarkup(
                              this.state.popup_messageText
                            )}
                          />

                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="primary"
                            onClick={() =>
                              this.closePopup()
                            }
                          >
                            Close
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    )
                  }

                  {this.state.show_swp_modal &&
                    <Modal
                      show={
                        this.state
                          .show_swp_modal
                      }
                      onHide={() =>
                        this.setState({
                          show_swp_modal: false,
                          modal_orderinfo: {},
                          swp_order: {
                            ...this.state.swp_order,
                            is_otp_enabled: false,
                            two_factor_authentication: {
                              mobile_otp: '',
                              email_otp: '',
                              twofactor_token_id: ''
                            }
                          }
                        })
                      }
                      size="lg"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                      keyboard={false}
                      fullscreen={true}
                      backdrop="static"
                    >
                      <Modal.Header closeButton>
                        <h4>Systematic Withdrawal Plan </h4>

                      </Modal.Header>
                      <Modal.Body>
                        <div>

                          <p>
                            {this.state.modal_orderinfo['mf_order_scheme_name']}
                          </p>
                          <hr />
                          <div className="row">
                            <div class="col-6">
                              <div class="form-group">
                                <p class="mr-sm-2 text-gray-dark" style={{ marginBottom: 0 }}>No Of Withdrawals</p>

                                <Select
                                  value={(this.state.modal_orderinfo['swp_information']['installment_numbers_list'] || []).find(obj => obj.value === this.state.swp_order['swp_no_of_installments'] || '')}
                                  onChange={(e) => this.handleChangeSelect(e, this.state.modal_orderinfo, 'swp_no_of_installments', 'swp')}
                                  options={this.state.modal_orderinfo['swp_information']['installment_numbers_list']}
                                  isSearchable={false}
                                  isDisabled={this.state.swp_order['is_otp_enabled']}
                                />
                              </div>
                            </div>
                            <div class="col-6">
                              <div class="form-group">
                                <p class="mr-sm-2 text-gray-dark" style={{ marginBottom: 0 }}>Choose Frequency</p>
                                {this.state.modal_orderinfo['swp_information']['frequency_list'] &&
                                  <Select
                                    value={this.state.modal_orderinfo['swp_information']['frequency_list'].find(obj => obj.value === this.state.swp_order['swp_frequency'] || '')}
                                    onChange={(e) => this.handleChangeSelect(e, this.state.modal_orderinfo, 'swp_frequency', 'swp')}
                                    options={this.state.modal_orderinfo['swp_information']['frequency_list']}
                                    isSearchable={false}
                                    isDisabled={this.state.swp_order['is_otp_enabled']}
                                  />
                                }
                              </div>
                            </div>
                          </div>
                          <br />
                          <div className="row">
                            <div class="col">
                              <div class="form-group">
                                <p class="mr-sm-2 text-gray-dark" style={{ marginBottom: 0 }}>Enter Amount</p>
                                <input
                                  style={{ width: 190, letterSpacing: 2 }}
                                  name="swp_amount"
                                  class="form-control"
                                  placeholder=""
                                  maxLength="7"
                                  type="tel"
                                  value={this.state.swp_order['swp_amount']}
                                  disabled={this.state.swp_order['is_otp_enabled']}
                                  onKeyPress={event => {
                                    if (event.target.key === 'Enter') {
                                      event.preventDefault();
                                    }
                                  }}
                                  onChange={event => {
                                    const targetValue = event.target.value.replace(/\s/g, '');

                                    if (targetValue.length === 0)
                                      this.setState({ swp_order: { ...this.state.swp_order, swp_amount: targetValue } });
                                    else {
                                      if (targetValue.match(/^[0-9]+$/)) {
                                        if (parseFloat(targetValue) <= parseFloat(this.state.modal_orderinfo['swp_information']['maximum_amount']))
                                          this.setState({ swp_order: { ...this.state.swp_order, swp_amount: targetValue } });

                                      }

                                    }

                                  }

                                  }
                                />
                                <ul className="list-horizontal">
                                  <li>
                                    <span style={{ color: `#733dd1` }}>
                                      Min.
                                      <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                      {this.state.modal_orderinfo['swp_information']['minimum_amount']} {`  `}</span>
                                  </li>
                                  <li>
                                    <span style={{ color: `#0f967b` }}>Max.
                                      <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                      {this.state.modal_orderinfo['swp_information']['maximum_amount']}
                                    </span>
                                  </li>
                                </ul>

                              </div>
                            </div>
                          </div>
                          <br />
                          <div className="row">
                            <div className="col">
                              <div class="form-group">
                                <p class="mr-sm-2 text-gray-dark" style={{ marginBottom: 0 }}>SWP Date</p>

                                <input
                                  type="text"
                                  style={{ width: 190 }}
                                  name="swp_date"
                                  class="form-control"
                                  placeholder=""
                                  maxLength="2"
                                  readOnly={true}
                                  value={this.state.swp_order['swp_date_value']}
                                  onClick={event => {
                                    this.setState({
                                      show_swpdate_popup: true,
                                      popup_swp_date_list: this.state.modal_orderinfo['swp_information']['swp_date_list']
                                    })
                                  }}
                                  disabled={
                                    (this.state.swp_order['swp_frequency'] || '').length < 4
                                    || this.state.swp_order['is_otp_enabled']}

                                />
                              </div>
                            </div>
                            <div className="col-4">
                              <p></p>
                              <button
                                className="btn btn-success btn-sm"
                                style={{ fontSize: 12, marginBottom: 10 }}
                                disabled={
                                  this.state.isloading ||

                                  isNaN(this.state.swp_order['swp_no_of_installments']) ||
                                  isNaN(this.state.swp_order['swp_amount']) ||
                                  (this.state.swp_order['swp_amount']).trim().length === 0 ||
                                  (this.state.swp_order['swp_date'] || ``).toString().trim().length === 0 ||

                                  parseInt(this.state.swp_order['swp_amount']) > parseInt(this.state.modal_orderinfo['swp_information']['maximum_amount']) ||
                                  parseInt(this.state.swp_order['swp_amount']) < parseInt(this.state.modal_orderinfo['swp_information']['minimum_amount']) ||

                                  this.state.swp_order['swp_frequency'].length < 5 ||
                                  parseInt(this.state.swp_order['swp_no_of_installments']) < 1 ||
                                  parseInt(this.state.swp_order['swp_date']) < 1 ||
                                  this.state.swp_order['is_otp_enabled']
                                }
                                onClick={() => {

                                  if ((parseInt(this.state.swp_order['swp_no_of_installments']) * parseInt(this.state.swp_order['swp_amount']))
                                    > parseInt(this.state.modal_orderinfo['swp_information']['maximum_amount']))
                                    alert(`Sorry, Your number of withdrawals exceeds your available withdrawal amount`);
                                  else
                                    this.sendOTP();
                                }}
                              >
                                Register
                              </button>
                            </div>
                          </div>
                          {this.state.swp_order['swp_date_text'].toString().length > 10 &&
                            <div className="row">
                              <p style={{ color: `#733dd1`, marginBottom: 0 }} >
                                Your 1st withdrawal will be placed on {new Date(this.state.swp_order['swp_date_text']).toLocaleString("en-IN", { year: 'numeric', month: 'short', day: 'numeric' })}
                              </p>
                            </div>
                          }
                          <br />

                        </div>
                        {this.state.swp_order['is_otp_enabled'] &&
                          <div>
                            <hr />
                            <div className="row">
                              <div class="col">
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                  <Form.Label>Enter OTP received on your registered mobile</Form.Label>
                                  <OTPInput
                                    value={this.state.swp_order['two_factor_authentication']['mobile_otp']}
                                    onChange={this.handle2FactorChange('mobile_otp', 'two_factor_authentication', 'swp_order')}
                                    autoFocus
                                    OTPLength={6}
                                    otpType="number"
                                    secure
                                    isInputNum={true}
                                  />
                                </Form.Group>
                              </div>
                            </div>
                            <br />
                            <Form.Group
                              className="mb-3"
                            >
                              <Form.Label>Enter OTP received on your registered email</Form.Label>
                              <OTPInput
                                value={this.state.swp_order['two_factor_authentication']['email_otp']}
                                onChange={this.handle2FactorChange('email_otp', 'two_factor_authentication', 'swp_order')}
                                OTPLength={6}
                                otpType="number"
                                secure
                                isInputNum={true}
                              />
                            </Form.Group>
                            <br />
                            <div className="row">
                              <ResendOTP maxTime={120} renderButton={renderButton} style={{ color: 'black', marginTop: 20 }} renderTime={renderTime}
                                onResendClick={(e) => { this.sendOTP() }}
                              />
                            </div>
                            <hr />
                            <div class="row">
                              <div class="col">
                                <br />
                                <button
                                  className="btn btn-success btn-sm"
                                  onClick={() => {
                                    this.OnCreateSwpOrder("stp", this.state.modal_orderinfo);
                                  }}
                                  disabled={
                                    this.state.isloading ||
                                    isNaN(this.state.swp_order['swp_no_of_installments']) ||
                                    isNaN(this.state.swp_order['swp_amount']) ||
                                    this.state.swp_order['swp_no_of_installments'].length < 1 ||
                                    this.state.swp_order['swp_amount'].length < 1 ||
                                    this.state.swp_order['swp_frequency'].length < 5 ||
                                    parseInt(this.state.swp_order['swp_no_of_installments']) < 1 ||
                                    parseInt(this.state.swp_order['swp_amount']) < parseInt(this.state.modal_orderinfo['minimum_swp_amount'])
                                  }
                                >
                                  Confirm SWP
                                </button>
                              </div>

                            </div>
                          </div>
                        }
                      </Modal.Body>
                      {/* <Modal.Footer>
                      <div className="row">
                        <div class="col">
                          <button
                            className="btn btn-success btn-sm"
                            style={{ fontSize: 12, width: 170 }}
                            onClick={() => {
                              this.setState({
                                show_modal_pin_confirm_dialog: true,
                                modal_pin_confirm_action: 'start swp',
                                modal_pin_confirm_value: ''
                              })
                            }}
                          >
                            Confirm
                          </button>
                        </div>
                        <div class="col">
                          <button
                            className="btn btn-warning btn-sm"
                            style={{ width: 100 }}
                            onClick={() =>
                              this.setState({ show_swp_modal: false })
                            }
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </Modal.Footer> */}
                      {this.state.isloading && (
                        <Spinner
                          animation="border"
                          variant="dark"
                          style={{
                            position: "fixed",
                            top: "50%",
                            left: "50%",
                            zIndex: 899,
                          }}
                        />
                      )}
                    </Modal>
                  }

                  <Modal
                    show={
                      this.state
                        .show_view_modal
                    }
                    onHide={() =>
                      this.setState({ show_view_modal: false })
                    }
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    keyboard={false}
                    fullscreen={true}
                    backdrop="static"
                  >
                    <Modal.Header closeButton>
                      {this.state.modal_orderinfo['mf_order_scheme_name']}
                    </Modal.Header>
                    <Modal.Body>
                      <div>
                        {this.state.isloading && (
                          <Spinner
                            animation="border"
                            variant="dark"
                            style={{
                              position: "fixed",
                              top: "50%",
                              left: "50%",
                              zIndex: 899,
                            }}
                          />
                        )}
                        {this.state.modal_orderinfo["show_child"] &&
                          (this.state.modal_orderinfo["display_installment_info_html"] || "")
                            .length > 7 && (
                            <div>
                              <div style={{ margin: 0, padding: 0 }}
                                dangerouslySetInnerHTML={this.htmlDefaultMarkup(
                                  this.state.modal_orderinfo[
                                  "display_installment_info_html"
                                  ]
                                )}
                              />

                              <hr />
                              <div >
                                <Card sx={{ backgroundColor: '#edf3f0', p: 2, borderRadius: 2 }}>
                                  <CardContent>

                                    <div className="row">

                                      <div class="col-6">
                                        <Button
                                          disabled={
                                            this.state.modal_orderinfo["mf_order_is_invest_more"] ===
                                            false
                                          }

                                          variant="primary"
                                          onClick={() => {
                                            this.gotoCheckOutPage(
                                              "investmore",
                                              this.state.modal_orderinfo
                                            );
                                          }}
                                        >
                                          Invest More
                                        </Button>
                                      </div>

                                      <div class="col-5">
                                        <Button
                                          disabled={
                                            this.state.modal_orderinfo["is_redeem_allowed"] === false
                                          }
                                          className="btn btn-warning"
                                          onClick={() => {
                                            this.gotoCheckOutPage("redeem", this.state.modal_orderinfo);
                                          }}
                                        >
                                          Redeem
                                        </Button>
                                      </div>

                                    </div>

                                    {this.state.modal_orderinfo['is_swp_allowed'] &&
                                      this.state.modal_orderinfo['mf_order_swp_enabled'] &&
                                      <div className="row">
                                        <Button
                                          className="btn btn-warning"
                                          onClick={() => {
                                            /*this.OnCancelSwpOrder("stp", this.state.modal_orderinfo);*/
                                            this.setState({
                                              show_modal_pin_confirm_dialog: true,
                                              modal_pin_confirm_action: 'cancel swp',
                                              modal_pin_confirm_value: ''
                                            })
                                          }}
                                        >
                                          Cancel SWP
                                        </Button>
                                      </div>
                                    }
                                    {/* {this.state.modal_orderinfo['is_swp_allowed'] &&
                                    !this.state.modal_orderinfo['mf_order_swp_enabled'] &&
                                    <div className="row">
                                      <Button
                                        className="btn btn-primary"
                                        style={{ color: 'white' }}
                                        onClick={() => {
                                          this.showSwpInformation(
                                            "stp",
                                            this.state.modal_orderinfo,
                                            "view"
                                          );
                                        }}
                                      >
                                        Start SWP
                                      </Button>
                                    </div>
                                  } */}
                                    {(this.state.modal_orderinfo['is_swp_allowed'] &&
                                      (this.state.modal_orderinfo["show_swp"] ||
                                        this.state.modal_orderinfo["mf_order_swp_enabled"])) &&
                                      <br />
                                    }
                                    <div className="row">
                                      {` `}
                                      {this.state.modal_orderinfo["is_cancel_allowed"] && (
                                        <button
                                          className="btn btn-danger btn-block"
                                          onClick={() => {
                                            /*this.OnCancelOrder("stp", this.state.modal_orderinfo);*/
                                            this.setState({
                                              show_modal_pin_confirm_dialog: true,
                                              modal_pin_confirm_action: 'cancel order',
                                              modal_pin_confirm_value: ''
                                            })
                                          }}
                                          disabled={this.state.isloading}
                                        >
                                          Cancel Order
                                        </button>
                                      )}
                                    </div>
                                  </CardContent>
                                </Card>
                              </div>

                            </div>
                          )}
                      </div>

                    </Modal.Body>
                    {/* <Modal.Footer >
                    {`      `}
                    <Button
                      variant="secondary"
                      onClick={() =>
                        this.setState({ show_view_modal: false })
                      }
                    >
                      Done
                    </Button>
                  </Modal.Footer> */}
                  </Modal>
                  {
                    this.state.display_mode === `order_list` &&
                    this.state.list_stp_orders.length > 0 &&
                    <Fab
                      style={{ top: 48, left: 135, transform: 'scale(0.8)' }}
                      // event="hover"
                      alwaysShowTitle={true}
                      mainButtonStyles={{
                        backgroundColor: "#9589ab"
                      }}
                      icon={<i className="mdi mdi-filter" ></i>}
                      onClick={() => this.setState({ show_modal_filter: true })}
                    >
                    </Fab>
                  }

                  {this.state
                    .show_modal_filter && (
                      <Modal
                        show={
                          this.state
                            .show_modal_filter
                        }
                        onHide={() =>
                          this.setState({ show_modal_filter: false })
                        }
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                      >
                        <Modal.Header>
                          <h5 style={{ textAlign: 'center' }}>
                            Search with status
                          </h5>
                        </Modal.Header>
                        <Modal.Body>
                          <div >
                            {!this.state.loading && (
                              <div>
                                <Container>
                                  <Row >
                                    <Col>
                                      <div class="form-group">
                                        <div
                                          style={{ textAlign: 'center' }} >
                                          <Select
                                            placeholder={`-CHOOSE STATUS-`}
                                            isMulti
                                            value={this.state.status_filter_selected}
                                            onChange={this.handleChangeStatus}
                                            options={this.state.status_filter_list}
                                          />
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  <div
                                    class="form-group"
                                    style={{ marginBottom: 10, marginTop: 10 }}
                                  ></div>
                                </Container>
                              </div>
                            )}
                          </div>

                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="primary"
                            onClick={() =>
                              this.resetFilter('stp')
                            }
                          >
                            Reset Filter
                          </Button>
                          {`      `}
                          <Button
                            variant="secondary"
                            onClick={() =>
                              this.setState({ show_modal_filter: false })
                            }
                          >
                            Done
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    )}


                </div>
              </div>

              {this.state.show_swp_cancel_modal && (
                <Modal
                  show={
                    this.state
                      .show_swp_cancel_modal
                  }
                  onHide={() =>
                    this.setState({
                      show_swp_cancel_modal: false,
                      modal_orderinfo: {},
                      swp_order: {
                        ...this.state.swp_order,
                        is_otp_enabled: false,
                        two_factor_authentication: {
                          mobile_otp: '',
                          email_otp: '',
                          twofactor_token_id: ''
                        }
                      }
                    })
                  }
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  keyboard={false}
                  fullscreen={true}
                  backdrop="static"
                >
                  <Modal.Header closeButton>
                    <h4>Systematic Withdrawal Plan - Cancellation </h4>

                  </Modal.Header>
                  <Modal.Body>
                    <div>

                      <p>
                        {this.state.modal_orderinfo['mf_order_scheme_name']}
                      </p>
                      <hr />
                      <div className="row">
                        <div class="col-5">
                          <div class="form-group">
                            <p class="mr-sm-2 text-gray-dark" style={{ marginBottom: 0 }}> Frequency</p>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-group">
                            {this.state.modal_orderinfo['mf_order_swp_information']['frequency']}
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div class="col-5">
                          <div class="form-group">
                            <p class="mr-sm-2 text-gray-dark" style={{ marginBottom: 0 }}>No Of Withdrawals</p>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-group">
                            <span>
                              {this.state.modal_orderinfo['mf_order_swp_information']['no_of_installments']}
                            </span>
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div class="col-5">
                          <div class="form-group">
                            <p class="mr-sm-2 text-gray-dark" style={{ marginBottom: 0 }}>SWP Amount</p>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-group">
                            <span>
                              {this.state.modal_orderinfo['mf_order_swp_information']['amount']}
                            </span>
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-5">
                          <div class="form-group">
                            End Date
                          </div>
                        </div>
                        <div className="col-6">
                          <span>
                            {new Date(this.state.modal_orderinfo['mf_order_swp_information']['end_date']).toLocaleString("en-IN", { year: 'numeric', month: 'short', day: 'numeric' })}
                          </span>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-6">
                          <div class="form-group">
                            Recent Withdrew Date
                          </div>
                        </div>
                        <div className="col-6">
                          <span>
                            {this.state.modal_orderinfo['mf_order_swp_information']['recent_withdrew_date'].length < 10 ? `-` :
                              new Date(this.state.modal_orderinfo['mf_order_swp_information']['recent_withdrew_date']).toLocaleString("en-IN", { year: 'numeric', month: 'short', day: 'numeric' })}
                          </span>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-6">
                          <div class="form-group">
                            Total Withdrew Amount
                          </div>
                        </div>
                        <div className="col-6">
                          <span>
                            {this.state.modal_orderinfo['mf_order_swp_information']['total_withdrew_amount']}
                          </span>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <p style={{ color: `#733dd1`, marginBottom: 0 }} >
                          Your 1st withdrawal will be placed on {new Date(this.state.modal_orderinfo['mf_order_swp_information']['start_date']).toLocaleString("en-IN", { year: 'numeric', month: 'short', day: 'numeric' })}
                        </p>
                        <br />
                      </div>
                      <br />
                      <div class="row">
                        <div class="col-1"></div>
                        <div class="col-10">
                          <button
                            className="btn btn-success btn-sm"
                            style={{ fontSize: 12, marginBottom: 10, width: 280 }}
                            disabled={
                              this.state.isloading ||
                              this.state.swp_order['is_otp_enabled']
                            }
                            onClick={() => {
                              this.sendOTP();
                            }}
                          >
                            Cancel SWP
                          </button>
                        </div>
                        <div class="col-1"></div>
                      </div>
                    </div>
                    {this.state.swp_order['is_otp_enabled'] &&
                      <div>
                        <hr />
                        <div className="row">
                          <div class="col">
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                              <Form.Label>Enter OTP received on your registered mobile</Form.Label>
                              <OTPInput
                                value={this.state.swp_order['two_factor_authentication']['mobile_otp']}
                                onChange={this.handle2FactorChange('mobile_otp', 'two_factor_authentication', 'swp_order')}
                                autoFocus
                                OTPLength={6}
                                otpType="number"
                                secure
                                isInputNum={true}
                              />
                            </Form.Group>
                          </div>
                        </div>
                        <br />
                        <Form.Group
                          className="mb-3"
                        >
                          <Form.Label>Enter OTP received on your registered email</Form.Label>
                          <OTPInput
                            value={this.state.swp_order['two_factor_authentication']['email_otp']}
                            onChange={this.handle2FactorChange('email_otp', 'two_factor_authentication', 'swp_order')}
                            OTPLength={6}
                            otpType="number"
                            secure
                            isInputNum={true}
                          />
                        </Form.Group>
                        <br />
                        <div className="row">
                          <ResendOTP maxTime={120} renderButton={renderButton} style={{ color: 'black', marginTop: 20 }} renderTime={renderTime}
                            onResendClick={(e) => { this.sendOTP() }}
                          />
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col">
                            <br />
                            <button
                              className="btn btn-success btn-sm"
                              onClick={() => {
                                this.OnCancelSwpOrder("stp", this.state.modal_orderinfo);
                              }}
                              disabled={
                                this.state.isloading
                              }
                            >
                              Confirm
                            </button>
                          </div>

                        </div>
                      </div>
                    }
                  </Modal.Body>
                  {this.state.isloading && (
                    <Spinner
                      animation="border"
                      variant="dark"
                      style={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        zIndex: 899,
                      }}
                    />
                  )}
                </Modal>
              )}

              {this.state.show_stp_cancel_modal && (
                <Modal
                  show={
                    this.state
                      .show_stp_cancel_modal
                  }
                  onHide={() =>
                    this.setState({
                      show_stp_cancel_modal: false,
                      modal_orderinfo: {},
                      swp_order: {
                        ...this.state.swp_order,
                        is_otp_enabled: false,
                        two_factor_authentication: {
                          mobile_otp: '',
                          email_otp: '',
                          twofactor_token_id: ''
                        }
                      }
                    })
                  }
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  keyboard={false}
                  fullscreen={true}
                  backdrop="static"
                >
                  <Modal.Header closeButton>
                    <h4>Systematic Transfer Plan - Cancellation </h4>

                  </Modal.Header>
                  <Modal.Body>
                    <div>

                      <p>
                        {this.state.modal_orderinfo['mf_order_scheme_name']}
                      </p>
                      <hr />
                      <div className="row">
                        <div class="col-5">
                          <div class="form-group">
                            <p class="mr-sm-2 text-gray-dark" style={{ marginBottom: 0 }}> Frequency</p>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-group">
                            {this.state.modal_orderinfo['mf_order_stp_information']['frequency']}
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div class="col-5">
                          <div class="form-group">
                            <p class="mr-sm-2 text-gray-dark" style={{ marginBottom: 0 }}>No Of Withdrawals</p>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-group">
                            <span>
                              {this.state.modal_orderinfo['mf_order_stp_information']['no_of_installments']}
                            </span>
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div class="col-5">
                          <div class="form-group">
                            <p class="mr-sm-2 text-gray-dark" style={{ marginBottom: 0 }}>SWP Amount</p>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-group">
                            <span>
                              {this.state.modal_orderinfo['mf_order_stp_information']['amount']}
                            </span>
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-5">
                          <div class="form-group">
                            End Date
                          </div>
                        </div>
                        <div className="col-6">
                          <span>
                            {new Date(this.state.modal_orderinfo['mf_order_stp_information']['end_date']).toLocaleString("en-IN", { year: 'numeric', month: 'short', day: 'numeric' })}
                          </span>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-6">
                          <div class="form-group">
                            Recent Withdrew Date
                          </div>
                        </div>
                        <div className="col-6">
                          <span>
                            {this.state.modal_orderinfo['mf_order_stp_information']['recent_withdrew_date'].length < 10 ? `-` :
                              new Date(this.state.modal_orderinfo['mf_order_stp_information']['recent_withdrew_date']).toLocaleString("en-IN", { year: 'numeric', month: 'short', day: 'numeric' })}
                          </span>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-6">
                          <div class="form-group">
                            Total Withdrew Amount
                          </div>
                        </div>
                        <div className="col-6">
                          <span>
                            {this.state.modal_orderinfo['mf_order_stp_information']['total_withdrew_amount']}
                          </span>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <p style={{ color: `#733dd1`, marginBottom: 0 }} >
                          Your 1st withdrawal will be placed on {new Date(this.state.modal_orderinfo['mf_order_stp_information']['start_date']).toLocaleString("en-IN", { year: 'numeric', month: 'short', day: 'numeric' })}
                        </p>
                        <br />
                      </div>
                      <br />
                      <div class="row">
                        <div class="col-1"></div>
                        <div class="col-10">
                          <button
                            className="btn btn-success btn-sm"
                            style={{ fontSize: 12, marginBottom: 10, width: 280 }}
                            disabled={
                              this.state.isloading ||
                              this.state.swp_order['is_otp_enabled']
                            }
                            onClick={() => {
                              this.sendOTP();
                            }}
                          >
                            Cancel SWP
                          </button>
                        </div>
                        <div class="col-1"></div>
                      </div>
                    </div>
                    {this.state.swp_order['is_otp_enabled'] &&
                      <div>
                        <hr />
                        <div className="row">
                          <div class="col">
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                              <Form.Label>Enter OTP received on your registered mobile</Form.Label>
                              <OTPInput
                                value={this.state.swp_order['two_factor_authentication']['mobile_otp']}
                                onChange={this.handle2FactorChange('mobile_otp', 'two_factor_authentication', 'swp_order')}
                                autoFocus
                                OTPLength={6}
                                otpType="number"
                                secure
                                isInputNum={true}
                              />
                            </Form.Group>
                          </div>
                        </div>
                        <br />
                        <Form.Group
                          className="mb-3"
                        >
                          <Form.Label>Enter OTP received on your registered email</Form.Label>
                          <OTPInput
                            value={this.state.swp_order['two_factor_authentication']['email_otp']}
                            onChange={this.handle2FactorChange('email_otp', 'two_factor_authentication', 'swp_order')}
                            OTPLength={6}
                            otpType="number"
                            secure
                            isInputNum={true}
                          />
                        </Form.Group>
                        <br />
                        <div className="row">
                          <ResendOTP maxTime={120} renderButton={renderButton} style={{ color: 'black', marginTop: 20 }} renderTime={renderTime}
                            onResendClick={(e) => { this.sendOTP() }}
                          />
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col">
                            <br />
                            <button
                              className="btn btn-success btn-sm"
                              onClick={() => {
                                this.OnCancelSwpOrder("stp", this.state.modal_orderinfo);
                              }}
                              disabled={
                                this.state.isloading
                              }
                            >
                              Confirm
                            </button>
                          </div>

                        </div>
                      </div>
                    }
                  </Modal.Body>
                  {this.state.isloading && (
                    <Spinner
                      animation="border"
                      variant="dark"
                      style={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        zIndex: 899,
                      }}
                    />
                  )}
                </Modal>
              )}

              {this.state.show_modal_pin_confirm_dialog && (
                <Modal show={this.state.show_modal_pin_confirm_dialog}
                  onHide={() => this.setState({
                    show_modal_pin_confirm_dialog: false,
                    modal_pin_confirm_action: '',
                    modal_pin_confirm_value: ''
                  })}
                  centered
                  keyboard={false}
                  fullscreen={false}
                  backdrop="static"
                >
                  <Modal.Header>

                  </Modal.Header>
                  <Modal.Body>

                    <p>{this.state["modal_pin_confirm_dialog_messagetext"]}</p>
                    <OTPInput
                      value={this.state.modal_pin_confirm_value}
                      onChange={event => this.setState({ modal_pin_confirm_value: event })}
                      autoFocus
                      OTPLength={4}
                      otpType="number"
                      disabled={false}
                      secure
                      isInputNum={true}
                    />
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="primary"
                      disabled={this.state.modal_pin_confirm_value.length !== 4}
                      onClick={() => {

                        if (this.state.modal_pin_confirm_action === 'start swp') {
                          this.OnCreateSwpOrder("stp", this.state.modal_orderinfo);
                        }
                        else if (this.state.modal_pin_confirm_action === 'cancel order') {
                          this.onCancelOrder(
                            "stp",
                            Object.assign(this.state.modal_orderinfo,
                              { pin_verification_value: this.state.modal_pin_confirm_value })
                          );
                        }
                        else if (this.state.modal_pin_confirm_action === 'cancel swp') {
                          this.OnCancelSwpOrder(
                            "stp",
                            Object.assign(this.state.modal_orderinfo, { pin_verification_value: this.state.modal_pin_confirm_value })
                          );
                        }

                      }}>
                      Confirm
                    </Button>
                    <Button variant="primary"
                      onClick={() => this.setState({
                        show_modal_pin_confirm_dialog: false,
                        modal_pin_confirm_action: '',
                        modal_pin_confirm_value: ''
                      })}>
                      Cancel
                    </Button>
                  </Modal.Footer>
                </Modal>
              )}

            </div>

          </div>
        </div>

        {
          <Modal
            show={
              this.state
                .show_modal_action
            }
            onHide={() =>
              this.setState({ show_modal_action: false })
            }
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
            backdrop="static"
          >
            <Modal.Header>
              <h5 style={{ textAlign: 'center' }}>
                Choose an action
              </h5>
            </Modal.Header>
            <Modal.Body>
              <div >

                {this.state.loading ?
                  <Spinner
                    animation="border"
                    variant="dark"
                    style={{
                      position: "fixed",
                      top: "50%",
                      left: "50%",
                      zIndex: 899,
                    }}
                  />
                  :
                  (
                    <div>

                      <Container>
                        <Row >
                          <Col>
                            <div class="form-group">
                              <div class="row">
                                <div class="col">
                                  <Form.Check
                                    type="radio"
                                    label={
                                      <span>
                                        <i className="fa fa-history fa-lg"></i>
                                        <span style={{
                                          backgroundColor: '#f5f0e4',
                                          fontSize: 18,
                                          padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                                        }}>
                                          {`Show History`}
                                        </span>
                                      </span>
                                    }
                                    style={{ fontSize: 18 }}
                                    name="show_history"
                                    id={`show_history`}
                                    value={`show_history`}
                                    checked={
                                      this.state.modal_action_name === `show_history`
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        modal_action_name: `show_history`
                                      })
                                    }
                                    key={`action_show_history`}
                                  />
                                </div>
                              </div>
                              {this.state.modal_action_order["is_view_statement_allowed"] &&
                                <br />
                              }
                              {this.state.modal_action_order["is_view_statement_allowed"] &&
                                <div class="row">
                                  <div class="col">
                                    <Form.Check
                                      type="radio"
                                      label={
                                        <span >
                                          <i className="fa fa-file-pdf-o fa-lg"></i>
                                          <span style={{
                                            backgroundColor: '#f5f0e4',
                                            fontSize: 18,
                                            padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                                          }}>
                                            {`View Statement`}
                                          </span>
                                        </span>
                                      }
                                      style={{ fontSize: 18 }}
                                      name="view_statement"
                                      id={`view_statement`}
                                      value={`view_statement`}
                                      checked={
                                        this.state.modal_action_name === `view_statement`
                                      }
                                      onChange={(e) =>
                                        this.setState({
                                          modal_action_name: `view_statement`
                                        })
                                      }
                                      key={`action_view_statement`}
                                    />
                                    <hr />
                                  </div>
                                </div>
                              }

                              {parseFloat(this.state.modal_action_order['mf_order_overall_investment_amount']) > 1 &&
                                <br />
                              }
                              {parseFloat(this.state.modal_action_order['mf_order_overall_investment_amount']) > 1 &&
                                <div>

                                  <div class="row">
                                    <div class="col">
                                      <Form.Check
                                        type="radio"
                                        label={
                                          <span>
                                            <i className="fa fa-info fa-lg"></i>
                                            <span style={{
                                              backgroundColor: '#f5f0e4',
                                              fontSize: 18,
                                              padding: 4, paddingLeft: 8, paddingRight: 8, margin: 22, borderRadius: 4
                                            }}>
                                              {`View Info`}
                                            </span>
                                          </span>
                                        }
                                        style={{ fontSize: 18 }}
                                        name="view_info"
                                        id={`view_info`}
                                        value={`view_info`}
                                        checked={
                                          this.state.modal_action_name === `view_info`
                                        }
                                        onChange={(e) =>
                                          this.setState({
                                            modal_action_name: `view_info`
                                          })
                                        }
                                        key={`action_view_info`}
                                      />
                                      <br />
                                    </div>
                                  </div>

                                  {this.state.modal_action_order['is_stp_allowed'] &&
                                    <div class="row">
                                      {this.state.modal_action_order['mf_order_stp_enabled'] ?
                                        <div class="col">
                                          <Form.Check
                                            type="radio"
                                            label={
                                              <span>
                                                <i className="la la-user"></i>
                                                <span style={{
                                                  backgroundColor: '#f5f0e4',
                                                  fontSize: 18,
                                                  padding: 4, paddingLeft: 8, paddingRight: 8, margin: 7, borderRadius: 4
                                                }}>
                                                  {`Cancel STP`}
                                                </span>
                                              </span>
                                            }
                                            style={{ fontSize: 18 }}
                                            name="cancel_stp"
                                            id={`cancel_stp`}
                                            value={`cancel_stp`}
                                            checked={
                                              this.state.modal_action_name === `cancel_stp`
                                            }
                                            onChange={(e) =>
                                              this.setState({
                                                modal_action_name: `cancel_stp`
                                              })
                                            }
                                            key={`action_cancel_stp`}
                                          />
                                          <br />
                                        </div>
                                        :
                                        <div class="col">
                                        </div>
                                      }
                                    </div>
                                  }

                                  {this.state.modal_action_order['is_swp_allowed'] &&
                                    <div class="row">
                                      {this.state.modal_action_order['mf_order_swp_enabled'] ?
                                        <div class="col">
                                          <Form.Check
                                            type="radio"
                                            label={
                                              <span>
                                                <i className="la la-user"></i>
                                                <span style={{
                                                  backgroundColor: '#f5f0e4',
                                                  fontSize: 18,
                                                  padding: 4, paddingLeft: 8, paddingRight: 8, margin: 20, borderRadius: 4
                                                }}>
                                                  {`Cancel SWP`}
                                                </span>
                                              </span>
                                            }
                                            style={{ fontSize: 18 }}
                                            name="cancel_swp"
                                            id={`cancel_swp`}
                                            value={`cancel_swp`}
                                            checked={
                                              this.state.modal_action_name === `cancel_swp`
                                            }
                                            onChange={(e) =>
                                              this.setState({
                                                modal_action_name: `cancel_swp`
                                              })
                                            }
                                            key={`action_cancel_swp`}
                                          />
                                          <br />
                                        </div>
                                        :
                                        <div class="col">
                                          <Form.Check
                                            type="radio"
                                            label={
                                              <span>
                                                <i className="fa fa-inr fa-lg"></i>
                                                <span style={{
                                                  backgroundColor: '#f5f0e4',
                                                  fontSize: 18,
                                                  padding: 4, paddingLeft: 8, paddingRight: 8, margin: 20, borderRadius: 4
                                                }}>
                                                  {`Start SWP`}
                                                </span>
                                              </span>
                                            }
                                            style={{ fontSize: 18 }}
                                            name="start_swp"
                                            id={`start_swp`}
                                            value={`start_swp`}
                                            checked={
                                              this.state.modal_action_name === `start_swp`
                                            }
                                            onChange={(e) =>
                                              this.setState({
                                                modal_action_name: `start_swp`
                                              })
                                            }
                                            key={`action_start_swp`}
                                          />
                                          <br />
                                        </div>
                                      }
                                    </div>
                                  }

                                </div>
                              }

                            </div>
                          </Col>
                        </Row>
                        <div
                          class="form-group"
                          style={{ marginBottom: 10, marginTop: 10 }}
                        ></div>
                      </Container>
                    </div>
                  )}
              </div>

            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={() => {

                  if (this.state.modal_action_name === 'show_history')
                    this.showTransactionHistory(this.state.modal_action_order);
                  else if (this.state.modal_action_name === 'view_statement')
                    this.routeChange("stp", this.state.modal_action_order, 'initiate');
                  else if (this.state.modal_action_name === 'view_info')
                    this.routeChange("stp", this.state.modal_action_order, 'view');
                  else if (this.state.modal_action_name === 'start_stp')
                    this.props.history.push({
                      pathname: "/stpSchemeList/",
                      state: {
                        request_mode: 'START STP', selected_order: this.state.modal_action_order
                      },
                    });
                  else if (this.state.modal_action_name === 'cancel_stp')
                    this.showStpInformation(
                      "stp", this.state.modal_action_order, "show_stp_cancel_modal"
                    );
                  else if (this.state.modal_action_name === 'start_swp')
                    this.showSwpInformation(
                      "stp", this.state.modal_action_order, "show_swp_modal"
                    );
                  else if (this.state.modal_action_name === 'cancel_swp')
                    this.showSwpInformation(
                      "stp", this.state.modal_action_order, "show_swp_cancel_modal"
                    );
                }}

                disabled={
                  this.state.modal_action_name.length < 7 ||
                  this.state.isloading
                }
              >
                Proceed
              </Button>
              {`      `}
              <Button
                variant="secondary"
                onClick={() =>
                  this.setState({ show_modal_action: false })
                }
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        }

        {this.state
          .show_swpdate_popup && (
            <Modal

              show={
                this.state
                  .show_swpdate_popup
              }
              onHide={() =>
                this.setState({ show_swpdate_popup: false })
              }

              size="sm"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>Choose a monthly SIP date</Modal.Header>
              <Modal.Body>
                <div>
                  {[1, 6, 11, 16, 21, 26].map((item, index) => (
                    <div className="row" key={index}>
                      <br />
                      {[0, 1, 2, 3, 4].map((increment, _index) => (

                        <div class="col-2" key={_index}>
                          {
                            this.state.popup_swp_date_list.indexOf((item + increment).toString()) > -1 ?
                              <button type="button"
                                className="btn-sm btn-success"
                                style={{ color: 'white', width: '30px', marginTop: '3px', marginLeft: '3px' }}
                                onClick={() => {

                                  var firstSwpDate = new Date();
                                  firstSwpDate = firstSwpDate.setMonth(firstSwpDate.getMonth() + 1);

                                  this.setState({
                                    show_swpdate_popup: false,
                                    swp_order: {
                                      ...this.state.swp_order,
                                      swp_date: (item + increment),
                                      swp_date_value: moment('1982-11-' +
                                        ((item + increment) < 10 ? `0` : ``) + (item + increment)).format('Do ')
                                        + ' of every ' + this.state.swp_order['swp_frequency'].toLowerCase().replace('ly', ''),
                                      swp_date_text: moment(new Date(firstSwpDate).setDate(parseInt((item + increment))))
                                    }
                                  })
                                }}
                                disabled={this.state.popup_swp_date_list.indexOf((item + increment).toString()) === -1}
                              >
                                {item + increment}
                              </button>
                              :
                              <div>
                                {(item + increment <= 28) &&
                                  <p
                                    style={{ width: '30px', textAlign: 'center', backgroundColor: '#dbcec5', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4 }}>
                                    {item + increment}
                                  </p>
                                }
                              </div>
                          }
                        </div>

                      ))}
                    </div>
                  ))}
                </div>
              </Modal.Body>
            </Modal>
          )}

      </>
    );
  }

}

// StpList.propTypes = {
//     validateOtp: PropTypes.func.isRequired,
//     isAuthenticated: PropTypes.bool
// };

/*
const mapStateToProps = state => ({
    loading: state.order.loading,
    order_result: state.order.order_result
});
*/

const mapStateToProps = ({ order }) => {
  const { loading, order_result } = order;
  return {
    loading,
    order_result,
  };
};

export default connect(mapStateToProps, {
  loadOrderList,
  loadSipStatus,
  cancelSipOrder,
  pauseSipOrder,
  createSwpOrder,
  twoFactorAuthentication,
  cancelSwpOrder
})(StpList);
