import React from "react";
import { Link } from "react-router-dom";
import { Button, Spinner, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { validateOtp, verifyEmail } from "../../jsx/actions/auth";
import { Redirect } from "react-router-dom";
import Popup from "./popup";
import Background from "../../images/background/bg-14.jpg";
import OTPInput, { ResendOTP } from "otp-input-react";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";

class VerifyEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      otp: "",
      password: "",
      confirm_password: "",
      data: "",
      token_id: ``,
      verify_otp: false,
      verify_password: false,
      verify_pin: false,
      show_popup: false,
      popup_message: "",
      isloading: false,
      route_parameters: null,
      emailButtonDisabled: true
    };
  }

  componentDidMount() {

    if (this.props.location && this.props.location.state && this.props.location.state.otp_result) {
      // console.log("otp_result: ", this.props.location.state.otp_result);
      // console.log("location state",this.props.location.state )
      // console.log("location", this.props.location)
      let _state = this.props.location.state.otp_result["state_parameters"];
      _state['emailButtonDisabled'] = !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(_state.email);
      _state['route_parameters'] = {};

      this.setState(Object.assign(_state,
        { token_id: this.props.location.state.otp_result['token_id'] }));
      window.history.replaceState(null, '');

    }

  }

  closePopup = () => {
    this.setState({
      show_popup: false,
    });
  };

  onSubmit = () => {
    const emailValidation = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    const { email } = this.state;
    this.setState({
      isloading: true,
      otp: ''
    });
    let body_parameters = {
      login_email: email,
      login_role: "customer",
      login_request_from: "email",
      login_request_mode: "sendotp",
      is_external_bb_access_token: true,
      bb_access_token: this.state.token_id,
    };

    this.props
      .verifyEmail(body_parameters)
      .then((response) => {

        this.setState({ isloading: false });
        if (this.props.email_otp_result["code"] === "000") {
          if (this.props.email_otp_result["state_parameters"])
            this.setState(Object.assign(
              this.props.email_otp_result["state_parameters"], {
              token_id: this.props.email_otp_result['token_id']
            }));
          else
            this.setState({
              verify_otp: this.props.email_otp_result["verify_otp"],
              verify_password: this.props.email_otp_result["verify_password"],
              token_id: this.props.email_otp_result['token_id']
            });
        }
        else
          alert(this.props.email_otp_result["messageText"]);

      })
      .catch((err) => {
        alert(err);
      });
  };

  validEmail(event) {

    this.setState({
      email: event.target.value,
      emailButtonDisabled: !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(event.target.value)
    });

  }

  onVerify = (request_mode) => {

    this.setState({ isloading: true });

    let body_parameters = {
      login_otp: this.state.otp,
      login_role: "customer",
      login_request_from: "email",
      login_request_mode: request_mode,
      is_external_bb_access_token: true,
      bb_access_token: this.state.token_id,
    };

    this.props
      .validateOtp(body_parameters)
      .then((response) => {

        if (this.props.login_otp_result["code"] === "000") {

          let _otp_result = {};

          if (this.props.login_otp_result["state_parameters"])
            _otp_result = Object.assign(
              this.props.login_otp_result["state_parameters"],
              {
                isloading: false,
                token_id: this.props.login_otp_result['token_id']
              });
          else
            _otp_result = {
                verify_otp: this.props.login_otp_result["verify_otp"],
                verify_password: this.props.login_otp_result["verify_password"],
                isloading: false,
                token_id: this.props.login_otp_result['token_id']
              };

            this.setState(_otp_result);
            
            if(_otp_result['verify_password']) {
              this.setState({
                isloading: false,
                show_popup: true,
                popup_message: this.props.login_otp_result["messageText"],
              });
      
              this.props.history.push({
                pathname: "/" + this.props.login_otp_result["navigateScreen"] + "/",
                state: { otp_result: this.props.login_otp_result },
              });
            }
            
        }
        else {
          alert(this.props.login_otp_result['messageText']);
          this.setState({ isloading: false });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  onSetPassword = (request_mode) => {

    this.setState({ isloading: true });

    let body_parameters = {
      login_email: this.state.email,
      login_role: "customer",
      login_request_from: "email",
      login_request_mode: request_mode,
      login_hint: this.state.password,
      is_external_bb_access_token: true,
      bb_access_token: this.state.token_id,
    };

    this.props
      .verifyEmail(body_parameters)
      .then((response) => {

        this.setState({
          isloading: false,
          show_popup: true,
          popup_message: this.props.email_otp_result["messageText"],
        });

        // this.props.history.push('/' + this.props.email_otp_result['navigateScreen'] + '/');
        this.props.history.push({
          pathname: "/" + this.props.email_otp_result["navigateScreen"] + "/",
          state: { otp_result: this.props.email_otp_result },
        });

      })
      .catch((err) => {
        console.log("errrrrrr");
        console.log(err);
      });
  };

  render() {

  // console.log("route param", this.state.route_parameters)
    // if(this.props.email_otp_result && this.props.email_otp_result['code'] === '000' &&
    //    this.props.email_otp_result['navigateScreen']) {

    //     return <Redirect to = {this.props.email_otp_result['navigateScreen']} />;
    // }

    // const renderButton = buttonProps => {
    //         return (
    //         <button onClick={this.onSubmit} {...buttonProps}>
    //             {buttonProps.remainingTime !== 0
    //             ? `Kindly wait for ${buttonProps.remainingTime} seconds to resend otp`
    //             : "Resend"}
    //         </button>
    //         );
    // };
    const renderButton = buttonProps => {
      return (
        <div class="row">
          <div class="col-6">
            <button style={{ width: 140 }} onClick={this.sendOTP} {...buttonProps}
              class={buttonProps.remainingTime !== 0 ? `btn btn-outline-secondary` : `btn btn-outline-success`}>
              {`Resend OTP`}
            </button>
          </div>
          <div class="col" >
            {buttonProps.remainingTime !== 0
              ? <span>You can resend otp in
                <span style={{ fontWeight: 'bold', color: 'green' }}>{' '}{buttonProps.remainingTime}</span> seconds.
              </span>
              : ``}
          </div>
        </div>
      );
    };
    const renderTime = () => React.Fragment;

    return (
      <>
        <div
          style={{
            background: `url(${Background})`,
            backgroundRepeat: "repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <div className="authincation section-padding">
            <div className="container h-100">
              <div className="row justify-content-center h-100">
                <div className="col-xl-5 col-md-6">
                  <div className="mini-logo text-center my-1">
                    <Link to={"./"}>
                      <img
                        src={require("./../../images/logo.png")}
                        alt=""
                        width="200px"
                      />
                    </Link>
                  </div>
                  <div >

                    {this.state.isloading && (
                      <Spinner
                        animation="border"
                        variant="secondary"
                        style={{ position: "fixed", top: "50%", left: "50%" }}
                      />
                    )}

                    <div className="card-header justify-content-center">
                      <div>
                        <h4 className="text-center">
                          {this.state.verify_password ? `Set Password` : `Email Verification`}</h4>
                        <p>*Required as per SEBI</p>
                      </div>
                    </div>

                    <div className="card-body">

                      {!this.state.route_parameters &&
                        <Card sx={{ backgroundColor: '#edf3f0', p: 2, borderRadius: 2 }}>
                          <CardContent  >
                            <Stack direction="row">
                              <Typography flex={1} whiteSpace="pre-line" >
                                Sorry the information you are looking for could not be found
                              </Typography>
                            </Stack>
                          </CardContent>
                          <CardContent>
                            <p>
                              <span>
                                <Link to={"/signin"} title="OrderList">
                                  Click here
                                </Link> to Sign-In again.
                              </span>
                            </p>
                          </CardContent>
                        </Card>
                      }

                      {this.state.route_parameters &&
                        <form action="#">
                          {!this.state.verify_otp && 
                          <div style={{ fontSize: 16, textAlign: 'left', }}>
                            {this.state.verify_password ? `Your email address` : `Enter your email address`}
                          </div>
                          }
                          {!this.state.verify_otp && 
                            <div style={{height: 7}} />
                          }
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              value={this.state.email}
                              disabled={
                                this.state.verify_otp ||
                                this.state.verify_password
                              }
                              maxLength="30"
                              onChange={(event) =>
                              // this.validEmail(event)
                              {
                                const targetValue = event.target.value.replace(/\s/g, '').toLowerCase();
                                this.setState({
                                  email: targetValue,
                                  emailButtonDisabled: !new RegExp(/\w([-_+.']*\w+)+@(\w(-*\w+)+\.)+[a-zA-Z]{2,4}$/gm).test(targetValue)
                                });
                              }
                              }
                            />
                          </div>
                          {!this.state.verify_otp && !this.state.verify_password && (
                            <div className="text-center">
                              <Button style={{ marginTop: 20 }}
                                className="btn btn-success btn-block"
                                variant="success"
                                onClick={() => this.onSubmit()}
                                disabled={this.state.isloading || this.state.emailButtonDisabled}
                              >
                                Submit
                              </Button>
                            </div>
                          )}
                        </form>
                      }

                    </div>

                    {this.state.verify_otp && (
                      <div className="card-body">
                        <p className="text-left">
                          Enter the OTP (one time password) sent to your
                          email address
                        </p>
                        {/* <p className="text-center mb-5">We have sent one time code to your registered mobile number</p> */}
                        <form action="#">
                          <div className="form-group">
                            {/* <input
                              type="password"
                              className="form-control text-center font-weight-bold"
                              value={this.state.otp}
                              onChange={(event) =>
                                this.setState({ otp: event.target.value })
                              }
                            /> */}
                            <OTPInput
                              value={this.state.otp}
                              onChange={event => this.setState({ otp: event })}
                              autoFocus
                              OTPLength={6}
                              otpType="number"
                              disabled={false}
                              secure
                            />
                            <br />
                            <div className="text-left">
                              <ResendOTP renderButton={renderButton} style={{ color: 'black', marginTop: 20 }} renderTime={renderTime}
                                onResendClick={(e) => { this.onSubmit() }}
                              // handelResendClick={() => this.sendOTP()} 
                              />
                            </div>
                          </div>
                          <div className="text-center" style={{ marginTop: 20 }}>
                            <Button
                              className="btn btn-success btn-block"
                              variant="success"
                              onClick={() => this.onVerify("verifyemailotp")}
                              disabled={this.state.isloading || this.state.otp.length < 6}
                            >
                              Verify
                            </Button>
                          </div>
                        </form>
                      </div>
                    )}

                    {this.state.verify_password && (
                      <div className="card-body">
                        {/* <h4 className="text-center">Kindly set your password</h4> */}
                        {/* <p className="text-center mb-5">We have sent one time code to your registered mobile number</p> */}
                        <form action="#">
                          <div className="form-group">
                            <div className="text-left">Enter new password</div>
                            <input
                              type="text"
                              className="form-control text-center font-weight-bold"
                              value={this.state.password}
                              maxLength="22"
                              onChange={(event) => {
                                const targetValue = event.target.value.replace(/\s/g, '');
                                if ((targetValue.match(/^[0-9a-zA-Z(\@)]+$/)
                                  || targetValue === "") && targetValue.toString().length <= 22) {
                                  this.setState({
                                    password: targetValue
                                  });
                                }

                              }
                              }
                            />
                          </div>
                          {this.state.password.length < 7 && (
                            <div className="form-group">
                              <p className="text-left text-danger">
                                Should be of minimum 7 & upto 22 characters
                              </p>
                            </div>
                          )}
                          <br/>
                          <div className="form-group">
                            <div className="text-left">Re-enter new password</div>
                            <input
                              type="password"
                              className="form-control text-center font-weight-bold"
                              value={this.state.confirm_password}
                              maxLength="22"
                              onChange={(event) => {
                                const targetValue = event.target.value.replace(/\s/g, '');
                                if ((targetValue.match(/^[0-9a-zA-Z(\@)]+$/)
                                  || targetValue === "") && targetValue.toString().length <= 22) {
                                  this.setState({
                                    confirm_password: targetValue
                                  });
                                }

                              }
                              }
                            />
                          </div>

                          {this.state.confirm_password.length > 0 && this.state.confirm_password !==
                            this.state.password && (
                              <div className="form-group">
                                <p className="text-left text-danger">
                                  Password does not match
                                </p>
                              </div>
                            )}
                          <div className="text-center" style={{ marginTop: 20 }}>
                            <Button
                              className="btn btn-success btn-block"
                              variant="success"
                              disabled={
                                this.state.isloading ||
                                this.state.password.length < 7 ||
                                this.state.password.trim() !==
                                this.state.confirm_password.trim()
                              }
                              onClick={() =>
                                this.onSetPassword("verifypassword")
                              }
                            >
                              Submit
                            </Button>
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                  <Modal show={this.state.show_popup} onHide={() => this.closePopup()}>
                    <Modal.Header>
                      <Modal.Title>Verified</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>{this.state.popup_message}</p>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="primary" onClick={() => {
                          this.closePopup();
                          this.props.history.push({
                            pathname: "/" + this.props.login_otp_result["navigateScreen"] + "/",
                            state: { otp_result: this.props.login_otp_result },
                          });
                        }
                      }>
                        Ok
                      </Button>
                    </Modal.Footer>
                  </Modal>

                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

VerifyEmail.propTypes = {
  validateOtp: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  email_otp_result: state.auth.email_otp_result,
  login_otp_result: state.auth.login_otp_result,
});
export default connect(mapStateToProps, { verifyEmail, validateOtp })(
  VerifyEmail
);
