import React, { } from 'react';
import { Link } from 'react-router-dom';
import { Form, Spinner } from 'react-bootstrap';
import { connect } from "react-redux";
import { Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { loadNomineeList, createOrder, modifyOrder, uploadNominee, twoFactorAuthentication } from '../../actions/orders';
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";

import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";
import { saveAs } from "file-saver";
import { PDFReader } from 'reactjs-pdf-reader';
import { Fab, Action } from 'react-tiny-fab';
import OTPInput, { ResendOTP } from "otp-input-react";

class OrderCheckout extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            selected_order: {},
            general_order: {},
            list_bank_account: [],

            /****** NOMINEE OPTIONS *******/
            list_nominee: [],
            nominee_option: 'existing',
            existing_nominee_id: '',
            existing_nominee_selected: false,
            is_no_nominee_registered: false,
            no_nominee_form: {},
            nonomineeformFile: null,
            src_nonomineeformFile: null,
            byte_nonomineeformFile: null,
            nominee_form_download_url: ``,
            no_nominee_form_download_url: ``,
            is_download_url_new_window: false,
            no_nominee_form_download_timeout: 4500,
            client_code: '',
            /****** NOMINEE OPTIONS *******/

            loading: false,
            nofitication_html: ``,
            render_pdf: null,

            invest_more_amount: 0.00,
            redeemable_amount: 0.00,
            is_redeem_full_checked: false,
            is_stage_verified: false,
            is_verify_enabled: false,
            is_verified_editable: false,
            esignkyc_information: {},
            esignkyc_attachment_list: {},
            is_esign_verified: false,
            is_esign_button_disabled: false,
            show_popup: false,
            pop_messagetext: '',
            isloading: false,
            error: '',

            show_nominee_form_modal: false,
            modal_nominee: {
                info: {},
                form: null
            },

            wizard_step: 1,
            show_previous_button: false,
            show_next_button: false,

            is_otp_enabled: false,
            two_factor_authentication: {
                mobile_otp: '',
                email_otp: '',
                twofactor_token_id: ''
            }
        };

    }

    componentDidMount() {

        window.addEventListener("scroll", () => {

            if (document.getElementById("navbar-orderlist-container") &&
                document.getElementById("navbar") &&
                document.getElementById("navbar").classList) {
                const topBorder = document
                    .getElementById("navbar-orderlist-container")
                    .getBoundingClientRect().top;

                topBorder >= 0
                    ? document.getElementById("navbar").classList.remove("fixed-orderlist")
                    : document.getElementById("navbar").classList.add("fixed-orderlist");
            }

        });

        let _state = this.props.location.state;
        _state['isloading'] = true;

        this.setState(_state);

        this.props
            .loadNomineeList({
                scheme_name: this.props.location.state.selected_order['param_scheme_name'],
                scheme_code: this.props.location.state.selected_order['param_scheme_code']
            })
            .then((response) => {

                if (this.props.order_result["code"] !== "000") {
                    this.setState({ isloading: false });
                    // alert(this.props.order_result["messageText"]);
                }
                else {
                    if (this.props.order_result["nominee_list"]) {

                        this.setState({
                            isloading: false,
                            nominee_list: this.props.order_result["nominee_list"],
                            is_no_nominee_registered: this.props.order_result["is_no_nominee_registered"],
                            no_nominee_form: this.props.order_result["no_nominee_form"] ||
                                this.props.location.state['no_nominee_form'],
                            no_nominee_form_download_url: this.props.order_result['no_nominee_form_download_url'],
                            is_download_url_new_window: this.props.order_result['is_download_url_new_window'],
                            no_nominee_form_download_timeout: this.props.order_result['no_nominee_form_download_timeout'] || 4500
                        });
                    }
                }
            })
            .catch((err) => {
                alert(err);
            });
        // window.history.replaceState(null, '');
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', null);
    }

    gotoPaymentPage() {

        this.props.history.push({
            pathname: '/paymentPage/',
            state: { selected_order: this.state.selected_order }
        });

        /*
        this.props.history.push({
            pathname: '/2FABseStarMF/',
            state: { selected_order: this.state.selected_order }
        });
        */
    }

    onCreateOrder = () => {

        this.setState({ isloading: true });

        let _bank_account = (this.state.list_bank_account || []).filter(el => {
            return el['is_primary'] === true;
        })[0];

        this.props.createOrder({
            client_info: {
                'scheme_code': this.state.general_order['scheme_code'],
                'scheme_name': this.state.general_order['scheme_name'],
                'scheme_isin': this.state.general_order['scheme_isin'],
                'buy_sell': 'P',
                'buy_sell_type': 'FRESH',
                'order_amount': this.state.general_order['order_amount'],
                'bank_account_information': _bank_account,
                'selected_order': this.state.selected_order,
                'nominee_option': this.state.nominee_option,
                'nominee_id': this.state.existing_nominee_id
            },
            two_factor_authentication: this.state.two_factor_authentication

        }).then(response => {

            if (this.props.order_result['code'] !== '000') {
                /*
                this.setState({
                    show_popup: true,
                    pop_messagetext: this.props.order_result['messageText']
                });
                */
                this.setState({ isloading: false });
                alert(this.props.order_result['messageText']);
            }
            else {
                if (this.props.order_result['result']) {

                    this.setState({
                        isloading: false,
                        is_otp_enabled: false,
                        two_factor_authentication: {
                            mobile_otp: '',
                            email_otp: '',
                            twofactor_token_id: ''
                        }
                    });

                    this.props.history.push({
                        pathname: '/orderEditCheckout/',
                        state: { selected_order: this.props.order_result['result'] }
                    });

                }

            }

        })
            .catch(err => {
                this.setState({ error: err })
            });


    };

    handleInvestAmount(event, attribute) {

        const re = /^\d+(\.\d{0,3})?$/ ///^\d*\.?\d*$/ ///^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            this.setState({
                /*invest_more_amount*/[attribute]: event.target.value.replace(/\b0+/g, '')
            });
        }

    }

    handleNomineeCheck = (e, item) => {

        let _existing_nominee_selected = false, _existing_nominee_id = '';
        const _list = this.state.list_nominee;

        _list.forEach(el => {

            if (el['id'] === item['id']) {

                item['is_selected'] = !item['is_selected'];
                _existing_nominee_selected = item['is_selected'];

                if (_existing_nominee_selected) {
                    _existing_nominee_id = item['id'] || item['id_information']['id_no'];
                }
                else
                    _existing_nominee_id = '';
            }
            else
                item['is_selected'] = false;

        });

        this.setState({
            list_nominee: _list,
            existing_nominee_selected: _existing_nominee_selected,
            existing_nominee_id: _existing_nominee_id
        });

    }

    changeNomineeHandler = async (event) => {

        if (event.target.files[0] && (event.target.files[0].type === 'image/png' ||
            event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/jpg')) {

            if (event.target.files[0].name.toUpperCase() === `NONOMINEE_DECLARATION_` + this.state.client_code + `.PNG` ||
                event.target.files[0].name.toUpperCase() === `NONOMINEE_DECLARATION_` + this.state.client_code + `.JPEG` ||
                event.target.files[0].name.toUpperCase() === `NONOMINEE_DECLARATION_` + this.state.client_code + `.JPG`) {

                const file = event.target.files[0];
                const buffer = await file.arrayBuffer();

                let byteArray = new Int8Array(buffer);
                this.setState({
                    nonomineeformFile: file,
                    byte_nonomineeformFile: byteArray,
                    src_nonomineeformFile: URL.createObjectURL(file)
                });

            }
            else {
                this.setState({
                    show_popup: true,
                    pop_messagetext: `Upload file name should be ` +
                        `NONOMINEE_DECLARATION_` + this.state.client_code + `.` +
                        event.target.files[0].type.split('/')[1] + ` only`
                });
            }
        }
        else {
            this.setState({
                show_popup: true,
                pop_messagetext: 'Upload file format should be of PNG, JPEG, JPG only'
            });
        }

    }

    async downloadNomineeForm(src_nominee_form, download_file_name) {

        if (src_nominee_form && src_nominee_form.length > 255) {

            const win = window.open("/downloadNominee/" +
                localStorage.getItem('token') + '/none/' + this.state.client_code, "_blank");
            win.focus();
            /*
            const byteCharacters = atob(src_nominee_form);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);

            // const { data } = await getTicketsPdf()
            const blob = new Blob([byteArray], { type: "application/pdf" });
            saveAs(blob, download_file_name + ".pdf");
            */

        }
    }

    onUploadNomineeDeclaration = (request_from) => {

        this.setState({ isloading: true });
        let formData = new FormData();

        let _nonomineeformFile = this.state.nonomineeformFile;

        if (_nonomineeformFile) {

            formData.append("file", _nonomineeformFile);
            formData.append("id_type", "no-nominee");
            formData.append("imageName", _nonomineeformFile.name);
            formData.append("imageType", _nonomineeformFile.type);
            // formData.append("pFileBytes", this.state.byte__nonomineeformFile);
            formData.append('nominee_information', JSON.stringify({
                id: 'none',
                name: '',
                relationship: '',
                poi: '',
                attachment_id: '',
                attachment_list: [],
                id_information: {
                    name: '', fathers_name: '', id_no: '', dob: '', id_type: '',
                    part: '', 'encoded_signature': '', encoded_image: '', nominee_is_minor: false
                }
            }));

            this.props
                .uploadNominee(formData)
                .then((response) => {

                    this.setState({ isloading: false });
                    if (this.props.order_result["code"] !== "000")
                        alert(this.props.order_result["messageText"]);
                    else {
                        if (this.props.order_result["state_parameters"]) {
                            this.setState(this.props.order_result["state_parameters"]);
                        }
                    }
                })
                .catch((err) => {
                    // console.log("line 340 :");
                    console.log(err);
                    alert(err);
                });
        }
    };

    createDefaultMarkup(text) {

        return {
            __html: this.state.nofitication_html,
        }
    };

    closePopup = () => {

        this.setState({
            show_popup: false,
            pop_messagetext: ''
        })

    }

    sendOTP = () => {

        this.setState({ isloading: true });

        this.props
            .twoFactorAuthentication({
                client_info: {
                    two_factor_authentication: this.state.two_factor_authentication,
                    request_action: 'single order registration',
                    is_otp_email_required: true
                },
            })
            .then((response) => {

                if (this.props.order_result["code"] !== "000") {
                    this.setState({ isloading: false });
                    alert(this.props.order_result["messageText"]);
                }
                else {

                    let _result = this.props.order_result; //["result"];

                    if (_result && _result['twofactor_id']) {

                        this.setState({
                            isloading: false,
                            is_otp_enabled: true,
                            two_factor_authentication: {
                                mobile_otp: '',
                                email_otp: '',
                                twofactor_token_id: _result['twofactor_id']
                            }
                        });

                    }

                }
            })
            .catch((err) => {
                alert(err);
            });

    }

    handle2FactorChange = (input, attribute) => e => {

        if (e)
            this.setState({
                [attribute]: { ...this.state[attribute], [input]: e }
            });
        else
            this.setState({ [input]: e });

    }

    downloadFile = (download_url, window_timeout) => {

        /*
        axios({
          url: download_url,
          method: 'GET',
          responseType: 'arraybuffer'
        })
          .then((response) => {
            // this.setState({ isloading: false });
          });
        */

        const win = window.open(download_url, "_blank", "toolbar=yes,scrollbars=yes, resizable=yes, top=500, left=500, width=400, height=400");
        win.focus();
        this.setState({ isloading: true });
        setTimeout(() => {
            this.setState({ isloading: false });
            win.close();
        }, window_timeout || 4500);

    };

    downloadDocument = (download_url, file_name, window_timeout, is_download_url_new_window) => {

        setTimeout(() => { this.setState({ isloading: false }); }, window_timeout || 4500);

        if (is_download_url_new_window) {
            const win = window.open(download_url, "_blank");
        }

    };

    render() {

        // if(this.props.loading)
        //   return <Spinner />;

        // if(this.props.order_result && this.props.order_result['esignkyc_stage_verified'])
        //    return <Redirect to={this.props.aadhaar_result['navigateScreen']} />; 

        const renderButton = buttonProps => {
            return (
                <div class="row">
                    <div class="col-6">
                        <button style={{ width: 140 }} onClick={this.sendOTP} {...buttonProps}
                            class={buttonProps.remainingTime !== 0 ? `btn btn-outline-secondary` : `btn btn-outline-success`}>
                            {`Resend OTP`}
                        </button>
                    </div>
                    <div class="col" >
                        {buttonProps.remainingTime !== 0
                            ? <span>You can resend otp in
                                <span style={{ fontWeight: 'bold', color: 'green' }}>{' '}{buttonProps.remainingTime}</span> seconds.
                            </span>
                            : ``}
                    </div>
                </div>
            );
        };
        const renderTime = () => React.Fragment;

        return (
            <>
                <Header1 />
                <Sidebar />

                {/* {this.state.isloading && <div className="loading-overlay"></div>} */}

                {this.state.isloading &&
                    <Spinner animation="border" variant="dark"
                        style={{ position: "fixed", top: "50%", left: "50%" }} />
                }

                {this.state.show_popup && (
                    <Modal show={this.state.show_popup} onHide={() => this.closePopup()}>
                        <Modal.Header>

                        </Modal.Header>
                        <Modal.Body>

                            <p>{this.state["pop_messagetext"]}</p>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={() => this.closePopup()}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}

                <div className="content-body" >
                    <div className="container" style={{ marginBottom: 50 }}>
                        <div className="row">

                            <div id="navbar-orderlist-container">
                                <div id="navbar" >
                                    <div className="card" style={{ backgroundColor: '#f8f8fb' }}>
                                        <div className="card-header">
                                            <h5 >New Order - Lumpsum</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div style={{ marginTop: 60 }}>

                                {!this.props.location.state &&
                                    <Card sx={{ backgroundColor: '#edf3f0', p: 2, borderRadius: 2 }}>
                                        <CardContent  >
                                            <Stack direction="row">
                                                <Typography flex={1} whiteSpace="pre-line" >
                                                    Sorry the information you are looking for could not be found
                                                    <p></p>
                                                    <Link className="page-back text-muted" to={'/sipList'}><span><i
                                                        className="fa fa-angle-left"></i></span> Go to Order List</Link>
                                                </Typography>
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                }

                                {!this.props.isloading && this.props.location.state &&
                                    <div>

                                        {(this.state.wizard_step === 1 || this.state.wizard_step === 3) &&
                                            <Card>
                                                <CardContent>
                                                    <h6>
                                                        {this.state.selected_order['param_scheme_name']}
                                                    </h6>
                                                    <table style={{ width: '100%' }}>
                                                        <tr>
                                                            <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'center' }}>
                                                                <span>
                                                                    <b>{` Last Nav `}</b>
                                                                </span>
                                                            </td>
                                                            <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                                                <span >
                                                                    <b>{this.state.selected_order['last_nav']}</b>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'center' }}>
                                                                <span>
                                                                    Scheme Code
                                                                </span>
                                                            </td>
                                                            <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                                                <span>
                                                                    {this.state.selected_order['param_scheme_code']}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </CardContent>
                                            </Card>
                                        }
                                        {/* {(this.state.wizard_step !== 3) &&
                                            <br />
                                        } */}
                                        {(this.state.wizard_step === 1) &&
                                            <Card>
                                                <CardContent>
                                                    <table style={{ width: '100%' }}>
                                                        <tr>
                                                            <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'center' }}>
                                                                <span>
                                                                    Redeem Allowed ?
                                                                </span>
                                                            </td>
                                                            <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                                                {this.state.selected_order['param_redemption_allowed'] === 'Y' ? 'YES' : 'NO'}
                                                            </td>
                                                            <td style={{ width: 10 }}></td>
                                                            <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'center' }}>
                                                                <span>
                                                                    Exit Load ?
                                                                </span>
                                                            </td>
                                                            <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                                                {this.state.selected_order['param_exit_load_flag'] === 'Y' ? 'YES' : 'NO'}
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'center' }}>
                                                                <span>
                                                                    SWP ?
                                                                </span>
                                                            </td>
                                                            <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                                                {this.state.selected_order['param_swp_flag'] === 'Y' ? 'YES' : 'NO'}
                                                            </td>
                                                            <td style={{ width: 10 }}></td>
                                                            <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'center' }}>
                                                                <span>
                                                                    STP ?
                                                                </span>
                                                            </td>
                                                            <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                                                {this.state.selected_order['param_stp_flag'] === 'Y' ? 'YES' : 'NO'}
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </CardContent>
                                            </Card>
                                        }
                                        
                                        {this.state.wizard_step === 1 &&
                                            <div>
                                                <Card>
                                                    <CardContent>

                                                        <h5>
                                                            Primary Bank Account
                                                        </h5>

                                                        {(this.state.list_bank_account || []).map((item, index) => (

                                                            <div class="card" key={index}>
                                                                {(this.state.list_bank_account || []).map((item, index) => (

                                                                    <table style={{ width: '100%' }}>
                                                                        <tr>
                                                                            <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                                                                                <span>
                                                                                    Bank Name
                                                                                </span>
                                                                            </td>
                                                                            <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                                                                {item["beneficiary_bank_name"]}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                                                                                <span>
                                                                                    Account Number
                                                                                </span>
                                                                            </td>
                                                                            <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                                                                {item["beneficiary_account_no"]}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                                                                                <span>
                                                                                    Name
                                                                                </span>
                                                                            </td>
                                                                            <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                                                                {item["beneficiary_account_holder_name"]}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                                                                                <span>
                                                                                    Account Type
                                                                                </span>
                                                                            </td>
                                                                            <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                                                                {item["beneficiary_account_type"]}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                                                                                <span>
                                                                                    IFSC
                                                                                </span>
                                                                            </td>
                                                                            <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                                                                {item["beneficiary_ifsc"]}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                                                                                <span>
                                                                                    Branch
                                                                                </span>
                                                                            </td>
                                                                            <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                                                                {item["beneficiary_bank_branch_name"]}
                                                                            </td>
                                                                        </tr>
                                                                    </table>


                                                                ))}

                                                            </div>


                                                        ))}

                                                    </CardContent>
                                                </Card>
                                            </div>
                                        }

                                        {this.state.wizard_step === 2 &&
                                            <div className="card" >
                                                <div className="card-body" >
                                                    <p>
                                                        Do you want to add nominee ?
                                                    </p>

                                                    <div class="row">
                                                        <div class="col">
                                                            <Form.Check
                                                                type="radio"
                                                                label="Yes"
                                                                name="nominee_option"
                                                                id="existing"
                                                                value="existing"
                                                                checked={
                                                                    this.state.nominee_option === "existing"
                                                                }
                                                                onChange={(e) =>
                                                                    this.setState({ ['nominee_option']: 'existing' })
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-5">
                                                            <Form.Check
                                                                type="radio"
                                                                label="No"
                                                                name="nominee_option"
                                                                id="none"
                                                                value="none"
                                                                checked={
                                                                    this.state.nominee_option ===
                                                                    "none"
                                                                }
                                                                onChange={(e) =>
                                                                    this.setState({ ['nominee_option']: 'none' })
                                                                }
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    {
                                                        this.state.nominee_option === 'none' &&

                                                        <div class="row">
                                                            <h6>
                                                                As per SEBI guidelines as an investor you are required to sign the declaration form (only for first time in our app).
                                                                {/* Signed declaration form will be used for subsequent fresh orders. */}
                                                            </h6>

                                                            <div style={{ marginLeft: -120 }}>
                                                                {this.state.is_no_nominee_registered &&
                                                                    this.state.no_nominee_form &&
                                                                    this.state.no_nominee_form
                                                                        .length > 255 && (
                                                                        <PDFReader
                                                                            data={atob(
                                                                                this.state
                                                                                    .no_nominee_form
                                                                            )}
                                                                            width="700"
                                                                        />
                                                                    )
                                                                }

                                                            </div>

                                                            {
                                                                !this.state.is_no_nominee_registered &&
                                                                <div >

                                                                    <div class="row">

                                                                        <div
                                                                            style={{
                                                                                textAlign: "left",
                                                                            }}
                                                                        >
                                                                            <b>Step 1</b>.
                                                                            {` `}
                                                                            {
                                                                                this.state.is_download_url_new_window === true ?
                                                                                    <button type="button"
                                                                                        className="btn-sm btn-primary"
                                                                                        style={{ color: 'white', width: '140px' }}
                                                                                        onClick={() => {
                                                                                            this.downloadDocument(this.state.no_nominee_form_download_url,
                                                                                                `NONOMINEE_DECLARATION_` + this.state.client_code + `.pdf`, this.state.no_nominee_form_download_timeout, this.state.is_download_url_new_window);
                                                                                        }}
                                                                                    >
                                                                                        Download Form
                                                                                    </button>
                                                                                    :
                                                                                    <a
                                                                                        className="btn-sm btn-info"
                                                                                        // variant="info"
                                                                                        download={
                                                                                            `NONOMINEE_DECLARATION_`
                                                                                            + this.state.client_code + `.pdf`
                                                                                        }
                                                                                        disabled={this.state.isloading}
                                                                                        href={this.state.no_nominee_form_download_url}
                                                                                        onClick={() => {
                                                                                            this.setState({ isloading: true });
                                                                                            setTimeout(() => { this.setState({ isloading: false }); }, 4500)
                                                                                        }
                                                                                        }
                                                                                    >
                                                                                        Download Form
                                                                                    </a>
                                                                            }
                                                                        </div>
                                                                        <hr />
                                                                        <p
                                                                            style={{
                                                                                textAlign: "left",
                                                                            }}
                                                                        >
                                                                            <b>Step 2</b>. Put your signature at Unit Holder (1)

                                                                        </p>
                                                                        <p
                                                                            style={{
                                                                                textAlign: "left",
                                                                            }}
                                                                        >
                                                                            <b>Step 3</b>. Scan/Take Photo & save as png/jpeg format.
                                                                            File name should be strictly as below
                                                                            <hr />
                                                                            <span style={{ marginLeft: -5, color: 'darkgreen' }}>
                                                                                {
                                                                                    ` NONOMINEE_DECLARATION_`
                                                                                    + this.state.client_code + `.png`}
                                                                            </span>
                                                                            <div style={{ fontSize: 15, textAlign: 'center' }}>or</div>
                                                                            <span style={{ marginLeft: -5, color: 'darkgreen' }}>
                                                                                {
                                                                                    ` NONOMINEE_DECLARATION_`
                                                                                    + this.state.client_code + ".jpeg"}{` `}
                                                                            </span>
                                                                        </p>
                                                                        <Form.Control
                                                                            type="file"
                                                                            onChange={
                                                                                this.changeNomineeHandler
                                                                            }
                                                                        />

                                                                    </div>

                                                                    {this.state.src_nonomineeformFile &&
                                                                        <div class="row">
                                                                            <img
                                                                                width="150"
                                                                                src={
                                                                                    this.state[
                                                                                    "src_nonomineeformFile"
                                                                                    ]
                                                                                }
                                                                            />
                                                                        </div>
                                                                    }
                                                                    <div class="row">
                                                                        <hr />
                                                                        <div class="col-3"></div>
                                                                        <div class="col">
                                                                            <Button
                                                                                className="btn btn-success btn-sm"
                                                                                variant="success"
                                                                                onClick={() => {
                                                                                    this.onUploadNomineeDeclaration('main');
                                                                                }
                                                                                }
                                                                                disabled={this.state.isloading ||
                                                                                    !this.state.src_nonomineeformFile}
                                                                            >
                                                                                Upload Declaration
                                                                            </Button>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            }

                                                        </div>
                                                    }
                                                    {
                                                        this.state.nominee_option === 'existing' &&

                                                        <div class="row">
                                                            <table style={{ marginLeft: 15 }}>
                                                                <thead>
                                                                    <tr>
                                                                        <th colSpan="5" style={{ fontSize: 12 }}>Name</th>
                                                                        <th colSpan="4" style={{ fontSize: 12 }}>Id Proof</th>
                                                                        <th colSpan="2" style={{ fontSize: 12 }}>Select</th>
                                                                    </tr>
                                                                </thead>
                                                                {(this.state.list_nominee || []).map((item, index) => (

                                                                    <tbody key={index} >
                                                                        <tr>
                                                                            <td colSpan={5}>
                                                                                <span style={{ fontSize: 13 }}>
                                                                                    {item["name"]}
                                                                                    {"("}
                                                                                    {item['relationship'] || ''}
                                                                                    {")"}
                                                                                </span>
                                                                                <br />
                                                                                <Button
                                                                                    className="btn btn-success btn-sm"
                                                                                    variant="link"
                                                                                    style={{ fontSize: 12 }}
                                                                                    onClick={() => {
                                                                                        this.setState({
                                                                                            show_nominee_form_modal: true,
                                                                                            modal_nominee: {
                                                                                                ...this.state.modal_nominee,
                                                                                                info: item,
                                                                                                form: item['nominee_form']
                                                                                            },

                                                                                        })
                                                                                    }}
                                                                                >
                                                                                    Show Form
                                                                                </Button>
                                                                            </td>
                                                                            <td colSpan={4}>
                                                                                <span style={{ fontSize: 13 }}>
                                                                                    {item["poi"]}
                                                                                    <br />{" ("}
                                                                                    {item["id_information"]['id_no']}
                                                                                    {")"}
                                                                                </span>

                                                                            </td>
                                                                            <td colSpan={2}>
                                                                                <label>
                                                                                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                                                                        <Form.Check type="checkbox"
                                                                                            // label="Choose"
                                                                                            onChange={e => { this.handleNomineeCheck(e, item) }}
                                                                                            defaultChecked={item['is_selected']}
                                                                                        />
                                                                                    </Form.Group>
                                                                                </label>
                                                                            </td>
                                                                        </tr>

                                                                    </tbody>

                                                                ))}

                                                            </table>
                                                        </div>
                                                    }

                                                </div>
                                            </div>
                                        }

                                        {this.state.wizard_step === 3 &&
                                            <Card>
                                                <CardContent>

                                                    {this.state.general_order['order_amount'] &&
                                                        <div class="row" >

                                                            {/* <table style={{ width: '100%' }}>
                                                                <tr>
                                                                    <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'center' }}>
                                                                        <span>
                                                                            <b>{` Last Nav `}</b>
                                                                        </span>
                                                                    </td>
                                                                    <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                                                        <span >
                                                                            <b>{this.state.selected_order['last_nav']}</b>
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                            <div style={{height:7}} /> */}
                                                            <table style={{ width: '100%' }}>
                                                                <tr>
                                                                    <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'center' }}>
                                                                        <span>
                                                                            <b>{` Purchase Amount `}</b>
                                                                        </span>
                                                                    </td>
                                                                    <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                                                        <span style={{ fontFamily: 'sans-serif'}}>
                                                                            &#8377; {this.state.general_order['order_amount']}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <div style={{height:5}} />
                                                                <tr>
                                                                    <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'center' }}>
                                                                        <span>
                                                                            <b>{` Cut-Off Time `}</b>
                                                                        </span>
                                                                    </td>
                                                                    <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                                                        <span>
                                                                            {this.state.selected_order['param_purchase_cutoff_time']}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                            
                                                            <div style={{ marginTop: 10 }} />
                                                            <hr />
                                                            {this.state.is_otp_enabled ?

                                                                <div>

                                                                    <div class="row">
                                                                        <div class="col">
                                                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                                                <label>Enter OTP received on your registered mobile</label>
                                                                                <OTPInput
                                                                                    value={this.state.two_factor_authentication['mobile_otp']}
                                                                                    onChange={this.handle2FactorChange('mobile_otp', 'two_factor_authentication')}
                                                                                    shouldAutoFocus={true}
                                                                                    OTPLength={6}

                                                                                    otpType="number"

                                                                                    disabled={false}
                                                                                    isInputSecure={true}

                                                                                    isInputNum={true}
                                                                                />
                                                                            </Form.Group>
                                                                        </div>
                                                                    </div>
                                                                    <br />
                                                                    <Form.Group
                                                                        className="mb-3"
                                                                    >
                                                                        <label>Enter OTP received on your registered email</label>
                                                                        <OTPInput
                                                                            value={this.state.two_factor_authentication['email_otp']}
                                                                            onChange={this.handle2FactorChange('email_otp', 'two_factor_authentication')}
                                                                            shouldAutoFocus={true}
                                                                            OTPLength={6}
                                                                            otpType="number"
                                                                            disabled={false}
                                                                            isInputSecure={true}
                                                                            isInputNum={true}
                                                                        />
                                                                    </Form.Group>
                                                                    <br />
                                                                    <div class="row">
                                                                        <ResendOTP maxTime={90} renderButton={renderButton} renderTime={renderTime}
                                                                            onResendClick={(e) => { this.sendOTP() }}
                                                                        />
                                                                    </div>
                                                                    <hr />
                                                                    <div class="row">
                                                                        <button
                                                                            className="btn btn-success btn-sm"
                                                                            onClick={() => {
                                                                                this.onCreateOrder();
                                                                            }}
                                                                            disabled={this.state.isloading || this.state.two_factor_authentication['mobile_otp'].length < 6
                                                                                || this.state.two_factor_authentication['email_otp'].length < 6}                                                                                >
                                                                            Confirm Order
                                                                        </button>
                                                                    </div>
                                                                    {this.state.isloading &&
                                                                        <Spinner animation="border" variant="dark"
                                                                            style={{ position: "fixed", top: "50%", left: "50%" }} />
                                                                    }
                                                                </div>
                                                                :
                                                                <div >
                                                                    {this.state.list_bank_account.length > 0 &&
                                                                        (this.state.selected_order['request_mode'] || '') !== 'paynow' &&

                                                                        <Button className="btn btn-success btn-block"
                                                                            variant="success"
                                                                            onClick={() => {
                                                                                this.sendOTP()
                                                                            }}
                                                                            disabled={this.state.isloading ||
                                                                                (this.state.nominee_option === 'existing' && !this.state.existing_nominee_selected) ||
                                                                                (this.state.nominee_option === 'none' && !this.state.is_no_nominee_registered)}
                                                                        >
                                                                            Create Order
                                                                        </Button>
                                                                    }
                                                                    {this.state.isloading &&
                                                                        <Spinner animation="border" variant="dark"
                                                                            style={{ position: "fixed", top: "50%", left: "50%" }} />
                                                                    }
                                                                </div>
                                                            }


                                                        </div>
                                                    }

                                                    {!this.state.is_otp_enabled && (this.state.selected_order['request_mode'] || '') === 'paynow' &&
                                                        <div className="card">
                                                            <form action="#">
                                                                <div class="row">
                                                                    <div dangerouslySetInnerHTML={this.createDefaultMarkup()} />
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col">

                                                                        <Button className="btn btn-success btn-block"
                                                                            variant="success"
                                                                            onClick={() => {
                                                                                this.gotoPaymentPage()
                                                                            }}
                                                                        >
                                                                            Pay {` `}
                                                                            <span style={{ fontFamily: 'sans-serif' }}>
                                                                                &#8377;{
                                                                                    this.state.selected_order["order_amount"]}
                                                                            </span>
                                                                        </Button>
                                                                    </div>

                                                                </div>


                                                            </form>
                                                        </div>
                                                    }

                                                </CardContent>
                                            </Card>
                                        }

                                        {this.state.show_nominee_form_modal &&
                                            <Modal
                                                show={
                                                    this.state
                                                        .show_nominee_form_modal
                                                }
                                                onHide={() =>
                                                    this.setState({ show_nominee_form_modal: false })
                                                }
                                                size="lg"
                                                aria-labelledby="contained-modal-title-vcenter"
                                                centered
                                                keyboard={false}
                                                fullscreen={true}
                                                backdrop="static"
                                            >
                                                <Modal.Header>{this.state.modal_nominee['info']['name']}</Modal.Header>
                                                <Modal.Body>
                                                    {this.state.modal_nominee['form'].length > 77 &&
                                                        <tr>
                                                            <td >
                                                                <PDFReader
                                                                    data={atob(
                                                                        this.state.modal_nominee['form']
                                                                    )}
                                                                    width="800"
                                                                />
                                                            </td>
                                                        </tr>
                                                    }
                                                </Modal.Body>
                                                <Modal.Footer>

                                                    <button
                                                        className="btn btn-warning btn-sm"
                                                        style={{ width: 100 }}
                                                        onClick={() =>
                                                            this.setState({ show_nominee_form_modal: false })
                                                        }
                                                    >
                                                        Close
                                                    </button>
                                                </Modal.Footer>
                                            </Modal>
                                        }

                                    </div>
                                }

                                {this.state.wizard_step > 0 &&
                                    <Fab
                                        style={{ bottom: 23, left: -10, transform: 'scale(0.8)' }}
                                        alwaysShowTitle={true}
                                        mainButtonStyles={{
                                            backgroundColor: "#d45950"
                                        }}
                                        icon={<i className="fa fa-arrow-left  fa-lg" ></i>}
                                        onClick={() => {
                                            if (this.state.wizard_step > 1)
                                                this.setState({
                                                    wizard_step: this.state.wizard_step - 1
                                                });
                                            else
                                                this.props.history.goBack();
                                        }
                                        }
                                    >
                                    </Fab>
                                }
                                {(this.state.wizard_step == 1 ||
                                    (
                                        this.state.wizard_step === 2 &&
                                        ((this.state.nominee_option === 'existing' && this.state.existing_nominee_selected) ||
                                            (this.state.nominee_option === 'none' /*&& this.state.is_no_nominee_registered*/))
                                    )) &&
                                    <Fab
                                        style={{ bottom: 23, right: -12, transform: 'scale(0.8)' }}
                                        alwaysShowTitle={true}
                                        mainButtonStyles={{
                                            backgroundColor: "#2ea34d"
                                        }}
                                        icon={<i className="fa fa-arrow-right  fa-lg" ></i>}
                                        onClick={() => {
                                            this.setState({
                                                wizard_step: this.state.wizard_step + 1
                                            });
                                        }}
                                    >
                                    </Fab>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </>
        )

    }

}

/*
const mapStateToProps = state => ({
    loading: state.order.loading,
    order_result: state.order.order_result
});
*/

const mapStateToProps = ({ order }) => {

    const { loading, order_result } = order;
    return {
        loading,
        order_result,

    }

};

export default connect(mapStateToProps, {
    loadNomineeList, createOrder, modifyOrder, uploadNominee, twoFactorAuthentication
})(OrderCheckout);