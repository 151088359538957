import api from '../utils/api';
import { setAlert } from './alert';
import {
  PAYMENT_RESULT_LOAD,
  PAYMENT_RESULT_SUCCESS,
  PAYMENT_RESULT_FAIL
} from './types';
import { API_END_POINTS } from '../constants/constants';

export const createRazorPayOrder = (body_parameters, callbackFunction) => async dispatch => {
   
  const body = body_parameters;

  try {

    dispatch({
      type: PAYMENT_RESULT_LOAD
    });
    
    const res = await api.post(API_END_POINTS.CREATE_PAYMENT_ORDER, body);

    dispatch({
      type: PAYMENT_RESULT_SUCCESS,
      payload: res.data
    });

    callbackFunction(res.data)
    
  } catch (err) {

    console.log(err)
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: PAYMENT_RESULT_FAIL,
      payload: {
        code: '-1',
        messageText: ''
      }
    });

  }
};

export const submitExpertAdvise = (body_parameters, callbackFunction) => async dispatch => {
   
  const body = body_parameters;

  try {

    dispatch({
      type: PAYMENT_RESULT_LOAD
    });
    
    const res = await api.post(API_END_POINTS.EXPERT_ADVISE_SUBMIT, body);

    dispatch({
      type: PAYMENT_RESULT_SUCCESS,
      payload: res.data
    });

    callbackFunction(res.data)
    
  } catch (err) {

    console.log(err)
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: PAYMENT_RESULT_FAIL,
      payload: {
        code: '-1',
        messageText: ''
      }
    });

  }
};
