import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
    createSipOrder,
    uploadMandate,
    registerMandate,
    loadBankAccountList,
    loadNachMandateForm,
    refreshMandateStatus,
    cancelMandate,
    downloadMandate
} from "../../actions/orders";
import { eNachMandate } from '../../../jsx/actions/auth';
import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";
import { PDFReader } from "reactjs-pdf-reader";
import { Card, CardContent, CardHeader } from "@mui/material";
import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';

import {
    Table,
    Collapse,
    Modal,
    Form,
    Row,
    Col,
    Button,
    Label,
    Spinner,
    Alert,
    ListGroup
} from "react-bootstrap";
import Select from "react-select";
import { saveAs } from "file-saver";
import { TabPanel, a11yProps } from "../../shared/Tabs";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";

const fileFormat = ["image/png", "image/jpeg", "image/jpg"];

class MandateList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selected_order: {},
            sip_order: {},
            list_bank_account: [],
            list_mandate_initial: [],
            list_mandate: [],
            list_pending_mandate: [],
            list_mandate_debit: [],

            list_nach_authorize_type: [],

            is_enach_mandate: false,
            src_nach_form: '',
            nach_form_download_url: ``,
            is_nach_form_download_url_new_window: false,
            selected_mandate_id: '',

            modal_src_nach_form: '',
            modal_selected_mandate_id: '',

            is_mandate_selected: false,
            is_mandate_registered: false,
            is_mandate_uploaded: false,
            is_debit_max_limit: false,

            mandate_nach_list: [],

            mandate_authorize_type: `ENACH`,
            mandate_amount: 25000,
            mandate_id: '',
            mandate_date_validation: {
                message_text: "",
            },
            is_first_order_today: false,
            loading: false,
            is_nach: true,
            nach_option: "existing",
            nach_option_new_processed: false,
            nach_option_new_processed_message_text: "",
            sip_information_text: "You need to make payment physically on ",
            render_pdf: null,
            nachmandateFile: null,
            src_nachmandateFile: null,
            byte_nachmandateFile: null,

            mandate_mode: 0,

            show_upload_popup: false,
            modal_upload_mandate: {},
            modal_nachmandateFile: null,
            src_modal_nachmandateFile: null,

            wizard_step: 1,
            show_previous_button: false,
            show_next_button: false,

            show_popup: false,
            popup_messageText: "",

            show_confirm_popup: false,
            confirm_popup_messageText: ``,
            confirm_popup_request_mode: ``,
            confirm_popup_mandate_info: {},

            invest_more_amount: 0.0,
            redeemable_amount: 0.0,
            is_redeem_full_checked: false,
            is_stage_verified: false,
            is_verify_enabled: false,
            is_verified_editable: false,
            esignkyc_information: {},
            esignkyc_attachment_list: {},
            is_esign_verified: false,
            is_esign_button_disabled: false,
            isloading: false

        };
    }

    componentDidMount() {
        window.addEventListener("scroll", () => {

            if (document.getElementById("navbar-portfolio-container") &&
                document.getElementById("navbar") &&
                document.getElementById("navbar").classList) {
                const topBorder = document
                    .getElementById("navbar-portfolio-container")
                    .getBoundingClientRect().top;

                topBorder >= 0
                    ? document.getElementById("navbar").classList.remove("fixed-portfolio")
                    : document.getElementById("navbar").classList.add("fixed-portfolio");
            }

        });
        this.onLoadBankAccountList();
    }

    onLoadBankAccountList = () => {

        this.setState({ isloading: true });

        this.props
            .loadBankAccountList({
                sip_date: ''
            })
            .then((response) => {

                if (this.props.order_result["code"] !== "000") {
                    this.setState({ isloading: false });
                    alert(this.props.order_result["messageText"]);
                } else {
                    let _result = this.props.order_result["result"];

                    if (_result) {

                        let _customer_pending_mandate_list = _result["customer_pending_mandate_list"];

                        let _nach_form = _customer_pending_mandate_list.length > 0 ?
                            (_result["mandate_nach_list"] || []).filter((el) => {
                                return (
                                    el["mandate_id"] ===
                                    _customer_pending_mandate_list[0]["mandate_id"]
                                );
                            }) : [];

                        let setStateObject = {

                            isloading: false,
                            list_bank_account: _result['customer_bank_list'],
                            list_mandate_initial: _result["customer_mandate_list"],
                            list_mandate: _result["customer_mandate_list"],
                            list_pending_mandate: _customer_pending_mandate_list,
                            list_mandate_debit: _result["customer_mandate_debit_list"],
                            mandate_nach_list: _result['mandate_nach_list'],
                            mandate_date_validation: _result["mandate_date_validation"],

                            is_enach_mandate: _nach_form.length > 0 ? _nach_form[0]["is_enach_mandate"] : ``,

                            is_register_mandate_allowed: _result['is_register_mandate_allowed'],
                            is_mandate_registered:
                                _customer_pending_mandate_list.filter((obj) => {
                                    return obj.is_selected === true;
                                }).length > 0,
                            src_nach_form:
                                _nach_form.length > 0 ? _nach_form[0]["nach_form"] : "",
                            nach_form_download_url:
                                _nach_form.length > 0 ? _nach_form[0]["download_url"] : "",

                            is_nach_form_download_url_new_window:
                                _nach_form.length > 0 ? _nach_form[0]["is_download_url_new_window"] : false,

                            selected_mandate_id:
                                _nach_form.length > 0 ? _nach_form[0]["mandate_id"] : "",
                            mandate_mode: 0,
                            list_nach_authorize_type: _result['list_nach_authorize_type']
                        };

                        if (_customer_pending_mandate_list.filter((obj) => {
                            return obj.is_selected === true;
                        }).length > 0) {
                            setStateObject['mandate_amount'] = _customer_pending_mandate_list.find(
                                (obj) => obj.is_selected === true
                            )['amount']
                        }

                        this.setState(setStateObject);

                    } else
                        this.setState({
                            list_mandate: [],
                            list_mandate_initial: [],
                            list_pending_mandate: [],
                            list_mandate_debit: [],
                            list_nach_authorize_type: [],

                            mandate_date_validation: {
                                message_text: "",
                            },
                            is_mandate_registered: false,
                            src_nach_form: "",
                            nach_form_download_url: ``,
                            is_nach_form_download_url_new_window: false,
                            selected_mandate_id: "",
                            mandate_mode: 0
                        });
                }
            })
            .catch((err) => {
                // console.log("line 146 :");
                console.log(err);
                alert(err);
            });
    };

    onloadNachMandateForm = (item) => {

        this.setState({ isloading: true });
        this.props
            .loadNachMandateForm(item)
            .then((response) => {
                this.setState({ isloading: false });
                if (this.props.order_result["code"] !== "000")
                    alert(this.props.order_result["messageText"]);
                else {
                    if (this.props.order_result["src_nach_form"])
                        this.setState({
                            show_upload_popup: true,
                            modal_upload_mandate: item,
                            modal_src_nach_form: this.props.order_result["src_nach_form"],
                            modal_selected_mandate_id: item['mandate_id']
                            // render_pdf: atob(this.props.order_result["pdf_base64"]),
                        });
                }
            })
            .catch((err) => {
                // console.log("line 168 :");
                console.log(err);
                alert(err);
            });
    };

    refreshMandateStatus = (mandate_id) => {

        this.setState({ isloading: true });
        this.props
            .refreshMandateStatus(
                Object.assign(this.state.selected_order, { mandate_id: mandate_id })
            )
            .then((response) => {
                this.setState({ isloading: false });
                if (this.props.order_result["code"] !== "000")
                    alert(this.props.order_result["messageText"]);
                else {
                    if (this.props.order_result["render_pdf"])
                        this.setState({
                            render_pdf: atob(this.props.order_result["pdf_base64"]),
                        });
                    else {

                        let _result = this.props.order_result["result"];

                        if (_result && _result["status"]
                        ) {

                            let _list = this.state.list_mandate_initial;

                            _list.forEach((el) => {

                                if (el["mandate_id"] === _result["mandateId"]) {

                                    el["is_processed"] = _result["is_processed"];
                                    el["is_reupload"] = _result["is_reupload"];

                                    el["status"] = _result["status"];

                                }

                            });

                            // console.log(_list);

                            this.setState({
                                list_mandate_initial: _list,
                                list_mandate: _list
                            });

                        }
                    }
                }
            })
            .catch((err) => {
                // console.log("line 168 :");
                console.log(err);
                alert(err);
            });
    };

    onRegisterMandate = () => {

        this.setState({ isloading: true });

        let _bank_information =
            this.state.list_bank_account.filter((el) => {
                return el["is_primary"] === true;
            })[0] || {};

        this.props
            .registerMandate({
                client_info: {
                    account_no: _bank_information["beneficiary_account_no"],
                    account_type: _bank_information["beneficiary_account_type"],
                    ifsc_code: _bank_information["beneficiary_ifsc"],
                    account_holder_name:
                        _bank_information["beneficiary_account_holder_name"],
                    debit_amount: this.state.mandate_amount,
                    request_mode: "web",
                    mandate_type: "X",
                    mandate_authorize_type: this.state.mandate_authorize_type
                },
            })
            .then((response) => {

                this.setState({ isloading: false });

                if (this.props.order_result["code"] !== "000")
                    alert(this.props.order_result["messageText"]);
                else {

                    let _result = this.props.order_result["result"];

                    if (_result) {

                        /*
                        if (_result['is_enach_mandate']) {
                            sessionStorage.setItem('enach_mandate_id', _result['enach_mandate_post_html']);
                        }
                        else 
                        */
                        {

                            let _customer_pending_mandate_list = _result["customer_pending_mandate_list"];
                            let _nach_form = _result["mandate_nach_list"].filter((el) => {
                                return (
                                    el["mandate_id"] ===
                                    _customer_pending_mandate_list[0]["mandate_id"]
                                );
                            });

                            this.setState({
                                list_mandate: _result["customer_mandate_list"],
                                list_pending_mandate: _customer_pending_mandate_list,
                                list_mandate_debit: _result["customer_mandate_debit_list"],
                                mandate_date_validation: _result["mandate_date_validation"],
                                is_mandate_registered:
                                    _customer_pending_mandate_list.filter((obj) => {
                                        return obj.is_selected === true;
                                    }).length > 0,

                                src_nach_form:
                                    _nach_form.length > 0 ? _nach_form[0]["nach_form"] : "",
                                nach_form_download_url:
                                    _nach_form.length > 0 ? _nach_form[0]["download_url"] : ``,
                                is_nach_form_download_url_new_window:
                                    _nach_form.length > 0 ? _nach_form[0]["is_download_url_new_window"] : false,

                                selected_mandate_id:
                                    _nach_form.length > 0 ? _nach_form[0]["mandate_id"] : "",

                                is_enach_mandate: _result['is_enach_mandate']
                            });
                        }
                    }
                }
            })
            .catch((err) => {
                // console.log("line 303 :");
                console.log(err);
                alert(err);
            });
    };

    onUploadMandate = (request_from) => {

        this.setState({ isloading: true });
        let formData = new FormData();

        let _nachmandateFile = (request_from === 'modal' ?
            this.state.modal_nachmandateFile : this.state.nachmandateFile);

        if (_nachmandateFile) {

            formData.append("upload_file", _nachmandateFile);
            formData.append("id_type", "scanmandate");
            formData.append("mandateId", (request_from === 'modal' ?
                this.state.modal_selected_mandate_id : this.state.selected_mandate_id));
            formData.append("mandateType", "XSIP");
            formData.append("imageName", _nachmandateFile.name);
            formData.append("imageType", _nachmandateFile.type);
            formData.append("fileExtension", _nachmandateFile.name.split('.').pop());
            // formData.append("pFileBytes", this.state.byte_nachmandateFile);

            this.props
                .uploadMandate(formData)
                .then((response) => {

                    if (this.props.order_result["code"] !== "000") {
                        this.setState({
                            isloading: false,
                            show_popup: true,
                            pop_messagetext: this.props.order_result["messageText"]
                        });
                    }
                    else {

                        // if (request_from === 'modal')
                        //     this.setState({
                        //         isloading: false, show_upload_popup: false
                        //     });

                        if (this.props.order_result["state_parameters"]) {
                            this.setState(Object.assign({
                                isloading: false,
                                show_popup: true,
                                show_upload_popup: (request_from === 'modal' ? false : this.state.show_upload_popup),
                                pop_messagetext: this.props.order_result["state_parameters"][
                                    "nach_option_new_processed_message_text"
                                ],
                            }, this.props.order_result["state_parameters"]));
                        }
                        this.onLoadBankAccountList();
                    }
                })
                .catch((err) => {
                    // console.log("line 340 :");
                    console.log(err);
                    alert(err);
                });
        }
    };

    onCancelMandate = (mandate_id) => {

        this.setState({ isloading: true });
        this.props
            .cancelMandate(
                Object.assign(this.state.selected_order, { mandate_id: mandate_id })
            )
            .then((response) => {

                let _result = this.props.order_result["result"];

                if (this.props.order_result["code"] !== "000") {
                    this.setState({
                        isloading: false,
                        show_popup: true,
                        popup_messageText: this.props.order_result["messageText"],
                        show_confirm_popup: false,
                        confirm_popup_messageText: ``,
                        confirm_popup_request_mode: ``,
                        confirm_popup_mandate_info: {},
                    });
                }
                else {
                    if (this.props.order_result["render_pdf"])
                        this.setState({
                            render_pdf: atob(this.props.order_result["pdf_base64"]),
                        });
                    else {

                        if (_result && _result["customer_mandate_list"]) {
                            let _customer_pending_mandate_list =
                                _result["customer_pending_mandate_list"];
                            let _nach_form = (_result["mandate_nach_list"] || []).filter((el) => {
                                return (
                                    el["mandate_id"] ===
                                    _customer_pending_mandate_list[0]["mandate_id"]
                                );
                            });

                            this.setState({
                                isloading: false,
                                list_mandate: _result["customer_mandate_list"],
                                list_pending_mandate: _customer_pending_mandate_list,
                                is_mandate_registered:
                                    _customer_pending_mandate_list.filter((obj) => {
                                        return obj.is_selected === true;
                                    }).length > 0,
                                src_nach_form:
                                    _nach_form.length > 0 ? _nach_form[0]["nach_form"] : "",
                                nach_form_download_url:
                                    _nach_form.length > 0 ? _nach_form[0]["download_url"] : ``,
                                is_nach_form_download_url_new_window:
                                    _nach_form.length > 0 ? _nach_form[0]["is_download_url_new_window"] : false,

                                selected_mandate_id:
                                    _nach_form.length > 0 ? _nach_form[0]["mandate_id"] : "",

                                show_confirm_popup: false,
                                confirm_popup_messageText: ``,
                                confirm_popup_request_mode: ``,
                                confirm_popup_mandate_info: {},

                                show_popup: true,
                                popup_messageText: this.props.order_result["messageText"],
                            });


                        }
                    }
                }

            })
            .catch((err) => {
                // console.log("line 168 :");
                console.log(err);
                alert(err);
            });
    };

    handleMandateCheck = (e, item) => {

        this.setState({ isloading: true });

        const _list = this.state.list_mandate_initial;

        _list.forEach((el) => {
            el["is_selected"] = false;

            if (el["mandate_id"] === item["mandate_id"])
                el["is_selected"] = e.target.checked;
        });

        let _selected_mandate = _list.filter((el) => {
            return el["is_selected"] === true;
        });
        this.setState({ isloading: false });

        this.setState({
            list_mandate: _selected_mandate.length > 0 ? _selected_mandate : _list,
            is_mandate_selected: _selected_mandate.length > 0,
            selected_mandate_id:
                _selected_mandate.length > 0 ? _selected_mandate[0]["mandate_id"] : "",
        });
    };

    handleMandateIdSelect = (item) => {
        this.setState({ isloading: true });
        const _list = this.state.list_pending_mandate;
        _list.forEach((el) => {
            if (el["mandate_id"] === item.value) item["is_selected"] = !item.value;
            else item["is_selected"] = false;
        });
        this.setState({ isloading: false });
        this.setState({
            selected_mandate_id: item.value,
            list_pending_mandate: _list,
            is_mandate_selected:
                _list.filter((el) => {
                    return el["is_selected"] === true;
                }).length > 0,
        });
    };

    handleInvestAmount(event, attribute) {
        const re = /^\d+(\.\d{0,3})?$/; ///^\d*\.?\d*$/ ///^[0-9\b]+$/;
        if (event.target.value === "" || re.test(event.target.value)) {
            this.setState({
        /*invest_more_amount*/[attribute]: event.target.value.replace(
                /\b0+/g,
                ""
            ),
            });
        }
    }

    changeHandler = async (event) => {

        if (
            event.target.files[0] && fileFormat.indexOf(event.target.files[0].type) > -1
            /*(event.target.files[0].type === "image/png" ||
                event.target.files[0].type === "image/jpeg" ||
                event.target.files[0].type === "image/jpg")*/
        ) {
            const file = event.target.files[0];
            const buffer = await file.arrayBuffer();

            let byteArray = new Int8Array(buffer);
            this.setState({
                nachmandateFile: file,
                byte_nachmandateFile: byteArray,
                src_nachmandateFile: URL.createObjectURL(file),
            });
        } else {
            this.setState({
                show_popup: true,
                popup_messageText:
                    "Upload file format should be of PNG, JPEG, JPG only",
            });
        }
    };

    changeHandlerModal = async (event) => {

        if (
            event.target.files[0] &&
            (event.target.files[0].type === "image/png" ||
                event.target.files[0].type === "image/jpeg" ||
                event.target.files[0].type === "image/jpg")
        ) {
            {
                const file = event.target.files[0];
                const buffer = await file.arrayBuffer();

                let byteArray = new Int8Array(buffer);
                this.setState({
                    modal_nachmandateFile: file,
                    src_modal_nachmandateFile: URL.createObjectURL(file),
                });
            }
        } else {
            this.setState({
                show_popup: true,
                popup_messageText:
                    "Upload file format should be of PNG, JPEG, JPG only",
            });
        }
    };

    handleRadioChange = (value, attribute) => {
        // console.log(event);
        this.setState({ [attribute]: value });
    };

    base64toBlob = (data, pdfContentType) => {
        // Cut the prefix `data:application/pdf;base64` from the raw base 64
        const base64WithoutPrefix = data.substr(
            `data:${pdfContentType};base64,`.length
        );

        const bytes = atob(base64WithoutPrefix);
        let length = bytes.length;
        let out = new Uint8Array(length);

        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }

        let _blob = new Blob([out], { type: pdfContentType });

        return URL.createObjectURL(_blob);
        // return new Blob([out], { type: pdfContentType });
    };

    downloadNachForm = async (download_url, window_timeout) => {
        setTimeout(() => { this.setState({ isloading: false }); }, window_timeout || 4500);
        const win = window.open(download_url, "_blank");
    }

    eNachmandatePage(request_mode, mandate_info) {

        /*
        this.props.history.push({
            pathname: "/enachMandatePage/",
            state: {
                request_mode: request_mode,
                mandate_info: mandate_info
            },
        });
        */

        this.setState({ isloading: true });

        // window.history.replaceState(null, '');
        this.props.eNachMandate({
            mandate_info: mandate_info
        }).then(async (response) => {

            if (this.props.payment_initiate_result['code'] !== '000') {
                this.setState({
                    isloading: false,
                    show_popup: true,
                    popup_messageText: this.props.payment_initiate_result['messageText']
                });
            }
            else {

                this.setState({
                    isloading: false,
                    // is_refresh: this.props.payment_initiate_result['is_refresh'],
                    // actionUrl: this.props.payment_initiate_result['actionUrl'],
                    // is_redirect: this.props.payment_initiate_result['is_redirect'],
                    // input_html: this.props.payment_initiate_result['input_html'],
                    // message_text: ``
                });

                // // await this.sleep(4000);
                // if (this.props.payment_initiate_result['is_redirect']) {
                localStorage.setItem('recent_enach_mandate_id', mandate_info['mandate_id']);
                // window.location.replace(this.props.payment_initiate_result['actionUrl'])
                window.location.replace(this.props.payment_initiate_result['redirectUrl']);
                // }
                // else
                //     sessionStorage.removeItem('recent_enach_mandate_id');
            }

        })
            .catch(err => {
                alert(err)
            });

    }

    /*
    downloadNachForm_dev = (src_nach_form, selected_mandate_id) => {

        if (src_nach_form && src_nach_form.length > 255) {

            this.setState({ isloading: true });
            this.props
                .downloadMandate(
                    Object.assign(this.state.selected_order, { param_mandate_id: selected_mandate_id })
                )
                .then((response) => {

                    this.setState({ isloading: false });
                });

        }
    }
    */

    closePopup = () => {
        this.setState({
            show_popup: false,
            popup_messageText: ``
        });
    };

    fnDateOrdinalformat(date, tmp) {
        return [
            (tmp = date.getDate()) +
            ([, 'st', 'nd', 'rd'][/1?.$/.exec(tmp)] || 'th'),
            ['January', 'February', 'March', 'April',
                'May', 'June', 'July', 'August',
                'September', 'October', 'November', 'December'
            ][date.getMonth()],
            date.getFullYear()
        ].join(' ')
    }

    render() {

        // if(this.props.loading)
        //   return <Spinner />;

        // if(this.props.order_result && this.props.order_result['esignkyc_stage_verified'])
        //    return <Redirect to={this.props.aadhaar_result['navigateScreen']} />;

        return (
            <>
                <Header1 />
                <Sidebar />

                {this.state.isloading && (
                    <Spinner
                        animation="border"
                        variant="dark"
                        style={{ position: "fixed", top: "50%", left: "50%" }}
                    />
                )}
                <div className="content-body" >
                    <div className="container">
                        <div className="row">
                            {<div id="navbar-portfolio-container">
                                <div id="navbar" >
                                    <div className="card-header">
                                        <div class="row">
                                            <div class="col" style={{ textAlign: 'center', }}>
                                                <h5>Mandate List</h5>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row" style={{ marginTop: '-10px' }}>
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            }

                            {this.state.isloading ?
                                <Alert variant="secondary text-center" style={{ marginTop: 60 }}>
                                    <Alert.Heading>
                                        We are processing your request...
                                    </Alert.Heading>
                                </Alert>
                                :
                                <div style={{ marginTop: 60 }}>
                                    {this.state.wizard_step == 1 &&
                                        <div>
                                            <h5 style={{ textAlign: 'center' }}>Your Primary Bank Account</h5>
                                            {(this.state.list_bank_account || []).map(
                                                (item, index) => (
                                                    <Card key={index}>
                                                        <CardContent>
                                                            <table style={{ width: '100%' }}>
                                                                <tr>
                                                                    <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                                                                        <span>
                                                                            Bank Name
                                                                        </span>
                                                                    </td>
                                                                    <td style={{ width: 180, height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                                                        {item["beneficiary_bank_name"]}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ width: 120, height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                                                                        <span>
                                                                            Account Number
                                                                        </span>
                                                                    </td>
                                                                    <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                                                        {item["beneficiary_account_no"]}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                                                                        <span>
                                                                            Name
                                                                        </span>
                                                                    </td>
                                                                    <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                                                        {item["beneficiary_account_holder_name"]}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                                                                        <span>
                                                                            Account Type
                                                                        </span>
                                                                    </td>
                                                                    <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                                                        {item["beneficiary_account_type"]}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                                                                        <span>
                                                                            IFSC
                                                                        </span>
                                                                    </td>
                                                                    <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                                                        {item["beneficiary_ifsc"]}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                                                                        <span>
                                                                            Branch
                                                                        </span>
                                                                    </td>
                                                                    <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                                                        {item["beneficiary_bank_branch_name"]}
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                            <table style={{ width: '99%' }}>
                                                                <tr>
                                                                    <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                                                                        <span>
                                                                            <u>ADDRESS</u>
                                                                        </span>
                                                                    </td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ height: 30, backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                                                                        <span>
                                                                            {item['beneficiary_bank_branch_address']}
                                                                        </span>
                                                                    </td>
                                                                    <td></td>
                                                                </tr>
                                                            </table>
                                                            {item["is_primary"] &&
                                                                <span style={{
                                                                    fontSize: 16, backgroundColor: '#d9b998', padding: 4, paddingLeft: 8,
                                                                    paddingRight: 8, margin: 4, borderRadius: 4
                                                                }}>
                                                                    PRIMARY ACCOUNT
                                                                </span>
                                                            }
                                                        </CardContent>
                                                    </Card>
                                                ))}
                                        </div>
                                    }
                                    <div >
                                        {this.state.wizard_step == 2 &&
                                            <div >

                                                {this.state.nach_option ===
                                                    "existing" && (this.state.list_mandate || []).length === 0 &&
                                                    <div>
                                                        <span style={{
                                                            textAlign: 'left',
                                                            width: 400,
                                                            fontSize: 13,
                                                            color: 'red'
                                                        }}>
                                                            Your mandate list is empty.
                                                        </span>
                                                        <br />
                                                        <hr />
                                                    </div>
                                                }

                                                <div class="row">
                                                    {/* {
                                                        <Form.Group as={Row} > */}
                                                    <div className="row">
                                                        <div className="col-7">
                                                            <Form.Check
                                                                type="radio"
                                                                label={
                                                                    <span>
                                                                        <span style={{

                                                                            fontSize: 18,
                                                                            padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                                                                        }}>
                                                                            {`Existing`}
                                                                        </span>
                                                                    </span>
                                                                }
                                                                name="nach_option"
                                                                id="existing"
                                                                value="existing"
                                                                checked={
                                                                    this.state.nach_option ===
                                                                    "existing"
                                                                }
                                                                onChange={(e) =>
                                                                    this.handleRadioChange(
                                                                        "existing",
                                                                        "nach_option"
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div className="col-5">
                                                            <Form.Check
                                                                type="radio"
                                                                label={
                                                                    <span>
                                                                        <span style={{
                                                                            fontSize: 18,
                                                                            padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                                                                        }}>
                                                                            {`Register`}
                                                                        </span>
                                                                    </span>
                                                                }
                                                                name="nach_option"
                                                                id="new"
                                                                value="new"
                                                                checked={
                                                                    this.state.nach_option === "new"
                                                                }
                                                                onChange={(e) =>
                                                                    this.handleRadioChange(
                                                                        "new",
                                                                        "nach_option"
                                                                    )
                                                                }
                                                                disabled={!this.state.is_register_mandate_allowed}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div>

                                                        {this.state.is_nach && (
                                                            <div class="row">
                                                                <div>
                                                                    <label></label>
                                                                </div>
                                                                {this.state
                                                                    .nach_option ===
                                                                    "new" &&
                                                                    this.state
                                                                        .list_pending_mandate
                                                                        .length > 0 &&
                                                                    <div>
                                                                        <Tabs
                                                                            value={this.state.mandate_mode}
                                                                            onChange={(event, newValue) => {
                                                                                this.setState({ mandate_mode: newValue });
                                                                            }}
                                                                            aria-label="basic tabs example"
                                                                        >
                                                                            <Tab label="SUBMIT MANDATE" {...a11yProps(0)} />
                                                                            <Tab label="VIEW MANDATE" {...a11yProps(1)} />
                                                                        </Tabs>
                                                                        <br />
                                                                        {/* <TabPanel value={this.state.mandate_mode} index={0}> */}
                                                                        {this.state.mandate_mode === 0 &&
                                                                            <div>

                                                                                {
                                                                                    <div className="row">
                                                                                        <div className="col-6">
                                                                                            Mandate Id <br />
                                                                                            <Select
                                                                                                width="140px"
                                                                                                value={this.state.list_pending_mandate.find(
                                                                                                    (obj) =>
                                                                                                        obj.is_selected ===
                                                                                                        true
                                                                                                )}
                                                                                                onChange={(e) =>
                                                                                                    this.handleMandateIdSelect(
                                                                                                        e
                                                                                                    )
                                                                                                }
                                                                                                options={
                                                                                                    this.state
                                                                                                        .list_pending_mandate
                                                                                                }
                                                                                                isDisabled={
                                                                                                    this.state
                                                                                                        .nach_option_new_processed
                                                                                                }
                                                                                                isSearchable={
                                                                                                    false
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                        <div className="col-6" >
                                                                                            Allowed Limit <br />
                                                                                            <Select
                                                                                                width="140px"

                                                                                                value={this.state.list_mandate_debit.find(
                                                                                                    (obj) =>
                                                                                                        obj.value.toString() ===
                                                                                                        this.state.mandate_amount.toString()
                                                                                                )}
                                                                                                onChange={(e) =>
                                                                                                    this.setState({
                                                                                                        mandate_amount:
                                                                                                            e.value,
                                                                                                    })
                                                                                                }
                                                                                                options={
                                                                                                    this.state
                                                                                                        .list_mandate_debit
                                                                                                }
                                                                                                isDisabled={
                                                                                                    this.state
                                                                                                        .is_mandate_registered
                                                                                                }
                                                                                            />

                                                                                        </div>
                                                                                    </div>
                                                                                }


                                                                                { //!this.state.is_enach_mandate &&
                                                                                    <div>
                                                                                        <br />
                                                                                        <Button
                                                                                            className="btn btn-warning btn-block"
                                                                                            variant="success"
                                                                                            onClick={(e) =>

                                                                                                this.setState({
                                                                                                    show_confirm_popup: true,
                                                                                                    confirm_popup_messageText: `Are you sure want to cancel the this mandate ?`,
                                                                                                    confirm_popup_request_mode: `cancel mandate`,
                                                                                                    confirm_popup_mandate_info: this.state.list_pending_mandate[0]["mandate_id"]
                                                                                                })
                                                                                            }
                                                                                            disabled={this.state.isloading}
                                                                                        >
                                                                                            Cancel Mandate
                                                                                        </Button>
                                                                                    </div>
                                                                                }
                                                                                <br />
                                                                                {
                                                                                    <span>
                                                                                        Mandate amount limit is checked for each transaction
                                                                                        and not sum total of transaction. For example: If your Mandate amount is Rs. 25,000,
                                                                                        you can set up as many Systematic Plans you want using this mandate however the transaction amount of
                                                                                        each of such Systematic Plan (+ charges if any) should be less than Rs 25,000.
                                                                                    </span>
                                                                                }
                                                                                {
                                                                                    <hr />
                                                                                }

                                                                                {this.state.nach_option === "new" &&
                                                                                    this.state.is_mandate_registered &&
                                                                                    !this.state.nach_option_new_processed && (

                                                                                        <div>

                                                                                            {this.state.is_enach_mandate ?
                                                                                                <div class="row">
                                                                                                    <Button
                                                                                                        className="btn btn-success btn-block"
                                                                                                        variant="success"
                                                                                                        onClick={(e) =>
                                                                                                            this.eNachmandatePage(
                                                                                                                `enach authentication`,
                                                                                                                this.state
                                                                                                                    .list_pending_mandate[0]
                                                                                                            )
                                                                                                        }
                                                                                                        disabled={this.state.isloading}
                                                                                                    >
                                                                                                        Verify e-NACH
                                                                                                    </Button>
                                                                                                </div>
                                                                                                :
                                                                                                <div class="row">

                                                                                                    <p
                                                                                                        style={{
                                                                                                            textAlign: "left",
                                                                                                        }}
                                                                                                    >
                                                                                                        <b>Step 1</b>.
                                                                                                        {
                                                                                                            this.state.is_nach_form_download_url_new_window === true ?
                                                                                                                <button type="button"
                                                                                                                    className="btn-sm btn-primary"
                                                                                                                    style={{ color: 'white', width: '140px' }}
                                                                                                                    onClick={() => {
                                                                                                                        this.downloadNachForm(this.state.nach_form_download_url, 4500);
                                                                                                                    }}
                                                                                                                >
                                                                                                                    Download Mandate
                                                                                                                </button>
                                                                                                                :
                                                                                                                <a
                                                                                                                    className="btn-sm btn-success"
                                                                                                                    // variant="success"
                                                                                                                    download={
                                                                                                                        this.state.selected_mandate_id + `.pdf`
                                                                                                                    }
                                                                                                                    href={this.state.nach_form_download_url}
                                                                                                                    onClick={() => {
                                                                                                                        this.setState({ isloading: true });
                                                                                                                        setTimeout(() => { this.setState({ isloading: false }); }, 4500)
                                                                                                                    }
                                                                                                                    }
                                                                                                                >
                                                                                                                    Download Mandate
                                                                                                                </a>
                                                                                                        }

                                                                                                    </p>
                                                                                                    <p
                                                                                                        style={{
                                                                                                            textAlign: "left",
                                                                                                        }}
                                                                                                    >
                                                                                                        <b>Step 2</b>. Put your signature

                                                                                                    </p>
                                                                                                    <p
                                                                                                        style={{
                                                                                                            textAlign: "left",
                                                                                                        }}
                                                                                                    >
                                                                                                        <b>Step 3</b>. Scan or Take photo and sava as png/jpeg format.
                                                                                                        Do not tilt the image while uploading.
                                                                                                    </p>
                                                                                                    <p
                                                                                                        style={{
                                                                                                            textAlign: "left",
                                                                                                        }}
                                                                                                    >
                                                                                                        File name
                                                                                                        should be strictly
                                                                                                        as
                                                                                                        <span style={{ fontWeight: 'bold', color: 'darkgreen' }}>{ //this.state.selected_mandate_id +
                                                                                                            ` ` + this.state
                                                                                                                .list_pending_mandate[0]["mandate_id"]
                                                                                                            + `.png`}</span>
                                                                                                        {` `}
                                                                                                        <span style={{ textAlign: 'center' }}>or</span>
                                                                                                        <span style={{ fontWeight: 'bold', color: 'darkgreen' }}>
                                                                                                            {` ` + this.state
                                                                                                                .list_pending_mandate[0]["mandate_id"] +
                                                                                                                ".jpeg"}{` `}
                                                                                                        </span>
                                                                                                    </p>
                                                                                                    <Form.Control
                                                                                                        type="file"
                                                                                                        onChange={
                                                                                                            this.changeHandler
                                                                                                        }
                                                                                                    />
                                                                                                    <div style={{ height: this.state.src_nachmandateFile ? 10 : 30 }} />

                                                                                                    {this.state
                                                                                                        .src_nachmandateFile &&
                                                                                                        <div>
                                                                                                            <div class="row">
                                                                                                                <div class="col-6">
                                                                                                                    <img
                                                                                                                        width="150"
                                                                                                                        src={
                                                                                                                            this.state[
                                                                                                                            "src_nachmandateFile"
                                                                                                                            ]
                                                                                                                        }
                                                                                                                    />
                                                                                                                </div>
                                                                                                                <div class="col-6">
                                                                                                                    <br />
                                                                                                                    <Button
                                                                                                                        className="btn btn-success btn-sm"
                                                                                                                        variant="success"
                                                                                                                        onClick={() => {
                                                                                                                            this.onUploadMandate('main');
                                                                                                                        }
                                                                                                                        }
                                                                                                                        disabled={this.state.isloading}
                                                                                                                    >
                                                                                                                        Upload Mandate
                                                                                                                    </Button>
                                                                                                                </div>

                                                                                                            </div>
                                                                                                            <div style={{ height: 10 }} />
                                                                                                        </div>
                                                                                                    }

                                                                                                    {this.state
                                                                                                        .show_popup && (
                                                                                                            <Modal
                                                                                                                show={
                                                                                                                    this.state
                                                                                                                        .show_popup
                                                                                                                }
                                                                                                                onHide={() =>
                                                                                                                    this.closePopup()
                                                                                                                }
                                                                                                                className="modal-fullscreen-sm-down"
                                                                                                            >
                                                                                                                <Modal.Header></Modal.Header>
                                                                                                                <Modal.Body>
                                                                                                                    <p>
                                                                                                                        {
                                                                                                                            this.state.popup_messageText
                                                                                                                        }
                                                                                                                    </p>
                                                                                                                </Modal.Body>
                                                                                                                <Modal.Footer>
                                                                                                                    <Button
                                                                                                                        variant="primary"
                                                                                                                        onClick={() =>
                                                                                                                            this.closePopup()
                                                                                                                        }
                                                                                                                    >
                                                                                                                        Ok
                                                                                                                    </Button>
                                                                                                                </Modal.Footer>
                                                                                                            </Modal>
                                                                                                        )}
                                                                                                </div>
                                                                                            }

                                                                                        </div>

                                                                                    )}
                                                                            </div>
                                                                        }
                                                                        {/* </TabPanel>
                                                                                <TabPanel value={this.state.mandate_mode} index={1}> */}
                                                                        {this.state.mandate_mode === 1 &&
                                                                            <div>
                                                                                {this.state.nach_option === "new" &&
                                                                                    this.state
                                                                                        .is_mandate_registered &&
                                                                                    !this.state
                                                                                        .nach_option_new_processed && (

                                                                                        <div class="row">
                                                                                            <div style={{ marginLeft: -90 }}>
                                                                                                {
                                                                                                    this.state
                                                                                                        .src_nach_form
                                                                                                        .length > 255 && (
                                                                                                        <PDFReader
                                                                                                            data={atob(
                                                                                                                this.state
                                                                                                                    .src_nach_form
                                                                                                            )}
                                                                                                            style={{ width: 350, height: 700 }}
                                                                                                        />
                                                                                                    )
                                                                                                }

                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                                {/* </TabPanel> */}
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                }
                                                                {this.state.nach_option ===
                                                                    "existing" && (this.state.list_mandate || []).length > 0 &&
                                                                    <ListGroup >
                                                                        {(this.state.list_mandate || []).map((item) => (
                                                                            <ListGroup.Item
                                                                                key={item["mandate_id"]}
                                                                                style={{
                                                                                    marginBottom: 5, border: '1px solid #a8acb3',
                                                                                    borderCollapse: 'collapse'
                                                                                }}
                                                                                action
                                                                                variant="light"
                                                                                className="wallet-address text-dark"
                                                                            >
                                                                                <table style={{ width: '100%' }}>
                                                                                    <tr>
                                                                                        <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                                                                                            <span>
                                                                                                Mandate Id
                                                                                            </span>
                                                                                        </td>
                                                                                        <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                                                                            {item["mandate_id"]}
                                                                                        </td>
                                                                                        <td style={{ width: 10 }}>

                                                                                        </td>
                                                                                        {!item['is_processed'] ?
                                                                                            <td style={{ height: 30, textAlign: 'center' }}>
                                                                                                ({item['is_reupload'] ?
                                                                                                    <Button
                                                                                                        className="btn btn-success btn-sm"
                                                                                                        variant="success"
                                                                                                        onClick={() => {

                                                                                                            const _list_nach_form = this.state.mandate_nach_list.filter(el => {
                                                                                                                return el['mandate_id'] === item['mandate_id'];
                                                                                                            });

                                                                                                            this.setState({
                                                                                                                show_upload_popup: true,
                                                                                                                modal_upload_mandate: Object.assign(item, {
                                                                                                                    nach_form_download_url: _list_nach_form[0]['download_url'],
                                                                                                                    is_nach_form_download_url_new_window: _list_nach_form[0]['is_download_url_new_window'],
                                                                                                                })
                                                                                                            })
                                                                                                        }}>
                                                                                                        Re-Upload
                                                                                                    </Button>
                                                                                                    :
                                                                                                    <button type="button"
                                                                                                        className="btn btn-success btn-sm"
                                                                                                        variant="success"
                                                                                                        onClick={(e) =>
                                                                                                            this.refreshMandateStatus(
                                                                                                                item[
                                                                                                                "mandate_id"
                                                                                                                ]
                                                                                                            )
                                                                                                        }
                                                                                                        disabled={this.state.isloading ||
                                                                                                            item["is_cancelled"]}
                                                                                                    >
                                                                                                        Refresh
                                                                                                    </button>
                                                                                                }
                                                                                                )
                                                                                            </td>
                                                                                            :
                                                                                            <td style={{ visibility: item['is_processed'] ? 'visible' : 'none', height: 30, fontWeight: 'bold' }}>
                                                                                                <Form.Group
                                                                                                    controlId="formBasicCheckbox"
                                                                                                >
                                                                                                    <Form.Check
                                                                                                        style={{ fontSize: 15 }}
                                                                                                        type="checkbox"
                                                                                                        label="Select"
                                                                                                        checked={
                                                                                                            item[
                                                                                                            "is_selected"
                                                                                                            ]
                                                                                                        }
                                                                                                        onChange={(
                                                                                                            e
                                                                                                        ) => {
                                                                                                            this.handleMandateCheck(
                                                                                                                e,
                                                                                                                item
                                                                                                            );
                                                                                                        }}
                                                                                                        defaultChecked={
                                                                                                            item[
                                                                                                            "is_primary"
                                                                                                            ]
                                                                                                        }
                                                                                                        disabled={item['is_cancelled'] || !item['is_processed'] || !item['is_active']}
                                                                                                    />
                                                                                                </Form.Group>
                                                                                            </td>
                                                                                        }

                                                                                    </tr>
                                                                                </table>

                                                                                <table style={{ width: '100%' }}>
                                                                                    <tr>
                                                                                        <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'center' }}>
                                                                                            <span>
                                                                                                Amount Limit
                                                                                            </span>
                                                                                        </td>
                                                                                        <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                                                                            <span style={{ fontFamily: 'sans-serif', fontSize: 14 }}>
                                                                                                &#8377;
                                                                                            </span>{item["amount"] || `-`}
                                                                                        </td>
                                                                                        <td style={{ width: 10 }}>

                                                                                        </td>
                                                                                        <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'center' }}>
                                                                                            <span>
                                                                                                Available
                                                                                            </span>
                                                                                        </td>
                                                                                        <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                                                                            <span style={{ fontFamily: 'sans-serif', fontSize: 14 }}>
                                                                                                &#8377;
                                                                                            </span>{item["amount"] - item["limit_used"]}
                                                                                        </td>
                                                                                    </tr>
                                                                                </table>

                                                                                <table style={{ width: '100%' }}>
                                                                                    <tr>
                                                                                        <td style={{ height: 30, textAlign: 'left' }}>
                                                                                            <span>
                                                                                                <i class="fa fa-check-square-o"></i>
                                                                                                {` Validity till `} {item["end_date"]}
                                                                                            </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                </table>
                                                                                <table style={{ width: '100%' }}>
                                                                                    <tr>
                                                                                        <td style={{ height: 30, textAlign: 'left' }}>
                                                                                            <span >
                                                                                                <span style={{ fontWeight: 'bold' }}>
                                                                                                    {item["status"] ||
                                                                                                        "awiting bank approval"}
                                                                                                </span>
                                                                                            </span>
                                                                                        </td>

                                                                                    </tr>
                                                                                </table>

                                                                            </ListGroup.Item>
                                                                        ))}
                                                                    </ListGroup>
                                                                }

                                                                {this.state.nach_option ===
                                                                    "existing" && (this.state.list_mandate || []).length === 0 &&
                                                                    <div>
                                                                        {
                                                                            this.state
                                                                                .list_pending_mandate
                                                                                .length === 0 ?
                                                                                <p>
                                                                                    <span style={{
                                                                                        width: 400,
                                                                                        fontSize: 16,
                                                                                        backgroundColor: '#d9b998', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                                                                                    }}>
                                                                                        Kindly register new mandate.
                                                                                    </span>
                                                                                </p>
                                                                                :
                                                                                <p>
                                                                                    <span style={{
                                                                                        width: 400,
                                                                                        fontSize: 12,
                                                                                        backgroundColor: '#d9b998', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                                                                                    }}>
                                                                                        You have created an mandate recently.
                                                                                    </span>
                                                                                    <hr />
                                                                                    <a
                                                                                        role="button"
                                                                                        style={{ fontSize: 16, color: '#e05d38', textDecoration: 'underline' }}
                                                                                        onClick={() => {
                                                                                            this.setState({
                                                                                                nach_option: 'new'
                                                                                            })
                                                                                        }}
                                                                                    >
                                                                                        Click here
                                                                                    </a>
                                                                                    <span style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                                                                                        {`  `} to complete mandate upload process.
                                                                                    </span>
                                                                                </p>
                                                                        }
                                                                    </div>
                                                                }

                                                                {(this.state.nach_option === "new" ||
                                                                    this.state.nach_option ===
                                                                    "existing") &&
                                                                    (!this.state.is_mandate_selected ||
                                                                        this.state.nach_option ===
                                                                        "new") &&
                                                                    this.state
                                                                        .list_pending_mandate
                                                                        .length === 0 &&
                                                                    (
                                                                        <div class="row">

                                                                            <div>
                                                                                {this.state
                                                                                    .nach_option ===
                                                                                    "new" && (
                                                                                        <div>

                                                                                            <table style={{ width: '100%' }}>
                                                                                                <tr>
                                                                                                    <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                                                                                                        <span style={{ fontSize: 14 }}>
                                                                                                            Allowed Limit
                                                                                                        </span>
                                                                                                    </td>
                                                                                                    <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                                                                                        <Select
                                                                                                            value={this.state.list_mandate_debit.find(
                                                                                                                (obj) =>
                                                                                                                    obj.value.toString() ===
                                                                                                                    this.state.mandate_amount.toString()
                                                                                                            )}
                                                                                                            onChange={(e) =>
                                                                                                                this.setState({
                                                                                                                    mandate_amount:
                                                                                                                        e.value,
                                                                                                                })
                                                                                                            }
                                                                                                            options={
                                                                                                                this.state
                                                                                                                    .list_mandate_debit
                                                                                                            }
                                                                                                            isDisabled={
                                                                                                                this.state
                                                                                                                    .is_mandate_registered
                                                                                                            }
                                                                                                            isSearchable={false}
                                                                                                        />
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </table>
                                                                                            <div style={{ height: 7 }} />
                                                                                            <table style={{ width: '100%' }}>
                                                                                                <tr>
                                                                                                    <td style={{ height: 30, textAlign: 'center' }}>
                                                                                                        <span style={{ fontSize: 14 }}>
                                                                                                            Authorize Type
                                                                                                        </span>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </table>
                                                                                            <div style={{ height: 3 }} />
                                                                                            <table style={{ width: '100%' }}>
                                                                                                <tr>
                                                                                                    <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'left' }}>
                                                                                                        <Select
                                                                                                            value={this.state.list_nach_authorize_type.find(
                                                                                                                (obj) =>
                                                                                                                    obj.value.toString() ===
                                                                                                                    this.state.mandate_authorize_type.toString()
                                                                                                            )}
                                                                                                            onChange={(e) =>
                                                                                                                this.setState({
                                                                                                                    mandate_authorize_type: e.value,
                                                                                                                })
                                                                                                            }
                                                                                                            options={
                                                                                                                this.state
                                                                                                                    .list_nach_authorize_type
                                                                                                            }
                                                                                                            isDisabled={
                                                                                                                this.state
                                                                                                                    .is_mandate_registered
                                                                                                            }
                                                                                                            isSearchable={false}
                                                                                                        />
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </table>
                                                                                            <div style={{ height: 3 }} />
                                                                                            {(this.state.list_nach_authorize_type || []).length > 0 &&
                                                                                                <p style={{ fontWeight: 'bold', fontSize: 16 }}>
                                                                                                    YOU HAVE OPTED FOR {(this.state.list_nach_authorize_type || []).filter((obj) => { return obj.value.toString() === this.state.mandate_authorize_type.toString() }
                                                                                                    )[0]['label']}
                                                                                                </p>
                                                                                            }
                                                                                            <div style={{ height: 5 }} />

                                                                                            <div className="row">
                                                                                                <div className="col">
                                                                                                    {this.state.list_pending_mandate.filter(
                                                                                                        (obj) => {
                                                                                                            return (
                                                                                                                obj.is_selected ===
                                                                                                                true
                                                                                                            );
                                                                                                        }
                                                                                                    ).length === 0 &&
                                                                                                        this.state
                                                                                                            .nach_option ===
                                                                                                        "new" && (
                                                                                                            <Button
                                                                                                                className="btn btn-success btn-block"
                                                                                                                variant="success"
                                                                                                                disabled={
                                                                                                                    (this.state
                                                                                                                        .mandate_date_validation[
                                                                                                                        "message_text"
                                                                                                                    ].length > 15 &&
                                                                                                                        !this.state
                                                                                                                            .is_first_order_today) ||
                                                                                                                    this.state.isloading
                                                                                                                }
                                                                                                                onClick={
                                                                                                                    this
                                                                                                                        .onRegisterMandate
                                                                                                                }
                                                                                                            >
                                                                                                                Register Mandate
                                                                                                            </Button>
                                                                                                        )}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )}

                                                                                {this.state
                                                                                    .nach_option ===
                                                                                    "new" &&
                                                                                    this.state
                                                                                        .list_pending_mandate
                                                                                        .length > 0 && (

                                                                                        <div class="row">
                                                                                            <div class="col-5">
                                                                                                <div>
                                                                                                    {" "}
                                                                                                    Choose Mandate
                                                                                                    <Select
                                                                                                        value={this.state.list_pending_mandate.find(
                                                                                                            (obj) =>
                                                                                                                obj.is_selected ===
                                                                                                                true
                                                                                                        )}
                                                                                                        onChange={(e) =>
                                                                                                            this.handleMandateIdSelect(
                                                                                                                e
                                                                                                            )
                                                                                                        }
                                                                                                        options={
                                                                                                            this.state
                                                                                                                .list_pending_mandate
                                                                                                        }
                                                                                                        isDisabled={
                                                                                                            this.state
                                                                                                                .nach_option_new_processed
                                                                                                        }
                                                                                                        isSearchable={
                                                                                                            false
                                                                                                        }
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-7">
                                                                                                <br />
                                                                                                <Button
                                                                                                    className="btn btn-warning btn-block"
                                                                                                    variant="success"
                                                                                                    onClick={(e) =>
                                                                                                        this.setState({
                                                                                                            show_confirm_popup: true,
                                                                                                            confirm_popup_messageText: `Are you sure want to cancel the this mandate ?`,
                                                                                                            confirm_popup_request_mode: `cancel mandate`,
                                                                                                            confirm_popup_mandate_info: this.state.list_pending_mandate[0]["mandate_id"]
                                                                                                        })
                                                                                                    }
                                                                                                    disabled={this.state.isloading}
                                                                                                >
                                                                                                    Cancel Mandate
                                                                                                </Button>
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                            </div>

                                                                            {this.state
                                                                                .nach_option ===
                                                                                "new" &&
                                                                                <div>
                                                                                    <div class="col">
                                                                                        <p></p>
                                                                                        <span style={{ fontWeight: 'bold' }}>Note {` : `}</span>
                                                                                        {
                                                                                            `Mandate amount limit is checked for each transaction
                                                                                                    and not sum total of transaction. For example: If your Mandate amount is Rs. 25,000,
                                                                                                    you can set up as many Systematic Plans you want using this mandate however the transaction amount of
                                                                                                    each of such Systematic Plan (+ charges if any) should be less than Rs 25,000.`
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            }

                                                                            <div class="row">
                                                                                <div class="col">
                                                                                    <Form.Group
                                                                                        className="mb-3"
                                                                                        controlId="formBasicCheckbox"
                                                                                    >
                                                                                        {this.state
                                                                                            .mandate_date_validation[
                                                                                            "message_text"
                                                                                        ].length > 15 && (
                                                                                                <div>
                                                                                                    <p
                                                                                                        style={{
                                                                                                            color: "red",
                                                                                                        }}
                                                                                                    >
                                                                                                        {
                                                                                                            this.state
                                                                                                                .mandate_date_validation[
                                                                                                            "message_text"
                                                                                                            ]
                                                                                                        }
                                                                                                    </p>

                                                                                                    <Form.Check
                                                                                                        type="checkbox"
                                                                                                        label="Is First Order Today"
                                                                                                        onChange={(e) =>
                                                                                                            this.setState(
                                                                                                                {
                                                                                                                    is_first_order_today:
                                                                                                                        !this
                                                                                                                            .state
                                                                                                                            .is_first_order_today,
                                                                                                                }
                                                                                                            )
                                                                                                        }
                                                                                                        defaultChecked={
                                                                                                            this.state
                                                                                                                .is_first_order_today
                                                                                                        }
                                                                                                        disabled={true}
                                                                                                    />
                                                                                                </div>
                                                                                            )}
                                                                                    </Form.Group>

                                                                                </div>
                                                                            </div>

                                                                            {this.state.nach_option === "new" &&
                                                                                this.state
                                                                                    .is_mandate_registered &&
                                                                                !this.state
                                                                                    .nach_option_new_processed &&
                                                                                (
                                                                                    <div >

                                                                                        <div class="row">
                                                                                            {
                                                                                                this.state
                                                                                                    .src_nach_form
                                                                                                    .length > 255 &&
                                                                                                <div class="col">
                                                                                                    <div style={{ marginLeft: -40 }}>
                                                                                                        <u>Your Mandate File Sample</u>
                                                                                                        <PDFReader
                                                                                                            data={atob(
                                                                                                                this.state
                                                                                                                    .src_nach_form
                                                                                                            )}
                                                                                                            width="350" height="350"
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            }
                                                                                        </div>

                                                                                        {this.state.is_enach_mandate ?
                                                                                            <div class="row">
                                                                                                <Button
                                                                                                    className="btn btn-success btn-block"
                                                                                                    variant="success"
                                                                                                    onClick={(e) =>
                                                                                                        this.eNachmandatePage(
                                                                                                            `enach authentication`,
                                                                                                            this.state
                                                                                                                .list_pending_mandate[0]
                                                                                                        )
                                                                                                    }
                                                                                                    disabled={this.state.isloading}
                                                                                                >
                                                                                                    Authenticate e-NACH
                                                                                                </Button>
                                                                                            </div>
                                                                                            :
                                                                                            <div class="row">

                                                                                                <p
                                                                                                    style={{
                                                                                                        textAlign: "left",
                                                                                                    }}
                                                                                                >
                                                                                                    <b>Step 1</b>.
                                                                                                    {this.state.list_pending_mandate[0]['is_download_url_new_window'] === true ?
                                                                                                        <button type="button"
                                                                                                            className="btn-sm btn-primary"
                                                                                                            style={{ color: 'white', width: '140px' }}
                                                                                                            onClick={() => {
                                                                                                                this.downloadNachForm(this.state.list_pending_mandate[0]['download_url'], 4500);
                                                                                                            }}
                                                                                                        >
                                                                                                            Download Mandate
                                                                                                        </button>
                                                                                                        :
                                                                                                        <a
                                                                                                            className="btn btn-success btn-block"
                                                                                                            variant="success"
                                                                                                            download={
                                                                                                                this.state.list_pending_mandate[0]["mandate_id"] + `.pdf`
                                                                                                            }
                                                                                                            href={this.state.list_pending_mandate[0]['download_url']}
                                                                                                            onClick={() => {
                                                                                                                this.setState({ isloading: true });
                                                                                                                setTimeout(() => { this.setState({ isloading: false }); }, 4500)
                                                                                                            }
                                                                                                            }
                                                                                                        >
                                                                                                            Download Mandate
                                                                                                        </a>
                                                                                                    }

                                                                                                </p>
                                                                                                <p
                                                                                                    style={{
                                                                                                        textAlign: "left",
                                                                                                    }}
                                                                                                >
                                                                                                    <b>Step 2</b>. Put your signature

                                                                                                </p>
                                                                                                <p
                                                                                                    style={{
                                                                                                        textAlign: "left",
                                                                                                    }}
                                                                                                >
                                                                                                    <b>Step 3</b>. Scan or Take Photo in png/jpeg format

                                                                                                </p>
                                                                                                <p
                                                                                                    style={{
                                                                                                        textAlign: "left",
                                                                                                    }}>
                                                                                                    Upload without changing the file name to complete the
                                                                                                    registration.
                                                                                                </p>
                                                                                                <p
                                                                                                    style={{
                                                                                                        textAlign: "left",
                                                                                                    }}
                                                                                                >
                                                                                                    For eg. file name
                                                                                                    should be strictly
                                                                                                    as
                                                                                                    <span style={{ fontWeight: 'bold', color: 'darkgreen' }}>{ //this.state.selected_mandate_id +
                                                                                                        ` ` + this.state
                                                                                                            .list_pending_mandate[0]["mandate_id"]
                                                                                                        + `.png`}</span>
                                                                                                    {` `}
                                                                                                    <span style={{ textAlign: 'center' }}>or</span>
                                                                                                    <span style={{ fontWeight: 'bold', color: 'darkgreen' }}>
                                                                                                        {` ` + this.state
                                                                                                            .list_pending_mandate[0]["mandate_id"] +
                                                                                                            ".jpeg"}{` `}
                                                                                                    </span>
                                                                                                </p>
                                                                                                <Form.Control
                                                                                                    type="file"
                                                                                                    onChange={
                                                                                                        this.changeHandler
                                                                                                    }
                                                                                                />

                                                                                                {this.state
                                                                                                    .src_nachmandateFile && (
                                                                                                        <div class="row">
                                                                                                            <div class="col-4">
                                                                                                                <img
                                                                                                                    width="150"
                                                                                                                    src={
                                                                                                                        this.state[
                                                                                                                        "src_nachmandateFile"
                                                                                                                        ]
                                                                                                                    }
                                                                                                                />
                                                                                                            </div>
                                                                                                            <div class="col-4">
                                                                                                                <br />
                                                                                                                <Button
                                                                                                                    className="btn btn-success btn-sm"
                                                                                                                    variant="success"
                                                                                                                    onClick={() => {
                                                                                                                        this.onUploadMandate('main');
                                                                                                                    }
                                                                                                                    }
                                                                                                                    disabled={this.state.isloading}
                                                                                                                >
                                                                                                                    Upload Mandate
                                                                                                                </Button>
                                                                                                            </div>

                                                                                                        </div>
                                                                                                    )}
                                                                                            </div>
                                                                                        }

                                                                                        <div class="row">
                                                                                            <Button
                                                                                                className="btn btn-warning btn-block"
                                                                                                variant="success"
                                                                                                onClick={(e) =>

                                                                                                    this.setState({
                                                                                                        show_confirm_popup: true,
                                                                                                        confirm_popup_messageText: `Are you sure want to cancel the this mandate ?`,
                                                                                                        confirm_popup_request_mode: `cancel mandate`,
                                                                                                        confirm_popup_mandate_info: this.state.list_pending_mandate[0]["mandate_id"]
                                                                                                    })
                                                                                                }
                                                                                                disabled={this.state.isloading}
                                                                                            >
                                                                                                Cancel Mandate
                                                                                            </Button>
                                                                                        </div>

                                                                                    </div>
                                                                                )}
                                                                        </div>
                                                                    )}
                                                            </div>
                                                        )}
                                                    </div>
                                                    {/* </Form.Group>
                                                    } */}

                                                    {this.state.render_pdf !== null && (
                                                        <PDFReader
                                                            data={this.state.render_pdf}
                                                            width="100px"
                                                            height="100px"
                                                        />
                                                    )}

                                                    {((this.state.nach_option === "existing" &&
                                                        this.state.is_mandate_selected) ||
                                                        (this.state.nach_option === "new" &&
                                                            this.state.nach_option_new_processed)) && (
                                                            <div class="row" style={{ margin: 20 }}>
                                                                <div class="col-lg-12">
                                                                    {/* {this.state.nach_option === "new" && ( */}
                                                                    <h5 className="card-title">
                                                                        {
                                                                            this.state
                                                                                .nach_option_new_processed_message_text
                                                                        }
                                                                    </h5>
                                                                    {/* )} */}


                                                                </div>
                                                            </div>
                                                        )}

                                                    {/* </div> */}
                                                </div>

                                                {/* </div>
                  </div> */}

                                            </div>
                                        }



                                        {this.state
                                            .show_popup && (
                                                <Modal
                                                    show={
                                                        this.state
                                                            .show_popup
                                                    }
                                                    onHide={() =>
                                                        this.closePopup()
                                                    }
                                                    className="modal-fullscreen-sm-down"
                                                >
                                                    <Modal.Header></Modal.Header>
                                                    <Modal.Body>
                                                        <p>
                                                            {
                                                                this.state.popup_messageText
                                                            }
                                                        </p>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <Button
                                                            variant="primary"
                                                            onClick={() =>
                                                                this.closePopup()
                                                            }
                                                        >
                                                            Close
                                                        </Button>
                                                    </Modal.Footer>
                                                </Modal>
                                            )}

                                        <Modal
                                            show={
                                                this.state
                                                    .show_upload_popup &&
                                                this.state
                                                    .modal_upload_mandate['mandate_id']
                                            }
                                            onHide={() => {
                                                this.setState({
                                                    modal_upload_mandate: {},
                                                    show_upload_popup: false,
                                                    modal_src_nach_form: ''
                                                });
                                            }
                                            }
                                        >
                                            <Modal.Header></Modal.Header>
                                            <Modal.Body>

                                                {this.state.isloading && <Spinner
                                                    animation="border"
                                                    variant="dark"
                                                    style={{ position: "fixed", top: "50%", left: "50%" }}
                                                />
                                                }

                                                <div class="row" style={{ marginLeft: -40 }}>

                                                    {this.state
                                                        .modal_src_nach_form
                                                        .length > 255 &&
                                                        <PDFReader
                                                            data={atob(
                                                                this.state
                                                                    .modal_src_nach_form
                                                            )}
                                                            width="450"
                                                        />
                                                    }

                                                </div>



                                                <div >
                                                    <div class="row">
                                                        <div style={{ marginTop: 10 }} class="col-3">
                                                            <b>Step 1</b>.
                                                        </div>
                                                        <div class="col">
                                                            {
                                                                this.state.is_nach_form_download_url_new_window === true ?
                                                                    <button type="button"
                                                                        className="btn-sm btn-primary"
                                                                        style={{ color: 'white', width: '140px' }}
                                                                        onClick={() => {
                                                                            this.downloadNachForm(this.state.nach_form_download_url, 4500);
                                                                        }}
                                                                    >
                                                                        Download Mandate
                                                                    </button>
                                                                    :
                                                                    <a
                                                                        className="btn btn-success btn-block"
                                                                        variant="success"
                                                                        download={
                                                                            this.state.modal_selected_mandate_id + `.pdf`
                                                                        }
                                                                        disabled={this.state.isloading}
                                                                        href={this.state.nach_form_download_url}
                                                                        onClick={() => {
                                                                            this.setState({ isloading: true });
                                                                            setTimeout(() => { this.setState({ isloading: false }); }, 4500)
                                                                        }
                                                                        }
                                                                    >
                                                                        Download Mandate
                                                                    </a>
                                                            }
                                                        </div>
                                                    </div>

                                                    <p
                                                        style={{
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        <b>Step 2</b>. Put your signature above your name
                                                    </p>
                                                    <p>
                                                        <b>Step 3</b>. Scan or Take photo & save as png/jpeg format.
                                                        Do not tilt the image while uploading.
                                                    </p>
                                                    <p
                                                        style={{
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        File name should be strictly as {` `}
                                                        <span style={{ fontWeight: 'bold', color: 'darkgreen' }}>{ //this.state.selected_mandate_id +
                                                            ` ` + this.state.modal_upload_mandate['mandate_id'] + `.png`}</span>
                                                        {` `}
                                                        or
                                                        <span style={{ fontWeight: 'bold', color: 'darkgreen' }}>
                                                            {` ` + this.state.modal_upload_mandate['mandate_id'] + ".jpeg"}{` `}
                                                        </span>
                                                    </p>
                                                    <Form.Control
                                                        type="file"
                                                        onChange={
                                                            this.changeHandlerModal
                                                        }
                                                    />

                                                </div>

                                                {this.state
                                                    .src_modal_nachmandateFile && (
                                                        <div class="row">
                                                            <div class="col-6">
                                                                <img
                                                                    width="150"
                                                                    src={
                                                                        this.state[
                                                                        "src_modal_nachmandateFile"
                                                                        ]
                                                                    }
                                                                />
                                                            </div>
                                                            <div class="col-6">
                                                                <br />
                                                                <Button
                                                                    className="btn btn-success btn-sm"
                                                                    variant="success"
                                                                    onClick={() => {
                                                                        this.onUploadMandate('modal');
                                                                    }
                                                                    }
                                                                    disabled={this.state.isloading}
                                                                >
                                                                    Upload Mandate
                                                                </Button>
                                                            </div>

                                                        </div>
                                                    )}

                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button
                                                    variant="primary"
                                                    onClick={() => {
                                                        this.setState({
                                                            modal_upload_mandate: {},
                                                            show_upload_popup: false
                                                        });
                                                    }
                                                    }
                                                >
                                                    Close
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>

                                        <Modal show={this.state.show_confirm_popup} centered>
                                            <Modal.Header>
                                                <Modal.Title></Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                {this.state.confirm_popup_messageText}
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={() => {
                                                    if (this.state.confirm_popup_request_mode === 'cancel mandate')
                                                        this.onCancelMandate(
                                                            this.state.confirm_popup_mandate_info
                                                        )
                                                }}>
                                                    Yes
                                                </Button>
                                                <Button variant="primary" onClick={() => {
                                                    this.setState({
                                                        show_confirm_popup: false,
                                                        confirm_popup_messageText: ``,
                                                        confirm_popup_request_mode: ``,
                                                        confirm_popup_mandate_info: {}
                                                    });
                                                }}>
                                                    No
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>

                                        {this.state.wizard_step > 0 &&
                                            <Fab
                                                style={{ bottom: 27, left: -10, transform: 'scale(0.8)' }}
                                                alwaysShowTitle={true}
                                                mainButtonStyles={{
                                                    backgroundColor: "#d45950"
                                                }}
                                                icon={<i className="fa fa-arrow-left  fa-lg" ></i>}
                                                onClick={() => {
                                                    if (this.state.wizard_step > 1) {
                                                        this.setState({
                                                            wizard_step: this.state.wizard_step - 1
                                                        });
                                                    }
                                                    else
                                                        this.props.history.goBack();
                                                }
                                                }
                                            >
                                            </Fab>
                                        }
                                        {(this.state.wizard_step == 1 ||
                                            (this.state.wizard_step === 3 && this.state.is_mandate_selected)) &&
                                            this.state.nach_option === "existing" &&
                                            <Fab
                                                style={{ bottom: 27, right: -10, transform: 'scale(0.8)' }}
                                                // event="hover"
                                                alwaysShowTitle={true}
                                                mainButtonStyles={{
                                                    backgroundColor: "#3d61cc"
                                                }}
                                                icon={<i className="fa fa-arrow-right  fa-lg" ></i>}
                                                onClick={() => this.setState({
                                                    wizard_step: this.state.wizard_step + 1
                                                })}
                                            >
                                            </Fab>
                                        }

                                    </div>

                                </div>
                            }
                        </div>
                    </div>
                </div >
            </>
        );
    }

}
// OrderCheckout.propTypes = {
//     validateOtp: PropTypes.func.isRequired,
//     isAuthenticated: PropTypes.bool
// };

/*
const mapStateToProps = state => ({
    loading: state.order.loading,
    order_result: state.order.order_result
});
*/

const mapStateToProps = ({ order, auth }) => {
    const { loading, order_result } = order;
    const { payment_initiate_result } = auth;
    return {
        loading,
        order_result,
        payment_initiate_result
    };
};

export default connect(mapStateToProps, {
    createSipOrder,
    uploadMandate,
    registerMandate,
    loadBankAccountList,
    loadNachMandateForm,
    refreshMandateStatus,
    cancelMandate,
    downloadMandate,
    eNachMandate
})(MandateList);