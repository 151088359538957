import React, { TouchableOpacity } from 'react';
import { Link } from 'react-router-dom';

import { Navbar, Spinner, DropdownButton } from 'react-bootstrap';
import Emitter from '../utils/emitter';
import { connect } from "react-redux";
import { logout } from "../../jsx/actions/auth";
import PropTypes from "prop-types";

class Header1 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isloading: false,
            masterList: JSON.parse(localStorage.getItem("profileData") || JSON.stringify({}))
        };

    }

    componentDidMount() {

        Emitter.once('HEADER1', (data) => {
            this.setState({ masterList: typeof data === 'string' ? JSON.parse(data) : data });
        });

    }

    onLogout = () => {

        this.setState({ isloading: true });

        this.props.logout({ 
            device_id: localStorage.getItem(`mobile_device_id`) || new window.DeviceUUID().get() 
          })
            .then((response) => {

                this.setState({ isloading: false });
                
                localStorage.removeItem('token');
                localStorage.removeItem('profileData');
                localStorage.removeItem('masterList');

                // this.props.history.push({ 
                //     pathname: '/'+this.props.login_result['navigateScreen']+'/',
                //     state: {}
                // }); 

                // window.location = this.props.login_result['navigateScreen'];
                window.location.replace(window.location.origin + '/signin/loggedout');
                /*
                this.props.history.push({ 
                    pathname: this.props.login_result['navigateScreen'] + '/loggedout' // `/signin/loggedout'`
                });
                */
            })
            .catch((err) => {

                this.setState({ isloading: false });

                localStorage.removeItem('token');
                localStorage.removeItem('profileData');
                localStorage.removeItem('masterList');

                window.location.replace(window.location.origin + '/signin'); //window.location = 'signin';

            });

        /*
        localStorage.removeItem('token'); 
        localStorage.removeItem('profileData'); 
        localStorage.removeItem('masterList'); 
        
        this.props.history.push({ 
            pathname: '/'
        });
        */
    };

    // function Header2(props) {

    render() {

        return (
            <>
                <div className="header landing_page" style={{ boxShadow: 0 }}>
                    <div className="container">
                        {this.state.isloading && <Spinner
                            animation="border"
                            variant="dark"
                            style={{ position: "fixed", top: "50%", left: "50%" }}
                        />
                        }
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="navigation">
                                    <Navbar expand="lg">
                                        <Link className="navbar-brand" to={'/home'}>
                                            <img src={require('./../../images/logo.png')} alt="" width="115px" />

                                        </Link>

                                        <div className="header_auth">
                                            <div className="header-right">
                                                {this.state.masterList &&
                                                    this.state.masterList['login_mobile_number'] ?
                                                    <DropdownButton
                                                        alignRight
                                                        title={this.state.masterList["login_mobile_number"]}
                                                        variant="success"
                                                        className="profile_log"
                                                    >
                                                        <Link to={'/esignkycVerification'} className="dropdown-item">
                                                            <i className="la la-user"></i> My KYC
                                                        </Link>
                                                        <Link to={'/mandateList'} className="dropdown-item">
                                                            <i className="mdi mdi-bank"></i> Mandate List
                                                        </Link>
                                                        <Link to={'/bankaccountList'} className="dropdown-item">
                                                            <i className="mdi mdi-bank"></i> Bank Account
                                                        </Link>
                                                        <Link to={'/mpinInformation'} className="dropdown-item">
                                                            <i className="la la-book"></i> mPin
                                                        </Link>
                                                        <Link to={'/customerSupport'} className="dropdown-item">
                                                            <i className="la la-phone"></i> Support
                                                        </Link>
                                                        {(this.state.masterList["login_category"] || ``) === 'admin' && 
                                                            <Link to={'/clientList'} className="dropdown-item">
                                                                <i className="la la-book"></i> Client List
                                                            </Link>
                                                        }
                                                        {(this.state.masterList["login_category"] || ``) === 'admin' && 
                                                            <Link to={'/dailyUpload'} className="dropdown-item">
                                                                <i className="la la-book"></i> Daily Upload
                                                            </Link>
                                                        }
                                                        {/* <Link to={'/settings'} className="dropdown-item">
                                                    <i className="la la-cog"></i> Setting
                                                </Link>
                                                <Link to={'/lock'} className="dropdown-item">
                                                    <i className="la la-lock"></i> Lock
                                                </Link> */}
                                                        {/* <Link to='/signin' className="dropdown-item logout">
                                                    <i className="la la-sign-out"></i> Logout
                                                </Link> */}

                                                        <a className="dropdown-item logout" key={7}
                                                            onClick={() => { this.onLogout() }}>
                                                            <i className="la la-sign-out"></i> Logout
                                                        </a>

                                                    </DropdownButton>
                                                    :
                                                    <Link to={"/signin"} class="btn btn-success">Sign in</Link>
                                                }
                                            </div>
                                        </div>
                                        {/* </Navbar.Collapse> */}

                                    </Navbar>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}

Header1.propTypes = {
    // login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,

};

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    login_result: state.auth.login_result,
    loading: state.master.loading,
});

export default connect(mapStateToProps, { logout })(Header1);