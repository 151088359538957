import {
    LIST_SCHEME

  } from '../actions/types';
  
  const initialState = {
    token: localStorage.getItem('token'),
    isAuthenticated: null,
    loading: true,
    result: null

  };
  
  function masterReducer(state = initialState, action) {

    const { type, payload } = action;

    switch (type) {
      
      case LIST_SCHEME:
          return {
            ...state,
            result: payload,
            isAuthenticated: true,
            loading: false
          };
      default:
        return state;
       
    }
   
  }
  
  export default masterReducer;
  