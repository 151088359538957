import {
  GENERAL_RESULT_LOAD,
  GENERAL_RESULT_SUCCESS,
  GENERAL_RESULT_FAIL

  } from '../actions/types';
  
  const initialState = {
    token: localStorage.getItem('token'),
    loading: false,
    general_result: null
  };
  
  function generalReducer(state = initialState, action) {

    const { type, payload } = action;

    switch (type) {

      case GENERAL_RESULT_LOAD:
        return {
          ...state,
          loading: true
        };

      case GENERAL_RESULT_SUCCESS:
        return {
          ...state,
          general_result: payload,
          loading: false
        };
      
      case GENERAL_RESULT_FAIL:
        return {
          ...state,
          general_result: payload,
          loading: false
        };

      default:
        return state;
       
    }
   
  }
  
  export default generalReducer;
  