import React, { } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { connect } from "react-redux";
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { loadPaymentResponse } from '../../actions/orders';
import { Redirect } from 'react-router-dom';
import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";
import Spinner from "../../layout/spinner";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";

class PaymentResponse extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            is_initiated: true,
            is_failed: false,
            is_redirect: false,
            loading: false,
            route_parameters: null,
            notification_html: `<html><head><title>Redirecting to Bank</title>
            <style>
                .bodytxt4 {font-family: Verdana, Arial, Helvetica, sans-serif;font-size: 12px;font-weight: bold;color: #666666;}
                .bodytxt {font-family: Verdana, Arial, Helvetica, sans-serif;font-size: 13px;font-weight: normal;color: #000000;}
                .bullet1 {list-style-type:square;list-style-position: inside;list-style-image: none;font-family: Verdana, Arial, Helvetica, sans-serif;font-size: 10px;font-weight: bold;color: #FF9900;}
                .bodytxt2 {font-family: Verdana, Arial, Helvetica, sans-serif;font-size: 8pt;font-weight: normal;color: #333333;}A.sac2 {COLOR: #000000;font-family: Verdana, Arial, Helvetica, sans-serif;font-size: 10px;font-weight: bold;text-decoration: none;}A.sac2:visited {COLOR: #314D5A; TEXT-DECORATION: none}A.sac2:hover {COLOR: #FF9900; TEXT-DECORATION: underline}
            </style>
        </head>
        <table width="100%" border="0" cellspacing="0" cellpadding="0">  
            <tr>
                <td align="left" valign="top">
                    <table width="100%" border="0" cellspacing="0" cellpadding="0">
                        <tr>
                           <td align="center" valign="middle">
                               <table width="100%" border="0" cellspacing="0" cellpadding="0">   
                                    <tr>
                                        <td  align="center"></td>  
                                    </tr>  
                                    <tr>
                                        <td height="85" align="center"><br>
                                            <table width="80%" border="0" cellpadding="0" cellspacing="1" bgcolor="#CCCCCC">
                                                <tr>  
                                                    <td bgcolor="#CCCCCC"><table width="100%" border="0" cellpadding="6" cellspacing="0" bgcolor="#FFFFFF">  
                                                        <tr> 
                                                            <td colspan="2" align="left" valign="bottom"><span class="bodytxt4">We are fetching your recent payment status ......</span></td>  
                                                        </tr>  
                                                        <tr valign="top"> 
                                                            <td colspan="2" align="left">
                                                                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                                    <tr>   
                                                                        <td width="87%" bgcolor="#cccccc" height="1" align="center"></td>
                                                                    </tr>
                                                                </table>
                                                            </td>  
                                                        </tr>  
                                                        <tr>
                                                            <td width="60%" align="left" valign="bottom"><table width="95%" border="0" cellpadding="1" cellspacing="0" bgcolor="#FFFFFF">
                                                                <tr>   
                                                                    <td align="right" valign="top"></td>  
                                                                    <td class="bodytxt">&nbsp;</td>
                                                                </tr>
                                                            <tr>   
                                                                <td align="right" valign="top"> <li class="bullet1"></li></td>  
                                                                <td class="bodytxt2" >When you submit the transaction, the server will take about 1 to 5 seconds to process, but it may take longer at certain times. </td>
                                                            </tr>
                                                            <tr>   
                                                                <td align="right" valign="top"><li class="bullet1"></li></td>  
                                                                <td class="bodytxt2" >Please do not press "Back" or "Refresh" buttons. </td>
                                                            </tr>  
                                            </table>
                                        </td>
                                        <td align="right" valign="bottom">
                                            <table width="80%" border="0" cellpadding="1" cellspacing="0" bgcolor="#FFFFFF">
                                                <tr bgcolor="#FFFCF8">   
                                                    <td align="right" bgcolor="#FFFFFF"></td>
                                                </tr>
                                                <tr bgcolor="#FFFCF8">   
                                                    <td align="right" valign="middle" bgcolor="#FFFFFF" class="bodytxt2">&nbsp;</td>
                                                </tr>
                                                <tr bgcolor="#FFFCF8">
                                                    <td align="right" bgcolor="#FFFFFF" class="bodytxt2" >&nbsp;</td>
                                                </tr>  
                                            </table>
                                        </td>  
                                    </tr>
                                </table>
                            </td>
                        </tr>  
                    </table>  
                </td>  
            </tr>
        </table>   
        
        </td></tr></table></td>  </tr>  </table>`
        };

    }

    componentDidMount() {

        this.onLoadPaymentResponse();
    }

    onLoadPaymentResponse = () => {

        // order_type = order_type === 0 ? 'single' : (order_type === 1 ? 'sip' : 'xsip')

        if(localStorage.getItem("recent_payment_order_number")) {

            let order_number = localStorage.getItem("recent_payment_order_number");
            localStorage.removeItem("recent_payment_order_number")
            this.props.loadPaymentResponse({
                request_from: 'paymentResponse',
                request_mode: 'order_payment_status',
                order_number: order_number
            }).then(response => {

                if (this.props.order_result['code'] !== '000') {
                    this.setState({
                        is_initiated: false,
                        is_failed: true
                    });
                }
                else {

                    if (this.props.order_result['notification_html']) {
                        this.setState({
                            is_initiated: false,
                            is_redirect: true,
                            'notification_html': this.props.order_result['notification_html']
                        });
                    }

                }

            })
            .catch(err => {
                alert(err)
            });

        }
        else
            this.setState({
                is_failed: true,
                is_initiated: false
            });

    };

    createDefaultMarkup(text) {
        return {
            __html: this.state.notification_html,
        }
    };

    gotoOrderListPage() {

        let pageName = 'orderList'; //(request_mode === 'SINGLE' ? 'orderList' : 'sipList') ;

        this.props.history.push({
            pathname: '/' + pageName + '/'
        });

    }

    render() {

        // if(this.props.loading)
        //   return <Spinner />;

        // if(this.props.order_result && this.props.order_result['esignkyc_stage_verified'])
        //    return <Redirect to={this.props.aadhaar_result['navigateScreen']} />; 

        return (
            <>
                <Header1 />
                {/* <Sidebar /> */}
                {this.props.loading && <Spinner animation="border" variant="dark"
                    style={{ position: "fixed", top: "50%", left: "50%" }} />}
                <div className="authincation section-padding">
                    <div className="container h-100">
                        <div className="row justify-content-center h-100 align-items-center">
                            <div className="col-xl-8 col-md-12">

                                {this.state.is_failed &&
                                    <div className="row"  style={{ marginTop: 160 }}>
                                        <div className="col-lg-10" style={{ margin: 40 }}>
                                            <Card sx={{ backgroundColor: '#edf3f0', p: 2, borderRadius: 2 }}>
                                                <CardContent  >
                                                    <Stack direction="row">
                                                        <Typography flex={1} whiteSpace="pre-line" >
                                                            Sorry the information you are looking for could not be found
                                                            <p></p>
                                                            <Link className="page-back text-muted" to={'/orderList'}><span><i
                                                                className="fa fa-angle-left"></i></span> Back</Link>
                                                            {'  '}Go to Order List
                                                        </Typography>
                                                    </Stack>
                                                </CardContent>
                                            </Card>
                                        </div>
                                    </div>
                                }

                                {(this.state.is_initiated || this.state.is_redirect) &&
                                    <div className="auth-form card">
                                        <div className="card-body" style={{ marginTop: 160 }}>
                                            <form action="#">
                                                <h4 className="text-center">Your Payment Status</h4>
                                                <br></br>
                                                <div class="row">
                                                    <div dangerouslySetInnerHTML={this.createDefaultMarkup()} />
                                                </div>
                                                {
                                                    this.state.is_redirect && 
                                                    <div class="row">
                                                    <Button
                                                className="btn btn-success btn-block"
                                                variant="success"
                                                onClick={() => this.gotoOrderListPage()}
                                              >
                                                Ok, Go to Order List
                                              </Button>
                                                    </div>
                                                }
                                            </form>
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </>
        )

    }

}

const mapStateToProps = ({ order }) => {

    const { loading, order_result } = order;
    return {
        loading,
        order_result
    }

};

export default connect(mapStateToProps, { loadPaymentResponse })
    (PaymentResponse);