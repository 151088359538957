

import React, { } from 'react';
import { eNachMandate } from '../../../jsx/actions/auth';
import { connect } from "react-redux";
import { refreshMandateStatus, } from "../../actions/orders";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";

class ENachMandatePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show_nothing: false,
            show_html: false,
            message_text: '',
            html: `<html><head><title>Redirecting to Bank</title>
            <style>
                .bodytxt4 {font-family: Verdana, Arial, Helvetica, sans-serif;font-size: 12px;font-weight: bold;color: #666666;}
                .bodytxt {font-family: Verdana, Arial, Helvetica, sans-serif;font-size: 13px;font-weight: normal;color: #000000;}
                .bullet1 {list-style-type:square;list-style-position: inside;list-style-image: none;font-family: Verdana, Arial, Helvetica, sans-serif;font-size: 10px;font-weight: bold;color: #FF9900;}
                .bodytxt2 {font-family: Verdana, Arial, Helvetica, sans-serif;font-size: 8pt;font-weight: normal;color: #333333;}A.sac2 {COLOR: #000000;font-family: Verdana, Arial, Helvetica, sans-serif;font-size: 10px;font-weight: bold;text-decoration: none;}A.sac2:visited {COLOR: #314D5A; TEXT-DECORATION: none}A.sac2:hover {COLOR: #FF9900; TEXT-DECORATION: underline}
            </style>
        </head>
        <table >
                <tr>  
                    <td ><table >  
                        <tr> 
                            <td colspan="2" align="left" valign="bottom"><span class="bodytxt4">Your payment request is being processed...</span></td>  
                        </tr>  
                        <tr valign="top"> 
                            <td colspan="2" align="left">
                                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                    <tr>   
                                        <td width="87%"  height="1" align="center"></td>
                                    </tr>
                                </table>
                            </td>  
                        </tr>  
                        <tr>
                            <td width="60%" align="left" valign="bottom"><table>
                                <tr>   
                                    <td align="right" valign="top"></td>  
                                    <td class="bodytxt">&nbsp;</td>
                                </tr>
                            <tr>   
                                <td height="19"  align="right" valign="top">
                                    <li class="bullet1"></li>
                                </td>  
                                <td class="bodytxt2">This is a secure payment gateway using 128 bit SSL encryption.</td>
                            </tr>
                            <tr>   
                                <td align="right" valign="top"> <li class="bullet1"></li></td>  
                                <td class="bodytxt2" >When you submit the transaction, the server will take about 1 to 5 seconds to process, but it may take longer at certain times. </td>
                            </tr>
                            <tr>   
                                <td align="right" valign="top"><li class="bullet1"></li></td>  
                                <td class="bodytxt2" >Please do not press "Submit" button once again or the "Back" or "Refresh" buttons. </td>
                            </tr>  
            </table>
        </td></tr></table></td>  </tr>  </table>`,
            is_redirect: false,
            route_parameters: null,
            arr_input: [],
            input_html: ``,
            actionUrl: '',
            enach_response_html: `<html><head><title>eNACH Mandate Authentication Response</title>
                <style>
                    .bodytxt {font-family: Verdana, Arial, Helvetica, sans-serif;font-size: 15px;font-weight: normal;color: #000000;}
                </style>
                </head>
                <span class="bodytxt">` + `#result_html#` + `</span>`
        };
        this.form = null
    }

    async componentDidMount() {

        let _query_params = (window.location.href || ``).split('?');

        if (_query_params.length > 1 /*&& localStorage.getItem('recent_enach_mandate_id')*/) 
        {        
            let _mandate_id = (_query_params[0] || ``).split('*')[1];

            _query_params = _query_params[1];
            let enach_response = _query_params.split('&'); //(this.props.match.params['enach_response'] || ``).split('&');

            let _enach_bse_digio_response = {};
            let _html_response = ``;
            let _split = [];

            enach_response.forEach(el => {

                _split = el.split('=');
                _enach_bse_digio_response[(_split[0] || ``).toString().toLowerCase()] = _split[1];

                _html_response += `<p><b>` + _split[0].toUpperCase() + `</b> : ` + (_split[1] || ``).replace(new RegExp('%20', 'g'), ' ') + `</p><br/>`;

            });

            if(_enach_bse_digio_response['status'] && 
                    (_enach_bse_digio_response['status'] || ``).toLowerCase().indexOf('success') > -1)
            {
                    this.props
                    .refreshMandateStatus({ 
                        enach_bse_digio_response: _enach_bse_digio_response,
                        mandate_id: localStorage.getItem('recent_enach_mandate_id') || (_mandate_id || ``) 
                    })
                    .then((response) => { })
                    .catch((err) => {
                        console.log("line 168 :");
                        console.log(err);
                    });
                }

            _html_response = this.state.enach_response_html.replace(new RegExp('#result_html#', 'g'), _html_response);

            this.setState({
                show_nothing: false,
                is_redirect: true,
                input_html: _html_response,
                show_html: true,
                message_text: ``
            });

            localStorage.removeItem('recent_enach_mandate_id');
            // this.props.history.replace('', null);
        }
        else {
            this.setState({
                show_nothing: true,
                show_html: false,
                message_text: ``
            });
        }

    }

    gotoCheckOutPage(request_mode, selected_order) {

        this.setState({ isloading: true });

        if (
            selected_order["order_list"] &&
            selected_order["order_list"].length > 0
        ) {
            // selected_order['order_list'] = selected_order['order_list'][0]['order_list'];
            selected_order["order_information"] =
                selected_order["order_list"][selected_order["order_list"].length - 1];
        }

        selected_order["bank_information"] = this.state.bank_information;
        selected_order["request_mode"] = request_mode;
        this.setState({ isloading: false });

        this.props.history.push({
            pathname: "/orderEditCheckout/",
            state: {
                request_mode: request_mode,
                selected_order: selected_order,
            },
        });

    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    createDefaultMarkup(text) {
        return {
            __html: this.state.html,
        }
    };

    createInput(text) {
        return {
            __html: this.state.input_html
        }
    }

    render() {
        return (

            <>
                <Header1 />
                <Sidebar />
                <div className="content-body">

                    <div className="container" style={{ marginBottom: 130 }}>

                        <div className="row" >
                            
                            <div className="row">
                                <div id="navbar-orderlist-container">
                                    <div id="navbar" >
                                        <div className="card" style={{ backgroundColor: '#f8f8fb' }}>
                                            <div className="card-header">
                                                <div class="row">
                                                <span style={{fontSize: '15px'}}>
                                                    eNACH Mandate Authentication
                                                </span>
                                                <hr/>
                                                {this.state.show_nothing &&                                                         
                                                    <Card sx={{ backgroundColor: '#edf3f0', p: 2, borderRadius: 2 }}>
                                                        <Stack direction="row">
                                                            Sorry the information you are looking for could not be found
                                                        </Stack>
                                                    </Card>
                                                }
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            { !this.state.show_nothing && 
                                <div style={{ marginTop: 60 }}>
                                    <Card sx={{ backgroundColor: '#edf3f0', p: 2, borderRadius: 2 }}>
                                        <Stack direction="row">
                                            {<div dangerouslySetInnerHTML={this.state.show_html ?
                                                this.createInput() : this.createDefaultMarkup()} />
                                            }
                                        </Stack>
                                    </Card>
                                </div>
                            }

                        </div>

                    </div>

                </div>

            </>
        );
    }

}


const mapStateToProps = state => ({
    payment_initiate_result: state.auth.payment_initiate_result
});

export default connect(mapStateToProps, { eNachMandate, refreshMandateStatus })(ENachMandatePage);