
import React from "react";
import { connect } from "react-redux";
import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";
import { Form, Modal, ListGroup, Row, Col, Container, Spinner } from "react-bootstrap";

import { TabPanel, a11yProps } from "../../shared/Tabs";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";

import Select, { components } from "react-select";
import { loadSchemeAdviseList, loadSchemeAdviseSubscribers } from "../../../jsx/actions/general";
import { addAdviseList, modifyAdviseList } from "../../../jsx/actions/master";
import { createRazorPayOrder, submitExpertAdvise } from "../../../jsx/actions/payments";
import ReactPaginate from "react-paginate";

import { Fab, Action } from 'react-tiny-fab';

import { Button, Badge, Collapse, Alert } from "react-bootstrap";

const filter_conditional_parameters = ['growth', 'minimum_investment', 'investment_method'];

class AdvisorAdviseList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      masterList: {}, //JSON.parse(localStorage.getItem("masterList") || JSON.stringify({})),
      error: null,
      isLoaded: false,
      mutualFundList: [],
      pageIndex: 1,
      openCollapse: false,
      pageIndexAvailability: true,
      selected_order: {},
      selected_scheme: {},
      stp_selected_scheme_list: [],
      stp_order: {
        stp_frequency: '',
        stp_scheme: {},
        stp_scheme_code: {},
        stp_installments: '',
        stp_amount: '',
        stp_date: '',
        stp_date_value: '',
        validation_message_text: '',
        mfapi_scheme_code: '',
        selected_stp_frequency: [],
        selected_stp_scheme_code: [],
        selected_stp_installments: [],
      },
      show_targetscheme_popup: false,
      show_stpdate_popup: false,
      popup_stp_date_list: [],
      filterOption: {
        category: [],
        risk: [],
        sub_category: [],
        growth: [],
        minimum_investment: [],
        investment_method: [],
      },
      stp_frequency_list: [],
      stp_scheme_specification_list: [],
      stp_installment_list: [],
      sub_category_list: [],
      selectedOptionAmc: [],
      selectedOptionCategory: [],
      selectedOptionRisk: [],
      selectedOptionSubCategory: [],
      selectedOptionGrowth: [],
      selectedOptionMinInvestment: [],
      selectedOptionInvestmentMethod: [],
      options: [],
      newValue: 0,

      scheme_list: [],
      scheme_list_pending: [],
      scheme_list_submitted: [],

      advise_information: {
        risk: {},
        ratings: {},
        invest_option: {},
        invest_period: {},
        recommendations: '',
        validity_till: ``,
      },

      initial_scheme_list: [],
      scheme_watch_list: [],
      initial_scheme_watch_list: [],
      watch_list: [],
      perPage: 30,
      page: 1,
      pages: 0,
      isloading: false,

      notification_html: ``,

      selected_pricing_id: '',

      list_scheme_advisors: [],
      tab_scheme_advise_status: 0,

      is_advise_scheme_selected: false,
      selected_scheme_advise: {},
      selected_scheme_advisor: {},

      total_adviselist_schemes: 0,
      total_allowed_schemes_in_adviselist: 0,

      selected_adviselist: '',
      display_mode: "view adviselist",
      show_modal_add_adviselist: false,
      modal_pin_confirm_action: '',
      modal_adviselist_name: '',
      modal_adviselist_confirm_enabled: false,

      show_modal_advise_pricing: false,
      show_modal_scheme_advisor: false,
      show_modal_scheme_advise: false,

      show_modal_information: false,
      modal_information_dialog_messagetext: '',

      show_modal_subscribers: false,
      list_scheme_advise_subscribers: [],

      modal_pin_confirm_dialog_messagetext: 'Enter your 4 digit pin to complete your action',
      modal_pin_confirm_dialog_error_messagetext: '',

      show_detail_mode: '',
      wizard_step: 1,
      show_previous_button: false,
      show_next_button: false

    };
  }

  componentDidMount() {

    window.addEventListener("scroll", () => {

      if (document.getElementById("navbar-adviselist-container") &&
        document.getElementById("navbar") &&
        document.getElementById("navbar").classList) {
        const topBorder = document
          .getElementById("navbar-adviselist-container")
          .getBoundingClientRect().top;

        topBorder >= 0
          ? document.getElementById("navbar").classList.remove("fixed")
          : document.getElementById("navbar").classList.add("fixed");
      }

    });

    this.loadAdviseList();

  }

  componentWillUnmount() {
    window.removeEventListener('scroll', null);
  }

  handleChangeAmc = (selectedOption) => {

    this.setState(
      {
        filterOption: { ...this.state.filterOption, scheme_amc: selectedOption },
        selectedOptionAmc: selectedOption,
        page: 0
      },
      () => this.filterGridList(/*selectedOption, 'scheme_amc'*/)

    );
  };

  handleChangeCategory = (selectedOption) => {

    this.setState(
      {
        filterOption: { ...this.state.filterOption, category: selectedOption },
        selectedOptionCategory: selectedOption,
        // sub_category_list: sub_category_list 
      },
      () => this.filterGridList(/*selectedOption, 'category'*/)

    );

  };

  handleChangeSubCategory = (selectedOption) => {

    this.setState({ selectedOptionSubCategory: selectedOption });
    this.filterGridList(/*selectedOption, 'sub_category'*/);

  };

  handleChangeRisk = (selectedOption, attribute) => {

    this.setState(
      {
        advise_information: {
          ...this.state.advise_information,
          [attribute]: selectedOption
        }
      }
    );

  };

  handleChangeGrowth = (selectedOption) => {

    this.setState(
      {
        filterOption: { ...this.state.filterOption, growth: selectedOption },
        selectedOptionGrowth: selectedOption
      },
      () => this.filterGridList(/*selectedOption, 'growth'*/)

    );

    // this.filterGrowthoption(selectedOption);
    // this.setState({ selectedOptionGrowth: selectedOption });

  };

  handleChangeMinInvestment = (selectedOption) => {

    this.setState(
      {
        filterOption: { ...this.state.filterOption, minimum_investment: selectedOption },
        selectedOptionMinInvestment: selectedOption
      },
      () => this.filterGridList(/*selectedOption, 'minimum_investment'*/)

    );

  };

  handleChangeInvestmentMode = (selectedOption) => {

    this.setState(
      {
        filterOption: { ...this.state.filterOption, investment_method: selectedOption },
        selectedOptionInvestmentMode: selectedOption
      },
      () => this.filterGridList(/*selectedOption, 'investment_method'*/)

    );

  };

  routeChange = (item) => {

    if ((window.matchMedia && window.matchMedia("(max-width: 480px)").matches)) {
      this.props.history.push({
        pathname: "/chartsmobile",
        state: {
          param_data: {
            scheme_isin: item['scheme_isin'],
            scheme_code: item['scheme_code'],
            scheme_name: item['scheme_name']
          }
        }
      });
    }
    else {

      const win = window.open("/charts/" + item['scheme_isin'] + '/' + item['scheme_code'] + '/' + item['scheme_name'], "_blank");
      win.focus();

    }

  };

  filterGridList = (amclistFilter) => {

    let _filtered_list = this.props.result['result']["scheme_list"] || []; //this.state.scheme_list || [];
    let _filterOption = amclistFilter ? amclistFilter : this.state.filterOption;

    if (amclistFilter) {

      this.setState({
        filterOption: {
          ...this.state.filterOption,
          scheme_amc: _filterOption['scheme_amc'],
          category: _filterOption['category'],
          risk: _filterOption['risk'],
          growth: _filterOption['growth'],
          minimum_investment: _filterOption['minimum_investment'],
          investment_method: _filterOption['investment_method']
        },
        selectedOptionGrowth: _filterOption['growth'],
        selectedOptionMinInvestment: _filterOption['minimum_investment'],
        selectedOptionInvestmentMode: _filterOption['investment_method']
        /*
        selectedOptionAmc: _filterOption['scheme_amc'],
        selectedOptionCategory: _filterOption['category'],
        selectedOptionRisk: _filterOption['risk'],
        selectedOptionSubCategory: _filterOption['sub_category']
        */
      });

    }

    let _selected_list = [];

    Object.keys(_filterOption).forEach(key => {

      /*if(key === 'risk' ||  key === 'category') {
        if(_filterOption[key]['value']) {
          _selected_list = _filterOption[key]['value'] === 'all' ? [] : [_filterOption[key]] ;
        }
        else
          _selected_list = [];
      }
      else*/
      _selected_list = _filterOption[key];

      if (_selected_list.length > 0) {

        if (filter_conditional_parameters
          .filter(__el => { return __el === key; }).length > 0) {

          switch (key) {

            case 'growth':

              let _filter = _filterOption[key];
              let _arr_values = [];
              let boolTrue = false;

              let __filtered_list = [];

              _filtered_list.forEach(el => {

                boolTrue = false;

                _filter.forEach(el_ => {

                  if (!boolTrue) {

                    _arr_values = el_.value.split('-');

                    boolTrue = (parseFloat(el['one_year_percent'] || 0) >= parseFloat(_arr_values[0]) &&
                      parseFloat(el['one_year_percent'] || 0) <= parseFloat(_arr_values[1]));

                    if (!boolTrue)
                      boolTrue = (parseFloat(el['three_year_percent'] || 0) >= parseFloat(_arr_values[0]) &&
                        parseFloat(el['three_year_percent'] || 0) <= parseFloat(_arr_values[1]));

                    if (!boolTrue)
                      boolTrue = (parseFloat(el['five_year_percent'] || 0) >= parseFloat(_arr_values[0]) &&
                        parseFloat(el['five_year_percent'] || 0) <= parseFloat(_arr_values[1]));

                  }

                  if (boolTrue)
                    __filtered_list.push(el);

                });

                // return boolTrue;

                /*
                return _filter.filter(el_ =>  {
                  _arr_values = el_.value.split('-');
                  return (parseFloat(el['one_year_percent'] || 0) >= parseFloat(_arr_values[0]) && 
                    parseFloat(el['one_year_percent'] || 0) <= parseFloat(_arr_values[1])) 
                    || 
                    (parseFloat(el['three_year_percent'] || 0) >= parseFloat(_arr_values[0]) && 
                    parseFloat(el['three_year_percent'] || 0) <= parseFloat(_arr_values[1]))
                    ||
                    (parseFloat(el['five_year_percent'] || 0) >= parseFloat(_arr_values[0]) && 
                    parseFloat(el['five_year_percent'] || 0) <= parseFloat(_arr_values[1])) ;
                });*/ //.length > 0;

              });

              _filtered_list = __filtered_list;

              // _filtered_list = [...new Set(_filtered_list)];

              break;
            case 'minimum_investment':
              _filtered_list = _filtered_list.filter(el => {

                return _filterOption[key].filter(el_ => {
                  return parseFloat(el['scheme_minimum_investment_amount'] || 0) >= parseFloat(el_.value) &&
                    parseFloat(el['scheme_minimum_investment_amount'] || 0) <= parseFloat(el_.value) + 10;
                }).length > 0;

              });
              break;
            case 'investment_method':

              let _condition = _filterOption[key].map(el => {
                return el['value'];
              });

              _filtered_list = _filtered_list.filter(el => {

                if (_condition.length > 1)
                  return (el[_condition[0]] === 'Y' || el[_condition[1]] === 'Y');
                else
                  return el[_condition[0]] === 'N';

              });
              break;

            case 'category':

              let condition_category = _filterOption[key].map(el => {
                return el['value'];
              });

              _filtered_list = _filtered_list.filter(el => {

                if (condition_category.length > 1)
                  return (el[condition_category[0]]);


              });
              break;

            default:
              break;
          }

        }
        else {

          let _loc = '';
          let _condition = _selected_list; /*.map(el => {
               return el.value.toLowerCase();
             });*/

          _filtered_list = _filtered_list.filter(el => {

            // return _condition.indexOf((el[key] || '').toLowerCase());
            _loc = (el[key] || '').toLowerCase();
            return _condition.filter(el_ => {
              return _loc === el_['value'].toLowerCase();
            }).length > 0;

          });

        }
      }

    });

    sessionStorage.setItem('amclistFilter', JSON.stringify(_filterOption));

    this.setState({
      scheme_list: _filtered_list,
      pages: Math.round/*floor*/(_filtered_list.length /
        this.props.result['per_page_count'] || this.state.perPage)
    });

  };

  filterGrowthoption = (selectedOption) => {

    let _filtered_list = this.state.scheme_list || [];

    let text = selectedOption[0].value || "";
    let filtered_list = _filtered_list.filter((item) => {
      return text.length > 1
        ? (
          item.three_year_percent.toString() ||
          item.one_month_percent.toString() ||
          item.one_year_percent.toString()
        ).match(text)
        : true;
    });

    filtered_list = [...new Set(filtered_list)];

    this.setState({
      scheme_list: filtered_list,
      pages: Math.round/*floor*/(filtered_list.length /
        this.props.result['per_page_count'] || this.state.perPage)
    });

  };

  filterMininvestmentoption = (selectedOption) => {

    let _filtered_list = this.state.scheme_list || [];

    let text = selectedOption[0].value || "";
    let filtered_list = _filtered_list.filter((item) => {
      return text.length > 1
        ? item.minimum_investment_amount.toString().match(text)
        : true;
    });

    this.setState({
      scheme_list: filtered_list,
      pages: Math.round/*floor*/(filtered_list.length /
        this.props.result['per_page_count'] || this.state.perPage)
    });

  };

  loadAdviseList = () => {

    this.setState({ isloading: true });

    this.props
      .loadSchemeAdviseList({
        file_index: this.state.pageIndex
      })
      .then((response) => {

        const { perPage } = this.state;

        if (this.props.result["code"] === "000") {

          this.setState({
            show_targetscheme_popup: true,
            selected_scheme_advisor: this.props.result['result']['selected_scheme_advisor'],
            masterList: this.props.result['result']["masterList"] || [],
            scheme_list: this.props.result['result']["list_scheme_advise"] || [],
            scheme_list_pending: this.props.result['result']["list_scheme_advise_pending"] || [],
            scheme_list_submitted: this.props.result['result']["list_scheme_advise_submitted"] || [],
            initial_scheme_list: this.props.result['result']["list_scheme_advise"] || [],
            total_allowed_schemes_in_adviselist: this.props.result['result']["total_allowed_schemes_in_adviselist"] || 10,

            pages:
              Math.round/*floor*/(
                (this.props.result['result']["list_scheme_advise"] || []).length /
                this.props.result['per_page_count'] || perPage
              ),
            isloading: false,
            perPage: this.props.result['per_page_count'] || this.state.perPage,
            display_mode: "view adviselist",
            wizard_step: 1,
            is_advise_scheme_selected: false
          });

          // this.filterGridList(
          //   sessionStorage.getItem('amclistFilter') ? JSON.parse(sessionStorage.getItem('amclistFilter')) : null);

          // if (sessionStorage.getItem('amclistFilter'))
          //   sessionStorage.removeItem('amclistFilter');

        } else {
          alert(this.props.result["messageText"]);
          this.setState({ isloading: false });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  loadSchemeAdviseSubscribers = (scheme) => {

    this.setState({ isloading: true });

    this.props
      .loadSchemeAdviseSubscribers({
        scheme_advisor_id: this.state.selected_watchlist['scheme_advisor_id'],
        scheme_code: scheme['scheme_code']
      })
      .then((response) => {

        if (this.props.result["code"] === "000") {

          this.setState({
            show_modal_subscribers: true,
            list_scheme_advise_subscribers: this.props.result['list_scheme_advise_subscribers'],
            isloading: false
          });

        } else {
          alert(this.props.result["messageText"]);
          this.setState({ isloading: false });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleSchemeCheck = (/*e,*/ item) => {

    // let _list = this.state.initial_scheme_list;

    // _list.forEach((el) => {
    //   el["is_checked"] = false;

    //   if (el["_id"] === item["_id"])
    //     el["is_checked"] = true; //e.target.checked;
    // });

    let _watch_scheme_filtered = this.state.scheme_watch_list.filter(el => {
      return el['scheme_isin'] === item['scheme_isin'];
    });

    _watch_scheme_filtered = _watch_scheme_filtered.map(el => {
      return {
        label: 'Min ₹' + el['stp_in_minimum_installment_amount']
          + ' - Max ₹' + (parseInt(el['stp_in_maximum_installment_amount']) < 1
            ? 99999999.000 : el['stp_in_maximum_installment_amount'])
          // + '| SETTLEMENT TYPE :' + el['param_settlement_type']
          + (el['scheme_name'].split('-').length < 3 ?
            '' :
            ('|' + el['scheme_name'].split('-')[el['scheme_name'].split('-').length - 1])),
        // + '| REDEEM ' + (el['param_redemption_allowed'] === 'N' ? 'NOT ' : '') + 'ALLOWED',
        value: el['scheme_code'],
        frequency: el['stp_frequency']
      }
    });

    this.setState({
      stp_frequency_list: this.props.result["stp_frequency_list"] || [],
      stp_installment_list: this.props.result["stp_installment_list"] || [],
      stp_order: {
        ...this.state.stp_order,
        stp_frequency: '',
        stp_scheme: {},
        stp_scheme_code: {},
        stp_installments: '',
        stp_amount: '',
        stp_date: '',
        stp_date_value: '',
        validation_message_text: '',
        selected_stp_frequency: [],
        selected_stp_scheme_code: [],
        selected_stp_installments: [],
      },
      // scheme_list: _list,
      stp_scheme_specification_list: _watch_scheme_filtered,
      selected_scheme: item,
      show_targetscheme_popup: false
    });
    // window.scrollTo(0, document.body.scrollHeight);

  };

  handleChangeAdviseList = (selectedOption) => {

    this.setState(
      {
        selected_adviselist: selectedOption
      },
      () => {

        let _scheme_watch_list = this.state.initial_scheme_watch_list.filter(el => {
          return el['id'] === selectedOption['value'];
        });

        _scheme_watch_list = _scheme_watch_list.length > 0 ?
          _scheme_watch_list[0]['scheme_list'] : []

        // const _scheme_list = this.state.initial_scheme_list.filter(el => {
        //   return el['scheme_code'] === selectedOption['value'];
        // });

        this.setState({
          scheme_watch_list: this.myFilter(this.state.initial_scheme_list,
            _scheme_watch_list.map(el => {
              return el['scheme_code']
            }).join(','))
        });

      });

    // this.setState({
    //   scheme_watch_list: this.state.initial_scheme_list.filter
    // });

    // this.setState(
    //   {
    //     status_filter_selected: selectedOption
    //   },
    //   () => {

    //     let _filtered_list_single_orders = [];

    //     selectedOption.forEach(item => {
    //       _filtered_list_single_orders = _filtered_list_single_orders.concat(
    //         this.state.list_single_orders.filter(el => {
    //           return el[item['value']] === true;
    //         })
    //       );
    //     });

    //     this.setState({
    //       _list_single_orders: selectedOption.length > 0 ?
    //         _filtered_list_single_orders : this.state.list_single_orders
    //     });

    //   });

  };

  handleRadioChange = (value, item, key, key_1) => {

    let json = {
      // selected_pricing_id: item['id']
      [key]: item['id']
    };
    if (key_1)
      json[key_1] = item;

    this.setState(json);

  };

  myFilter(base, query) {

    const queries = query.split(",")
    return base.filter((a) => {
      let doReturn = false;
      queries.forEach((q) => {

        if (a.scheme_code.includes(q)) {
          doReturn = true;
        }

      })
      return doReturn;
    });

  }

  handleChangeSelect = (selectedOption, input) => {

    if (input === 'stp_frequency') {

      this.setState({
        stp_order: {
          ...this.state.stp_order,
          [input]: selectedOption.value,
          selected_stp_frequency: selectedOption
        },
        stp_selected_scheme_list: this.state.stp_scheme_specification_list.filter(el => {
          return el['frequency'].toLowerCase() === selectedOption.value.toLowerCase();
        })
      });

    }
    else if (input === 'stp_scheme_code') {

      let _selected_scheme = this.state.scheme_watch_list.filter(el => {
        return el['scheme_code'] === selectedOption.value;
      });

      if (_selected_scheme.length > 0) {

        _selected_scheme = _selected_scheme[0];

        let _loc = [];
        for (var _rec = parseInt(_selected_scheme['stp_minimum_installment_numbers']);
          _rec <= parseInt(_selected_scheme['stp_maximum_installment_numbers']); _rec++) {
          _loc.push({ value: _rec, label: _rec });
        }

        let _mfapi_scheme_code = this.state.scheme_list.filter(el => {
          return el['scheme_isin'] === _selected_scheme['scheme_isin'] ||
            el['scheme_isin_1'] === _selected_scheme['scheme_isin'];
        });

        _selected_scheme['mfapi_scheme_code'] = _mfapi_scheme_code.legnth > 0 ?
          _mfapi_scheme_code[0]['scheme_code'] : '';

        this.setState({

          stp_order: {
            ...this.state.stp_order,
            [input]: _selected_scheme['scheme_code'],
            mfapi_scheme_code: _mfapi_scheme_code.legnth > 0 ?
              _mfapi_scheme_code[0]['scheme_code'] : '',
            stp_scheme: _selected_scheme,
            selected_stp_scheme_code: selectedOption
          },
          stp_installment_list: _loc,
          popup_stp_date_list: _selected_scheme['stp_dates'].split(',')

        });

      }

    }
    else if (input === 'stp_installments') {

      this.setState({
        stp_order: {
          ...this.state.stp_order,
          [input]: selectedOption.value,
          selected_stp_installments: selectedOption
        }
      });

    }
    // else if (input === 'stp_amount') {

    // }

  };

  handlePageClick = (event) => {

    let page = event.selected;
    this.setState({ page });
    window.scrollTo(0, 0);

  };

  onNextPrevious = (count) => {
    // this.setState({pageIndex: this.state.pageIndex + count});
  };

  getBase64Image = (imgUrl) => {

    var img = new Image();

    // onload fires when the image is fully loadded, and has width and height

    img.onload = function () {

      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      var dataURL = canvas.toDataURL("image/png"),
        dataURL = dataURL.replace(/^data:image\/(png|jpg);base64,/, "");

      return (dataURL); // the base64 string

    };

    // set attributes and src 
    img.setAttribute('crossOrigin', 'anonymous'); //
    img.src = imgUrl;

  }

  handleSchemeSelect = (item) => {

    let _list_scheme = this.state.scheme_list;
    _list_scheme.forEach(el => {
      item['is_checked'] = (el['scheme_code'] === item['scheme_code']);
    });

    this.setState({
      scheme_list: _list_scheme,
      selected_scheme_advise: item,
      is_advise_scheme_selected: true
    });

  };

  submitAdviseList = (request_mode) => {

    this.setState({ isloading: true });

    this.props
      .submitExpertAdvise({
        advise_information: this.state.advise_information,
        selected_scheme_info: {
          scheme_code: this.state.selected_scheme_advise['scheme_code'],
          scheme_isin: this.state.selected_scheme_advise['scheme_isin'],
          scheme_isin_1: this.state.selected_scheme_advise['scheme_isin_1']
        },
        selected_scheme_advisor: this.state.selected_scheme_advisor,
        request_mode: request_mode,
      }).then((response) => {

        if (this.props.payment_result["code"] !== "000") {
          this.setState({
            isloading: false
          });
        }
        else {
          this.loadAdviseList();
        }
      })
      .catch((err) => {
        alert(err);
      });

  }

  createDefaultMarkup() {
    return {
      __html: this.state.modal_information_dialog_messagetext,
    }
  };

  render() {

    const {
      page, perPage, pages,
      scheme_list, scheme_list_pending, scheme_list_submitted,
      selected_scheme_advise
    } = this.state;

    const _scheme_list = this.state.tab_scheme_advise_status === 0 ?
      scheme_list_pending : scheme_list_submitted /*scheme_list*/;

    let _list_scheme = _scheme_list.length > perPage ?
      _scheme_list.slice(page * perPage, (page + 1) * perPage) : _scheme_list;

    return (
      <>
        <Header1 />
        <Sidebar />

        <div>
          <div id="navbar-adviselist-container">
            <div id="navbar" >
              <div className="card" style={{ backgroundColor: '#f8f8fb' }}>
                <div class="row">

                  <div class="col-xl-12">
                    <h4 className="text-center">
                      {
                        this.state.display_mode === 'add advise details' ?
                          `Submit Your Advise` : `Scheme Advise List`
                      }
                    </h4>
                  </div>

                </div>
                {this.state.display_mode === 'view adviselist' &&
                  <div>
                    <Box >
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                          value={this.state.tab_scheme_advise_status}
                          onChange={(event, newValue) => {
                            this.setState({ tab_scheme_advise_status: newValue });
                          }}
                          aria-label="basic tabs example"
                        >
                          <Tab style={{ width: 140 }} label="PENDING" {...a11yProps(0)} />
                          <Tab style={{ width: 180 }} label="SUBMITTED" {...a11yProps(1)} />
                        </Tabs>
                      </Box>
                      {/* <TabPanel value={this.state.tab_scheme_advise_status} index={0}>
                        <div class="buy-sell-widget">

                        </div>
                      </TabPanel>
                      <TabPanel value={this.state.tab_scheme_advise_status} index={1}>

                        <div class="buy-sell-widget">

                        </div>
                      </TabPanel> */}
                    </Box>
                    {_list_scheme && scheme_list.length >= perPage && (
                      <ReactPaginate
                        breakLabel="..."
                        previousLabel={"←"}
                        nextLabel={"→"}
                        pageCount={pages}
                        onPageChange={this.handlePageClick}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    )}
                  </div>
                }
              </div>
            </div>
          </div>

          <div className="container" >

            <div class="row">
              <div class="col-xl-12" style={{
                marginTop: this.state.display_mode === 'view adviselist' &&
                  scheme_list.length <= perPage ? 20 : -5
              }}>

                <div class="transaction-widget">
                  {this.state.isloading && (
                    <Spinner
                      animation="border"
                      variant="dark"
                      style={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        zIndex: 899,
                      }}
                    />
                  )}

                  {this.state.display_mode === 'view adviselist' &&
                    (this.state.isloading || _list_scheme.length == 0) && (
                      <Alert variant="secondary text-center">
                        <Alert.Heading>
                          {this.state.isloading
                            ? "We are fetching, Kindly wait"
                            :
                            `Your list is empty`
                          }
                        </Alert.Heading>
                      </Alert>
                    )}

                  {this.state.display_mode === 'view adviselist' &&
                    <div>

                      <ListGroup >
                        {_list_scheme.map((item) => (
                          <ListGroup.Item
                            key={item["_id"]}
                            style={{ marginBottom: 5 }}
                            action
                            variant="light"
                            className="wallet-address text-dark"
                          >
                            <div class="row" 
                              onClick={() => this.handleSchemeSelect(item)}>
                              <div class="col">
                                {/* <p> */}
                                {` `}{item["scheme_name_display_1"]}
                                {item["scheme_name_display_2"].length > 2 &&
                                  <span>
                                    {` `} {item["scheme_name_display_2"]}
                                  </span>
                                }
                                {/* </p> */}
                              </div>
                            </div>

                            <Row onClick={() => this.handleSchemeSelect(item)}>
                              <Col xs={1}>
                                <img className="fundlogo" src={item["logo_url"]} />
                              </Col>
                              <Col>
                                <span
                                  style={{
                                    fontSize: 13,
                                    backgroundColor: "#F6F3F1"
                                  }}
                                >
                                  {item["scheme_category"]}
                                </span>
                              </Col>
                            </Row>

                            {item["one_year_percent"] &&
                              !isNaN(parseInt(item["one_year_percent"])) && (<Row>

                                <Row onClick={() => this.handleSchemeSelect(item)}>

                                  <Col>
                                    <div class="row">
                                      <div class="col">
                                        <div
                                          style={{
                                            borderBottom: "1px solid",
                                            borderBottomColor: "lightgray",
                                            fontSize: 10,
                                            fontWeight: 'bold',
                                            marginTop: 10,
                                            textAlign: 'center'
                                          }}
                                        >
                                          Lumpsum (%)
                                        </div>
                                        <div class="row">
                                          {item["one_year_percent"] &&
                                            !isNaN(
                                              parseInt(item["one_year_percent"])
                                            ) && (
                                              <div
                                                style={{
                                                  width: "30px",
                                                  textAlign: "center",
                                                }}
                                                class="col"
                                              >
                                                <div
                                                  style={{
                                                    fontSize: 12,
                                                    color: "#7d7777",
                                                  }}
                                                >
                                                  1Y
                                                </div>
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    color:
                                                      item[
                                                        "one_year_percent"
                                                      ].substring(0, 1) === "-" ||
                                                        ""
                                                        ? "#f57362"
                                                        : "#03b45e",
                                                  }}
                                                >
                                                  {item["one_year_percent"]}
                                                </span>
                                              </div>
                                            )}
                                          {item["three_year_percent"] &&
                                            !isNaN(
                                              parseInt(item["three_year_percent"])
                                            ) && (
                                              <div
                                                style={{
                                                  width: "30px",
                                                  textAlign: "center",
                                                }}
                                                class="col"
                                              >
                                                <div
                                                  style={{
                                                    fontSize: 12,
                                                    color: "#7d7777",
                                                  }}
                                                >
                                                  3Y
                                                </div>
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    color:
                                                      item[
                                                        "three_year_percent"
                                                      ].substring(0, 1) === "-" ||
                                                        ""
                                                        ? "#f57362"
                                                        : "#03b45e",
                                                  }}
                                                >
                                                  {item["three_year_percent"]}
                                                </span>
                                              </div>
                                            )}
                                          {item["five_year_percent"] &&
                                            !isNaN(
                                              parseInt(item["five_year_percent"])
                                            ) && (
                                              <div
                                                style={{
                                                  width: "30px",
                                                  textAlign: "center",
                                                }}
                                                class="col"
                                              >
                                                <div
                                                  style={{
                                                    fontSize: 12,
                                                    color: "#7d7777",
                                                  }}
                                                >
                                                  5Y
                                                </div>
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    color:
                                                      item[
                                                        "five_year_percent"
                                                      ].substring(0, 1) === "-" ||
                                                        ""
                                                        ? "#f57362"
                                                        : "#03b45e",
                                                  }}
                                                >
                                                  {item["five_year_percent"]}
                                                </span>
                                              </div>
                                            )}
                                        </div>
                                      </div>

                                    </div>
                                  </Col>

                                  <Col>
                                    <div class="col">
                                      <div
                                        style={{
                                          fontFamily: "sans-serif",
                                          borderBottom: "1px solid",
                                          borderBottomColor: "lightgray",
                                          fontSize: 10,
                                          fontWeight: 'bold',
                                          marginTop: 10,
                                          textAlign: 'center'
                                        }}
                                      >
                                        {/* <span>
                                    SIP (Eg. &#8377;1000 monthly)
                                  </span> */}
                                        SIP (%)
                                      </div>
                                      <div class="row">
                                        {item["sip_returns_data"] &&
                                          item["sip_returns_data"][
                                          "1y_percent"
                                          ] &&
                                          !isNaN(
                                            parseInt(
                                              item["sip_returns_data"][
                                              "1y_percent"
                                              ]
                                            )
                                          ) && (
                                            <div
                                              style={{
                                                width: "30px",
                                                textAlign: "center",
                                              }}
                                              class="col"
                                            >
                                              <div
                                                style={{
                                                  fontSize: 12,
                                                  color: "#7d7777",
                                                }}
                                              >
                                                1Y
                                              </div>
                                              <span
                                                style={{
                                                  fontSize: 13,
                                                  color:
                                                    item["sip_returns_data"][
                                                      "1y_percent"
                                                    ].substring(0, 1) === "-" ||
                                                      ""
                                                      ? "#f57362"
                                                      : "#03b45e",
                                                }}
                                              >
                                                {
                                                  item["sip_returns_data"][
                                                  "1y_percent"
                                                  ]
                                                }
                                              </span>
                                            </div>
                                          )}
                                        {item["sip_returns_data"] &&
                                          item["sip_returns_data"][
                                          "3y_percent"
                                          ] &&
                                          !isNaN(
                                            parseInt(
                                              item["sip_returns_data"][
                                              "3y_percent"
                                              ]
                                            )
                                          ) && (
                                            <div
                                              style={{
                                                width: "30px",
                                                textAlign: "center",
                                              }}
                                              class="col"
                                            >
                                              <div
                                                style={{
                                                  fontSize: 12,
                                                  color: "#7d7777",
                                                }}
                                              >
                                                3Y
                                              </div>
                                              <span
                                                style={{
                                                  fontSize: 13,
                                                  color:
                                                    item["sip_returns_data"][
                                                      "3y_percent"
                                                    ].substring(0, 1) === "-" ||
                                                      ""
                                                      ? "#f57362"
                                                      : "#03b45e",
                                                }}
                                              >
                                                {
                                                  item["sip_returns_data"][
                                                  "3y_percent"
                                                  ]
                                                }
                                              </span>
                                            </div>
                                          )}
                                        {item["sip_returns_data"] &&
                                          item["sip_returns_data"][
                                          "5y_percent"
                                          ] &&
                                          !isNaN(
                                            parseInt(
                                              item["sip_returns_data"][
                                              "5y_percent"
                                              ]
                                            )
                                          ) && (
                                            <div
                                              style={{
                                                width: "30px",
                                                textAlign: "center",
                                              }}
                                              class="col"
                                            >
                                              <div
                                                style={{
                                                  fontSize: 12,
                                                  color: "#7d7777",
                                                }}
                                              >
                                                5Y
                                              </div>
                                              <span
                                                style={{
                                                  fontSize: 13,
                                                  color:
                                                    item["sip_returns_data"][
                                                      "5y_percent"
                                                    ].substring(0, 1) === "-" ||
                                                      ""
                                                      ? "#f57362"
                                                      : "#03b45e",
                                                }}
                                              >
                                                {
                                                  item["sip_returns_data"][
                                                  "5y_percent"
                                                  ]
                                                }
                                              </span>
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </Row>)}
                            {
                              this.state.tab_scheme_advise_status == 1 &&
                              <hr />
                            }
                            {
                              this.state.tab_scheme_advise_status == 1 &&
                              <Row>
                                <Col xs={8}>
                                  <span
                                    style={{ fontSize: 15 }}
                                    class="text-dark"
                                  >
                                    Total Subscribers
                                    {` `}
                                    <span
                                      style={{
                                        fontSize: 12,
                                        color: "InfoText",
                                        backgroundColor:
                                          item["risk"].toLowerCase().indexOf("low") >
                                            -1
                                            ? "lightgreen"
                                            : item["risk"]
                                              .toLowerCase()
                                              .indexOf("high") > -1
                                              ? "#d96f76"
                                              : "#81a6d6",
                                        padding: 4,
                                        paddingLeft: 8,
                                        paddingRight: 8,
                                        margin: 4,
                                        borderRadius: 4,
                                      }}
                                    >
                                      {`  `}
                                      {item["total_subscribers"]}
                                    </span>
                                  </span>
                                </Col>
                                <Col>
                                <button type="button"
                                    className="btn-sm btn-success"
                                    style={{ marginTop: -5, color: 'white', textAlign: 'right' }}
                                    onClick={() => {
                                      this.loadSchemeAdviseSubscribers(item);
                                    }}
                                  >
                                    View
                                  </button>
                                </Col>
                              </Row>
                            }

                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                      {
                        this.state.is_advise_scheme_selected &&
                        this.state.display_mode == "view adviselist" &&
                        <Fab
                          style={{ bottom: 32, right: -15, transform: 'scale(0.82)' }}
                          alwaysShowTitle={true}
                          mainButtonStyles={{
                            backgroundColor: "#c84bd1"
                          }}
                          icon={<i className="fa fa-arrow-right  fa-lg" ></i>}
                          onClick={() => {
                            this.setState({
                              display_mode: 'add advise details',
                              advise_information: this.state.selected_scheme_advise['selected_advise_information']
                              // show_modal_scheme_advisor: false,
                              // show_modal_advise_pricing: true
                            });

                          }}
                        >
                        </Fab>
                      }
                    </div>
                  }

                  {this.state.display_mode === 'show advise details' &&
                    <div class="transaction-widget">

                    </div>
                  }

                  {this.state.display_mode === 'add advise details' &&
                    <div class="transaction-widget" style={{ marginTop: -35 }}>

                      <div className="card-body" >

                        <div class="row">
                          <div class="col">
                            {` `}{selected_scheme_advise["scheme_name_display_1"]}
                            {selected_scheme_advise["scheme_name_display_2"].length > 2 &&
                              <span>
                                {` `} {selected_scheme_advise["scheme_name_display_2"]}
                              </span>
                            }
                          </div>
                        </div>

                        <Row>
                          <Col xs={1}>
                            <img className="fundlogo" src={selected_scheme_advise["logo_url"]} />
                          </Col>
                          <Col>
                            <span
                              style={{
                                fontSize: 13,
                                backgroundColor: "#F6F3F1"
                              }}
                            >
                              {selected_scheme_advise["scheme_category"]}
                            </span>
                          </Col>
                        </Row>

                        {this.state.wizard_step === 1 &&
                          selected_scheme_advise["one_year_percent"] &&
                          !isNaN(parseInt(selected_scheme_advise["one_year_percent"])) && (<Row>
                            <Row>
                              <Col>
                                <div class="row">
                                  <div class="col">
                                    <div
                                      style={{
                                        borderBottom: "1px solid",
                                        borderBottomColor: "lightgray",
                                        fontSize: 10,
                                        fontWeight: 'bold',
                                        marginTop: 10,
                                        textAlign: 'center'
                                      }}
                                    >
                                      Lumpsum (%)
                                    </div>
                                    <div class="row">
                                      {selected_scheme_advise["one_year_percent"] &&
                                        !isNaN(
                                          parseInt(selected_scheme_advise["one_year_percent"])
                                        ) && (
                                          <div
                                            style={{
                                              width: "30px",
                                              textAlign: "center",
                                            }}
                                            class="col"
                                          >
                                            <div
                                              style={{
                                                fontSize: 12,
                                                color: "#7d7777",
                                              }}
                                            >
                                              1Y
                                            </div>
                                            <span
                                              style={{
                                                fontSize: 13,
                                                color:
                                                  selected_scheme_advise[
                                                    "one_year_percent"
                                                  ].substring(0, 1) === "-" ||
                                                    ""
                                                    ? "#f57362"
                                                    : "#03b45e",
                                              }}
                                            >
                                              {selected_scheme_advise["one_year_percent"]}
                                            </span>
                                          </div>
                                        )}
                                      {selected_scheme_advise["three_year_percent"] &&
                                        !isNaN(
                                          parseInt(selected_scheme_advise["three_year_percent"])
                                        ) && (
                                          <div
                                            style={{
                                              width: "30px",
                                              textAlign: "center",
                                            }}
                                            class="col"
                                          >
                                            <div
                                              style={{
                                                fontSize: 12,
                                                color: "#7d7777",
                                              }}
                                            >
                                              3Y
                                            </div>
                                            <span
                                              style={{
                                                fontSize: 13,
                                                color:
                                                  selected_scheme_advise[
                                                    "three_year_percent"
                                                  ].substring(0, 1) === "-" ||
                                                    ""
                                                    ? "#f57362"
                                                    : "#03b45e",
                                              }}
                                            >
                                              {selected_scheme_advise["three_year_percent"]}
                                            </span>
                                          </div>
                                        )}
                                      {selected_scheme_advise["five_year_percent"] &&
                                        !isNaN(
                                          parseInt(selected_scheme_advise["five_year_percent"])
                                        ) && (
                                          <div
                                            style={{
                                              width: "30px",
                                              textAlign: "center",
                                            }}
                                            class="col"
                                          >
                                            <div
                                              style={{
                                                fontSize: 12,
                                                color: "#7d7777",
                                              }}
                                            >
                                              5Y
                                            </div>
                                            <span
                                              style={{
                                                fontSize: 13,
                                                color:
                                                  selected_scheme_advise[
                                                    "five_year_percent"
                                                  ].substring(0, 1) === "-" ||
                                                    ""
                                                    ? "#f57362"
                                                    : "#03b45e",
                                              }}
                                            >
                                              {selected_scheme_advise["five_year_percent"]}
                                            </span>
                                          </div>
                                        )}
                                    </div>
                                  </div>

                                </div>
                              </Col>

                              <Col>
                                <div class="col">
                                  <div
                                    style={{
                                      fontFamily: "sans-serif",
                                      borderBottom: "1px solid",
                                      borderBottomColor: "lightgray",
                                      fontSize: 10,
                                      fontWeight: 'bold',
                                      marginTop: 10,
                                      textAlign: 'center'
                                    }}
                                  >
                                    SIP (%)
                                  </div>
                                  <div class="row">
                                    {selected_scheme_advise["sip_returns_data"] &&
                                      selected_scheme_advise["sip_returns_data"][
                                      "1y_percent"
                                      ] &&
                                      !isNaN(
                                        parseInt(
                                          selected_scheme_advise["sip_returns_data"][
                                          "1y_percent"
                                          ]
                                        )
                                      ) && (
                                        <div
                                          style={{
                                            width: "30px",
                                            textAlign: "center",
                                          }}
                                          class="col"
                                        >
                                          <div
                                            style={{
                                              fontSize: 12,
                                              color: "#7d7777",
                                            }}
                                          >
                                            1Y
                                          </div>
                                          <span
                                            style={{
                                              fontSize: 13,
                                              color:
                                                selected_scheme_advise["sip_returns_data"][
                                                  "1y_percent"
                                                ].substring(0, 1) === "-" ||
                                                  ""
                                                  ? "#f57362"
                                                  : "#03b45e",
                                            }}
                                          >
                                            {
                                              selected_scheme_advise["sip_returns_data"][
                                              "1y_percent"
                                              ]
                                            }
                                          </span>
                                        </div>
                                      )}
                                    {selected_scheme_advise["sip_returns_data"] &&
                                      selected_scheme_advise["sip_returns_data"][
                                      "3y_percent"
                                      ] &&
                                      !isNaN(
                                        parseInt(
                                          selected_scheme_advise["sip_returns_data"][
                                          "3y_percent"
                                          ]
                                        )
                                      ) && (
                                        <div
                                          style={{
                                            width: "30px",
                                            textAlign: "center",
                                          }}
                                          class="col"
                                        >
                                          <div
                                            style={{
                                              fontSize: 12,
                                              color: "#7d7777",
                                            }}
                                          >
                                            3Y
                                          </div>
                                          <span
                                            style={{
                                              fontSize: 13,
                                              color:
                                                selected_scheme_advise["sip_returns_data"][
                                                  "3y_percent"
                                                ].substring(0, 1) === "-" ||
                                                  ""
                                                  ? "#f57362"
                                                  : "#03b45e",
                                            }}
                                          >
                                            {
                                              selected_scheme_advise["sip_returns_data"][
                                              "3y_percent"
                                              ]
                                            }
                                          </span>
                                        </div>
                                      )}
                                    {selected_scheme_advise["sip_returns_data"] &&
                                      selected_scheme_advise["sip_returns_data"][
                                      "5y_percent"
                                      ] &&
                                      !isNaN(
                                        parseInt(
                                          selected_scheme_advise["sip_returns_data"][
                                          "5y_percent"
                                          ]
                                        )
                                      ) && (
                                        <div
                                          style={{
                                            width: "30px",
                                            textAlign: "center",
                                          }}
                                          class="col"
                                        >
                                          <div
                                            style={{
                                              fontSize: 12,
                                              color: "#7d7777",
                                            }}
                                          >
                                            5Y
                                          </div>
                                          <span
                                            style={{
                                              fontSize: 13,
                                              color:
                                                selected_scheme_advise["sip_returns_data"][
                                                  "5y_percent"
                                                ].substring(0, 1) === "-" ||
                                                  ""
                                                  ? "#f57362"
                                                  : "#03b45e",
                                            }}
                                          >
                                            {
                                              selected_scheme_advise["sip_returns_data"][
                                              "5y_percent"
                                              ]
                                            }
                                          </span>
                                        </div>
                                      )}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Row>)}

                      </div>
                      {this.state.wizard_step === 1 &&
                        <div className="card-body" >

                          <Row >
                            <Col xs={5}>
                              <h5
                                class="text-gray-dark"
                                style={{ marginTop: 10, fontSize: 14 }}
                              >
                                Choose Risk
                              </h5>
                            </Col>
                            <Col>
                              <div class="form-group">
                                <Select
                                  // isMulti
                                  value={this.state.advise_information["risk"]}
                                  onChange={(e) => this.handleChangeRisk(e, 'risk')}
                                  options={this.state.masterList["risk"]}
                                />
                              </div>
                            </Col>
                          </Row>
                          <br />
                          <Row >
                            <Col xs={5}>
                              <h5
                                class="text-gray-dark"
                                style={{ marginTop: 10, fontSize: 14 }}
                              >
                                Your Rating
                              </h5>
                            </Col>
                            <Col>
                              <div class="form-group">
                                <Select
                                  // isMulti
                                  value={this.state.advise_information["ratings"]}
                                  onChange={(e) => this.handleChangeRisk(e, 'ratings')}
                                  options={this.state.masterList["ratings"]}
                                />
                              </div>
                            </Col>
                          </Row>
                          <br />
                          <Row >
                            <Col xs={5}>
                              <h5
                                class="text-gray-dark"
                                style={{ fontSize: 14 }}
                              >
                                Invest Type (Recommended)
                              </h5>
                            </Col>
                            <Col>
                              <div class="form-group">
                                <Select
                                  // isMulti
                                  value={this.state.advise_information["invest_option"]}
                                  onChange={(e) => this.handleChangeRisk(e, 'invest_option')}
                                  options={this.state.masterList["investment_option"]}
                                />
                              </div>
                            </Col>
                          </Row>
                          <br />
                          <Row >
                            <Col xs={5}>
                              <h5
                                class="text-gray-dark"
                                style={{ fontSize: 14 }}
                              >
                                Invest Period (Months)
                              </h5>
                            </Col>
                            <Col>
                              <div class="form-group">
                                <Select
                                  // isMulti
                                  value={this.state.advise_information["invest_period"]}
                                  onChange={(e) => this.handleChangeRisk(e, 'invest_period')}
                                  options={this.state.masterList["investment_period"] || []}
                                />
                              </div>
                            </Col>
                          </Row>
                          <hr />
                          <br />
                          <span>
                            <u>
                              P.S.
                            </u>
                          </span>
                          <br />
                          <p>
                            All the above parameters are mandatory.
                            Kindly click next button to enter your recommendations/advise.
                          </p>

                        </div>
                      }
                      {this.state.wizard_step === 2 &&
                        // <div className="card-body">
                        <Form.Group as={Row} className="mb-3">
                          <Col sm={12}>
                            <Form.Label >Recommendations/Advise*</Form.Label>
                            {`  `}(Max 700 characters)
                            <textarea
                              className="form-control"
                              style={{
                                height: 400
                              }}
                              value={this.state.advise_information['recommendations']}
                              onChange={(e) => {
                                this.setState({
                                  advise_information: {
                                    ...this.state.advise_information,
                                    recommendations: e.target.value
                                  }
                                })
                              }}
                              maxLength="700"
                            // defaultValue={
                            //   values["address_information"]["address"] || ""
                            // }
                            // readOnly={values['is_bse_nominee_verified']}
                            />
                          </Col>
                        </Form.Group>
                        // </div>
                      }

                      {
                        <Fab
                          style={{ bottom: 32, left: -15, transform: 'scale(0.82)' }}
                          alwaysShowTitle={true}
                          mainButtonStyles={{
                            backgroundColor: "#d45950"
                          }}
                          icon={<i className="fa fa-arrow-left  fa-lg" ></i>}
                          onClick={() => {

                            if (this.state.wizard_step > 1)
                              this.setState({
                                wizard_step: this.state.wizard_step - 1
                              });
                            else {
                              this.setState({
                                display_mode: 'view adviselist',
                                is_advise_scheme_selected: false
                              });
                            }
                          }}
                        >
                        </Fab>
                      }
                      {
                        // this.state.wizard_step === 1 &&
                        <Fab
                          style={{ bottom: 32, right: -15, transform: 'scale(0.82)' }}
                          alwaysShowTitle={true}
                          mainButtonStyles={{
                            backgroundColor: "#c84bd1"
                          }}
                          icon={<i className="fa fa-arrow-right  fa-lg" ></i>}
                          onClick={() => {
                            if (this.state.wizard_step === 2) {
                              const confirmBox = window.confirm(
                                "Are you sure to submit your advise ?"
                              )
                              if (confirmBox === true) {
                                this.submitAdviseList('add');
                              }
                            }
                            else {
                              let _advise_information = true;
                              Object.keys(this.state.advise_information).forEach(key => {

                                if (key !== 'recommendations' && key !== 'validity_till') {
                                  if (!this.state.advise_information[key]['value']) {
                                    _advise_information = false;
                                  }
                                }

                              });

                              if (_advise_information)
                                this.setState({
                                  wizard_step: this.state.wizard_step + 1
                                });
                              else {
                                alert('Kindly choose all options and proceed');
                              }
                            }
                          }}
                        >
                        </Fab>
                      }
                    </div>
                  }

                </div>

              </div>
            </div>
          </div>

          {this.state.show_modal_subscribers && (
              <Modal show={this.state.show_modal_subscribers}
                onHide={() => this.setState({
                  show_modal_subscribers: false
                })}
                centered
                keyboard={false}
                fullscreen={true}
                backdrop="static"
              >
                <Modal.Header>
                  <p>Your Subscribers List</p>
                </Modal.Header>
                <Modal.Body>
                  {` Empty`}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="primary"
                    onClick={() => this.setState({
                      show_modal_subscribers: false
                    })}>
                    Cancel
                  </Button>
                </Modal.Footer>
              </Modal>
            )}

        </div>

      </>
    );
  }

}

const mapStateToProps = (state) => ({
  result: state.general.general_result,
  order_result: state.order.order_result,
  payment_result: state.payment.payment_result
});

export default connect(mapStateToProps,
  {
    addAdviseList, modifyAdviseList, createRazorPayOrder,
    submitExpertAdvise, loadSchemeAdviseList
  })(AdvisorAdviseList);

