import React from "react";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import { Button, Spinner, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import {
  dailyUpload
} from "../../../jsx/actions/verify";
import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";
import Select from 'react-select';

class DailyUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isloading: false,
      selectedFile: null,
      src_selectedFile: null,
      show_popup: false,
      pop_messagetext: "",

      file_upload_action_name: ``,

      action_mapping: [{
          name: "DAILY_UPLOAD_LUMPSUM_FUNDCONFIRM",
          value: "DAILY_UPLOAD_LUMPSUM_FUNDCONFIRM"
        },
        {        
          name: "DAILY_UPLOAD_XSIP_FUNDCONFIRM",
          value: "XSIP PAYMENT CONFIRMATION"
        },
        {
          name: "DAILY_PURCHASE_STATUS_REJECTION_REFUND",
          value: "PURCHASE STATUS REJECTION REFUND CONFIRMATION"
        }]
      
    };
  }

  componentDidMount() {

  }

  closePopup = () => {
    this.setState({
      show_popup: false,
      pop_messagetext: "",
    });
  };

  changeHandler = (event) => {

    if (event.target.files[0] &&
      event.target.files[0].type === "text/plain") {
      this.setState({
        selectedFile: event.target.files[0],
        src_selectedFile: URL.createObjectURL(event.target.files[0]),
      });
    } else {

      if (event.target.files.length > 0)
        this.setState({
          show_popup: true,
          pop_messagetext: "Upload file format should be of text only",
        });

    }
  };

  handleRadioChange = (value, attribute) => {

    this.setState({ [attribute]: value });

  };

  onUpload = () => {

    this.setState({ isloading: true });
    let _api_method_name = this.state.file_upload_action_name.toUpperCase();

    let formData = new FormData();

    let _file_category = this.state.action_mapping.filter(el => {
      return el['name'] === _api_method_name;
    });

    
    formData.append("upload_file", this.state.selectedFile);
    formData.append("file_category", _file_category.length > 0 ? _file_category[0]['value'] : ``);
    formData.append("filename", this.state.selectedFile);
    formData.append("report_category", _file_category.length > 0 ? _file_category[0]['value'] : ``);

    this.props
      .dailyUpload(_api_method_name, formData)
      .then((response) => {

        let _daily_upload_result = this.props.daily_upload_result;

        if (_daily_upload_result['code'] === '000')
          this.setState({
            isloading: false,
            show_popup: true,
            pop_messagetext: _daily_upload_result["messageText"],
            src_selectedFile: null,
            selectedFile: null
          });
        else
          this.setState({
            isloading: false,
            show_popup: true,
            pop_messagetext: _daily_upload_result["messageText"],
          });

      })
      .catch((err) => {
        this.setState({ isloading: false });
        alert(err);
      });
  };

  onRunNavUpdate = () => {

    this.setState({ isloading: true });

    if (this.state.file_upload_action_name.toUpperCase() === `DAILY_UPLOAD_NAV_UPDATE`) {

      this.props
        .dailyNavUpdate({
          request_action_from: `web ui`
        })
        .then((response) => {

          let _daily_upload_result = this.props.daily_upload_result;

          if (_daily_upload_result['code'] === '000')
            this.setState({
              isloading: false,
              show_popup: true,
              pop_messagetext: _daily_upload_result["messageText"],
              src_selectedFile: null,
              selectedFile: null
            });
          else
            this.setState({
              isloading: false,
              show_popup: true,
              pop_messagetext: _daily_upload_result["messageText"],
            });

        })
        .catch((err) => {
          alert(err);
        });
    }
    else if (this.state.file_upload_action_name.toUpperCase() === `DAILY_UPLOAD_SCRAP_HOLDINGS`) {

      let _api_method_name = this.state.file_upload_action_name.toUpperCase();

      let formData = new FormData();

      this.props
        .dailyUpload(_api_method_name, formData)
        .then((response) => {

          let _daily_upload_result = this.props.daily_upload_result;

          if (_daily_upload_result['code'] === '000')
            this.setState({
              isloading: false,
              show_popup: true,
              pop_messagetext: _daily_upload_result["messageText"],
              src_selectedFile: null,
              selectedFile: null
            });
          else
            this.setState({
              isloading: false,
              show_popup: true,
              pop_messagetext: _daily_upload_result["messageText"],
            });

        })
        .catch((err) => {
          this.setState({ isloading: false });
          alert(err);
        });

    }
  };

  render() {

    return (
      <>
        <Header1 />
        <Sidebar />

        {this.state.isloading &&
          <Spinner animation="border" variant="dark"
            style={{ position: "fixed", top: "50%", left: "50%" }} />
        }

        <div className="authincation section-padding">
          <div className="content-body" style={{ marginTop: 50 }}>
            <div className="container">
              <div class="row">

                <div class="col-xl-12">

                  <h4 className="text-center">
                    {`BSE StarMF Offline Data Uploads`}
                  </h4>
                  {<span>
                    <span style={{ color: 'green', fontSize: 14, fontWeight: 'bold' }}>
                      Note : </span> Upload file format should be of ,txt only.
                  </span>
                  }
                  <div style={{ height: 20 }} />

                  <Form>

                    <div>

                      <table style={{ width: '100%' }}>
                        <tr>
                          <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                            <Form.Check
                              type="radio"
                              label={
                                <span>
                                  <span style={{
                                    backgroundColor: '#f5f0e4',
                                    fontSize: 16
                                  }}>
                                    {`SCHEME MASTER ALL-SIP-STP-SWP`}
                                  </span>
                                </span>
                              }

                              name="categoryGeneral"
                              id="categoryGeneral"
                              value={`DAILY_UPLOAD_SCHEME_MASTER`}
                              checked={
                                this.state.file_upload_action_name === `DAILY_UPLOAD_SCHEME_MASTER`
                              }
                              onChange={(e) =>
                                this.handleRadioChange(
                                  `DAILY_UPLOAD_SCHEME_MASTER`,
                                  "file_upload_action_name"
                                )
                              }
                            />
                          </td>
                        </tr>
                        <div style={{ height: 20 }} />
                        <tr>
                          <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'left' }}>
                            <Form.Check
                              type="radio"
                              label={
                                <span>
                                  <span style={{
                                    backgroundColor: '#f5f0e4',
                                    fontSize: 16
                                  }}>
                                    {`LUMPSUM FUND CONFIRMATION REPORT`}
                                  </span>
                                </span>
                              }

                              name="categoryLumpsumFundConfirm"
                              id="categoryLumpsumFundConfirm"
                              value={`DAILY_UPLOAD_LUMPSUM_FUNDCONFIRM`}
                              checked={
                                this.state.file_upload_action_name === `DAILY_UPLOAD_LUMPSUM_FUNDCONFIRM`
                              }
                              onChange={(e) =>
                                this.handleRadioChange(
                                  `DAILY_UPLOAD_LUMPSUM_FUNDCONFIRM`,
                                  "file_upload_action_name"
                                )
                              }
                            />
                          </td>
                        </tr>
                        <div style={{ height: 20 }} />
                        <tr>
                          <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'left' }}>
                            <Form.Check
                              type="radio"
                              label={
                                <span>
                                  <span style={{
                                    backgroundColor: '#f5f0e4',
                                    fontSize: 16
                                  }}>
                                    {`XSIP FUND CONFIRMATION REPORT`}
                                  </span>
                                </span>
                              }

                              name="categoryXsipFundConfirm"
                              id="categoryXsipFundConfirm"
                              value={`DAILY_UPLOAD_XSIP_FUNDCONFIRM`}
                              checked={
                                this.state.file_upload_action_name === `DAILY_UPLOAD_XSIP_FUNDCONFIRM`
                              }
                              onChange={(e) =>
                                this.handleRadioChange(
                                  `DAILY_UPLOAD_XSIP_FUNDCONFIRM`,
                                  "file_upload_action_name"
                                )
                              }
                            />
                          </td>
                        </tr>
                        <div style={{ height: 20 }} />
                        <tr>
                          <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'left' }}>
                            <Form.Check
                              type="radio"
                              label={
                                <span>
                                  <span style={{
                                    backgroundColor: '#f5f0e4',
                                    fontSize: 16
                                  }}>
                                    {`PURCHASE STATUS REJECTION REFUND`}
                                  </span>
                                </span>
                              }

                              name="categoryPurchaseStatusConfirm"
                              id="categoryPurchaseStatusConfirm"
                              value={`DAILY_PURCHASE_STATUS_REJECTION_REFUND`}
                              checked={
                                this.state.file_upload_action_name === `DAILY_PURCHASE_STATUS_REJECTION_REFUND`
                              }
                              onChange={(e) =>
                                this.handleRadioChange(
                                  `DAILY_PURCHASE_STATUS_REJECTION_REFUND`,
                                  "file_upload_action_name"
                                )
                              }
                            />
                          </td>
                        </tr>                        
                        <div style={{ height: 20 }} />
                        <tr>
                          <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'left' }}>
                            <Form.Check
                              type="radio"
                              label={
                                <span>
                                  <span style={{
                                    backgroundColor: '#f5f0e4',
                                    fontSize: 16
                                  }}>
                                    {`RUN DAILY NAV UPDATE`}
                                  </span>
                                </span>
                              }

                              name="categoryNavUpdate"
                              id="categoryNavUpdate"
                              value={`DAILY_UPLOAD_NAV_UPDATE`}
                              checked={
                                this.state.file_upload_action_name === `DAILY_UPLOAD_NAV_UPDATE`
                              }
                              onChange={(e) =>
                                this.handleRadioChange(
                                  `DAILY_UPLOAD_NAV_UPDATE`,
                                  "file_upload_action_name"
                                )
                              }
                            />
                          </td>
                        </tr>
                        <div style={{ height: 20 }} />
                        <tr>
                          <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'left' }}>
                            <Form.Check
                              type="radio"
                              label={
                                <span>
                                  <span style={{
                                    backgroundColor: '#f5f0e4',
                                    fontSize: 16
                                  }}>
                                    {`RUN HOLDINGS LIST UPDATE`}
                                  </span>
                                </span>
                              }

                              name="categoryNavUpdate"
                              id="categoryNavUpdate"
                              value={`DAILY_UPLOAD_SCRAP_HOLDINGS`}
                              checked={
                                this.state.file_upload_action_name === `DAILY_UPLOAD_SCRAP_HOLDINGS`
                              }
                              onChange={(e) =>
                                this.handleRadioChange(
                                  `DAILY_UPLOAD_SCRAP_HOLDINGS`,
                                  "file_upload_action_name"
                                )
                              }
                            />
                          </td>
                        </tr>
                      </table>
                    </div>

                    <Form.Group controlId="formFile" >
                      {this.state.file_upload_action_name.length > 7 && (

                        this.state.file_upload_action_name === `DAILY_UPLOAD_NAV_UPDATE` ||
                          this.state.file_upload_action_name === `DAILY_UPLOAD_SCRAP_HOLDINGS` ?
                          <div className="text-center">
                            <div style={{ height: 15 }}></div>
                            <Button
                              className="btn btn-success btn-block"
                              variant="success"
                              onClick={this.onRunNavUpdate}
                              disabled={this.state.isloading}
                            >
                              Run Now
                            </Button>
                          </div>
                          :
                          <div>
                            <br />
                            <Form.Control
                              type="file"
                              onClick={(event) => { const { target = {} } = event || {}; target.value = ""; }}
                              onChange={this.changeHandler}
                            />
                          </div>
                      )}
                    </Form.Group>

                  </Form>

                  {this.state["src_selectedFile"] &&
                    this.state.file_upload_action_name !== `DAILY_UPLOAD_NAV_UPDATE` &&
                    this.state.file_upload_action_name !== `DAILY_UPLOAD_SCRAP_HOLDINGS` &&

                    <div className="card">

                      {this.state.isloading &&
                        <Spinner animation="border" variant="dark"
                          style={{ position: "fixed", top: "50%", left: "50%" }} />
                      }

                      <div className="card-body">

                        <form action="#">
                          <div style={{ height: 15 }}></div>
                          <div className="text-center">
                            <Button
                              className="btn btn-success btn-block"
                              variant="success"
                              onClick={this.onUpload}
                              disabled={this.state.isloading}
                            >
                              Upload Now
                            </Button>
                          </div>
                        </form>
                      </div>
                    </div>

                  }

                  {this.state.show_popup && (
                    <Modal show={this.state.show_popup} onHide={() => this.closePopup()}>
                      <Modal.Header>

                      </Modal.Header>
                      <Modal.Body>

                        <p>{this.state["pop_messagetext"]}</p>

                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="primary" onClick={() => this.closePopup()}>
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  )}

                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
// DailyUpload.propTypes = {
//     validateOtp: PropTypes.func.isRequired,
//     isAuthenticated: PropTypes.bool
// };

const mapStateToProps = (state) => ({
  daily_upload_result: state.verify.daily_upload_result,
});

export default connect(mapStateToProps, {
  dailyUpload
})(DailyUpload);
