
import React from "react";
import { ListGroup, Form, Alert, Modal, /*Card,*/ Button, Spinner, Row, Col, Container, } from "react-bootstrap";
import { connect } from "react-redux";
import Select from 'react-select';
import {
  loadOrderList,
  loadPaymentResponse,
  cancelOrder,
  createSwpOrder,
  twoFactorAuthentication,
  cancelSwpOrder,
  cancelStpOrder
} from "../../actions/orders";
import {
  reportGeneric
} from "../../actions/report";

import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import { Card, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import OTPInput, { ResendOTP } from "otp-input-react";

class OrderList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab_orders: 0,
      tab_single_order_child: 0,
      cardopen: false,
      list_single_orders: [],
      _list_single_orders: [],
      status_filter_list: [],
      status_filter_selected: null,

      nominee_list: [],
      pay_bank_mode_list: [],

      is_otp_email_required: true,

      two_factor_auth_order: {
        request_mode: '',
        display_text: '',
        is_otp_enabled: false,
        two_factor_authentication: {
          mobile_otp: '',
          email_otp: '',
          twofactor_token_id: ''
        }
      },

      swp_order: {
        display_text: '',
        swp_no_of_installments: '',
        swp_amount: '',
        swp_date: ``, // new Date(new Date().setDate(new Date().getDate() + 8)),
        swp_date_value: ``,
        swp_date_text: ``,
        swp_frequency: '',
        is_otp_enabled: false,
        two_factor_authentication: {
          mobile_otp: '',
          email_otp: '',
          twofactor_token_id: ''
        }
      },
      swp_minimum_date: new Date(new Date().setDate(new Date().getDate() + 8)),
      swp_maximum_date: new Date().setDate(new Date().getDate() + 40),

      stp_order: {
        display_text: '',
        is_otp_enabled: false,
        two_factor_authentication: {
          mobile_otp: '',
          email_otp: '',
          twofactor_token_id: ''
        }
      },

      display_mode: `order_list`,
      notification_html: ``,

      selected_order: {},

      bank_information: {},
      is_verify_enabled: false,
      is_confirm_chk_enabled: false,
      is_verified_editable: false,
      esignkyc_information: {},
      esignkyc_attachment_list: {},
      is_esign_verified: false,
      is_esign_button_disabled: false,
      isloading: false,
      show_popup: false,
      popup_headerText: '',
      popup_messageText: '',

      show_swp_modal: false,
      show_swp_cancel_modal: false,
      show_stp_cancel_modal: false,
      show_view_modal: false,
      modal_orderinfo: {},
      modal_header_text: '',
      show_modal_filter: false,
      show_modal_action: false,
      modal_action_name: ``,
      modal_action_order: {},

      show_modal_otp_confirm_dialog: false,
      modal_otp_header_text: '',
      modal_otp_body_html: '',
      modal_otp_info: {},
      modal_otp_confirm_action: '',

      show_modal_pin_confirm_dialog: false,
      modal_pin_confirm_action: '',
      modal_pin_confirm_value: '',
      modal_pin_confirm_dialog_messagetext: 'Enter your 4 digit pin to complete your action',

      show_swpdate_popup: false,
      popup_swp_date_list: [],

      tabValue: "active",


    };
  }

  componentDidMount() {

    window.addEventListener("scroll", () => {

      if (document.getElementById("navbar-orderlist-container") &&
        document.getElementById("navbar") &&
        document.getElementById("navbar").classList) {
        const topBorder = document
          .getElementById("navbar-orderlist-container")
          .getBoundingClientRect().top;

        topBorder >= 0
          ? document.getElementById("navbar").classList.remove("fixed-orderlist")
          : document.getElementById("navbar").classList.add("fixed-orderlist");
      }

    });

    this.setState({ isloading: true });
    this.onLoadOrderList(0);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', null);
  }

  handleConfirmCheck = (event) => {
    this.setState({
      is_confirm_chk_checked: !this.state.is_confirm_chk_checked,
    });
  };

  onLoadOrderList = (order_type) => {

    order_type = order_type === 0 ? "single" : 0; //(order_type === 1 ? 'sip' : 'xsip')

    this.props
      .loadOrderList({
        order_type: order_type,
      })
      .then((response) => {
        if (this.props.order_result["code"] !== "000") {
          this.setState({
            display_mode: ``,
            ["list_" + order_type + "_orders"]: [], ['_list_' + order_type + '_orders']: [],
            isloading: false
          });
          if (this.props.order_result["code"] !== "007")
            alert(this.props.order_result["messageText"]);
        }
        else {

          if (this.props.order_result["result"]) {

            let _filter_orders = (this.props.order_result["result"] || []).filter(el => {
              return el['mf_order_tab_category'] === this.state.tabValue;
            })

            this.setState({
              bank_information: this.props.order_result["bank_information"],
              ["list_" + order_type + "_orders"]: this.props.order_result["result"],
              ["_list_" + order_type + "_orders"]: _filter_orders, //this.props.order_result["result"],
              status_filter_list: this.props.order_result['status_filter_list'],
              nominee_list: this.props.order_result['nominee_list'],
              pay_bank_mode_list: this.props.order_result['pay_bank_mode_list'],
              show_swp_modal: false,
              show_swp_cancel_modal: false,
              show_stp_cancel_modal: false,
              show_view_modal: false,
              modal_orderinfo: {},
              modal_header_text: '',
              show_modal_filter: false,
              display_mode: 'order_list',
              isloading: false,
              selected_order: {}
            });

          }
          else
            this.setState({
              display_mode: ``,
              ["list_" + order_type + "_orders"]: [], ['_list_' + order_type + '_orders']: [],
              isloading: false
            });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  handleChangeStatus = (selectedOption) => {

    this.setState(
      {
        status_filter_selected: selectedOption
      },
      () => {

        let _filtered_list_single_orders = [];

        selectedOption.forEach(item => {
          _filtered_list_single_orders = _filtered_list_single_orders.concat(
            this.state.list_single_orders.filter(el => {
              return el[item['value']] === true;
            })
          );
        });

        this.setState({
          _list_single_orders: selectedOption.length > 0 ?
            _filtered_list_single_orders : this.state.list_single_orders
        });

      });

  };

  handleTabChange = (event, newValue) => {

    let _filter_orders = (this.state.list_single_orders || []).filter(el => {
      return el['mf_order_tab_category'] === newValue;
    })
    this.setState({
      tabValue: newValue,
      _list_single_orders: _filter_orders
    });

  };

  gotoCheckOutPage(request_mode, selected_order) {

    if ((selected_order["mf_order_action_list"] || []).length > 0) {

      selected_order["order_information"] =
        selected_order['mf_order_action_list'][selected_order["mf_order_action_list"].length - 1];
    }

    selected_order["bank_information"] = this.state.bank_information;
    selected_order['nominee_list'] = this.state.nominee_list;

    selected_order["request_mode"] = request_mode;
    selected_order['selected_pay_bank_mode'] = '';

    if (request_mode === 'redeem' || request_mode === 'invest_more') {

      this.props.history.push({
        pathname: "/orderEditCheckout/",
        state: {
          request_mode: request_mode,
          selected_order: selected_order
        },
      });

    }
    // else if (request_mode === 'invest_more') {
    //   this.setState({
    //     selected_order: selected_order,
    //     display_mode: 'invest_more'
    //   });
    // }
    else {
      this.setState({
        selected_order: selected_order,
        display_mode: 'order_pay_now'
      });
    }

    // this.props.history.push({
    //   pathname: "/paymentPage/",
    //   state: { selected_order: this.state.selected_order },
    // });



  }

  routeChange = (type, item, mode) => {

    /*if((window.matchMedia && window.matchMedia("(max-width: 480px)").matches) ){
        this.props.history.push({ 
          pathname: "/chartsmobile", 
          state: { param_data: {
              scheme_isin: item['scheme_isin'],
              scheme_code: item['scheme_code'], 
              scheme_name: item['scheme_name'] 
            }
          }
        });
    }
    else */

    /*{

      const win = window.open("/consolidatedStatement/"
        + item['mf_order_registration_no'] + '/' + item['mf_order_number'] + '/'
        + item['mf_order_type'].toLowerCase()
        + '/' + localStorage.getItem('token'), "_blank");
      win.focus();

    }*/

    this.props.history.push({
      pathname: '/consolidatedStatement/',
      state: {
        param_order: {
          order_registration_no: item['mf_order_registration_no'],
          order_number: item['mf_order_number'],
          order_type: item['mf_order_type'].toLowerCase(),
          order_id: item['_id'].toString()
        }
      }
    });

    /*
    this.props.history.push({ 
      pathname: "/consolidatedStatement/"
      + item['mf_order_registration_no'] + '/' + item['mf_order_number'] + '/'
      + item['mf_order_type'].toLowerCase()
      + '/' + localStorage.getItem('token')
    });
    */

  };

  showChildInformation(order_type, order, request_type) {

    if (request_type === "view") {

      this.setState({ isloading: true });

      this.props
        .loadPaymentResponse({
          request_mode: "order_payment_status",
          order_type: "single",
          order_number: order["mf_order_number"] || "",
          order_id: order["_id"],
          selected_order: order,
          is_mobile_version: true
        })
        .then((response) => {

          if (this.props.order_result) {

            if (this.props.order_result["code"] !== "000") {
              alert(this.props.order_result["messageText"]);
              this.setState({ isloading: false });
            }
            else {

              let _result = this.props.order_result["result"];
              if (_result) {

                let _list = this.state['list_' + order_type + '_orders'];
                let _new_list = [];

                _list.forEach(el => {

                  if (el['mf_order_registration_no'] === _result['mf_order_registration_no']) {
                    _result['show_child'] = true;
                    _new_list.push(_result);
                  }
                  else
                    _new_list.push(el);
                });

                let _filtered_list_single_orders = (this.state.status_filter_selected || []).length > 0 ?
                  [] : _new_list;

                (this.state.status_filter_selected || []).forEach(item => {
                  _filtered_list_single_orders = _filtered_list_single_orders.concat(
                    _new_list.filter(el => {
                      return el[item['value']] === true;
                    })
                  );
                });

                this.setState({
                  isloading: false,
                  modal_orderinfo: _result,
                  show_view_modal: true,
                  ["list_" + order_type + "_orders"]: _new_list,
                  ["_list_" + order_type + "_orders"]: _filtered_list_single_orders
                });

              }

            }

          }

        })
        .catch((err) => {
          alert(err);
        });
    } else {

      let _list = this.state["list_" + order_type + "_orders"];

      _list.forEach((el) => {
        if (
          order["mf_order_registration_no"] === el["mf_order_registration_no"]
        )
          el["show_child"] = false; // !el['show_child'];
      });

      this.filterListBasedOnStatus(_list, order_type);

    }
  }

  showSwpInformation(order_type, order, request_type) {

    let _result = this.state["list_" + order_type + "_orders"]; //this.props.order_result["result"];

    if (_result) {

      let _list = _result; //this.state["list_" + order_type + "_orders"];
      _list.forEach((el) => {

        if (order["order_id"] === el["order_id"]) {

          this.setState({
            [request_type]: true,
            // show_swp_modal: true,
            swp_order: {
              ...this.state.swp_order,
              is_otp_enabled: false,
              two_factor_authentication: {
                mobile_otp: '',
                email_otp: '',
                twofactor_token_id: ''
              }
            },
            modal_orderinfo: el
          });

        }
        else
          el['show_swp'] = false;
      });

      this.filterListBasedOnStatus(_list, order_type);

    }
  }

  showStpInformation(order_type, order, request_type) {

    let _result = this.state["list_" + order_type + "_orders"]; //this.props.order_result["result"];

    if (_result) {

      let _list = _result; //this.state["list_" + order_type + "_orders"];
      _list.forEach((el) => {

        if (order["order_id"] === el["order_id"]) {

          this.setState({
            [request_type]: true,
            // show_swp_modal: true,
            stp_order: {
              ...this.state.stp_order,
              is_otp_enabled: false,
              two_factor_authentication: {
                mobile_otp: '',
                email_otp: '',
                twofactor_token_id: ''
              }
            },
            modal_orderinfo: el
          });

        }
        else
          el['stp_order'] = false;
      });

      this.filterListBasedOnStatus(_list, order_type);

    }
  }

  showChildHistoryInformation(order_type, order, request_type) {

    if (request_type === "view") {
      this.setState({ isloading: true });
    }

    let _list = this.state["list_" + order_type + "_orders"];

    _list.forEach((el) => {
      if (order["mf_order_registration_no"] === el["mf_order_registration_no"])
        el["show_child_history"] = request_type === "view" ? true : false;
    });

    this.setState({
      isloading: false,
      ["list_" + order_type + "_orders"]: _list,
    });
  }

  onCancelOrder = (order_type, order) => {

    this.setState({ isloading: true });

    this.props
      .cancelOrder({
        order_type: order_type,
        client_info: order,
      })
      .then((response) => {

        const _response = this.props.order_result;

        if (_response["code"] !== "000") {
          alert(_response["messageText"]);
          this.setState({
            isloading: false
          });
        }
        else {

          if (_response["result"]) {
            this.setState({
              isloading: false,
              display_mode: 'scheme_action_completed',
              notification_html: _response['notification_html'] || _response['messageText'],
              show_modal_otp_confirm_dialog: false,
              modal_otp_header_text: '',
              modal_otp_body_html: ``,
              modal_otp_info: {},
              modal_otp_confirm_action: '',
              two_factor_auth_order: {
                ...this.state.two_factor_auth_order,
                request_mode: '',
                display_text: '',
                is_otp_enabled: false,
                two_factor_authentication: {
                  mobile_otp: '',
                  email_otp: '',
                  twofactor_token_id: ''
                }
              }
            });
            // this.filterListBasedOnStatus(_response["result"], order_type, 'bank_information');
          }
          else {
            this.setState({
              ['list_' + order_type + '_orders']: [],
              ['_list_' + order_type + '_orders']: []
            });
          }
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  handleChangeSelect = (selectedOption, order, input, category) => {

    if (input === 'swp_frequency') {

      if (category === 'swp') {

        this.setState({
          swp_order: {
            ...this.state.swp_order,
            swp_frequency: selectedOption.value
          }
        });

      }

    }
    else if (input === 'swp_no_of_installments') {

      if (category === 'swp')
        this.setState({
          swp_order: {
            ...this.state.swp_order,
            swp_no_of_installments: selectedOption.value
          }
        });

    }

  }

  onCreateSwpOrder = (order_type, order) => {

    this.setState({ isloading: true });

    this.props
      .createSwpOrder({
        order_type: order_type,
        client_info: Object.assign(
          { swp_order: this.state.swp_order }, order
        ),
      })
      .then((response) => {


        const _response = this.props.order_result;

        if (_response["code"] !== "000") {
          alert(_response["messageText"]);
          this.setState({
            isloading: false
          });
        }
        else {

          let _result = _response["result"];

          if (_result) {

            /*
            let _list = this.state['list_' + order_type + '_orders'];
            let _new_list = [];

            _list.forEach(el => {

              _result['show_swp'] = (el['_id'] === _result['_id']);
              _new_list.push(_result);

            });
            */

            this.setState({
              isloading: false,
              // ["list_" + order_type + "_orders"]: _new_list,
              show_swp_modal: false,
              swp_order: {
                ...this.state.swp_order,
                display_text: '',
                swp_no_of_installments: '',
                swp_amount: '',
                swp_date: ``, // new Date(new Date().setDate(new Date().getDate() + 8)),
                swp_date_value: ``,
                swp_date_text: ``,
                swp_frequency: '',
                is_otp_enabled: false,
                two_factor_authentication: {
                  mobile_otp: '',
                  email_otp: '',
                  twofactor_token_id: ''
                }
              },
              modal_orderinfo: {},
              status_filter_selected: [],
              display_mode: 'scheme_action_completed',
              notification_html: _response['notification_html'] || ``,
            });

            // this.filterListBasedOnStatus(_new_list, order_type);

          }

        }
      })
      .catch((err) => {
        alert(err);
      });

  };

  onCancelSwpOrder = (order_type, order) => {

    this.setState({ isloading: true });

    this.props
      .cancelSwpOrder({
        order_type: order_type,
        client_info: Object.assign(
          { swp_order: this.state.swp_order }, order
        ),
      })
      .then((response) => {

        const _response = this.props.order_result;

        if (_response["code"] !== "000") {
          alert(_response["messageText"]);
          this.setState({ isloading: false });
        }
        else {

          let _result = _response["result"];

          if (_result) {

            /*
            let _list = this.state['list_' + order_type + '_orders'];
            let _new_list = [];

            _list.forEach(el => {

              _new_list.push(el);
              if (el['tab_single_order_child'] === _result['tab_single_order_child']) {
                _result['show_swp'] = false;
                _new_list.push(_result);
              }
            });

            this.filterListBasedOnStatus(_new_list, order_type);
            */

            this.setState({
              isloading: false,
              display_mode: 'scheme_action_completed',
              notification_html: _result['notification_html'] || ``,
            });

          }

        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  onCancelStpOrder = (order_type, order) => {

    this.setState({ isloading: true });

    this.props
      .cancelStpOrder({
        order_type: order_type,
        client_info: Object.assign(
          { stp_order: this.state.stp_order }, order
        ),
      })
      .then((response) => {

        const _response = this.props.order_result;

        if (_response["code"] !== "000") {
          alert(_response["messageText"]);
          this.setState({ isloading: false });
        }
        else {

          let _result = _response['result'];
          if (_result) {

            let _list = this.state['list_' + order_type + '_orders'];
            let _new_list = [];

            _list.forEach(el => {

              _new_list.push(el);
              if (el['tab_single_order_child'] === _result['tab_single_order_child']) {
                _result['show_swp'] = false;
                _new_list.push(_result);
              }
            });

            this.setState({
              isloading: false,
              display_mode: 'scheme_action_completed',
              notification_html: _response['notification_html'] || ``,
            });

            this.filterListBasedOnStatus(_new_list, order_type);

          }

        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  showTransactionHistory(item) {

    let _html = `<div>we will show you soon</div>`;

    this.setState({
      show_popup: true,
      popup_headerText: 'Transaction History',
      popup_messageText: item['modal_transaction_history_html'] || _html,
    })

  }

  filterListBasedOnStatus = (order_result, order_type, attribute_name) => {

    let _new_list = (order_result || []);

    let _filtered_list_single_orders = (this.state.status_filter_selected || []).length > 0 ?
      [] : _new_list;

    (this.state.status_filter_selected || []).forEach(item => {
      _filtered_list_single_orders = _filtered_list_single_orders.concat(
        _new_list.filter(el => {
          return el[item['value']] === true;
        })
      );
    });

    let _state_parameters = {
      isloading: false,
      ['list_' + order_type + '_orders']: _new_list,
      ['_list_' + order_type + '_orders']: _filtered_list_single_orders
    }

    if (attribute_name)
      _state_parameters[[attribute_name]] = order_result[attribute_name];

    this.setState(_state_parameters);

  }

  resetFilter = (order_type) => {

    let _new_list = this.props.order_result["result"] || [];

    let _state_parameters = {
      show_modal_filter: false,
      status_filter_selected: [],
      ['list_' + order_type + '_orders']: _new_list,
      ['_list_' + order_type + '_orders']: _new_list
    }

    this.setState(_state_parameters);

  }

  closePopup = () => {
    this.setState({
      show_popup: false,
      popup_headerText: '',
      popup_messageText: ''
    });
  };

  createDefaultMarkup(text) {
    return {
      __html: text,
    };
  }

  htmlDefaultMarkup() {
    return {
      __html: this.state.notification_html,
    }
  };

  twoFactorInitiateCancellation = (action_name, header_text, item) => {

    let body_html = ``; //item[''];
    if (action_name === 'cancel order') {
      body_html = `<div class="form-group">
          <p>` + item['mf_order_scheme_name'] + `</p>
          <div class="d-flex justify-content-between mt-3"
            style={{ backgroundColor: '#dbcec5', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4 }}>
            <p class="mb-0">Exit Load Enabled: ` + (item['scheme_information']['param_exit_load_flag'] === 'Y' ? 'YES' : 'NO') + `</p>` +
        (item['scheme_information']['param_exit_load_flag'] === 'Y' ?
          `<p class="mb-0">Exit Load Value: ` + item['scheme_information']['param_exit_load'] + `</p>`
          :
          ``
        ) + `
          </div>

          <div class="d-flex justify-content-between mt-3"
            style={{ backgroundColor: '#dbcec5', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4 }}>
            <p class="mb-0">Lockin Period Enabled: ` + (item['scheme_information']['param_lockin_flag'] === 'Y' ? 'YES' : 'NO') + `</p>` +
        (item['scheme_information']['param_lockin_period_flag'] === 'Y' ?
          `<p class="mb-0">Lockin Period : ` + item['param_lockin_period'] + `</p>`
          :
          ``
        ) +
        `</div>
        </div>`;
    }
    // this.setState({
    //   show_modal_otp_confirm_dialog: true,
    //   modal_otp_header_text: header_text, //'Cancel Order',
    //   modal_otp_body_html: body_html,
    //   modal_otp_info: item,
    //   modal_otp_confirm_action: action_name //'cancel order'
    // });

    if (action_name === 'cancel order')
      this.sendOTP('two_factor_auth_order', action_name, {
        show_modal_otp_confirm_dialog: true,
        modal_otp_header_text: header_text,
        modal_otp_body_html: body_html,
        modal_otp_info: item,
        modal_otp_confirm_action: action_name
      });
  }

  sendOTP = (attribute_name, request_action, state_data) => {

    this.setState({ isloading: true });

    this.props
      .twoFactorAuthentication({
        client_info: {
          two_factor_authentication: this.state[attribute_name]['two_factor_authentication'],
          request_action: request_action || this.state.request_action, //'swp registration',
          is_otp_email_required: this.state.is_otp_email_required
        },
      })
      .then((response) => {

        if (this.props.order_result["code"] !== "000") {
          this.setState({ isloading: false });
          alert(this.props.order_result["messageText"]);
        }
        else {

          let _result = this.props.order_result; //["result"];

          if (_result && _result['twofactor_id']) {

            let _stateData = {
              isloading: false,
              [attribute_name]: {
                ...this.state[attribute_name],
                is_otp_enabled: true,
                two_factor_authentication: {
                  mobile_otp: '',
                  email_otp: '',
                  twofactor_token_id: _result['twofactor_id']
                },
              }
            };

            if (state_data)
              _stateData = Object.assign(_stateData, state_data);

            this.setState(_stateData);

          }

        }
      })
      .catch((err) => {
        alert(err);
      });

  }

  checkStatusAndPay = (item, is_redirect) => {

    this.setState({ isloading: true });

    this.props
      .loadPaymentResponse({
        request_mode: "order_payment_status",
        order_type: "single",
        order_number: item["mf_order_number"] || "",
        order_id: item["_id"],
        selected_order: item,
        is_mobile_version: true
      })
      .then((response) => {

        if (this.props.order_result) {

          if (this.props.order_result["code"] !== "000") {
            alert(this.props.order_result["messageText"]);
            this.setState({ isloading: false });
          }
          else {

            let _result = this.props.order_result["result"];

            if (_result) {

              if (_result['is_pay_now']) {

                this.setState({
                  isloading: false
                });

                if (is_redirect)
                  this.gotoCheckOutPage("paynow", _result['selected_order']);

              }
              else {

                let _list = this.state.list_single_orders;
                let _new_list = [];

                _list.forEach(el => {

                  if (el['mf_order_registration_no'] === _result['mf_order_registration_no']) {

                    if (!(_result['ignore_order'] || false)) {
                      // _result['show_child'] = true;
                      _new_list.push(_result);
                    }
                  }
                  else
                    _new_list.push(el);

                });

                let _filtered_list_single_orders = (this.state.status_filter_selected || []).length > 0 ?
                  [] : _new_list;

                (this.state.status_filter_selected || []).forEach(item => {
                  _filtered_list_single_orders = _filtered_list_single_orders.concat(
                    _new_list.filter(el => {
                      return el[item['value']] === true;
                    })
                  );
                });

                this.setState({
                  isloading: false,
                  /*
                  modal_orderinfo: !(_result['ignore_order'] || false) ? _result : {},
                  show_view_modal: !(_result['ignore_order'] || false),
                  */
                  list_single_orders: _new_list,
                  _list_single_orders: _filtered_list_single_orders
                });

                if (_result['ignore_order'])
                  alert(this.props.order_result['messageText']);

              }


            }

          }

        }

      })
      .catch((err) => {
        alert(err);
      });


  }

  refreshOrder = (item, request_mode) => {

    this.setState({ isloading: true });

    const method_attributes = item['mf_order_status_id'] === 8 ? 'allotment' : `allotment`;
    this.props
      .reportGeneric({
        method_attributes: method_attributes,
        request_mode: request_mode,
        order_number: item["mf_order_number"] || ``,
        order_id: item["_id"] || ``,
        client_code: item['mf_order_client_code'],
        is_mobile_version: true
      })
      .then((response) => {

        this.setState({ isloading: false });

        // if (this.props.order_result) {

        //   if (this.props.order_result["code"] !== "000") {
        //     alert(this.props.order_result["messageText"]);
        //     this.setState({ isloading: false });
        //   }
        //   else {

        //     let _result = this.props.order_result["result"];

        //     if (_result) {

        //       if (_result['is_pay_now']) {

        //         this.setState({
        //           isloading: false
        //         });

        //         if (is_redirect)
        //           this.gotoCheckOutPage("paynow", _result['selected_order']);

        //       }
        //       // else {

        //       //   let _list = this.state['list_single_orders'];
        //       //   let _new_list = [];

        //       //   _list.forEach(el => {

        //       //     if (el['mf_order_registration_no'] === _result['mf_order_registration_no']) {

        //       //       if (!(_result['ignore_order'] || false)) {
        //       //         _result['show_child'] = true;
        //       //         _new_list.push(_result);
        //       //       }
        //       //     }
        //       //     else
        //       //       _new_list.push(el);
        //       //   });

        //       //   let _filtered_list_single_orders = (this.state.status_filter_selected || []).length > 0 ?
        //       //     [] : _new_list;

        //       //   (this.state.status_filter_selected || []).forEach(item => {
        //       //     _filtered_list_single_orders = _filtered_list_single_orders.concat(
        //       //       _new_list.filter(el => {
        //       //         return el[item['value']] === true;
        //       //       })
        //       //     );
        //       //   });

        //       //   this.setState({
        //       //     isloading: false,
        //       //     modal_orderinfo: !(_result['ignore_order'] || false) ? _result : {},
        //       //     show_view_modal: !(_result['ignore_order'] || false),
        //       //     list_single_orders: _new_list,
        //       //     _list_single_orders: _filtered_list_single_orders
        //       //   });

        //       //   if (_result['ignore_order'])
        //       //     alert(this.props.order_result['messageText']);

        //       // }

        //     }

        //   }

        // }

      })
      .catch((err) => {
        alert(err);
      });

  }

  handle2FactorChange = (input, attribute, event) => e => {

    if (e)
      this.setState({
        [event]: {
          ...this.state[event],
          [attribute]: {
            ...this.state[event][attribute],
            [input]: e
          }
        }
      });
    else
      this.setState({ [input]: e });

  }

  render() {

    const renderButton = buttonProps => {
      return (
        <div className="row">
          <div class="col">
            <button onClick={this.sendOTP} {...buttonProps}
              class={buttonProps.remainingTime !== 0 ? `btn btn-outline-secondary` : `btn btn-outline-success`}>
              {`Resend OTP`}
            </button>
          </div>
          <div class="col-6" >
            {buttonProps.remainingTime !== 0
              ? <span>
                You can resend otp in
                <span style={{ fontWeight: 'bold', color: 'green' }}>{' '}{buttonProps.remainingTime}</span> secs.
              </span>
              : ``}
          </div>
        </div>
      );
    };
    const renderTime = () => React.Fragment;

    return (
      <>
        <Header1 />
        <Sidebar />
        <div className="content-body">

          {this.state.isloading && (
            <Spinner
              animation="border"
              variant="dark"
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                zIndex: 899,
              }}
            />
          )}

          <div className="container" style={{ marginBottom: 50 }}>

            <div className="row" >
              <div id="navbar-orderlist-container">
                <div id="navbar" >
                  <div className="card" style={{ backgroundColor: '#f8f8fb' }}>
                    <div className="card-header">
                      <div class="row">
                        <div class={this.state.display_mode === 'order_list' ||
                          this.state.display_mode === `` ?
                          "col" : "col-8"}>
                          <h5 >
                            {this.state.display_mode === 'order_pay_now' ? `Order Payment` :
                              (this.state.display_mode === 'invest_more' ? `Additional Order` : `Lumpsum Order list`)}</h5>
                        </div>
                        {
                          this.state.display_mode != 'order_list' &&
                          this.state.display_mode != `` &&
                          <div class="col-4" >
                            <button type="button"
                              className="btn-sm btn-primary"
                              style={{ marginLeft: -1, marginTop: '-15px', color: 'white', width: '110px' }}
                              onClick={() => {
                                this.onLoadOrderList();
                              }}
                            >
                              Refresh List
                            </button>
                          </div>
                        }

                      </div>

                    </div>

                    <div class="row" style={{ marginTop: '-10px' }}>
                      <hr />

                    </div>
                    {
                      this.state.display_mode != 'order_list' &&
                      this.state.display_mode == `` &&
                      <Alert variant="secondary text-center">
                        <Alert.Heading>
                          No orders found
                        </Alert.Heading>
                      </Alert>
                    }
                  </div>
                </div>
              </div>


              <div style={{ marginTop: 60, marginBottom: 30 }}>

                <div>

                  {/* {(this.state.list_single_orders || []).length < 1 &&
                    this.state.display_mode === `` && (
                      <Alert variant="secondary text-center">
                        <Alert.Heading>
                          {this.state.isloading
                            ? "We are fetching your orders"
                            : "No orders found"}
                        </Alert.Heading>
                      </Alert>
                    )} */}
                  {this.state.isloading &&
                    <Alert variant="secondary text-center">
                      <Alert.Heading>
                        We are fetching your orders
                      </Alert.Heading>
                    </Alert>
                  }

                  {this.state.display_mode === 'order_list' &&
                    <div>
                      {!this.state.isloading &&
                        <Box sx={{ width: '100%' }}>
                          <Tabs
                            value={this.state.tabValue}
                            onChange={this.handleTabChange}
                            textColor="secondary"
                            indicatorColor="secondary"
                            aria-label="secondary tabs example"
                          >
                            <Tab value="active" label="ACTIVE" />
                            <Tab value="pending" label={<span>PENDING <br />(Allotment)</span>} />
                            <Tab value="archives" label="ARCHIVES" />
                          </Tabs>
                        </Box>
                      }
                      <ListGroup >
                        {(this.state._list_single_orders || []).map((item, index) => (
                          <ListGroup.Item
                            key={index}
                            style={{
                              marginBottom: 10, border: '1px solid #a8acb3',
                              borderCollapse: 'collapse'
                            }}
                            action
                            variant="light"
                            className="wallet-address text-dark"
                          >
                            {item['mf_order_swp_enabled'] &&
                              <div class="row" style={{ marginBottom: 5 }}>

                                {/* <div class="col">
                                  {(item['mf_order_folio_no'] || '').length > 6 ?
                                    <span>
                                      {`FOLIO#`}
                                      <span
                                        style={{
                                          backgroundColor: item['mf_order_status'] === 'CXL' ? '#dae0e0' : '#e9f1f5',
                                          padding: 4, paddingLeft: 8, paddingRight: 8, borderRadius: 4
                                        }}
                                      >
                                        {item['mf_order_folio_no']}
                                      </span>
                                    </span>
                                    :
                                    ((item["mf_order_number"] || '').length < 4 ?
                                      <span style={{
                                        color: 'InfoText',
                                        backgroundColor: item['mf_order_status'] === 'CXL' ? '#ec9fa4' : '#a6c5ed',
                                        fontWeight: 500,
                                        padding: 4, paddingLeft: 8, paddingRight: 8, margin: -4, borderRadius: 4
                                      }}>
                                        Reg No:{" "} {item["mf_order_registration_no"]}
                                      </span>
                                      :
                                      (item['mf_order_folio_no'] || '').length < 4 &&
                                      <span style={{
                                        fontSize: 15
                                      }}>
                                        Order# {item["mf_order_number"]}
                                      </span>
                                    )
                                  }

                                </div> */}

                                {/* <div class="col-5">
                                  <span
                                    onClick={() => {
                                      this.showSwpInformation(
                                        "single",
                                        item,
                                        "show_swp_cancel_modal"
                                      );
                                    }}
                                    style={{
                                      // color: 'InfoText',
                                      backgroundColor: '#eab3f2',
                                      fontWeight: 500,
                                      padding: 4, paddingLeft: 8, paddingRight: 8, margin: -4, borderRadius: 4
                                    }} >
                                    {` `} SWP Enabled
                                    {` `}
                                    <i className="mdi mdi-arrow-right" ></i>
                                  </span>
                                </div> */}

                              </div>
                            }

                            <table style={{ width: '100%' }}>
                              <tr>

                                <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'center' }}>
                                  <span>
                                    {(item['mf_order_folio_no'] || '').length > 6 ? `Folio No` :
                                      ((item["mf_order_number"] || '').length < 4 ? `Reg No` :
                                        (item['mf_order_folio_no'] || '').length < 4 ? `Order#` : ``
                                      )
                                    }
                                  </span>
                                </td>
                                <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'center' }}>
                                  <span>
                                    Scheme Name
                                  </span>
                                  {(item['is_order_cancelled'] || item['tab_category_status'] === 'cancelled') &&
                                    <span style={{
                                      color: 'InfoText',
                                      backgroundColor: '#ec9fa4',
                                      fontWeight: 500,
                                      padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                                    }}>
                                      {`CANCELLED`}
                                    </span>
                                  }
                                  {item['tab_category_status'] === 'rejected' &&
                                    <span style={{
                                      color: 'InfoText',
                                      backgroundColor: '#ec9fa4',
                                      fontWeight: 500,
                                      padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                                    }}>
                                      {`REJECTED`}
                                    </span>
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                  <span style={{ margin: 4 }}>
                                    {(item['mf_order_folio_no'] || '').length > 6 ? item['mf_order_folio_no'] :
                                      ((item["mf_order_number"] || '').length < 4 ? item["mf_order_registration_no"] :
                                        (item['mf_order_folio_no'] || '').length < 4 ? item['mf_order_number'] : ``
                                      )
                                    }
                                  </span>
                                </td>

                                <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                  <span style={{ margin: 4 }}>
                                    {(item["mf_order_scheme_name"] || ``).replace('-', '')}
                                  </span>
                                </td>
                              </tr>
                            </table>
                            <Row style={{ marginBottom: 5 }}>
                            </Row>
                            {
                              item['mf_order_swp_enabled'] &&

                              <table style={{ width: '100%' }}>
                                <tr>
                                  <td onClick={() => {
                                    this.showSwpInformation(
                                      "single",
                                      item,
                                      "show_swp_cancel_modal"
                                    );
                                  }} style={{ height: 30, backgroundColor: '#dbc7ee', border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                    <span >
                                      {` `} SWP ENABLED
                                    </span>
                                  </td>
                                  <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                    <span >
                                      {item["mf_order_swp_information"]['frequency']}
                                    </span>
                                  </td>
                                  <td style={{ height: 30, backgroundColor: '#dbc7ee', border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                    <span >
                                      <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                      {item["mf_order_swp_information"]['amount']}
                                    </span>
                                  </td>
                                </tr>

                                <Row style={{ marginBottom: 10 }}>
                                </Row>

                              </table>
                            }
                            {/* <Row>
                              <Col>
                                <span>
                                  {item["mf_order_scheme_name"] || ""}
                                </span>
                              </Col>
                            </Row> */}
                            {(item['mf_order_last_status_message'] || []).length > 10 &&
                              <Row>
                                <Col>
                                  <div>
                                    <span style={{ color: 'green', borderBottomColor: 'red' }}>Recent Status </span>
                                    {` `}
                                    {/* <button type="button" class="btn-sm btn-warning"
                                        style={{ color: 'white' }}
                                        onClick={() => {
                                          this.checkStatusAndPay(item, false);
                                        }}
                                        disabled={this.state.isloading}>
                                        Refresh
                                      </button> */}
                                  </div>
                                  <span
                                    style={{
                                      fontSize: 14, marginBottom: 0
                                    }}
                                  >
                                    {item["mf_order_last_status_message"] || ""}
                                  </span>
                                </Col>

                              </Row>
                            }

                            {/* {item['mf_order_swp_enabled'] &&
                            <Row>
                              <span>
                                <span style={{
                                  // color: 'InfoText',
                                  backgroundColor: '#d1b66d',
                                  fontWeight: 500,
                                  padding: 4, paddingLeft: 8, paddingRight: 8, margin: -4, borderRadius: 4
                                }} >
                                  {` `} SWP Enabled
                                </span>
                                <span style={{ marginLeft: 10 }}>
                                  {item["mf_order_swp_information"]['frequency']} OF
                                  {` `}<span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                  {item["mf_order_swp_information"]['amount']}
                                </span>
                              </span>
                            </Row>
                          } */}


                            <Row>
                              {item['mf_order_stp_enabled'] ?
                                <span
                                  onClick={() => {
                                    this.props.history.push({
                                      pathname: "/stpList/",
                                      state: {
                                        request_mode: 'STP LIST',
                                        source_selected_order: item,
                                      },
                                    })
                                  }}
                                  style={{
                                    width: 110,
                                    backgroundColor: '#d1b66d',
                                    fontWeight: 500,
                                    padding: 4, margin: 11, borderRadius: 4
                                  }} >
                                  {` STP Enabled`}
                                  {` `}
                                  <i className="mdi mdi-arrow-right" ></i>
                                </span>
                                :
                                <div class="row" style={{ marginBottom: 5 }}></div>
                              }
                            </Row>

                            {
                              !item['mf_order_2fa_bsestarmf_submitted'] &&
                              item['mf_order_status'] !== 'CXL' &&
                              !item['mf_order_is_allotted'] &&
                              <div class="row">

                                <div class="col">
                                  {item["mf_order_message"]} {`  `}
                                </div>

                                <div class="row">
                                  <div class="col">
                                    <Button
                                      style={{ marginTop: -10, marginLeft: -15 }}
                                      className="text-success"
                                      variant="link" onClick={() => {
                                        this.setState({
                                          show_popup: true,
                                          popup_headerText: item['modal_rta_validation_information']['header'],
                                          popup_messageText:
                                            item['modal_rta_validation_information']['body'],
                                        })
                                      }}>
                                      Why ? </Button>
                                  </div>

                                </div>

                              </div>
                            }

                            {item['mf_order_2fa_bsestarmf_submitted'] &&
                              item['mf_order_message_is_showed'] &&
                              <div class="row">

                                <div class="col">
                                  {item["mf_order_message"]} {`  `}
                                  {!item['mf_order_is_allotted'] &&
                                    <Button
                                      style={{ marginTop: -4, marginLeft: -15 }}
                                      className="text-success"
                                      variant="link" onClick={() => {
                                        this.setState({
                                          show_popup: true,
                                          popup_headerText: item['modal_rta_validation_information']['header'],
                                          popup_messageText:
                                            item['modal_rta_validation_information']['body'],
                                        })
                                      }}>
                                      Why ? </Button>
                                  }
                                </div>
                                {/* {
                                !item['mf_order_is_allotted'] &&
                                <div class="col-4">
                                  <Button
                                    style={{ marginTop: -10, marginLeft: -15 }}
                                    className="text-success"
                                    variant="link" onClick={() => {
                                      this.setState({
                                        show_popup: true,
                                        popup_headerText: item['modal_rta_validation_information']['header'],
                                        popup_messageText:
                                          item['modal_rta_validation_information']['body'],
                                      })
                                    }}>
                                    Why ? </Button>
                                </div>
                              } */}
                              </div>
                            }



                            {item['mf_order_is_allotted'] &&
                              parseFloat(item['mf_order_overall_investment_amount']) > 1 ?

                              <table style={{ width: '100%' }}>
                                <tr>
                                  <td style={{ height: 30, width: '50px', backgroundColor: '#f7f5f5', border: '1px solid #e2e0e4', textAlign: 'left' }}>
                                    <span style={{
                                      margin: 4
                                    }}>
                                      Invested
                                    </span>
                                  </td>
                                  <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                    <span style={{
                                      margin: 4
                                    }}>
                                      <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                      {item['mf_order_overall_investment_amount']}
                                    </span>
                                  </td>
                                  <td style={{ height: 30, width: '50px', backgroundColor: '#f7f5f5', border: '1px solid #e2e0e4', textAlign: 'left' }}>
                                    <span style={{
                                      margin: 4
                                    }}>
                                      {parseFloat(item['returns_difference_amount'] || 0) < 0 ? `Loss    ` : `Profit  `}
                                    </span>
                                  </td>
                                  <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                    <span style={{
                                      margin: 4,
                                      color:
                                        parseFloat(item['returns_difference_amount'] || 0) < 0
                                          ? "#f57362"
                                          : parseFloat(item['returns_difference_amount'] || 0) === 0 ? '' : 'green',
                                    }}>
                                      <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                      {
                                        item['returns_difference_amount'] || 0
                                      }
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ height: 30, width: '50px', backgroundColor: '#f7f5f5', border: '1px solid #e2e0e4', textAlign: 'right' }}>

                                    <span style={{
                                      margin: 4
                                    }}>
                                      Returns
                                    </span>
                                  </td>
                                  <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                    <span style={{
                                      margin: 4,
                                      color: parseFloat(item['total_returns_amount'] || 0) -
                                        parseFloat(item['mf_order_overall_investment_amount']) < 0
                                        ? "#f57362"
                                        : (parseFloat(item['total_returns_amount'] || 0) -
                                          parseFloat(item['mf_order_overall_investment_amount'])) === 0 ? '' : 'green',
                                    }}>
                                      <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                      {item['total_returns_amount']}
                                    </span>
                                  </td>
                                  <td style={{ height: 30, width: '50px', backgroundColor: '#f7f5f5', border: '1px solid #e2e0e4', textAlign: 'right' }}>
                                    <span style={{
                                      margin: 4,
                                    }}>
                                      Percent
                                    </span>
                                  </td>
                                  <td style={{ height: 30, width: '50px', border: '1px solid #e2e0e4', textAlign: 'right' }}>
                                    <span style={{
                                      fontFamily: 'Verdana, sans-serif',
                                      margin: 4,
                                      fontWeight: 500,
                                      color:
                                        parseFloat(item['returns_difference_amount']) < 0
                                          ? "#f57362"
                                          : (parseFloat(item['returns_difference_amount']) === 0 ? '' : 'green'),
                                    }}>
                                      {parseFloat(item['returns_difference_amount']) > 0 ? `+` : ``}
                                      {item['total_returns_percent']}%</span>
                                  </td>
                                </tr>
                              </table>
                              :
                              <div>
                                {
                                  // item['mf_order_2fa_bsestarmf_submitted'] ||
                                  ((item['mf_order_status_id'] === 1 || item['mf_order_status_id'] === 6
                                    || item['mf_order_status_id'] === 8) ||
                                    (item['mf_order_last_status_message'] || []).length > 10) &&
                                  <div >
                                    {item['mf_order_is_paynow'] ?
                                      <button type="button" class="btn-sm btn-info"
                                        style={{ color: 'white' }}
                                        onClick={() => {
                                          this.checkStatusAndPay(item, true)
                                        }}
                                        disabled={this.state.isloading}>
                                        Pay Now
                                      </button>
                                      :
                                      <button type="button" class="btn-sm btn-info"
                                        style={{ color: 'white' }}
                                        onClick={() => {
                                          this.checkStatusAndPay(item, false);
                                          // this.refreshOrder(item, 'refresh');
                                        }}
                                        disabled={this.state.isloading}>
                                        Refresh
                                      </button>
                                    }

                                    <span>&nbsp;&nbsp;&nbsp;</span>

                                    <button type="button" class="btn-sm btn-danger"
                                      style={{ color: 'white' }}
                                      onClick={() => {
                                        this.twoFactorInitiateCancellation('cancel order', 'Cancel Order', item);
                                      }}
                                      disabled={this.state.isloading}>
                                      Cancel Order
                                    </button>
                                    {/* </div> */}

                                    <span>&nbsp;&nbsp;&nbsp;</span>
                                    {parseFloat(item['mf_order_overall_investment_amount']) < 1 &&
                                      <button type="button" class="btn-sm btn-success"
                                        style={{ color: 'white' }}
                                        onClick={() => {
                                          // this.setState({
                                          //   show_modal_action: true,
                                          //   modal_action_order: item
                                          // });
                                          this.showTransactionHistory(item);
                                        }}
                                        disabled={this.state.isloading}>
                                        Show History
                                      </button>
                                    }
                                  </div>
                                }
                              </div>
                            }
                            <Row style={{ marginBottom: 7 }}>
                            </Row>

                            {parseFloat(item['mf_order_overall_investment_amount']) > 1 &&
                              <table style={{ width: '100%', }}>
                                <tr>
                                  <td style={{ height: 30, textAlign: 'left' }}>
                                    <button type="button" class="btn-sm btn-success"
                                      style={{ color: 'white' }}
                                      onClick={() => {
                                        this.setState({
                                          show_modal_action: true,
                                          modal_action_order: item
                                        });
                                      }}
                                      disabled={this.state.isloading}>
                                      {parseFloat(item['mf_order_overall_investment_amount']) > 1 ?
                                        `Explore` : `Show History`
                                      }
                                    </button>
                                  </td>
                                  {item['mf_order_is_invest_more'] &&
                                    <td style={{ height: 30, textAlign: 'center' }}>

                                      <button type="button"
                                        class="btn-sm btn-info"
                                        style={{ color: 'white' }}
                                        onClick={() => {
                                          this.gotoCheckOutPage(
                                            "invest_more",
                                            item
                                          );
                                        }}
                                      >
                                        Invest More
                                      </button>
                                    </td>
                                  }
                                  {item["is_redeem_allowed"] &&
                                    <td style={{ height: 30, textAlign: 'right' }}>
                                      <button type="button"
                                        style={{ marginLeft: -5 }}
                                        class="btn-sm btn-warning"
                                        onClick={() => {
                                          this.gotoCheckOutPage("redeem", item);
                                        }}
                                      >
                                        Redeem
                                      </button>
                                    </td>
                                  }
                                </tr>
                              </table>
                            }

                            {/* <div class="row">
                              {item['mf_order_is_allotted'] &&
                                parseFloat(item['mf_order_overall_investment_amount']) > 1 ?
                                <div class="col-6">

                                  <div class="row">
                                    <div class="col-4">
                                      Invested
                                    </div>
                                    <div class="col-8">
                                      <span style={{
                                        // color: 'InfoText',

                                        fontWeight: 500,
                                        padding: 4, margin: 4, borderRadius: 4
                                      }}>
                                        <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                        {item['mf_order_overall_investment_amount']}
                                      </span>
                                    </div>
                                    <div style={{ height: 5 }} />
                                    <div class="row">
                                      <div class="col-4">
                                        Returns
                                      </div>
                                      <div class="col-8">
                                        <span style={{
                                          padding: 4, paddingLeft: 12, paddingRight: 8, margin: 4, borderRadius: 4,
                                          color: parseFloat(item['total_returns_amount'] || 0) -
                                            parseFloat(item['mf_order_overall_investment_amount']) < 0
                                            ? "#f57362"
                                            : (parseFloat(item['total_returns_amount'] || 0) -
                                              parseFloat(item['mf_order_overall_investment_amount'])) === 0 ? '' : 'green',
                                        }}>
                                          <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                          {item['total_returns_amount']}
                                        </span>

                                      </div>
                                    </div>
                                  </div>
                                  <hr />
                                </div>
                                :
                                <div class="col">
                                  {
                                    ((item['mf_order_status_id'] === 1 || item['mf_order_status_id'] === 6
                                      || item['mf_order_status_id'] === 8) ||
                                      (item['mf_order_last_status_message'] || []).length > 10) &&
                                    <div >
                                      {item['mf_order_is_paynow'] ?
                                        <button type="button" class="btn-sm btn-info"
                                          style={{ color: 'white' }}
                                          onClick={() => {
                                            this.checkStatusAndPay(item, true)
                                          }}
                                          disabled={this.state.isloading}>
                                          Pay Now
                                        </button>
                                        :
                                        <button type="button" class="btn-sm btn-info"
                                          style={{ color: 'white' }}
                                          onClick={() => {
                                            this.checkStatusAndPay(item, false);
                                          }}
                                          disabled={this.state.isloading}>
                                          Refresh
                                        </button>
                                      }

                                      <span>&nbsp;&nbsp;&nbsp;</span>

                                      <button type="button" class="btn-sm btn-danger"
                                        style={{ color: 'white' }}
                                        onClick={() => {
                                          this.twoFactorInitiateCancellation('cancel order', 'Cancel Order', item);
                                        }}
                                        disabled={this.state.isloading}>
                                        Cancel Order
                                      </button>

                                      <span>&nbsp;&nbsp;&nbsp;</span>
                                      {parseFloat(item['mf_order_overall_investment_amount']) < 1 &&
                                        <button type="button" class="btn-sm btn-success"
                                          style={{ color: 'white' }}
                                          onClick={() => {
                                            this.showTransactionHistory(item);
                                          }}
                                          disabled={this.state.isloading}>
                                          Show History
                                        </button>
                                      }
                                    </div>
                                  }
                                </div>
                              }

                              {parseFloat(item['mf_order_overall_investment_amount']) > 1 &&
                                <div class="col-6">
                                  <div class="row">
                                    <div class="col-4">
                                      {parseFloat(item['returns_difference_amount'] || 0) < 0 ? `Loss` : 'Profit'}
                                    </div>
                                    <div class="col-8">
                                      <span style={{
                                        padding: 4, margin: 4, borderRadius: 4,
                                        color:
                                          parseFloat(item['returns_difference_amount'] || 0) < 0
                                            ? "#f57362"
                                            : parseFloat(item['returns_difference_amount'] || 0) === 0 ? '' : 'green',
                                      }}>
                                        <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                        {
                                          item['returns_difference_amount'] || 0
                                        }
                                      </span>
                                    </div>
                                  </div>
                                  <div style={{ height: 5 }} />
                                  <div class="row">
                                    <div class="col-4">
                                      Returns%
                                    </div>
                                    <div class="col-8">
                                      <span style={{
                                        fontFamily: 'Verdana, sans-serif',
                                        padding: 4, margin: 4, borderRadius: 4,
                                        fontWeight: 500,
                                        color:
                                          parseFloat(item['returns_difference_amount']) < 0
                                            ? "#f57362"
                                            : (parseFloat(item['returns_difference_amount']) === 0 ? '' : 'green'),
                                      }}>
                                        {parseFloat(item['returns_difference_amount']) > 0 ? `+` : ``}
                                        {item['total_returns_percent']}</span>
                                    </div>
                                  </div>
                                  <hr />
                                </div>
                              }
                            </div> */}

                            {/* {parseFloat(item['mf_order_overall_investment_amount']) > 1 &&
                              <div class="row" >



                                <div class={item["is_redeem_allowed"] ||
                                  item["mf_order_is_invest_more"] ? "col-4" : "col-11"}>
                                  <button type="button" class="btn-sm btn-success"
                                    style={{ color: 'white' }}
                                    onClick={() => {
                                      this.setState({
                                        show_modal_action: true,
                                        modal_action_order: item
                                      });
                                    }}
                                    disabled={this.state.isloading}>
                                    {parseFloat(item['mf_order_overall_investment_amount']) > 1 ?
                                      `Explore` : `Show History`
                                    }
                                  </button>
                                </div>

                                {item['mf_order_is_invest_more'] &&
                                  <div class="col-5">
                                    <button type="button"
                                      class="btn-sm btn-info"
                                      style={{ color: 'white' }}
                                      onClick={() => {
                                        this.gotoCheckOutPage(
                                          "invest_more",
                                          item
                                        );
                                      }}
                                    >
                                      Invest More
                                    </button>
                                  </div>
                                }

                                <div class="col-3">
                                  {item["is_redeem_allowed"] &&
                                    <button type="button"
                                      style={{ marginLeft: -5 }}
                                      class="btn-sm btn-warning"
                                      onClick={() => {
                                        this.gotoCheckOutPage("redeem", item);
                                      }}
                                    >
                                      Redeem
                                    </button>
                                  }
                                </div>

                                
                              </div>
                            } */}

                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                      {(this.state._list_single_orders || []).length === 0 &&
                        !this.state.isloading &&
                        <Alert variant="secondary text-center">
                          <Alert.Heading>
                            No orders found
                          </Alert.Heading>
                        </Alert>
                      }
                    </div>
                  }

                  {this.state.display_mode === 'scheme_action_completed' &&
                    <Alert variant="text-center">
                      <Alert.Heading>
                        <div className="row">
                          <div className="col-lg-12">
                            <div dangerouslySetInnerHTML={this.htmlDefaultMarkup()} />
                          </div>
                        </div>
                      </Alert.Heading>
                    </Alert>
                  }

                  {[`order_pay_now`, `invest_more`].indexOf(this.state.display_mode) > -1 &&
                    <div>

                      {this.state.display_mode === 'invest_more' &&
                        // <div className="row">
                        //   <div className="col-lg-12">
                        <Card>
                          <CardContent>
                            {this.state.selected_order["mf_order_scheme_name"] || ``}
                            {` | `}
                            <b>{` Last Nav : `}</b>
                            <span style={{
                              fontWeight: 'bold', backgroundColor: '#f0e7e6', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                            }}>
                              {this.state.selected_order['nav_information'][0]['data'][0]['nav']}
                            </span>

                            <div class="d-flex justify-content-between mt-3"
                              style={{ backgroundColor: '#dbcec5', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4 }}>
                              <p class="mb-0">Exit Load Enabled: {this.state.selected_order['param_exit_load_flag'] === 'Y' ? 'YES' : 'NO'}</p>
                              {this.state.selected_order['param_exit_load_flag'] === 'Y' &&
                                <p class="mb-0">Exit Load Value: {this.state.selected_order['param_exit_load']}</p>
                              }
                            </div>

                            <div class="d-flex justify-content-between mt-3"
                              style={{ backgroundColor: '#dbcec5', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4 }}>
                              <p class="mb-0">Lockin Period Enabled: {this.state.selected_order['lockin_period_flag'] === 'Y' ? 'YES' : 'NO'}</p>
                              {this.state.selected_order['lockin_period_flag'] === 'Y' &&
                                <p class="mb-0">Lockin Period : {this.state.selected_order['lockin_period']}</p>
                              }
                            </div>

                          </CardContent>
                        </Card>
                        //     </div>
                        // </div>
                      }


                      <div className="row">
                        <div className="col-lg-12">
                          <Card>
                            <CardContent>
                              <div class="row">
                                {this.state.bank_information["beneficiary_bank_name"] && (
                                  <div style={{ marginTop: 10, textAlign: 'left' }} className="card">

                                    <div class="row">
                                      <span style={{ fontSize: 14 }}>
                                        ACCOUNT NUMBER :{" "}
                                        <b>{
                                          this.state.bank_information[
                                          "beneficiary_account_no"
                                          ]
                                        }</b>
                                      </span>
                                    </div>
                                    <div class="row">
                                      <span style={{ fontSize: 14 }}>
                                        IFSC :{" "}
                                        <b>{
                                          this.state.bank_information[
                                          "beneficiary_ifsc"
                                          ]
                                        }</b>
                                      </span>
                                    </div>
                                    <div class="row">
                                      <span style={{ fontSize: 14 }}>
                                        ACCOUNT TYPE :{" "}
                                        <b>{
                                          this.state.bank_information[
                                          "beneficiary_account_type"
                                          ]
                                        }</b>
                                      </span>
                                    </div>
                                    <div class="row">
                                      <span style={{ fontSize: 14 }}>
                                        <b>{
                                          ' ' + this.state.bank_information[
                                          "beneficiary_bank_name"
                                          ]
                                        }</b>

                                        <p>
                                          <b>{
                                            this.state.bank_information[
                                            "beneficiary_bank_branch_name"
                                            ]
                                          }</b>
                                        </p>
                                      </span>
                                    </div>

                                  </div>
                                )
                                }
                              </div>

                              <div class="row">
                                <div class="col-4">
                                  <label class="mr-sm-2">Mode Of Payment</label>
                                </div>
                                <div class="col-8">
                                  <Select
                                    value={this.state.pay_bank_mode_list.find(obj => obj.value === this.state.selected_order['selected_pay_bank_mode'])}
                                    onChange={(e) => {
                                      this.setState({
                                        selected_order: { ...this.state.selected_order, selected_pay_bank_mode: e.value }
                                      });
                                    }}
                                    options={this.state.pay_bank_mode_list}
                                  />

                                </div>
                              </div>
                              {this.state.selected_order['selected_pay_bank_mode'] === 'UPI' &&
                                <div class="row" style={{ marginBottom: 7 }}>
                                  <div class="col">
                                    <label class="mr-sm-2">Enter UPI ID (as per your linked bank account)</label>
                                    <input
                                      type="text"
                                      className="form-control text-center"
                                      style={{ fontSize: 16 }}
                                      value={this.state.selected_order['upi_id']}
                                      onChange={(event) =>
                                        this.setState({
                                          selected_order: { ...this.state.selected_order, upi_id: event.target.value }
                                        })
                                      }
                                      disabled={this.state.selected_order['selected_pay_bank_mode'].length < 3}
                                    />
                                  </div>
                                </div>}
                              <hr />
                              <div class="row">
                                <div class="col">
                                  <Button
                                    className="btn btn-success btn-block"
                                    variant="success"
                                    onClick={() => {
                                      this.props.history.push({
                                        pathname: "/paymentPage/",
                                        state: { selected_order: this.state.selected_order },
                                      });
                                    }}
                                    disabled={
                                      this.state.selected_order['selected_pay_bank_mode'].length < 3 ||
                                      this.state.isloading
                                    }
                                  >
                                    <span style={{ fontFamily: 'sans-serif', fontSize: 16 }}>
                                      Pay {` `}
                                      {/* </span>
                                    <span style={{ fontFamily: 'sans-serif', fontSize:16 }}> */}
                                      &#8377;{` `}{
                                        this.state.selected_order["mf_order_amount"]}
                                    </span>
                                  </Button>
                                </div>
                              </div>
                            </CardContent>
                          </Card>
                        </div>
                      </div>

                    </div>
                  }

                  {this.state
                    .show_popup && (
                      <Modal
                        show={
                          this.state
                            .show_popup
                        }
                        onHide={() =>
                          this.closePopup()
                        }
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        keyboard={false}
                        fullscreen={true}
                        backdrop="static"
                      >
                        <Modal.Header>{this.state.popup_headerText}</Modal.Header>
                        <Modal.Body>
                          <div style={{ margin: 0, padding: 0, fontSize: 12 }}
                            dangerouslySetInnerHTML={this.createDefaultMarkup(
                              this.state.popup_messageText
                            )}
                          />

                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="primary"
                            onClick={() =>
                              this.closePopup()
                            }
                          >
                            Close
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    )
                  }

                  {this.state.show_swp_modal &&
                    <Modal
                      show={
                        this.state
                          .show_swp_modal
                      }
                      onHide={() =>
                        this.setState({
                          show_swp_modal: false,
                          modal_orderinfo: {},
                          swp_order: {
                            ...this.state.swp_order,
                            is_otp_enabled: false,
                            two_factor_authentication: {
                              mobile_otp: '',
                              email_otp: '',
                              twofactor_token_id: ''
                            }
                          }
                        })
                      }
                      size="lg"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                      keyboard={false}
                      fullscreen={true}
                      backdrop="static"
                    >
                      <Modal.Header closeButton>
                        <h4>Systematic Withdrawal Plan </h4>

                      </Modal.Header>
                      <Modal.Body>
                        <div>

                          <p>
                            {this.state.modal_orderinfo['mf_order_scheme_name']}
                          </p>
                          <hr />
                          <div className="row">
                            <div class="col-6">
                              <div class="form-group">
                                <p class="mr-sm-2 text-gray-dark" style={{ marginBottom: 0 }}>No Of Withdrawals</p>

                                <Select
                                  value={(this.state.modal_orderinfo['swp_information']['installment_numbers_list'] || []).find(obj => obj.value === this.state.swp_order['swp_no_of_installments'] || '')}
                                  onChange={(e) => this.handleChangeSelect(e, this.state.modal_orderinfo, 'swp_no_of_installments', 'swp')}
                                  options={this.state.modal_orderinfo['swp_information']['installment_numbers_list']}
                                  isSearchable={false}
                                  isDisabled={this.state.swp_order['is_otp_enabled']}
                                />
                              </div>
                            </div>
                            <div class="col-6">
                              <div class="form-group">
                                <p class="mr-sm-2 text-gray-dark" style={{ marginBottom: 0 }}>Choose Frequency</p>
                                {this.state.modal_orderinfo['swp_information']['frequency_list'] &&
                                  <Select
                                    value={this.state.modal_orderinfo['swp_information']['frequency_list'].find(obj => obj.value === this.state.swp_order['swp_frequency'] || '')}
                                    onChange={(e) => this.handleChangeSelect(e, this.state.modal_orderinfo, 'swp_frequency', 'swp')}
                                    options={this.state.modal_orderinfo['swp_information']['frequency_list']}
                                    isSearchable={false}
                                    isDisabled={this.state.swp_order['is_otp_enabled']}
                                  />
                                }
                              </div>
                            </div>
                          </div>
                          <br />
                          <div className="row">
                            <div class="col">
                              <div class="form-group">
                                <p class="mr-sm-2 text-gray-dark" style={{ marginBottom: 0 }}>Enter Amount</p>
                                <input
                                  style={{ width: 190, letterSpacing: 2 }}
                                  name="swp_amount"
                                  class="form-control"
                                  placeholder=""
                                  maxLength="7"
                                  type="tel"
                                  value={this.state.swp_order['swp_amount']}
                                  disabled={this.state.swp_order['is_otp_enabled']}
                                  onKeyPress={event => {
                                    if (event.target.key === 'Enter') {
                                      event.preventDefault();
                                    }
                                  }}
                                  onChange={event => {
                                    const targetValue = event.target.value.replace(/\s/g, '');

                                    if (targetValue.length === 0)
                                      this.setState({ swp_order: { ...this.state.swp_order, swp_amount: targetValue } });
                                    else {
                                      if (targetValue.match(/^[0-9]+$/)) {
                                        if (parseFloat(targetValue) <= parseFloat(this.state.modal_orderinfo['swp_information']['maximum_amount']))
                                          this.setState({ swp_order: { ...this.state.swp_order, swp_amount: targetValue } });

                                      }

                                    }

                                  }

                                  }
                                />
                                <ul className="list-horizontal">
                                  <li>
                                    <span style={{ color: `#733dd1` }}>
                                      Min.
                                      <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                      {this.state.modal_orderinfo['swp_information']['minimum_amount']} {`  `}</span>
                                  </li>
                                  <li>
                                    <span style={{ color: `#0f967b` }}>Max.
                                      <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                      {this.state.modal_orderinfo['swp_information']['maximum_amount']}
                                    </span>
                                  </li>
                                </ul>

                              </div>
                            </div>
                          </div>
                          <br />
                          <div className="row">
                            <div className="col">
                              <div class="form-group">
                                <p class="mr-sm-2 text-gray-dark" style={{ marginBottom: 0 }}>SWP Date</p>

                                <input
                                  type="text"
                                  style={{ width: 190 }}
                                  name="swp_date"
                                  class="form-control"
                                  placeholder=""
                                  maxLength="2"
                                  readOnly={true}
                                  value={this.state.swp_order['swp_date_value']}
                                  onClick={event => {
                                    this.setState({
                                      show_swpdate_popup: true,
                                      popup_swp_date_list: this.state.modal_orderinfo['swp_information']['swp_date_list']
                                    })
                                  }}
                                  disabled={
                                    (this.state.swp_order['swp_frequency'] || '').length < 4
                                    || this.state.swp_order['is_otp_enabled']}

                                />
                              </div>
                            </div>
                            <div className="col-4">
                              <p></p>
                              <button
                                className="btn btn-success btn-sm"
                                style={{ fontSize: 12, marginBottom: 10 }}
                                disabled={
                                  this.state.isloading ||

                                  isNaN(this.state.swp_order['swp_no_of_installments']) ||
                                  isNaN(this.state.swp_order['swp_amount']) ||
                                  (this.state.swp_order['swp_amount']).trim().length === 0 ||
                                  (this.state.swp_order['swp_date'] || ``).toString().trim().length === 0 ||

                                  parseInt(this.state.swp_order['swp_amount']) > parseInt(this.state.modal_orderinfo['swp_information']['maximum_amount']) ||
                                  parseInt(this.state.swp_order['swp_amount']) < parseInt(this.state.modal_orderinfo['swp_information']['minimum_amount']) ||

                                  this.state.swp_order['swp_frequency'].length < 5 ||
                                  parseInt(this.state.swp_order['swp_no_of_installments']) < 1 ||
                                  parseInt(this.state.swp_order['swp_date']) < 1 ||
                                  this.state.swp_order['is_otp_enabled']
                                }
                                onClick={() => {

                                  if ((parseInt(this.state.swp_order['swp_no_of_installments']) * parseInt(this.state.swp_order['swp_amount']))
                                    > parseInt(this.state.modal_orderinfo['swp_information']['maximum_amount']))
                                    alert(`Sorry, Your number of withdrawals exceeds your available withdrawal amount`);
                                  else
                                    this.sendOTP('swp_order', 'swp registration');
                                }}
                              >
                                Register
                              </button>
                            </div>
                          </div>
                          {this.state.swp_order['swp_date_text'].toString().length > 10 &&
                            <div className="row">
                              <p style={{ color: `#733dd1`, marginBottom: 0 }} >
                                Your 1st withdrawal will be placed on {new Date(this.state.swp_order['swp_date_text']).toLocaleString("en-IN", { year: 'numeric', month: 'short', day: 'numeric' })}
                              </p>
                            </div>
                          }
                          <br />

                        </div>
                        {this.state.swp_order['is_otp_enabled'] &&
                          <div>
                            <hr />
                            <div className="row">
                              <div class="col">
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                  <Form.Label>Enter OTP received on your registered mobile</Form.Label>
                                  <OTPInput
                                    value={this.state.swp_order['two_factor_authentication']['mobile_otp']}
                                    onChange={this.handle2FactorChange('mobile_otp', 'two_factor_authentication', 'swp_order')}
                                    autoFocus
                                    OTPLength={6}
                                    otpType="number"
                                    secure
                                    isInputNum={true}
                                  />
                                </Form.Group>
                              </div>
                            </div>
                            {
                              this.state.is_otp_email_required &&
                              <Form.Group
                                className="mb-3"
                              >
                                <Form.Label>Enter OTP received on your registered email</Form.Label>
                                <OTPInput
                                  value={this.state.swp_order['two_factor_authentication']['email_otp']}
                                  onChange={this.handle2FactorChange('email_otp', 'two_factor_authentication', 'swp_order')}
                                  OTPLength={6}
                                  otpType="number"
                                  secure
                                  isInputNum={true}
                                />
                              </Form.Group>
                            }
                            <br />
                            <div className="row">
                              <ResendOTP maxTime={90} renderButton={renderButton} style={{ color: 'black', marginTop: 20 }} renderTime={renderTime}
                                onResendClick={(e) => { this.sendOTP('swp_order', 'swp registration') }}
                              />
                            </div>
                            <hr />
                            <div class="row">
                              <div class="col">
                                <br />
                                <button
                                  className="btn btn-success btn-sm"
                                  onClick={() => {
                                    this.onCreateSwpOrder("single", this.state.modal_orderinfo);
                                  }}
                                  disabled={
                                    this.state.isloading ||
                                    isNaN(this.state.swp_order['swp_no_of_installments']) ||
                                    isNaN(this.state.swp_order['swp_amount']) ||
                                    this.state.swp_order['swp_no_of_installments'].length < 1 ||
                                    this.state.swp_order['swp_amount'].length < 1 ||
                                    this.state.swp_order['swp_frequency'].length < 5 ||
                                    parseInt(this.state.swp_order['swp_no_of_installments']) < 1 ||
                                    parseInt(this.state.swp_order['swp_amount']) < parseInt(this.state.modal_orderinfo['minimum_swp_amount'])
                                  }
                                >
                                  Confirm SWP
                                </button>
                              </div>

                            </div>
                          </div>
                        }
                      </Modal.Body>

                      {this.state.isloading && (
                        <Spinner
                          animation="border"
                          variant="dark"
                          style={{
                            position: "fixed",
                            top: "50%",
                            left: "50%",
                            zIndex: 899,
                          }}
                        />
                      )}
                    </Modal>
                  }

                  <Modal
                    show={
                      this.state
                        .show_view_modal
                    }
                    onHide={() =>
                      this.setState({ show_view_modal: false })
                    }
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    keyboard={false}
                    fullscreen={true}
                    backdrop="static"
                  >
                    <Modal.Header closeButton>
                      {this.state.modal_orderinfo['mf_order_scheme_name']}
                    </Modal.Header>
                    <Modal.Body>
                      <div>
                        {this.state.isloading && (
                          <Spinner
                            animation="border"
                            variant="dark"
                            style={{
                              position: "fixed",
                              top: "50%",
                              left: "50%",
                              zIndex: 899,
                            }}
                          />
                        )}
                        {this.state.modal_orderinfo["show_child"] &&
                          (this.state.modal_orderinfo["display_installment_info_html"] || "")
                            .length > 7 && (
                            <div>
                              <div style={{ margin: 0, padding: 0 }}
                                dangerouslySetInnerHTML={this.createDefaultMarkup(
                                  this.state.modal_orderinfo[
                                  "display_installment_info_html"
                                  ]
                                )}
                              />

                              <hr />
                              <div >
                                <Card sx={{ backgroundColor: '#edf3f0', p: 2, borderRadius: 2 }}>
                                  <CardContent>

                                    <div className="row">

                                      <div class="col-6">
                                        <Button
                                          disabled={
                                            this.state.modal_orderinfo["mf_order_is_invest_more"] ===
                                            false
                                          }

                                          variant="primary"
                                          onClick={() => {
                                            this.gotoCheckOutPage(
                                              "invest_more",
                                              this.state.modal_orderinfo
                                            );
                                          }}
                                        >
                                          Invest More
                                        </Button>
                                      </div>
                                      <div class="col-5">
                                        <Button
                                          disabled={
                                            this.state.modal_orderinfo["is_redeem_allowed"] === false
                                          }
                                          className="btn btn-warning"
                                          onClick={() => {
                                            this.gotoCheckOutPage("redeem", this.state.modal_orderinfo);
                                          }}
                                        >
                                          Redeem
                                        </Button>
                                      </div>

                                    </div>
                                    <br />
                                    {this.state.modal_orderinfo["mf_order_is_paynow"] &&
                                      <div className="row">
                                        <Button
                                          disabled={
                                            this.state.modal_orderinfo["mf_order_is_paynow"] === false ||
                                            this.state.isloading
                                          }
                                          variant="primary"
                                          size="sm"
                                          onClick={() => {
                                            this.gotoCheckOutPage("paynow", this.state.modal_orderinfo);
                                          }}
                                        >
                                          Pay Now
                                        </Button>
                                        <br />
                                      </div>
                                    }

                                    {this.state.modal_orderinfo['is_swp_allowed'] &&
                                      this.state.modal_orderinfo['mf_order_swp_enabled'] &&
                                      <div className="row">
                                        <Button
                                          className="btn btn-warning"
                                          onClick={() => {
                                            /*this.onCancelSwpOrder("single", this.state.modal_orderinfo);*/
                                            this.twoFactorInitiateCancellation('cancel swp', 'Cancel SWP', this.state.modal_orderinfo);
                                          }}
                                        >
                                          Cancel SWP
                                        </Button>
                                      </div>
                                    }
                                    {/* {this.state.modal_orderinfo['is_swp_allowed'] &&
                                    !this.state.modal_orderinfo['mf_order_swp_enabled'] &&
                                    <div className="row">
                                      <Button
                                        className="btn btn-primary"
                                        style={{ color: 'white' }}
                                        onClick={() => {
                                          this.showSwpInformation(
                                            "single",
                                            this.state.modal_orderinfo,
                                            "view"
                                          );
                                        }}
                                      >
                                        Start SWP
                                      </Button>
                                    </div>
                                  } */}
                                    {(this.state.modal_orderinfo['is_swp_allowed'] &&
                                      (this.state.modal_orderinfo["show_swp"] ||
                                        this.state.modal_orderinfo["mf_order_swp_enabled"])) &&
                                      <br />
                                    }
                                    <div className="row">
                                      {` `}
                                      {this.state.modal_orderinfo["is_cancel_allowed"] && (
                                        <button
                                          className="btn btn-danger btn-block"
                                          onClick={() => {
                                            /*this.onCancelOrder("single", this.state.modal_orderinfo);*/
                                            this.twoFactorInitiateCancellation('cancel order', 'Cancel Order', this.state.modal_orderinfo);
                                          }}
                                          disabled={this.state.isloading}
                                        >
                                          Cancel Order
                                        </button>
                                      )}
                                    </div>
                                  </CardContent>
                                </Card>
                              </div>

                            </div>
                          )}
                      </div>

                    </Modal.Body>
                    {/* <Modal.Footer >
                    {`      `}
                    <Button
                      variant="secondary"
                      onClick={() =>
                        this.setState({ show_view_modal: false })
                      }
                    >
                      Done
                    </Button>
                  </Modal.Footer> */}
                  </Modal>

                  {/* {
                    ['order_pay_now', `invest_more`].indexOf(this.state.display_mode) < 0 &&
                    this.state.display_mode != `` &&
                    (this.state._list_single_orders || []).length > 0 &&
                    <Fab
                      style={{ top: 48, left: 135, transform: 'scale(0.8)' }}
                      alwaysShowTitle={true}
                      mainButtonStyles={{
                        backgroundColor: "#9589ab"
                      }}
                      icon={<i className="mdi mdi-filter" ></i>}
                      onClick={() => this.setState({ show_modal_filter: true })}
                    >
                    </Fab>
                  } */}

                  {/*this.state
                    .show_modal_filter && (*/
                    <Modal
                      show={
                        this.state
                          .show_modal_filter
                      }
                      onHide={() =>
                        this.setState({ show_modal_filter: false })
                      }
                      size="lg"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                    >
                      <Modal.Header>
                        <h5 style={{ textAlign: 'center' }}>
                          Search with status
                        </h5>
                      </Modal.Header>
                      <Modal.Body>
                        <div >
                          {!this.state.loading && (
                            <div>
                              <Container>
                                <Row >
                                  <Col>
                                    <div class="form-group">
                                      <div
                                        style={{ textAlign: 'center' }} >
                                        <Select
                                          placeholder={`-CHOOSE STATUS-`}
                                          isMulti
                                          value={this.state.status_filter_selected}
                                          onChange={this.handleChangeStatus}
                                          options={this.state.status_filter_list}
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                                <div
                                  class="form-group"
                                  style={{ marginBottom: 10, marginTop: 10 }}
                                ></div>
                              </Container>
                            </div>
                          )}
                        </div>

                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="primary"
                          onClick={() =>
                            this.resetFilter('single')
                          }
                        >
                          Reset Filter
                        </Button>
                        {`      `}
                        <Button
                          variant="secondary"
                          onClick={() =>
                            this.setState({ show_modal_filter: false })
                          }
                        >
                          Done
                        </Button>
                      </Modal.Footer>
                    </Modal>
                    /*)*/
                  }


                </div>
              </div>

              {this.state
                .show_swp_cancel_modal &&
                <Modal
                  show={
                    this.state
                      .show_swp_cancel_modal
                  }
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  keyboard={false}
                  fullscreen={true}
                  backdrop="static"
                >
                  <Modal.Header closeButton>
                    <h4>Systematic Withdrawal Plan | Cancellation</h4>
                  </Modal.Header>
                  <Modal.Body>
                    <div>

                      <p>
                        {this.state.modal_orderinfo['mf_order_scheme_name']}
                      </p>
                      <hr />
                      <p>
                        <span style={{ color: `#733dd1`, marginBottom: 0 }} >
                          Your 1st withdrawal placed on {new Date(this.state.modal_orderinfo['mf_order_swp_information']['start_date']).toLocaleString("en-IN", { year: 'numeric', month: 'short', day: 'numeric' })}
                        </span>
                      </p>
                      <table style={{ width: '100%' }}>
                        <tr>
                          <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', }}>
                            <span>
                              SWP Number
                            </span>
                          </td>
                          <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                            <span>
                              {this.state.modal_orderinfo['mf_order_swp_information']['registration_no']}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', }}>
                            <span>
                              Frequency
                            </span>
                          </td>
                          <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                            <span>
                              {this.state.modal_orderinfo['mf_order_swp_information']['frequency']}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', }}>
                            <span>
                              No Of Withdrawals
                            </span>
                          </td>
                          <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                            <span>
                              {this.state.modal_orderinfo['mf_order_swp_information']['no_of_installments']}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', }}>
                            <span>
                              SWP Amount
                            </span>
                          </td>
                          <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                            <span>
                              {this.state.modal_orderinfo['mf_order_swp_information']['amount']}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', }}>
                            <span>
                              End Date
                            </span>
                          </td>
                          <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                            <span>
                              {new Date(this.state.modal_orderinfo['mf_order_swp_information']['end_date']).toLocaleString("en-IN", { year: 'numeric', month: 'short', day: 'numeric' })}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', }}>
                            <span>
                              Recent Withdrawn Date
                            </span>
                          </td>
                          <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                            <span>
                              {this.state.modal_orderinfo['mf_order_swp_information']['recent_withdrew_date'].length < 10 ? `-` :
                                new Date(this.state.modal_orderinfo['mf_order_swp_information']['recent_withdrew_date']).toLocaleString("en-IN", { year: 'numeric', month: 'short', day: 'numeric' })}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', }}>
                            <span>
                              Total Amount Withdrawn
                            </span>
                          </td>
                          <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                            <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                            <span>
                              {this.state.modal_orderinfo['mf_order_swp_information']['total_withdrew_amount']}
                            </span>
                          </td>
                        </tr>
                      </table>
                      <div style={{ height: 15 }}></div>
                      <table style={{ width: '100%' }}>
                        <tr>
                          <td style={{ height: 30, textAlign: 'left', }}>
                            <button
                              className="btn btn-warning btn-sm"
                              onClick={() => {
                                this.setState({
                                  show_swp_cancel_modal: false,
                                  modal_orderinfo: {},
                                  swp_order: {
                                    ...this.state.swp_order,
                                    is_otp_enabled: false,
                                    two_factor_authentication: {
                                      mobile_otp: '',
                                      email_otp: '',
                                      twofactor_token_id: ''
                                    }
                                  }
                                })
                              }}
                            >
                              Close
                            </button>
                          </td>
                          <td style={{ height: 30, textAlign: 'right', }}>
                            <button
                              className="btn btn-success btn-sm"
                              disabled={
                                this.state.isloading ||
                                this.state.swp_order['is_otp_enabled']
                              }
                              onClick={() => {
                                this.sendOTP('swp_order', 'swp cancellation');
                              }}
                            >
                              Cancel SWP
                            </button>
                          </td>
                        </tr>
                      </table>

                    </div>
                    {this.state.swp_order['is_otp_enabled'] &&
                      <div>
                        <hr />
                        <div className="row">
                          <div class="col">
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                              <Form.Label>Enter OTP received on your registered mobile</Form.Label>
                              <OTPInput
                                value={this.state.swp_order['two_factor_authentication']['mobile_otp']}
                                onChange={this.handle2FactorChange('mobile_otp', 'two_factor_authentication', 'swp_order')}
                                autoFocus
                                OTPLength={6}
                                otpType="number"
                                secure
                                isInputNum={true}
                              />
                            </Form.Group>
                          </div>
                        </div>
                        {
                          this.state.is_otp_email_required &&
                          <Form.Group
                            className="mb-3"
                          >
                            <Form.Label>Enter OTP received on your registered email</Form.Label>
                            <OTPInput
                              value={this.state.swp_order['two_factor_authentication']['email_otp']}
                              onChange={this.handle2FactorChange('email_otp', 'two_factor_authentication', 'swp_order')}
                              OTPLength={6}
                              otpType="number"
                              secure
                              isInputNum={true}
                            />
                          </Form.Group>
                        }
                        {/* <br /> */}
                        <div className="row">
                          <ResendOTP maxTime={120} renderButton={renderButton} style={{ color: 'black', marginTop: 20 }} renderTime={renderTime}
                            onResendClick={(e) => { this.sendOTP('swp_order', 'swp cancellation') }}
                          />
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-6"></div>
                          <div class="col-6">
                            <table style={{ width: '100%' }}>
                              <tr>
                                <td style={{ height: 30, textAlign: 'right', }}>
                                  <button
                                    className="btn btn-success btn-sm"
                                    onClick={() => {
                                      this.onCancelSwpOrder("xsip", this.state.modal_orderinfo);
                                    }}
                                    disabled={
                                      this.state.isloading ||
                                      (this.state.is_otp_email_required && this.state.swp_order['two_factor_authentication']['email_otp'].length < 6) ||
                                      this.state.swp_order['two_factor_authentication']['mobile_otp'].length < 6
                                    }
                                  >
                                    Confirm
                                  </button>
                                </td>
                              </tr>
                            </table>
                          </div>

                        </div>
                      </div>
                    }
                  </Modal.Body>
                  {this.state.isloading && (
                    <Spinner
                      animation="border"
                      variant="dark"
                      style={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        zIndex: 899,
                      }}
                    />
                  )}
                </Modal>
              }

              {this.state.show_stp_cancel_modal && (
                <Modal
                  show={
                    this.state
                      .show_stp_cancel_modal
                  }
                  onHide={() =>
                    this.setState({
                      show_stp_cancel_modal: false,
                      modal_orderinfo: {},
                      stp_order: {
                        ...this.state.stp_order,
                        is_otp_enabled: false,
                        two_factor_authentication: {
                          mobile_otp: '',
                          email_otp: '',
                          twofactor_token_id: ''
                        }
                      }
                    })
                  }
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  keyboard={false}
                  fullscreen={true}
                  backdrop="static"
                >
                  <Modal.Header closeButton>
                    <h4>Systematic Transfer Plan - Cancellation </h4>

                  </Modal.Header>
                  <Modal.Body>
                    <div>

                      <p>
                        {this.state.modal_orderinfo['mf_order_scheme_name']}
                      </p>
                      <hr />
                      <div className="row">
                        <div class="col-5">
                          <div class="form-group">
                            <p class="mr-sm-2 text-gray-dark" style={{ marginBottom: 0 }}> Frequency</p>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-group">
                            {this.state.modal_orderinfo['mf_order_stp_information']['frequency']}
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div class="col-5">
                          <div class="form-group">
                            <p class="mr-sm-2 text-gray-dark" style={{ marginBottom: 0 }}>No Of Withdrawals</p>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-group">
                            <span>
                              {this.state.modal_orderinfo['mf_order_stp_information']['no_of_installments']}
                            </span>
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div class="col-5">
                          <div class="form-group">
                            <p class="mr-sm-2 text-gray-dark" style={{ marginBottom: 0 }}>SWP Amount</p>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-group">
                            <span>
                              {this.state.modal_orderinfo['mf_order_stp_information']['amount']}
                            </span>
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-5">
                          <div class="form-group">
                            End Date
                          </div>
                        </div>
                        <div className="col-6">
                          <span>
                            {new Date(this.state.modal_orderinfo['mf_order_stp_information']['end_date']).toLocaleString("en-IN", { year: 'numeric', month: 'short', day: 'numeric' })}
                          </span>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-6">
                          <div class="form-group">
                            Recent Withdrew Date
                          </div>
                        </div>
                        <div className="col-6">
                          <span>
                            {this.state.modal_orderinfo['mf_order_stp_information']['recent_withdrew_date'].length < 10 ? `-` :
                              new Date(this.state.modal_orderinfo['mf_order_stp_information']['recent_withdrew_date']).toLocaleString("en-IN", { year: 'numeric', month: 'short', day: 'numeric' })}
                          </span>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-6">
                          <div class="form-group">
                            Total Withdrew Amount
                          </div>
                        </div>
                        <div className="col-6">
                          <span>
                            {this.state.modal_orderinfo['mf_order_stp_information']['total_withdrew_amount']}
                          </span>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <p style={{ color: `#733dd1`, marginBottom: 0 }} >
                          Your 1st withdrawal will be placed on {new Date(this.state.modal_orderinfo['mf_order_stp_information']['start_date']).toLocaleString("en-IN", { year: 'numeric', month: 'short', day: 'numeric' })}
                        </p>
                        <br />
                      </div>
                      <br />
                      <div class="row">
                        <div class="col-1"></div>
                        <div class="col-10">
                          <button
                            className="btn btn-success btn-sm"
                            style={{ fontSize: 12, marginBottom: 10, width: 280 }}
                            disabled={
                              this.state.isloading ||
                              this.state.swp_order['is_otp_enabled']
                            }
                            onClick={() => {
                              this.sendOTP('stp_order', 'stp cancellation');
                            }}
                          >
                            Cancel STP
                          </button>
                        </div>
                        <div class="col-1"></div>
                      </div>
                    </div>
                    {this.state.stp_order['is_otp_enabled'] &&
                      <div>
                        <hr />
                        <div className="row">
                          <div class="col">
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                              <Form.Label>Enter OTP received on your registered mobile</Form.Label>
                              <OTPInput
                                value={this.state.stp_order['two_factor_authentication']['mobile_otp']}
                                onChange={this.handle2FactorChange('mobile_otp', 'two_factor_authentication', 'swp_order')}
                                autoFocus
                                OTPLength={6}
                                otpType="number"
                                secure
                                isInputNum={true}
                              />
                            </Form.Group>
                          </div>
                        </div>
                        <br />
                        <Form.Group
                          className="mb-3"
                        >
                          <Form.Label>Enter OTP received on your registered email</Form.Label>
                          <OTPInput
                            value={this.state.stp_order['two_factor_authentication']['email_otp']}
                            onChange={this.handle2FactorChange('email_otp', 'two_factor_authentication', 'swp_order')}
                            OTPLength={6}
                            otpType="number"
                            secure
                            isInputNum={true}
                          />
                        </Form.Group>
                        <br />
                        <div className="row">
                          <ResendOTP maxTime={120} renderButton={renderButton} style={{ color: 'black', marginTop: 20 }} renderTime={renderTime}
                            onResendClick={(e) => { this.sendOTP('stp_order', 'stp cancellation') }}
                          />
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col">
                            <br />
                            <button
                              className="btn btn-success btn-sm"
                              onClick={() => {
                                this.onCancelStpOrder("single", this.state.modal_orderinfo);
                              }}
                              disabled={
                                this.state.isloading
                              }
                            >
                              Confirm
                            </button>
                          </div>

                        </div>
                      </div>
                    }
                  </Modal.Body>
                  {this.state.isloading && (
                    <Spinner
                      animation="border"
                      variant="dark"
                      style={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        zIndex: 899,
                      }}
                    />
                  )}
                </Modal>
              )}

              <Modal
                show={this.state.show_modal_otp_confirm_dialog}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                keyboard={false}
                fullscreen={true}
                backdrop="static"
              >
                {this.state.isloading && (
                  <Spinner
                    animation="border"
                    variant="dark"
                    style={{
                      position: "fixed",
                      top: "50%",
                      left: "50%",
                      zIndex: 899,
                    }}
                  />
                )}
                <Modal.Header>
                  {this.state.modal_otp_header_text}
                </Modal.Header>
                <Modal.Body>
                  <div class="row">
                    <div
                      dangerouslySetInnerHTML={this.createDefaultMarkup(
                        this.state.modal_otp_body_html
                      )}
                    />
                  </div>
                  {!this.state.two_factor_auth_order['is_otp_enabled'] &&
                    <div className="row">
                      <hr />
                      <Button className="btn btn-warning"
                        disabled={this.state.isloading}
                        onClick={() => {
                          this.sendOTP('two_factor_auth_order', this.state.modal_otp_confirm_action);
                        }}>
                        Cancel Now
                      </Button>
                    </div>
                  }
                  {this.state.two_factor_auth_order['is_otp_enabled'] &&
                    <div>
                      <hr />
                      <div className="row">
                        <div class="col">
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Enter OTP received on your registered mobile</Form.Label>
                            <OTPInput
                              value={this.state.two_factor_auth_order['two_factor_authentication']['mobile_otp']}
                              onChange={this.handle2FactorChange('mobile_otp', 'two_factor_authentication', 'two_factor_auth_order')}
                              autoFocus
                              OTPLength={6}
                              otpType="number"
                              secure
                              isInputNum={true}
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <br />
                      <Form.Group
                        className="mb-3"
                      >
                        <Form.Label>Enter OTP received on your registered email</Form.Label>
                        <OTPInput
                          value={this.state.two_factor_auth_order['two_factor_authentication']['email_otp']}
                          onChange={this.handle2FactorChange('email_otp', 'two_factor_authentication', 'two_factor_auth_order')}
                          OTPLength={6}
                          otpType="number"
                          secure
                          isInputNum={true}
                        />
                      </Form.Group>
                      <br />
                      <div className="row">
                        <ResendOTP maxTime={120} renderButton={renderButton} style={{ color: 'black', marginTop: 20 }} renderTime={renderTime}
                          onResendClick={(e) => { this.sendOTP('two_factor_auth_order') }}
                        />
                      </div>

                    </div>
                  }
                </Modal.Body>
                <Modal.Footer>
                  {this.state.two_factor_auth_order['is_otp_enabled'] &&
                    <button
                      className="btn btn-success btn-sm"
                      onClick={() => {

                        if (this.state.modal_otp_confirm_action === 'start swp') {
                          this.onCreateSwpOrder(
                            "single",
                            Object.assign(this.state.modal_otp_info,
                              {
                                two_factor_authentication: this.state.two_factor_auth_order['two_factor_authentication']
                              })
                          );
                        }
                        else if (this.state.modal_otp_confirm_action === 'cancel order') {
                          this.onCancelOrder(
                            "single",
                            Object.assign(this.state.modal_otp_info,
                              {
                                two_factor_authentication: this.state.two_factor_auth_order['two_factor_authentication']
                              })
                          );
                        }
                        else if (this.state.modal_otp_confirm_action === 'cancel swp') {
                          this.onCancelSwpOrder(
                            "single",
                            Object.assign(this.state.modal_otp_info,
                              {
                                two_factor_authentication: this.state.two_factor_auth_order['two_factor_authentication']
                              })
                          );
                        }

                      }}
                      disabled={
                        this.state.isloading ||
                        this.state.two_factor_auth_order['two_factor_authentication']['mobile_otp'].length < 6 ||
                        this.state.two_factor_auth_order['two_factor_authentication']['email_otp'].length < 6
                      }
                    >
                      Confirm
                    </button>
                  }
                  <Button variant="primary"
                    onClick={() => this.setState({
                      show_modal_otp_confirm_dialog: false,
                      modal_otp_header_text: '',
                      modal_otp_body_html: ``,
                      modal_otp_info: {},
                      modal_otp_confirm_action: '',
                      two_factor_auth_order: {
                        ...this.state.two_factor_auth_order,
                        request_mode: '',
                        display_text: '',
                        is_otp_enabled: false,
                        two_factor_authentication: {
                          mobile_otp: '',
                          email_otp: '',
                          twofactor_token_id: ''
                        }
                      }
                    })}>
                    Close
                  </Button>
                </Modal.Footer>

              </Modal>
              {/* )} */}


              {this.state.show_modal_pin_confirm_dialog && (
                <Modal show={this.state.show_modal_otp_confirm_dialog}
                  onHide={() => this.setState({
                    show_modal_pin_confirm_dialog: false,
                    modal_pin_confirm_action: '',
                    modal_pin_confirm_value: ''
                  })}
                  centered
                  keyboard={false}
                  fullscreen={false}
                  backdrop="static"
                >
                  <Modal.Header>

                  </Modal.Header>
                  <Modal.Body>

                    <p>{this.state["modal_pin_confirm_dialog_messagetext"]}</p>
                    <OTPInput
                      value={this.state.modal_pin_confirm_value}
                      onChange={event => this.setState({ modal_pin_confirm_value: event })}
                      autoFocus
                      OTPLength={4}
                      otpType="number"
                      disabled={false}
                      secure
                      isInputNum={true}
                    />
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="primary"
                      disabled={this.state.modal_pin_confirm_value.length !== 4}
                      onClick={() => {

                        if (this.state.modal_pin_confirm_action === 'start swp') {
                          this.onCreateSwpOrder(
                            "single",
                            Object.assign(this.state.modal_orderinfo,
                              {
                                two_factor_authentication: this.state.two_factor_auth_order['two_factor_authentication']
                              })
                          );
                        }
                        else if (this.state.modal_pin_confirm_action === 'cancel order') {
                          this.onCancelOrder(
                            "single",
                            Object.assign(this.state.modal_orderinfo,
                              {
                                two_factor_authentication: this.state.two_factor_auth_order['two_factor_authentication']
                              })
                          );
                        }
                        else if (this.state.modal_pin_confirm_action === 'cancel swp') {
                          this.onCancelSwpOrder(
                            "single",
                            Object.assign(this.state.modal_orderinfo,
                              {
                                two_factor_authentication: this.state.two_factor_auth_order['two_factor_authentication']
                              })
                          );
                        }

                      }}>
                      Confirm
                    </Button>
                    <Button variant="primary"
                      onClick={() => this.setState({
                        show_modal_otp_confirm_dialog: false,
                        modal_pin_confirm_action: '',
                        modal_pin_confirm_value: ''
                      })}>
                      Cancel
                    </Button>
                  </Modal.Footer>
                </Modal>
              )}

            </div>

          </div>
        </div>

        {this.state
          .show_swpdate_popup && (
            <Modal

              show={
                this.state
                  .show_swpdate_popup
              }
              onHide={() =>
                this.setState({ show_swpdate_popup: false })
              }

              size="sm"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>Choose a monthly SIP date</Modal.Header>
              <Modal.Body>
                <div>
                  {[1, 6, 11, 16, 21, 26].map((item, index) => (
                    <div className="row" key={index}>
                      <br />
                      {[0, 1, 2, 3, 4].map((increment, _index) => (

                        <div class="col-2" key={_index}>
                          {
                            this.state.popup_swp_date_list.indexOf((item + increment).toString()) > -1 ?
                              <button type="button"
                                className="btn-sm btn-success"
                                style={{ color: 'white', width: '30px', marginTop: '3px', marginLeft: '3px' }}
                                onClick={() => {

                                  var firstSwpDate = new Date();
                                  firstSwpDate = firstSwpDate.setMonth(firstSwpDate.getMonth() + 1);

                                  this.setState({
                                    show_swpdate_popup: false,
                                    swp_order: {
                                      ...this.state.swp_order,
                                      swp_date: (item + increment),
                                      swp_date_value: moment('1982-11-' +
                                        ((item + increment) < 10 ? `0` : ``) + (item + increment)).format('Do ')
                                        + ' of every ' + this.state.swp_order['swp_frequency'].toLowerCase().replace('ly', ''),
                                      swp_date_text: moment(new Date(firstSwpDate).setDate(parseInt((item + increment))))
                                    }
                                  })
                                }}
                                disabled={this.state.popup_swp_date_list.indexOf((item + increment).toString()) === -1}
                              >
                                {item + increment}
                              </button>
                              :
                              <div>
                                {(item + increment <= 28) &&
                                  <p
                                    style={{ width: '30px', textAlign: 'center', backgroundColor: '#dbcec5', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4 }}>
                                    {item + increment}
                                  </p>
                                }
                              </div>
                          }
                        </div>

                      ))}
                    </div>
                  ))}
                </div>
              </Modal.Body>
            </Modal>
          )}

        {
          <Modal
            show={
              this.state
                .show_modal_action
            }
            onHide={() =>
              this.setState({ show_modal_action: false })
            }
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
            backdrop="static"
          >
            <Modal.Header>
              <h5 style={{ textAlign: 'center' }}>
                Choose an action
              </h5>
            </Modal.Header>
            <Modal.Body>
              <div >

                {this.state.loading ?
                  <Spinner
                    animation="border"
                    variant="dark"
                    style={{
                      position: "fixed",
                      top: "50%",
                      left: "50%",
                      zIndex: 899,
                    }}
                  />
                  :
                  (
                    <div>

                      <Container>
                        <Row >
                          <Col>
                            <div class="form-group">
                              <div class="row">
                                <div class="col">
                                  <Form.Check
                                    type="radio"
                                    label={
                                      <span>
                                        <i className="fa fa-history fa-lg"></i>
                                        <span style={{
                                          backgroundColor: '#f5f0e4',
                                          fontSize: 18,
                                          padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                                        }}>
                                          {`Show History`}
                                        </span>
                                      </span>
                                    }
                                    style={{ fontSize: 18 }}
                                    name="show_history"
                                    id={`show_history`}
                                    value={`show_history`}
                                    checked={
                                      this.state.modal_action_name === `show_history`
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        modal_action_name: `show_history`
                                      })
                                    }
                                    key={`action_show_history`}
                                  />
                                </div>
                              </div>
                              {this.state.modal_action_order["is_view_statement_allowed"] &&
                                <br />
                              }
                              {this.state.modal_action_order["is_view_statement_allowed"] &&
                                <div class="row">
                                  <div class="col">
                                    <Form.Check
                                      type="radio"
                                      label={
                                        <span >
                                          <i className="fa fa-file-pdf-o fa-lg"></i>
                                          <span style={{
                                            backgroundColor: '#f5f0e4',
                                            fontSize: 18,
                                            padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                                          }}>
                                            {`View Statement`}
                                          </span>
                                        </span>
                                      }
                                      style={{ fontSize: 18 }}
                                      name="view_statement"
                                      id={`view_statement`}
                                      value={`view_statement`}
                                      checked={
                                        this.state.modal_action_name === `view_statement`
                                      }
                                      onChange={(e) =>
                                        this.setState({
                                          modal_action_name: `view_statement`
                                        })
                                      }
                                      key={`action_view_statement`}
                                    />
                                    <hr />
                                  </div>
                                </div>
                              }

                              {parseFloat(this.state.modal_action_order['mf_order_overall_investment_amount']) > 1 &&
                                <br />
                              }
                              {parseFloat(this.state.modal_action_order['mf_order_overall_investment_amount']) > 1 &&
                                <div>

                                  <div class="row">
                                    <div class="col">
                                      <Form.Check
                                        type="radio"
                                        label={
                                          <span>
                                            <i className="fa fa-info fa-lg"></i>
                                            <span style={{
                                              backgroundColor: '#f5f0e4',
                                              fontSize: 18,
                                              padding: 4, paddingLeft: 8, paddingRight: 8, margin: 22, borderRadius: 4
                                            }}>
                                              {`View Info`}
                                            </span>
                                          </span>
                                        }
                                        style={{ fontSize: 18 }}
                                        name="view_info"
                                        id={`view_info`}
                                        value={`view_info`}
                                        checked={
                                          this.state.modal_action_name === `view_info`
                                        }
                                        onChange={(e) =>
                                          this.setState({
                                            modal_action_name: `view_info`
                                          })
                                        }
                                        key={`action_view_info`}
                                      />
                                      <br />
                                    </div>
                                  </div>

                                  {this.state.modal_action_order['is_stp_allowed'] &&
                                    <div class="row">
                                      {this.state.modal_action_order['mf_order_stp_enabled'] ?
                                        <div class="col">
                                          <Form.Check
                                            type="radio"
                                            label={
                                              <span>
                                                <i className="la la-user"></i>
                                                <span style={{
                                                  backgroundColor: '#f5f0e4',
                                                  fontSize: 18,
                                                  padding: 4, paddingLeft: 8, paddingRight: 8, margin: 7, borderRadius: 4
                                                }}>
                                                  {`Cancel STP`}
                                                </span>
                                              </span>
                                            }
                                            style={{ fontSize: 18 }}
                                            name="cancel_stp"
                                            id={`cancel_stp`}
                                            value={`cancel_stp`}
                                            checked={
                                              this.state.modal_action_name === `cancel_stp`
                                            }
                                            onChange={(e) =>
                                              this.setState({
                                                modal_action_name: `cancel_stp`
                                              })
                                            }
                                            key={`action_cancel_stp`}
                                          />
                                          <br />
                                        </div>
                                        :
                                        <div class="col">
                                          <Form.Check
                                            type="radio"
                                            label={
                                              <span>
                                                <i className="fa fa-rocket fa-lg"></i>
                                                <span style={{
                                                  backgroundColor: '#f5f0e4',
                                                  fontSize: 18,
                                                  padding: 4, paddingLeft: 8, paddingRight: 8, margin: 7, borderRadius: 4
                                                }}>
                                                  {`Start STP`}
                                                </span>
                                              </span>
                                            }
                                            style={{ fontSize: 18 }}
                                            name="start_stp"
                                            id={`start_stp`}
                                            value={`start_stp`}
                                            checked={
                                              this.state.modal_action_name === `start_stp`
                                            }
                                            onChange={(e) =>
                                              this.setState({
                                                modal_action_name: `start_stp`
                                              })
                                            }
                                            key={`action_start_stp`}
                                          />
                                          <br />
                                        </div>
                                      }
                                    </div>
                                  }

                                  {this.state.modal_action_order['is_swp_allowed'] &&
                                    <div class="row">
                                      {this.state.modal_action_order['mf_order_swp_enabled'] ?
                                        <div class="col">
                                          <Form.Check
                                            type="radio"
                                            label={
                                              <span>
                                                <i className="la la-user"></i>
                                                <span style={{
                                                  backgroundColor: '#f5f0e4',
                                                  fontSize: 18,
                                                  padding: 4, paddingLeft: 8, paddingRight: 8, margin: 20, borderRadius: 4
                                                }}>
                                                  {`Cancel SWP`}
                                                </span>
                                              </span>
                                            }
                                            style={{ fontSize: 18 }}
                                            name="cancel_swp"
                                            id={`cancel_swp`}
                                            value={`cancel_swp`}
                                            checked={
                                              this.state.modal_action_name === `cancel_swp`
                                            }
                                            onChange={(e) =>
                                              this.setState({
                                                modal_action_name: `cancel_swp`
                                              })
                                            }
                                            key={`action_cancel_swp`}
                                          />
                                          <br />
                                        </div>
                                        :
                                        <div class="col">
                                          <Form.Check
                                            type="radio"
                                            label={
                                              <span>
                                                <i className="fa fa-inr fa-lg"></i>
                                                <span style={{
                                                  backgroundColor: '#f5f0e4',
                                                  fontSize: 18,
                                                  padding: 4, paddingLeft: 8, paddingRight: 8, margin: 20, borderRadius: 4
                                                }}>
                                                  {`Start SWP`}
                                                </span>
                                              </span>
                                            }
                                            style={{ fontSize: 18 }}
                                            name="start_swp"
                                            id={`start_swp`}
                                            value={`start_swp`}
                                            checked={
                                              this.state.modal_action_name === `start_swp`
                                            }
                                            onChange={(e) =>
                                              this.setState({
                                                modal_action_name: `start_swp`
                                              })
                                            }
                                            key={`action_start_swp`}
                                          />
                                          <br />
                                        </div>
                                      }
                                    </div>
                                  }

                                </div>
                              }

                            </div>
                          </Col>
                        </Row>
                        <div
                          class="form-group"
                          style={{ marginBottom: 10, marginTop: 10 }}
                        ></div>
                      </Container>
                    </div>
                  )}
              </div>

            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={() => {

                  if (this.state.modal_action_name === 'show_history')
                    this.showTransactionHistory(this.state.modal_action_order);
                  else if (this.state.modal_action_name === 'view_statement')
                    this.routeChange("single", this.state.modal_action_order, 'initiate');
                  else if (this.state.modal_action_name === 'view_info')
                    this.routeChange("single", this.state.modal_action_order, 'view');
                  else if (this.state.modal_action_name === 'start_stp')
                    this.props.history.push({
                      pathname: "/stpSchemeList/",
                      state: {
                        request_mode: 'START STP', selected_order: this.state.modal_action_order
                      },
                    });
                  else if (this.state.modal_action_name === 'cancel_stp')
                    this.showStpInformation(
                      "single", this.state.modal_action_order, "show_stp_cancel_modal"
                    );
                  else if (this.state.modal_action_name === 'start_swp')
                    this.showSwpInformation(
                      "single", this.state.modal_action_order, "show_swp_modal"
                    );
                  else if (this.state.modal_action_name === 'cancel_swp')
                    this.showSwpInformation(
                      "single", this.state.modal_action_order, "show_swp_cancel_modal"
                    );

                }
                }
                disabled={
                  this.state.modal_action_name.length < 7 ||
                  this.state.isloading
                }
              >
                Proceed
              </Button>
              {`      `}
              <Button
                variant="secondary"
                onClick={() =>
                  this.setState({ show_modal_action: false })
                }
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        }

      </>
    );
  }

}

// OrderList.propTypes = {
//     validateOtp: PropTypes.func.isRequired,
//     isAuthenticated: PropTypes.bool
// };

/*
const mapStateToProps = state => ({
    loading: state.order.loading,
    order_result: state.order.order_result
});
*/

const mapStateToProps = ({ order, report }) => {
  const { order_result } = order;
  const { report_result } = report

  return {
    order_result,
    report_result
  };
};

export default connect(mapStateToProps, {
  loadOrderList,
  loadPaymentResponse,
  cancelOrder,
  createSwpOrder,
  twoFactorAuthentication,
  cancelSwpOrder,
  cancelStpOrder,
  reportGeneric
})(OrderList);
