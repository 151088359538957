import React, { } from 'react';
import { Form } from 'react-bootstrap';
import { connect } from "react-redux";
import { Button, Spinner, Alert, Modal } from 'react-bootstrap';
import { createClientCode, loadStageInfo } from '../../../jsx/actions/verify';
import { Redirect } from 'react-router-dom';
import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";
import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import { Card, CardContent, CardHeader, Stack, Typography } from "@mui/material";

class ClientDocuments extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            is_kyc_verified: true,
            is_esign_verified: true,
            is_client_code_approved: false,
            is_stage_verified: false,
            is_verify_enabled: false,
            is_confirm_chk_enabled: false,
            is_verified_editable: false,
            esignkyc_information: {},
            esignkyc_attachment_list: {},
            is_esign_verified: false,
            is_esign_button_disabled: false,
            is_recreate_client_code: false,
            show_popup: false,
            pop_messagetext: "",
            isloading: false,
        };

    }

    componentDidMount() {

        // this.props.history.replace(`/clientList`, this.state );

        window.scrollTo(0, 0);

        // document.getElementById(".images img").click(function () {
        //     document.getElementById("#full-image").attr("src", document.getElementById(this).attr("src"));
        //     document.getElementById('#image-viewer').show();
        // });

        // document.getElementById("#image-viewer .close").click(function () {
        //     document.getElementById('#image-viewer').hide();
        // });

        /*
        let isMobile = (window.matchMedia && window.matchMedia("(max-width: 480px)").matches);

        let received_data = (isMobile && this.props.location.state && this.props.location.state['customer_id']) ?
            this.props.location.state['customer_id'] : this.props.match.params;
        */

        let received_data = this.props.location.state;

        if (received_data) {
            sessionStorage.setItem('client_documents_data', JSON.stringify(received_data));
        }
        else
            received_data = JSON.parse(sessionStorage.getItem('client_documents_data') || JSON.stringify({}));

        this.onLoadStageInfo(received_data);

    }

    handleConfirmCheck = (event) => {
        this.setState({ is_confirm_chk_checked: !this.state.is_confirm_chk_checked })
    }

    onLoadStageInfo = (received_data) => {
        
        this.setState({ isloading: true });
        this.props.loadStageInfo({
            customer_id: received_data['customer_id'] || '',
            is_backoffice: true,
            stage_name: 'esignkyc'
        }).then(response => {

            this.setState({ isloading: false });
            if (this.props.personal_result['code'] !== '000')
                alert(this.props.personal_result['messageText']);
            else {

                if (this.props.personal_result['state_parameters'])
                    this.setState(this.props.personal_result['state_parameters']);
                else
                    this.setState({
                        is_stage_verified: this.props.personal_result['is_verified'],
                        is_verify_enabled: this.props.personal_result['is_verified'],
                        is_confirm_chk_enabled: true,
                        esignkyc_information: this.props.personal_result['id_information']
                    });
            }

        })
            .catch(err => {
                alert(err)
            });

    };

    onCreateClientCode = (request_mode) => {

        let _customer_id = this.props.location.state ?
            this.props.location.state.customer_id : null;

        if (!_customer_id || _customer_id.length < 7)
            _customer_id = (
                sessionStorage.getItem('client_documents_data')
                    ? JSON.parse(sessionStorage.getItem('client_documents_data'))['customer_id'] : ''
            );

        if (_customer_id && _customer_id.length > 7) {

            this.setState({ isloading: true });

            this.props.createClientCode({
                customer_id: _customer_id,
                is_backoffice: true,
                stage_name: 'create client code',
                request_mode: request_mode
            }).then(response => {

                this.setState({
                    isloading: false,
                    show_popup: true,
                    pop_messagetext: this.props.personal_result['messageText'],
                });

                /*
                if (this.props.personal_result['code'] !== '000')
                    alert(this.props.personal_result['messageText']);
                else */
                {
                    // this.props.history.push({
                    //     pathname: '/clientList/',
                    //     state: { /* customer_id: item['_id'] */ }
                    // });

                }

            })
                .catch(err => {
                    alert(err)
                });

        }


    };

    closePopup = () => {
        this.setState({
            show_popup: false,
            pop_messagetext: "",
        });
    };

    render() {

        if (this.props.personal_result && this.props.personal_result['esignkyc_stage_verified'])
            return <Redirect to={this.props.aadhaar_result['navigateScreen']} />;

        return (
            <>
                <Header1 />
                <Sidebar />
                <div className="authincation section-padding">
                    <div className="container" style={{ marginTop: 50 }}>
                        <div className="content-body">
                            <div class="row">
                                <div class="card">
                                    <div class="col-xl-12">

                                        <h5 className="text-center" style={{ marginTop: 20 }}>KYC INFO
                                            {this.state.is_kyc_verified && this.state.is_esign_verified &&
                                                !this.state.is_client_code_approved ? `(CREATE CLIENT CODE)` : ``
                                            }
                                        </h5>
                                        <div>
                                            {this.state.isloading && <Spinner animation="border" variant="dark" style={{ position: "fixed", top: "50%", left: "50%" }} />}

                                            <hr />
                                            {this.state.isloading ?
                                                <Alert variant="secondary text-center">
                                                    <Alert.Heading>
                                                        We are fetching the kyc information
                                                    </Alert.Heading>
                                                </Alert>
                                                :
                                                <div class="card">
                                                    <div class="row">
                                                        <span style={{ width: 90 }}>
                                                            Email Id
                                                        </span> {this.state.login_email}
                                                    </div>
                                                    <div class="row">
                                                        <span style={{ width: 90 }}>
                                                            Mobile
                                                        </span> {this.state.login_mobile_number}
                                                    </div>
                                                    <hr />
                                                </div>
                                            }

                                            {this.state.esignkyc_information && (this.state.esignkyc_information['pan'] || []).length > 0 &&
                                                ((this.state.esignkyc_information['pan'] || [])[0]['text'] || ``).length > 6 ?
                                                <div>
                                                    {this.state.login_stage !== 'pan' &&
                                                        <Card >
                                                            <b>PAN INFORMATION</b>
                                                            <table style={{ width: '100%' }}>
                                                                {this.state.esignkyc_information['pan'].map((el, i) =>
                                                                    <tr key={i} >
                                                                        <td style={{ width: 160, height: 30, border: '1px solid #f0eded', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                                                                            <span>
                                                                                {el['attribute']}
                                                                            </span>
                                                                        </td>
                                                                        <td style={{ height: 30, border: '1px solid #f0eded', textAlign: 'center' }}>
                                                                            {el['text'] && el['text'].length < 20 ?
                                                                                <label>
                                                                                    {el['text']}
                                                                                </label> :
                                                                                <label style={{ paddingBottom: 20 }}>
                                                                                    {el['text']}
                                                                                </label>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </table>
                                                            <div style={{ height: 10 }} />
                                                            <table style={{ width: '100%' }}>
                                                                {(this.state.esignkyc_information['pan_attachment_list'] || []).map((el, i) =>
                                                                    <tr>
                                                                        <td>
                                                                            <img key={i} src={el} style={{ width: 330, height: 250 }} />
                                                                        </td>
                                                                    </tr>
                                                                )
                                                                }
                                                            </table>
                                                        </Card>
                                                    }

                                                    {this.state.esignkyc_information['aadhaar'] &&
                                                        ((this.state.esignkyc_information['aadhaar'] || [])[0]['text'] || ``).length > 5 &&
                                                        <Card >
                                                            <div style={{ height: 10 }} />
                                                            <b>AADHAAR INFORMATION</b>
                                                            <table style={{ width: '100%' }}>
                                                                {this.state.esignkyc_information['aadhaar'].map((el, i) =>

                                                                    el['attribute'] !== 'address_information' &&
                                                                    <tr key={i} >
                                                                        <td style={{ width: 120, height: 30, border: '1px solid #f0eded', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                                                                            <span>
                                                                                {el['attribute']}
                                                                            </span>
                                                                        </td>
                                                                        <td style={{ height: 30, border: '1px solid #f0eded', textAlign: 'left' }}>
                                                                            {el['text'] && el['text'].length < 20 ?
                                                                                <label>
                                                                                    {el['text']}
                                                                                </label> :
                                                                                <label style={{ paddingBottom: 20 }}>
                                                                                    {el['text']}
                                                                                </label>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </table>
                                                            <div style={{ height: 10 }} />
                                                            <table style={{ width: '100%' }}>
                                                                {(this.state.esignkyc_information['aadhaar_attachment_list'] || []).map((el, i) =>
                                                                    <tr>
                                                                        <td>
                                                                            <img key={i} src={el} style={{ width: 330, height: 250 }} />
                                                                        </td>
                                                                    </tr>
                                                                )
                                                                }
                                                            </table>

                                                        </Card>
                                                    }
                                                    {this.state.esignkyc_information['bank'] &&
                                                        ((this.state.esignkyc_information['bank'] || [])[0]['text'] || ``).length > 5 &&
                                                        <Card>
                                                            <div style={{ height: 10 }} />
                                                            <b>BACK ACCOUNT INFORMATION</b>
                                                            <table style={{ width: '100%' }}>
                                                                {this.state.esignkyc_information['bank'].map((el, i) =>
                                                                    el['attribute'] !== 'BRANCH ADDRESS' &&
                                                                    <tr key={i} >
                                                                        <td style={{ width: 120, height: 30, border: '1px solid #f0eded', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                                                                            <span>
                                                                                {el['attribute']}
                                                                            </span>
                                                                        </td>
                                                                        <td style={{ height: 30, border: '1px solid #f0eded', textAlign: 'left' }}>
                                                                            <label>
                                                                                {el['text']}
                                                                            </label>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </table>
                                                            <div style={{ height: 10 }} />
                                                            <table style={{ width: '100%' }}>
                                                                {(this.state.esignkyc_information['bank_attachment_list'] || []).map((el, i) =>
                                                                    <tr>
                                                                        <td>
                                                                            <img key={i} src={el} style={{ width: 330, height: 250 }} />
                                                                        </td>
                                                                    </tr>
                                                                )
                                                                }
                                                            </table>
                                                        </Card>
                                                    }
                                                    {((this.state.esignkyc_information['address'] || [])[0]['text'] || ``).length > 10 &&
                                                        this.state.esignkyc_information['address'] &&
                                                        <Card>
                                                            <div style={{ height: 10 }} />
                                                            <div class="row">
                                                                <form action="#">
                                                                    <b>COMMUNICATION ADDRESS</b>
                                                                    {[this.state.esignkyc_information['address'][0]].map((el, i) =>
                                                                        <div key={i}>
                                                                            <label style={{ height: 22 }}>
                                                                                <span style={{ color: '#13855d' }}>
                                                                                    {el['attribute']}
                                                                                </span>
                                                                                {' : '}
                                                                                <label>
                                                                                    {el['text']}
                                                                                </label>
                                                                            </label>
                                                                        </div>
                                                                    )
                                                                    }
                                                                    <div style={{ height: 5 }} />
                                                                    <table style={{ width: '100%' }}>
                                                                        {this.state.esignkyc_information['address'].map((el, i) =>

                                                                            el['attribute'].toLowerCase() !== 'address' &&
                                                                            <tr key={i} >
                                                                                <td style={{ width: 120, height: 30, border: '1px solid #f0eded', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                                                                                    <span>
                                                                                        {el['attribute']}
                                                                                    </span>
                                                                                </td>
                                                                                <td style={{ height: 30, border: '1px solid #f0eded', textAlign: 'left' }}>
                                                                                    {el['text'] && el['text'].length < 20 ?
                                                                                        <label>
                                                                                            {el['text']}
                                                                                        </label> :
                                                                                        <label style={{ paddingBottom: 20 }}>
                                                                                            {el['text']}
                                                                                        </label>
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                        )}
                                                                    </table>
                                                                </form>
                                                            </div>
                                                            {((this.state.esignkyc_information['general'] || [])[0]['text'] || ``).length > 2 &&
                                                                <div class="row">
                                                                    <form action="#">
                                                                        <div style={{ height: 10 }} />
                                                                        <b>GENERAL INFORMATION</b>
                                                                        <table style={{ width: '100%' }}>
                                                                            {this.state.esignkyc_information['general'].map((el, i) =>

                                                                                el['attribute'].toLowerCase() !== 'email' &&
                                                                                <tr key={i} >
                                                                                    <td style={{ width: 180, height: 30, border: '1px solid #f0eded', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                                                                                        <span>
                                                                                            {el['attribute']}
                                                                                        </span>
                                                                                    </td>
                                                                                    <td style={{ height: 30, border: '1px solid #f0eded', textAlign: 'center' }}>
                                                                                        {el['text'] && el['text'].length < 20 ?
                                                                                            <label>
                                                                                                {el['text']}
                                                                                            </label> :
                                                                                            <label style={{ paddingBottom: 20 }}>
                                                                                                {el['text']}
                                                                                            </label>
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                            )}
                                                                        </table>
                                                                    </form>
                                                                </div>
                                                            }
                                                            <div class="row">
                                                                {this.state.esignkyc_information['general_attachment_list'] &&
                                                                    <div>
                                                                        {this.state.esignkyc_information['general_attachment_list'].map((el, i) =>
                                                                            <div key={i} className="form-group">
                                                                                <img src={el} style={{ width: 330, height: 250 }} />
                                                                            </div>
                                                                        )
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>

                                                            {this.state.esignkyc_information['nominee'] &&
                                                                ((this.state.esignkyc_information['nominee'] || [])[0]['text'] || ``).length > 4 &&
                                                                <div class="row">
                                                                    <div class="col">
                                                                        <form action="#">
                                                                            <div style={{ height: 10 }} />
                                                                            <b>NOMINEE INFORMATION</b>
                                                                            <table style={{ width: '100%' }}>
                                                                                {this.state.esignkyc_information['nominee'].map((el, i) =>

                                                                                    el['attribute'].toLowerCase() !== 'email' &&
                                                                                    <tr key={i} >
                                                                                        <td style={{ height: 30, border: '1px solid #f0eded', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                                                                                            <span>
                                                                                                {el['attribute']}
                                                                                            </span>
                                                                                        </td>
                                                                                        <td style={{ height: 30, border: '1px solid #f0eded', textAlign: 'center' }}>
                                                                                            {el['text'] && el['text'].length < 20 ?
                                                                                                <label>
                                                                                                    {el['text']}
                                                                                                </label> :
                                                                                                <label style={{ paddingBottom: 20 }}>
                                                                                                    {el['text']}
                                                                                                </label>
                                                                                            }
                                                                                        </td>
                                                                                    </tr>
                                                                                )}
                                                                            </table>
                                                                        </form>
                                                                    </div>
                                                                    <div class="col">
                                                                        {this.state.esignkyc_information['nominee_attachment_list'] &&
                                                                            <div >
                                                                                {this.state.esignkyc_information['nominee_attachment_list'].map((el, i) =>
                                                                                    <div key={i} className="form-group">
                                                                                        <img src={el} style={{ width: 330, height: 250 }} />
                                                                                    </div>
                                                                                )
                                                                                }
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            }
                                                        </Card>
                                                    }
                                                </div>
                                                :
                                                <p>
                                                    {this.state.isloading ? `` : `Information not available.`}
                                                </p>
                                            }

                                            {this.state.isloading &&
                                                <Spinner animation="border" variant="dark" style={{ position: "fixed", top: "50%", left: "50%" }} />
                                            }
                                            {
                                                this.state.is_kyc_verified && this.state.is_esign_verified &&
                                                <div>
                                                    {/* {this.state.is_recreate_client_code} */}
                                                    {!this.state.is_client_code_approved ?
                                                        <div className="card-body">
                                                            <div>
                                                                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                                                    <Form.Check type="checkbox" label="I confirm to create client code based on the above information."
                                                                        value={this.state.is_confirm_chk_checked}
                                                                        onChange={this.handleConfirmCheck} defaultChecked={this.state.is_confirm_chk_checked}
                                                                    />
                                                                </Form.Group>
                                                                {
                                                                    this.state.is_confirm_chk_checked &&
                                                                    <div className="text-center">
                                                                        <Button
                                                                            className="btn btn-success btn-block"
                                                                            variant="success"
                                                                            onClick={() => {
                                                                                this.onCreateClientCode('new')
                                                                            }}
                                                                            disabled={this.state.isloading}
                                                                        >
                                                                            Create Client Code
                                                                        </Button>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="card-body">
                                                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                                                <Form.Check type="checkbox" label="I confirm to create client code based on the above information."
                                                                    value={this.state.is_confirm_chk_checked}
                                                                    onChange={this.handleConfirmCheck} defaultChecked={this.state.is_confirm_chk_checked}
                                                                />
                                                            </Form.Group>
                                                            {
                                                                this.state.is_confirm_chk_checked &&
                                                                <div className="text-center">
                                                                    <Button
                                                                        className="btn btn-success btn-block"
                                                                        variant="success"
                                                                        onClick={() => {
                                                                            this.onCreateClientCode('recreate')
                                                                        }}
                                                                        disabled={
                                                                            !this.state.is_recreate_client_code
                                                                            || this.state.isloading
                                                                        }>
                                                                        Re-Create Client Code
                                                                    </Button>
                                                                </div>
                                                            }
                                                        </div>
                                                    }

                                                </div>

                                            }
                                            {/* </div> */}
                                        </div>
                                    </div>
                                    <Fab
                                        style={{ top: 70, right: -25, transform: 'scale(0.8)' }}
                                        alwaysShowTitle={true}
                                        mainButtonStyles={{
                                            backgroundColor: "#d45950"
                                        }}
                                        icon={<i className="fa fa-close  fa-lg" ></i>}
                                        onClick={() => this.props.history.goBack()}
                                    >
                                    </Fab>
                                </div></div>
                        </div></div></div>

                {this.state.show_popup && (
                    <Modal show={this.state.show_popup} onHide={() => this.closePopup()}>
                        <Modal.Header>
                        </Modal.Header>
                        <Modal.Body>
                            <p>{this.state["pop_messagetext"]}</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={() => {
                                this.closePopup();
                                // this.props.history.push({
                                //     pathname: '/clientList/',
                                //     state: { /* customer_id: item['_id'] */ }
                                // });
                            }
                            }>
                                Ok
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}

            </>
        )

    }

}
// ClientDocuments.propTypes = {
//     validateOtp: PropTypes.func.isRequired,
//     isAuthenticated: PropTypes.bool
// };

const mapStateToProps = state => ({
    personal_result: state.verify.personal_result
});

export default connect(mapStateToProps, { createClientCode, loadStageInfo })
    (ClientDocuments);