import React, { } from 'react';
import { loadPaymentResponse } from '../../actions/orders';
import { connect } from "react-redux";
import { Button ,Spinner} from "react-bootstrap";

class OrderResponse extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selected_order: {},
            isloading:this.props.loading,
            is_status_loaded: false,
            notification_html: `<html><head><title>Your Order Response</title>
            <style>
                .bodytxt4 {font-family: Verdana, Arial, Helvetica, sans-serif;font-size: 12px;font-weight: bold;color: #666666;}
                .bodytxt {font-family: Verdana, Arial, Helvetica, sans-serif;font-size: 13px;font-weight: normal;color: #000000;}
                .bullet1 {list-style-type:square;list-style-position: inside;list-style-image: none;font-family: Verdana, Arial, Helvetica, sans-serif;font-size: 10px;font-weight: bold;color: #FF9900;}
                .bodytxt2 {font-family: Verdana, Arial, Helvetica, sans-serif;font-size: 8pt;font-weight: normal;color: #333333;}A.sac2 {COLOR: #000000;font-family: Verdana, Arial, Helvetica, sans-serif;font-size: 10px;font-weight: bold;text-decoration: none;}A.sac2:visited {COLOR: #314D5A; TEXT-DECORATION: none}A.sac2:hover {COLOR: #FF9900; TEXT-DECORATION: underline}
            </style>
        </head>
        <table width="100%" border="0" cellspacing="0" cellpadding="0">  
            <tr>
                <td align="left" valign="top">
                    <table width="100%" border="0" cellspacing="0" cellpadding="0">
                        <tr>
                           <td align="center" valign="middle">
                               <table width="100%" border="0" cellspacing="0" cellpadding="0">   
                                    <tr>
                                        <td  align="center"></td>  
                                    </tr>  
                                    <tr>
                                        <td height="85" align="center"><br>
                                            <table width="80%" border="0" cellpadding="0" cellspacing="1" bgcolor="#CCCCCC">
                                                <tr>  
                                                    <td bgcolor="#CCCCCC"><table width="100%" border="0" cellpadding="6" cellspacing="0" bgcolor="#FFFFFF">  
                                                        <tr> 
                                                            <td colspan="2" align="left" valign="bottom"><span class="bodytxt4">We are fetching your order...</span></td>  
                                                        </tr>  
                                                        <tr valign="top"> 
                                                            <td colspan="2" align="left">
                                                                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                                    <tr>   
                                                                        <td width="87%" bgcolor="#cccccc" height="1" align="center"></td>
                                                                    </tr>
                                                                </table>
                                                            </td>  
                                                        </tr>  
                                                        <tr>
                                                            <td width="60%" align="left" valign="bottom"><table width="95%" border="0" cellpadding="1" cellspacing="0" bgcolor="#FFFFFF">
                                                                <tr>   
                                                                    <td align="right" valign="top"></td>  
                                                                    <td class="bodytxt">&nbsp;</td>
                                                                </tr>
                                                            <tr>   
                                                                <td align="right" valign="top"> <li class="bullet1"></li></td>  
                                                                <td class="bodytxt2" >When you submit the transaction, the server will take about 1 to 5 seconds to process, but it may take longer at certain times. </td>
                                                            </tr>
                                                            <tr>   
                                                                <td align="right" valign="top"><li class="bullet1"></li></td>  
                                                                <td class="bodytxt2" >Please do not press "Submit" button once again or the "Back" or "Refresh" buttons. </td>
                                                            </tr>  
                                            </table>
                                        </td>
                                        <td align="right" valign="bottom">
                                            <table width="80%" border="0" cellpadding="1" cellspacing="0" bgcolor="#FFFFFF">
                                                <tr bgcolor="#FFFCF8">   
                                                    <td align="right" bgcolor="#FFFFFF"></td>
                                                </tr>
                                                <tr bgcolor="#FFFCF8">   
                                                    <td align="right" valign="middle" bgcolor="#FFFFFF" class="bodytxt2">&nbsp;</td>
                                                </tr>
                                                <tr bgcolor="#FFFCF8">
                                                    <td align="right" bgcolor="#FFFFFF" class="bodytxt2" >&nbsp;</td>
                                                </tr>  
                                            </table>
                                        </td>  
                                    </tr>
                                </table>
                            </td>
                        </tr>  
                    </table>  
                </td>  
            </tr>
        </table>   
        
        </td></tr></table></td>  </tr>  </table>`
        };
        this.form = null
    }

    async componentDidMount() {
        
        if (this.props.location.state) {
            this.setState({isloading:true})
            this.setState({ selected_order: this.props.location.state.selected_order});
            let selected_order = this.props.location.state.selected_order;

            this.props.loadPaymentResponse({
                request_from: 'orderResponse',
                request_mode: 'order_payment_status',
                order_type: selected_order['mf_order_type'],
                order_number: selected_order['mf_order_number'] || '',
                selected_order: selected_order
            }).then(response => {
                this.setState({isloading:false})
                if (this.props.order_result['code'] !== '000')
                    alert(this.props.order_result['messageText']);
                else {
    
                    if (this.props.order_result['notification_html']) {
                        this.setState({
                            notification_html: this.props.order_result['notification_html'],
                            is_status_loaded: true
                        });
                    }
                        
                }
    
            })
            .catch(err => {
                alert(err)
            });

        }

    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    createDefaultMarkup() {
        return {
            __html: this.state.notification_html,
        }
    };

    gotoListPage() {
       
        let pageName = this.state.selected_order['mf_order_type'] === 'SINGLE' ? 
            'orderList' : 'sipList' ;

        this.props.history.push({
            pathname: '/' + pageName + '/'
        });

    }

    render() {

        return (
            
            <div>
                {this.state.isloading &&
                <Spinner animation="border" variant="dark" 
               style={{ position: "fixed", top: "50%", left: "50%" }}/>
             }

                <div dangerouslySetInnerHTML={this.createDefaultMarkup()} />
                {
                    this.state.is_status_loaded && 
                    <Button
                        className="btn btn-success btn-block"
                        variant="success"
                        onClick={this.gotoListPage}>
                        Ok, Go to list
                    </Button>
                }
            </div>

        );
    }

}


const mapStateToProps = state => ({
    payment_initiate_result: state.auth.payment_initiate_result,
    loading: state.master.loading,
});

export default connect(mapStateToProps, { loadPaymentResponse })(OrderResponse);