
import React from "react";
import { Link } from "react-router-dom";
import { Form, Alert, Modal, Button, Spinner, Row, Col, Container, } from "react-bootstrap";
import { connect } from "react-redux";
import Select from 'react-select';
import {
  loadPortfolio,
  loadPaymentResponse,
  cancelOrder,
  createSwpOrder,
  cancelSwpOrder
} from "../../actions/orders";
import DatePicker from "react-datepicker";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";
import { Box, Card, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import OTPInput, { ResendOTP } from "otp-input-react";
import { textAlign } from "@mui/system";

class PortfolioInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

      investment_information: {},
      list_single_orders: [],
      _list_single_orders: [],
      status_filter_list: [],
      status_filter_selected: null,
      actual_order_count: 0,
      unallotted_xsip_order_count: 0,
      isloading: false,

      show_modal_filter: false,
      is_esign_verified: false,
      is_kyc_approved: false,

      // is_bse_photo_verified: false,
      // is_bse_clientcode_approved: false,

      kyc_stage: ``

    };
  }

  componentDidMount() {

    if (localStorage.getItem('token')) {

      window.addEventListener("scroll", () => {

        if (document.getElementById("navbar-portfolio-container") &&
          document.getElementById("navbar") &&
          document.getElementById("navbar").classList) {
          const topBorder = document
            .getElementById("navbar-portfolio-container")
            .getBoundingClientRect().top;

          topBorder >= 0
            ? document.getElementById("navbar").classList.remove("fixed-portfolio")
            : document.getElementById("navbar").classList.add("fixed-portfolio");
        }

      });
      this.onLoadPortfolio(0);

    }
    else {
      this.props.history.push({
        pathname: '/',
        state: {}
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', null);
  }

  handleConfirmCheck = (event) => {
    this.setState({
      is_confirm_chk_checked: !this.state.is_confirm_chk_checked,
    });
  };

  onLoadPortfolio = (order_type) => {
    // console.log('UserAgent:', navigator.userAgent)
    this.setState({ isloading: true });

    order_type = order_type === 0 ? "single" : 0; //(order_type === 1 ? 'sip' : 'xsip')

    this.props
      .loadPortfolio({
        device_information: {
          userAgent: navigator.userAgent
        }
      })
      .then((response) => {
        console.log("results in portfolio page", this.props.order_result)
        if (this.props.order_result["code"] !== "000") {
          // alert(this.props.order_result["messageText"]);
        }
        else {

          if (this.props.order_result["result"]) {

            this.setState({
              investment_information: this.props.order_result["result"],
              actual_order_count: this.props.order_result[`actual_order_count`],
              unallotted_xsip_order_count: this.props.order_result['unallotted_xsip_order_count'],
              is_esign_verified: this.props.order_result[`is_esign_verified`],
              is_kyc_approved: this.props.order_result[`is_kyc_approved`],
              kyc_stage: this.props.order_result[`kyc_stage`],
              // is_bse_photo_verified: false,
              // is_bse_clientcode_approved: false,
              isloading: false
            });

          }
          else
            this.setState({
              investment_information: {},
              isloading: false
            });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  createDefaultMarkup(text) {
    return {
      __html: text,
    };
  }

  render() {

    return (
      <>
        <Header1 />
        <Sidebar />
        {this.state.isloading && (
          <Spinner
            animation="border"
            variant="dark"
            style={{ position: "fixed", top: "50%", left: "50%" }}
          />
        )}

        <div className="content-body" >
          <div className="container" style={{ marginBottom: 100 }}>
            <div className="row" >
              <div id="navbar-portfolio-container">

                <div id="navbar" >
                  <div className="card" style={{ backgroundColor: '#f8f8fb' }}>
                    <div className="card-header">
                      <div class="row">
                        <div class="col">
                          <h5 >Portfolio (After allotment)</h5>
                        </div>

                      </div>
                    </div>

                    <div class="row" style={{ marginTop: '-15px' }}>
                      <hr />
                    </div>
                  </div>

                </div>
              </div>

              <div style={{ marginTop: 50, marginBottom: 30 }}>

                <div >

                  {this.state.isloading ?
                    <Alert variant="secondary text-center">
                      <Alert.Heading>
                        We are fetching your portfolio
                      </Alert.Heading>
                    </Alert>
                    :
                    this.state.is_esign_verified && this.state.is_kyc_approved ?
                      <div>
                        {
                          (this.state.investment_information || {})['overall_investment_amount'] ?
                            <div>

                              <Card>
                                <CardContent>

                                  <table style={{ width: '100%' }}>
                                    <tr>
                                      <td style={{ height: 30, width: '130px', backgroundColor: '#f7f5f5', border: '1px solid #e2e0e4', textAlign: 'right' }}>
                                        <span style={{
                                          margin: 8
                                        }}>
                                          Total Investment
                                        </span>
                                      </td>
                                      <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                        <span style={{
                                          margin: 8
                                        }}>
                                          <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                          <span style={{ color: '#429bf5', marginLeft: 2 }}>
                                            {this.state.investment_information['overall_investment_amount']}
                                          </span>
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ height: 30, backgroundColor: '#f7f5f5', border: '1px solid #e2e0e4', textAlign: 'right' }}>

                                        <span style={{
                                          margin: 8
                                        }}>
                                          Total Returns
                                        </span>
                                      </td>
                                      <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                        <span style={{
                                          margin: 8
                                        }}>
                                          <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                          <span style={{
                                            color:
                                              this.state.investment_information['returns_difference_amount'].toString().substring(0, 1) === "-" ||
                                                ""
                                                ? "#f57362"
                                                : "#03b45e", marginLeft: 2
                                          }}>
                                            {this.state.investment_information['total_returns_amount']}
                                          </span>
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ height: 30, backgroundColor: '#f7f5f5', border: '1px solid #e2e0e4', textAlign: 'right' }}>
                                        <span style={{
                                          margin: 8
                                        }}>
                                          {
                                            this.state.investment_information['returns_difference_amount'].toString().substring(0, 1) === "-" ||
                                              "" ? 'Loss' : 'Profit'
                                          }
                                        </span>
                                      </td>
                                      <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                        <span style={{
                                          margin: 8
                                        }}>
                                          <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                          <span style={{
                                            color:
                                              this.state.investment_information['returns_difference_amount'].toString().substring(0, 1) === "-" ||
                                                ""
                                                ? "#f57362"
                                                : "#03b45e", marginLeft: 2
                                          }}>
                                            {this.state.investment_information['returns_difference_amount']}
                                          </span>
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ height: 30, backgroundColor: '#f7f5f5', border: '1px solid #e2e0e4', textAlign: 'right' }}>

                                        <span style={{
                                          margin: 8
                                        }}>
                                          Returns Percent
                                        </span>
                                      </td>
                                      <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                        <span style={{
                                          margin: 8
                                        }}>
                                          <span style={{
                                            color:
                                              this.state.investment_information['returns_difference_amount'].toString().substring(0, 1) === "-" ||
                                                ""
                                                ? "#f57362"
                                                : "#03b45e",
                                          }}>
                                            {this.state.investment_information['total_returns_percent']}%
                                          </span>
                                        </span>
                                      </td>
                                    </tr>
                                  </table>

                                  {/* <div className="row">
                                    <div style={{ textAlign: "right" }} className="col-7">
                                      <h6>Total Investment</h6>
                                    </div>
                                    <div className="col">
                                      <h6>
                                        <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                        <span style={{ color: '#429bf5', marginLeft: 2 }}>
                                          {this.state.investment_information['overall_investment_amount']}
                                        </span>
                                      </h6>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div style={{ textAlign: "right" }} className="col-7">
                                      <h6>Total Returns</h6>
                                    </div>
                                    <div className="col">
                                      <h6>
                                        <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                        <span style={{
                                          color:
                                            this.state.investment_information['returns_difference_amount'].toString().substring(0, 1) === "-" ||
                                              ""
                                              ? "#f57362"
                                              : "#03b45e", marginLeft: 2
                                        }}>
                                          {this.state.investment_information['total_returns_amount']}
                                        </span>
                                      </h6>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col">
                                    </div>
                                    <div className="col">
                                      <hr />
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div style={{ textAlign: "right" }} className="col-7">
                                      <h6>
                                        {
                                          this.state.investment_information['returns_difference_amount'].toString().substring(0, 1) === "-" ?
                                            `Loss` : `Gain`
                                        }
                                      </h6>
                                    </div>
                                    <div className="col">
                                      <h6>
                                        <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                        <span style={{
                                          color:
                                            this.state.investment_information['returns_difference_amount'].toString().substring(0, 1) === "-" ||
                                              ""
                                              ? "#f57362"
                                              : "#03b45e", marginLeft: 2
                                        }}>
                                          {this.state.investment_information['returns_difference_amount']}
                                        </span>
                                      </h6>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div style={{ textAlign: "right" }} className="col-7">
                                      <h6>
                                        Returns Percent
                                      </h6>
                                    </div>
                                    <div className="col">
                                      <h6>
                                        <span style={{
                                          color:
                                            this.state.investment_information['returns_difference_amount'].toString().substring(0, 1) === "-" ||
                                              ""
                                              ? "#f57362"
                                              : "#03b45e",
                                        }}>
                                          {this.state.investment_information['total_returns_percent']}%
                                        </span>
                                      </h6>
                                    </div>
                                  </div> */}

                                </CardContent>
                              </Card>
                              {
                                (parseInt(this.state.investment_information['single_order_count']) > 0 ||
                                  parseInt(this.state.investment_information['sip_order_count']) > 0) &&
                                <Row style={{ marginBottom: 7 }}>
                                </Row>
                              }

                              {parseInt(this.state.investment_information['single_order_count']) > 0 &&
                                <div>
                                  <Card>
                                    <CardContent>

                                      {
                                        <table style={{ width: '100%' }}>
                                          <tr>
                                            <td className="col-3">
                                              <span style={{
                                                color: 'InfoText',
                                                backgroundColor: '#ec9fa4', fontWeight: 500,
                                                padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                                              }}>
                                                Lumpsum
                                              </span>
                                            </td>
                                            <td >

                                              <span className="circleNumberCount">
                                                {this.state.investment_information['single_order_count']}
                                              </span>
                                            </td>
                                            <td className="col-3">
                                              <span style={{
                                                fontWeight: 500, borderRadius: 4
                                              }}>
                                                <button type="button"
                                                  className="btn-sm btn-success"
                                                  style={{ color: 'black', width: 70 }}
                                                  onClick={() => {
                                                    this.props.history.push({
                                                      pathname: "/orderList/",
                                                    });
                                                  }}
                                                >

                                                  <span className="icon"><i className="fa fa-arrow-right fa-lg"></i></span>
                                                </button>
                                              </span>
                                            </td>
                                          </tr>
                                        </table>
                                      }

                                      <table style={{ width: '100%' }}>
                                        <tr>
                                          <td style={{ height: 30, width: '130px', backgroundColor: '#f7f5f5', border: '1px solid #e2e0e4', textAlign: 'right' }}>
                                            <span style={{
                                              margin: 8
                                            }}>
                                              Total Investment
                                            </span>
                                          </td>
                                          <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                            <span style={{
                                              margin: 8
                                            }}>
                                              <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                              <span style={{ color: '#429bf5', marginLeft: 2 }}>
                                                {this.state.investment_information['single']['overall_investment_amount']}
                                              </span>
                                            </span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td style={{ height: 30, backgroundColor: '#f7f5f5', border: '1px solid #e2e0e4', textAlign: 'right' }}>

                                            <span style={{
                                              margin: 8
                                            }}>
                                              Total Returns
                                            </span>
                                          </td>
                                          <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                            <span style={{
                                              margin: 8
                                            }}>
                                              <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                              <span style={{
                                                color:
                                                  this.state.investment_information['single']['returns_difference_amount'].toString().substring(0, 1) === "-" ||
                                                    ""
                                                    ? "#f57362"
                                                    : "#03b45e", marginLeft: 2
                                              }}>
                                                {this.state.investment_information['single']['total_returns_amount']}
                                              </span>
                                            </span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td style={{ height: 30, backgroundColor: '#f7f5f5', border: '1px solid #e2e0e4', textAlign: 'right' }}>
                                            <span style={{
                                              margin: 8
                                            }}>
                                              {
                                                this.state.investment_information['single']['returns_difference_amount'].toString().substring(0, 1) === "-" ||
                                                  "" ? 'Loss' : 'Profit'
                                              }
                                            </span>
                                          </td>
                                          <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                            <span style={{
                                              margin: 8
                                            }}>
                                              <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                              <span style={{
                                                color:
                                                  this.state.investment_information['single']['returns_difference_amount'].toString().substring(0, 1) === "-" ||
                                                    ""
                                                    ? "#f57362"
                                                    : "#03b45e", marginLeft: 2
                                              }}>
                                                {this.state.investment_information['single']['returns_difference_amount']}
                                              </span>
                                            </span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td style={{ height: 30, backgroundColor: '#f7f5f5', border: '1px solid #e2e0e4', textAlign: 'right' }}>

                                            <span style={{
                                              margin: 8
                                            }}>
                                              Returns Percent
                                            </span>
                                          </td>
                                          <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                            <span style={{
                                              margin: 8
                                            }}>
                                              <span style={{
                                                color:
                                                  this.state.investment_information['single']['returns_difference_amount'].toString().substring(0, 1) === "-" ||
                                                    ""
                                                    ? "#f57362"
                                                    : "#03b45e",
                                              }}>
                                                {this.state.investment_information['single']['total_returns_percent']}%
                                              </span>
                                            </span>
                                          </td>
                                        </tr>
                                      </table>

                                    </CardContent>
                                  </Card>
                                </div>
                              }
                               
                              {/* {this.state.unallotted_single_order_count > 0 &&
                                <table style={{ width: '100%' }}>
                                  <tr>
                                    <td>
                                      <br />
                                      <b>{this.state.unallotted_single_order_count}</b> of your <b>
                                        Order{this.state.unallotted_single_order_count > 1 ? `(s)` : ``}</b> are active and awaiting your payment.
                                        Investment amount will be reflected after the allotment.
                                    </td>
                                  </tr>
                                </table>
                              } */}
                              
                              {
                                parseInt(this.state.investment_information['single_order_count']) > 0 &&
                                parseInt(this.state.investment_information['sip_order_count']) > 0 &&
                                <Row style={{ marginBottom: 15 }}>
                                </Row>
                              }

                              {parseInt(this.state.investment_information['sip_order_count']) > 0 &&
                                <div className="card">
                                  <div className="card-body">
                                    {
                                      <table style={{ width: '100%' }}>
                                        <tr>
                                          <td className="col-3">
                                            <span style={{
                                              color: 'InfoText',
                                              backgroundColor: '#ec9fa4', fontWeight: 500,
                                              padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                                            }}>
                                              SIP(s)
                                            </span>
                                          </td>
                                          <td >

                                            <span className="circleNumberCount">
                                              {this.state.investment_information['sip_order_count']}
                                            </span>
                                          </td>
                                          <td className="col-3">
                                            <span style={{
                                              fontWeight: 500, borderRadius: 4
                                            }}>
                                              <button type="button"
                                                className="btn-sm btn-success"
                                                style={{ color: 'black', width: 70 }}
                                                onClick={() => {
                                                  this.props.history.push({
                                                    pathname: "/sipList/",
                                                  });
                                                }}
                                              >

                                                <span className="icon"><i className="fa fa-arrow-right fa-lg"></i></span>
                                              </button>
                                            </span>
                                          </td>
                                        </tr>
                                      </table>
                                    }

                                    {this.state.investment_information['xsip']['overall_investment_amount'] > 0 &&
                                      <table style={{ width: '100%' }}>
                                        <tr>
                                          <td style={{ height: 30, width: '130px', backgroundColor: '#f7f5f5', border: '1px solid #e2e0e4', textAlign: 'right' }}>
                                            <span style={{
                                              margin: 8
                                            }}>
                                              Total Investment
                                            </span>
                                          </td>
                                          <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                            <span style={{
                                              margin: 8
                                            }}>
                                              <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                              <span style={{ color: '#429bf5', marginLeft: 2 }}>
                                                {this.state.investment_information['xsip']['overall_investment_amount']}
                                              </span>
                                            </span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td style={{ height: 30, backgroundColor: '#f7f5f5', border: '1px solid #e2e0e4', textAlign: 'right' }}>

                                            <span style={{
                                              margin: 8
                                            }}>
                                              Total Returns
                                            </span>
                                          </td>
                                          <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                            <span style={{
                                              margin: 8
                                            }}>
                                              <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                              <span style={{
                                                color:
                                                  this.state.investment_information['xsip']['returns_difference_amount'].toString().substring(0, 1) === "-" ||
                                                    ""
                                                    ? "#f57362"
                                                    : "#03b45e", marginLeft: 2
                                              }}>
                                                {this.state.investment_information['xsip']['total_returns_amount']}
                                              </span>
                                            </span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td style={{ height: 30, backgroundColor: '#f7f5f5', border: '1px solid #e2e0e4', textAlign: 'right' }}>
                                            <span style={{
                                              margin: 8
                                            }}>
                                              {
                                                this.state.investment_information['xsip']['returns_difference_amount'].toString().substring(0, 1) === "-" ||
                                                  "" ? 'Loss' : 'Profit'
                                              }
                                            </span>
                                          </td>
                                          <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                            <span style={{
                                              margin: 8
                                            }}>
                                              <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                              <span style={{
                                                color:
                                                  this.state.investment_information['xsip']['returns_difference_amount'].toString().substring(0, 1) === "-" ||
                                                    ""
                                                    ? "#f57362"
                                                    : "#03b45e", marginLeft: 2
                                              }}>
                                                {this.state.investment_information['xsip']['returns_difference_amount']}
                                              </span>
                                            </span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td style={{ height: 30, backgroundColor: '#f7f5f5', border: '1px solid #e2e0e4', textAlign: 'right' }}>

                                            <span style={{
                                              margin: 8
                                            }}>
                                              Returns Percent
                                            </span>
                                          </td>
                                          <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                            <span style={{
                                              margin: 8
                                            }}>
                                              <span style={{
                                                color:
                                                  this.state.investment_information['xsip']['returns_difference_amount'].toString().substring(0, 1) === "-" ||
                                                    ""
                                                    ? "#f57362"
                                                    : "#03b45e",
                                              }}>
                                                {this.state.investment_information['xsip']['total_returns_percent']}%
                                              </span>
                                            </span>
                                          </td>
                                        </tr>
                                      </table>
                                    }

                                    {this.state.unallotted_xsip_order_count > 0 &&
                                      <table style={{ width: '100%' }}>
                                        <tr>
                                          <td>
                                            <br />
                                            <b>{this.state.unallotted_xsip_order_count}</b> of your <b>
                                              SIP{this.state.unallotted_xsip_order_count > 1 ? `(s)` : ``}</b> are active and awaiting debit of 1st installment.
                                            Investment amount will be reflected after the allotment.
                                          </td>
                                        </tr>
                                      </table>
                                    }

                                  </div>
                                </div>
                              }

                              {
                                (parseInt(this.state.investment_information['sip_order_count']) > 0 ||
                                  parseInt(this.state.investment_information['stp_order_count']) > 0) &&
                                <br />
                              }

                              {parseInt(this.state.investment_information['stp_order_count']) > 0 &&
                                <div className="card">
                                  <div className="card-body">
                                    <div className="row">
                                      <div className="col-4">
                                        <span style={{
                                          color: 'InfoText',
                                          backgroundColor: '#ec9fa4', fontWeight: 500,
                                          padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                                        }}>
                                          STP(s)
                                        </span>
                                      </div>
                                      <div className="col-3">
                                        <h6 className="circleNumberCount">
                                          {this.state.investment_information['stp_order_count']}
                                        </h6>
                                      </div>
                                      <div className="col-5" >

                                        <span style={{
                                          fontWeight: 500, borderRadius: 4
                                        }}>
                                          <button type="button"
                                            className="btn-sm btn-success"
                                            style={{ marginTop: -7, color: 'black', width: 70 }}
                                            onClick={() => {
                                              this.props.history.push({
                                                pathname: "/stpList/",
                                              });
                                            }}
                                          >

                                            <span className="icon"><i className="fa fa-arrow-right fa-lg"></i></span>
                                          </button>
                                        </span>

                                      </div>
                                    </div>
                                    <div className="row">
                                      <div style={{ textAlign: "right" }} className="col-7">
                                        <h6>Total Investment</h6>
                                      </div>
                                      <div className="col">
                                        <h6>
                                          <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                          <span style={{ color: '#429bf5', marginLeft: 2 }}>
                                            {this.state.investment_information['stp']['overall_investment_amount']}
                                          </span>
                                        </h6>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div style={{ textAlign: "right" }} className="col-7">
                                        <h6>Total Returns</h6>
                                      </div>
                                      <div className="col">
                                        <h6>
                                          <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                          <span style={{
                                            color:
                                              this.state.investment_information['stp']['returns_difference_amount'].toString().substring(0, 1) === "-" ||
                                                ""
                                                ? "#f57362"
                                                : "#03b45e", marginLeft: 2
                                          }}>
                                            {this.state.investment_information['stp']['total_returns_amount']}
                                          </span>
                                        </h6>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div style={{ textAlign: "right" }} className="col-7">
                                        <h6>
                                          {
                                            this.state.investment_information['stp']['returns_difference_amount'].toString().substring(0, 1) === "-" ||
                                              "" ? 'Loss' : 'Profit'
                                          }
                                        </h6>
                                      </div>
                                      <div className="col">
                                        <h6>
                                          <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                          <span style={{
                                            color:
                                              this.state.investment_information['stp']['returns_difference_amount'].toString().substring(0, 1) === "-" ||
                                                ""
                                                ? "#f57362"
                                                : "#03b45e", marginLeft: 2
                                          }}>
                                            {this.state.investment_information['stp']['returns_difference_amount']}
                                          </span>
                                        </h6>
                                      </div>

                                    </div>

                                    <div className="row">
                                      <div style={{ textAlign: "right" }} className="col-7">
                                        <h6>
                                          Returns Percent
                                        </h6>
                                      </div>
                                      <div className="col">
                                        <h6>
                                          <span style={{
                                            color:
                                              this.state.investment_information['stp']['returns_difference_amount'].toString().substring(0, 1) === "-" ||
                                                ""
                                                ? "#f57362"
                                                : "#03b45e", marginLeft: 2
                                          }}>
                                            {this.state.investment_information['stp']['total_returns_percent']}%
                                          </span>
                                        </h6>
                                      </div>
                                    </div>

                                  </div>
                                </div>
                              }

                            </div>
                            :

                            this.state.actual_order_count > 0 ?
                              <Alert variant="secondary text-center">
                                <Alert.Heading>
                                  You have orders waiting for payment confirmation.
                                  <hr />
                                  <span style={{ textAlign: 'left' }}>
                                    <Link to={"/orderList"} title="OrderList">
                                      Click here
                                      {/* <span>
                                      <i className="fa fa-bar-chart"></i>
                                    </span> */}
                                    </Link> to view pending orders
                                  </span>
                                </Alert.Heading>
                              </Alert>
                              :
                              <Alert variant="secondary text-center">
                                {this.state.investment_information['is_bse_photo_verified'] ?
                                  <Alert.Heading>
                                    There were no investments.
                                    <hr />
                                    <span style={{ textAlign: 'left' }}>
                                      Kindly <Link to={"/schemeList"} title="SchemeList">
                                        click here {` `}
                                        <span><i className="fa fa-bar-chart"></i></span>
                                      </Link>
                                      {` `} to start your investing
                                    </span>
                                  </Alert.Heading>
                                  :
                                  <Alert.Heading>
                                    You should have received an email from BSE with subject "UCC Authentication"
                                    and request you to follow the instructions stated in the email to complete your registration.
                                    If already done, Kindly ignore.
                                    <hr />
                                    <span style={{ textAlign: 'left' }}>
                                      Your KYC will be approved by BSE as early as possible.
                                    </span>
                                    <hr />
                                    <span style={{ textAlign: 'left' }}>
                                      <Link to={"/schemeList"} title="SchemeList">
                                        Click here
                                      </Link>
                                      {` to explore scheme list`}
                                    </span>
                                  </Alert.Heading>
                                }
                              </Alert>
                        }
                      </div>
                      :
                      <div>
                        {
                          !this.state.is_esign_verified ?
                            <Alert variant="secondary text-center">
                              <Alert.Heading>
                                Your KYC is pending
                                <hr />
                                <span style={{ textAlign: 'left' }}>
                                  <Link to={`/` + this.state.kyc_stage} title="KYC Submission">
                                    Click here
                                  </Link> to complete the KYC process
                                </span>
                              </Alert.Heading>
                            </Alert>
                            :
                            <Alert variant="secondary text-center">
                              <Alert.Heading>
                                {
                                  !this.state.is_kyc_approved ? `Your KYC is pending for approval from BSE StarMF. We will notify you shortly.`
                                    :
                                    ``
                                }
                                { }
                                <hr />
                                <span style={{ textAlign: 'left' }}>
                                  <Link to={"/schemeList"} title="SchemeList">
                                    Click here
                                  </Link>
                                  {` to explore scheme list`}
                                </span>
                              </Alert.Heading>
                            </Alert>
                        }
                      </div>
                  }

                  {/* <Fab
                    style={{ bottom: 27, right: -20 }}
                    // event="hover"
                    alwaysShowTitle={true}
                    mainButtonStyles={{
                      backgroundColor: "#00b5ad"
                    }}
                    icon={<i className="mdi mdi-filter" ></i>}
                    onClick={() => this.setState({ show_modal_filter: true })}
                  >
                  </Fab> */}

                </div>

              </div>

            </div></div></div>
      </>
    );
  }

}

const mapStateToProps = ({ order }) => {
  const { order_result } = order;

  return {
    order_result,
  };
};

export default connect(mapStateToProps, {
  loadPortfolio,
  loadPaymentResponse,
  cancelOrder,
  createSwpOrder,
  cancelSwpOrder
})(PortfolioInformation);
