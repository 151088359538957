import React, { } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { connect } from "react-redux";
import { Button, Spinner, ListGroup, Row, Col, Modal, Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { loadClientList, saveBankAccount, addBankAccount, elogPhotoUpload, fatcaUpload } from '../../actions/verify';
import { Redirect } from 'react-router-dom';
import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";
import Box from "@mui/material/Box";
import { Fab, Action } from 'react-tiny-fab';
import { Card, CardContent, Stack, Typography } from "@mui/material";
import ReactPaginate from "react-paginate";

class ClientList extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            loading: false,
            is_refresh: false,
            message_text: '',
            list_client: [],
            _list_client: [],
            activity_mode: 'list',
            bank_account_number: '',
            bank_ifsc_code: '',
            bank_account_type: '',
            bank_name: {},
            is_stage_verified: false,
            is_verify_enabled: false,
            is_confirm_chk_enabled: false,
            is_verified_editable: false,
            selectedFile: null,
            src_selectedFile: null,
            bank_information: [],
            bank_attachment_list: [],
            file_bank_tiff: null,
            show_popup: false,
            pop_messagetext: '',
            list_bank: [],
            list_account_type: [],
            selectedAccountType: [],
            isloading: false,
            show_modal_filter: false,
            show_modal_action: false,

            stage_filter_list: [],
            status_filter_list: [],
            stage_filter_selected: [],
            status_filter_selected: [],
            search_text_selected: ``,
            perPage: 20,
            page: 0,
            pages: 0,
            is_page_redirect: false
        };

    }

    componentDidMount() {

        window.addEventListener("scroll", () => {

            if (document.getElementById("navbar-schemelist-container") &&
                document.getElementById("navbar") &&
                document.getElementById("navbar").classList) {
                const topBorder = document
                    .getElementById("navbar-schemelist-container")
                    .getBoundingClientRect().top;

                topBorder >= 0
                    ? document.getElementById("navbar").classList.remove("fixed-schemelist")
                    : document.getElementById("navbar").classList.add("fixed-schemelist");
            }

        });
        
        {
            this.onLoadClientList();            
        }
    }
   

    handleConfirmCheck = (e, item) => {

        const _list = this.state.list_client;
        _list.forEach(el => {

            if (el['beneficiary_account_no'] === item['beneficiary_account_no'])
                item['is_primary'] = !item['is_primary'];
            else
                item['is_primary'] = false;
        });

        this.setState({ list_client: _list });


    }

    onLoadClientList = () => {

        this.setState({ isloading: true });

        this.props.loadClientList({})
            .then(response => {

                if (this.props.client_result['code'] !== '000') {

                    if (this.props.client_result['is_redirect'])
                        this.setState({
                            isloading: false,
                            message_text: this.props.client_result['messageText'],
                            is_refresh: true
                        });

                }
                else {

                    this.setState({
                        isloading: false,
                        list_client: this.props.client_result['result'] || [],
                        // _list_client: this.props.client_result['result'] || [],
                        pages: Math.round(
                            (this.props.client_result['result'] || []).length /
                            (this.props.client_result["per_page_count"] || this.state.perPage)
                        ),
                        stage_filter_list: this.props.client_result['stage_filter_list'],
                        status_filter_list: this.props.client_result['status_filter_list'],
                        is_refresh: false
                    });
                }

            })
            .catch(err => {
                alert(err)
            });


    };

    onElogPhotoUpload = (item) => {

        this.setState({ isloading: true });

        this.props.elogPhotoUpload({
            customer_id: item['_id'],
            is_backoffice: true,
            stage_name: 'elog photo upload'
        }).then(response => {

            this.setState({ isloading: false });

            if (this.props.client_result['code'] !== '000')
                alert(this.props.client_result['messageText']);
            else {

                /*
                let _list_client = this.state.list_client;
                _list_client.map(function (el) {
                    if (el['mf_order_registration_no'] === this.props) {
                        el = _result;
                        el['show_child'] = true;
                    }
                });
                */
                if (this.props.client_result['result'])
                    this.setState({
                        list_client: this.props.client_result['result'],
                        // _list_client: this.props.client_result['result'],
                        stage_filter_list: this.props.client_result['stage_filter_list'],
                        status_filter_list: this.props.client_result['status_filter_list']
                    });

            }

        })
            .catch(err => {
                alert(err)
            });


    };

    onFATCAUpload = (item) => {

        this.setState({ isloading: true });

        this.props.fatcaUpload({
            customer_id: item['_id'],
            is_backoffice: true,
            stage_name: 'elog photo upload'
        }).then(response => {

            if (this.props.client_result['code'] !== '000') {
                this.setState({ isloading: false });
                alert(this.props.client_result['messageText']);
            }
            else {
                if (this.props.client_result['result'])
                    this.setState({
                        isloading: false,
                        list_client: this.props.client_result['result'],
                        // _list_client: this.props.client_result['result'],
                        stage_filter_list: this.props.client_result['stage_filter_list'],
                        status_filter_list: this.props.client_result['status_filter_list']
                    });
            }

        })
            .catch(err => {
                alert(err)
            });

    };

    gotoClientInformationPage(item) {

        this.setState({
            is_page_redirect: true
        });

        this.props.history.push({
            pathname: '/clientDocuments',
            state: {
                customer_id: item['_id']
            }
        });
        // const win = window.open("/clientDocuments/" + item['_id'], "_blank");
        // win.focus();

    }

    dataURLtoFile(dataurl, filename) {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        this.setState({
            file_bank_tiff: new File([u8arr], (filename + "_bank.tiff"), { type: mime.split('/')[0] + '/tiff' })
        });

    }

    handleChangeStage = (selectedOption) => {
        this.setState({
            stage_filter_selected: selectedOption
        }, () => {
            let _filtered_client_list = [];
    
            selectedOption.forEach(item => {
                _filtered_client_list = _filtered_client_list.concat(
                    this.state.list_client.filter(el => {
                        return el['login_stage'] === item['value'].trim().toLowerCase();
                    })
                );
            });
    
            this.setState({                    
                list_client: selectedOption.length > 0 ?
                    _filtered_client_list :  this.props.client_result["result"]
            });
        });
    };
    
    handleChangeStatus = (selectedOption) => {
        this.setState({
            status_filter_selected: selectedOption
        }, () => {
            let _filtered_client_list = [];
    
            selectedOption.forEach(item => {
                _filtered_client_list = _filtered_client_list.concat(
                    this.state.list_client.filter(el => {
                        return el[item['value']] === true;
                    })
                );
            });
    
            this.setState({
                list_client: selectedOption.length > 0 ?
                    _filtered_client_list :  this.props.client_result["result"]
            });
        });
    };
    
    handleTextChange = (event, attribute) => {
      
        let _filtered_client_list = this.state.list_client.filter(el => {
            return el['login_mobile_number'].trim().toLowerCase().indexOf(event.target.value.trim().toLowerCase()) > -1 ||
                el['login_email'].trim().toLowerCase().indexOf(event.target.value.trim().toLowerCase()) > -1 ||
                el['login_personal_information']['client_code'].trim().toLowerCase().indexOf(event.target.value.trim().toLowerCase()) > -1;
        });
    
        this.setState({
            [attribute]: event.target.value,
            list_client: event.target.value.length > 0 ?
                _filtered_client_list : this.props.client_result["result"]
        });
    };
    

    resetFilter = () => {
        let _new_list = this.props.client_result["result"] || [];
        this.setState({
            show_modal_filter: false,
            stage_filter_selected: [],
            status_filter_selected: [],
            search_text_selected: ``,
            list_client: _new_list
        });
    };


    //     const { stage_filter_selected, status_filter_selected, search_text_selected, list_client } = this.state;
    
    //     // Filter the list based on selected filters
    //     let filteredList = list_client.filter(client => {
    //         // Check if the client matches the selected stage options
    //         const matchesStage = stage_filter_selected.length === 0 ||
    //             stage_filter_selected.some(option => client['login_stage'] === option.value.trim().toLowerCase());
    
    //         // Check if the client matches the selected status options
    //         const matchesStatus = status_filter_selected.length === 0 ||
    //             status_filter_selected.some(option => client[option.value] === true);
    
    //         // Check if the client matches the search text
    //         const matchesSearch = search_text_selected.length === 0 ||
    //             client['login_mobile_number'].includes(search_text_selected) ||
    //             client['login_email'].includes(search_text_selected) ||
    //             client['login_personal_information']['client_code'].includes(search_text_selected);
    
    //         return matchesStage && matchesStatus && matchesSearch;
    //     });
    
    //     // Update state with filtered list and close modal
    //     this.setState({ list_client: filteredList, show_modal_filter: false });
    // };
    

    handlePageClick = (event) => {
        let page = event.selected;
        this.setState({ page });
        window.scrollTo(0, 0);
    };

    applyFiltersAndCloseModal = () => {
        const { stage_filter_selected, status_filter_selected, search_text_selected } = this.state;
        
        // Initialize filtered list with the entire client list
        let filteredList = [...this.props.client_result.result]; // Using spread operator to clone the array
        
        // Apply filters
        filteredList = this.applyStageFilter(filteredList, stage_filter_selected);
        filteredList = this.applyStatusFilter(filteredList, status_filter_selected);
        filteredList = this.applyTextSearchFilter(filteredList, search_text_selected);
    
        // Update state with filtered list and close modal
        this.setState({ list_client: filteredList, show_modal_filter: false });
    };
    
    applyStageFilter = (list, stageFilterSelected) => {
        if (stageFilterSelected.length === 0) return list;
        return list.filter(client => {
            return stageFilterSelected.some(stage => {
                return client['login_stage'] === stage['value'].trim().toLowerCase();
            });
        });
    };
    
    applyStatusFilter = (list, statusFilterSelected) => {
        if (statusFilterSelected.length === 0) return list;
        return list.filter(client => {
            return statusFilterSelected.every(status => {
                return client[status['value']] === true;
            });
        });
    };
    
    applyTextSearchFilter = (list, searchText) => {
        if (searchText.trim() === "") return list;
        return list.filter(client => {
            return (
                client['login_mobile_number'].trim().toLowerCase().includes(searchText.trim().toLowerCase()) ||
                client['login_email'].trim().toLowerCase().includes(searchText.trim().toLowerCase()) ||
                client['login_personal_information']['client_code'].trim().toLowerCase().includes(searchText.trim().toLowerCase())
            );
        });
    };
    
    
    
    
    // handleTextChange = (event, attribute) => {
    //     const searchText = event.target.value.trim().toLowerCase();
    //     const { list_client } = this.state;
    
    //     let filtered_client_list = list_client.filter(client => {
    //         return client['login_mobile_number'].toLowerCase().includes(searchText) ||
    //             client['login_email'].toLowerCase().includes(searchText) ||
    //             client['login_personal_information']['client_code'].toLowerCase().includes(searchText);
    //     });
    
    //     this.setState({
    //         [attribute]: event.target.value,
    //         list_client: searchText.length > 0 ? filtered_client_list : list_client
    //     });
    // };
    
    

    render() {

        const { page, perPage, pages, list_client } = this.state;

        let _list_client =
            list_client.length > perPage
                ? list_client.slice(page * perPage, (page + 1) * perPage)
                : list_client;

        return (
            <>
                <Header1 />
                <Sidebar />
                {this.state.isloading && (
                    <Spinner
                        animation="border"
                        variant="dark"
                        style={{
                            position: "fixed",
                            top: "50%",
                            left: "50%",
                            zIndex: 899,
                        }}
                    />
                )}

                <div >
                    <div id="navbar-schemelist-container" style={{height:53}}>
                        <div id="navbar" >
                            <div className="card" style={{ backgroundColor: '#f8f8fb' }}>
                                <div>
                                    <h6 className="text-center">CUSTOMER LIST</h6>
                                    {_list_client && list_client.length >= perPage && (
                                        <ReactPaginate
                                            breakLabel="..."
                                            previousLabel={"←"}
                                            nextLabel={"→"}
                                            pageCount={pages}
                                            onPageChange={this.handlePageClick}
                                            breakClassName={"page-item"}
                                            breakLinkClassName={"page-link"}
                                            containerClassName={"pagination"}
                                            pageClassName={"page-item"}
                                            pageLinkClassName={"page-link"}
                                            previousClassName={"page-item"}
                                            previousLinkClassName={"page-link"}
                                            nextClassName={"page-item"}
                                            nextLinkClassName={"page-link"}
                                            activeClassName={"active"}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12" style={{ marginBottom: 30 }}>
                                <div class="transaction-widget">
                                    {this.state.is_refresh ?
                                        <Card sx={{ backgroundColor: '#edf3f0', p: 2, borderRadius: 2 }}>
                                            <CardContent  >
                                                <Stack direction="row">
                                                    <Typography flex={1} whiteSpace="pre-line" >
                                                        {this.state.message_text.trim().length < 4 ?
                                                            `Sorry the information you are looking for could not be found`
                                                            :
                                                            this.state.message_text
                                                        }
                                                        <p></p>
                                                        <Link className="page-back text-muted" to={'/home'}><span><i
                                                            className="fa fa-angle-left"></i></span> Back</Link>
                                                        {'  '}Go to Home
                                                    </Typography>
                                                </Stack>
                                            </CardContent>
                                        </Card>
                                        :
                                        this.state.activity_mode === 'list' &&
                                        <ListGroup >
                                            {(_list_client || []).map((item) => (
                                                <ListGroup.Item
                                                    key={item["_id"]}
                                                    style={{ marginBottom: 5 }}
                                                    action
                                                    variant="light"
                                                    className="wallet-address text-dark"
                                                >
                                                    <table style={{ width: '100%' }}>
                                                        <tr>
                                                            <td style={{ height: 30, textAlign: 'left' }}>
                                                                <span> {item["login_email"]} </span>
                                                            </td>
                                                        </tr>
                                                    </table>

                                                    <table style={{ width: '100%' }}>
                                                        <tr>
                                                            <td style={{ height: 30, textAlign: 'center' }}>
                                                                <span> {item["login_mobile_number"]} </span>
                                                            </td>
                                                            <td style={{ height: 30, textAlign: 'center' }}>
                                                                <span style={{ color: 'InfoText', backgroundColor: '#e3e0de', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4 }} >
                                                                    {item["login_stage"]}
                                                                </span>
                                                            </td>
                                                            {!item['login_bse_clientcode_verified'] &&
                                                                <td style={{ height: 30, textAlign: 'left' }}>
                                                                    <span style={{ backgroundColor: '#e3e0de', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4 }} >
                                                                        {item["login_status"]}
                                                                    </span>
                                                                </td>
                                                            }
                                                        </tr>
                                                    </table>

                                                    <table style={{ width: '100%' }}>
                                                        <tr style={{ height: 5 }}>
                                                        </tr>
                                                        {item['login_bse_clientcode_verified'] &&
                                                            <tr>
                                                                <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                                                                    <span> Fatca </span>
                                                                </td>
                                                                <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                                                    {item['login_bse_fatca_verified'] ? 'YES' : 'NO'}
                                                                </td>
                                                                <td style={{ width: 15, height: 30, textAlign: 'center' }}>
                                                                    {` `}
                                                                </td>
                                                                <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                                                                    <span> Elog </span>
                                                                </td>
                                                                <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                                                    {item['login_bse_photo_verified'] ? 'YES' : 'NO'}
                                                                </td>
                                                            </tr>
                                                        }
                                                        <tr>
                                                            <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                                                                <span> KYC </span>
                                                            </td>
                                                            <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                                                {item['login_kyc_verified'] ? 'YES' : 'NO'}
                                                            </td>
                                                            <td style={{ width: 15, height: 30, textAlign: 'center' }}>
                                                                {` `}
                                                            </td>
                                                            <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                                                                <span> e-SIGN </span>
                                                            </td>
                                                            <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                                                {item['login_esign_verified'] ? 'YES' : 'NO'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                                                                <span> Client Code </span>
                                                            </td>
                                                            <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                                                {item['login_bse_clientcode_verified'] ? 'YES' : 'NO'}
                                                            </td>
                                                            <td style={{ width: 15, height: 30, textAlign: 'center' }}>
                                                                {` `}
                                                            </td>
                                                            <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                                                                <span> Photo Match </span>
                                                            </td>
                                                            <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                                                {item['login_bse_photo_verified'] ? 'YES' : 'NO'}
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <div style={{ height: 5 }} />

                                                    <Row>
                                                        <hr />
                                                    </Row>
                                                    <Row>
                                                        {
                                                            item['login_bse_clientcode_verified'] &&
                                                            item['login_bse_photo_verified'] === false &&
                                                            item['login_bse_fatca_verified'] === true &&
                                                            <Col xs={7}>

                                                                <Button className="btn btn-success btn-block btn-sm"
                                                                    variant="success" size="sm"
                                                                    onClick={() => {
                                                                        this.onElogPhotoUpload(item)
                                                                    }}
                                                                    disabled={this.state.isloading}
                                                                >
                                                                    Upload Elog
                                                                </Button>

                                                            </Col>
                                                        }
                                                        {
                                                            item['login_bse_clientcode_verified'] &&
                                                            // item['login_bse_photo_verified'] === true &&
                                                            item['login_bse_fatca_verified'] === false &&
                                                            <Col xs={6}>

                                                                <Button className="btn btn-success btn-block btn-sm"
                                                                    variant="success" size="sm"
                                                                    onClick={() => {
                                                                        this.onFATCAUpload(item)
                                                                    }}
                                                                    disabled={this.state.isloading}
                                                                >
                                                                    Upload FATCA
                                                                </Button>

                                                            </Col>
                                                        }
                                                        <Col xs={2}>
                                                            <Button className="btn btn-success btn-xs"
                                                                variant="success" size="sm"
                                                                onClick={() => {
                                                                    this.gotoClientInformationPage(item)
                                                                }}
                                                            >
                                                                View
                                                            </Button>
                                                        </Col>


                                                    </Row>

                                                </ListGroup.Item>
                                            ))}
                                        </ListGroup>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>

                    {
                        (this.props.client_result && 
                            this.props.client_result['result'] || []).length > 0 &&
                        <Fab
                            style={{ bottom: 25, left: 260, transform: 'scale(0.82)' }}
                            alwaysShowTitle={true}
                            mainButtonStyles={{
                                backgroundColor: "#9589ab"
                            }}
                            icon={<i className="mdi mdi-filter" ></i>}
                            onClick={() => this.setState({ show_modal_filter: true })}
                        >
                        </Fab>
                    }

                    <Modal
                        show={
                            this.state
                                .show_modal_filter
                        }
                        onHide={() =>
                            this.setState({ show_modal_filter: false })
                        }
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header>
                            <h5 style={{ textAlign: 'center' }}>
                                Search with status
                            </h5>
                        </Modal.Header>
                        <Modal.Body>
                            <div >
                                {!this.state.loading && (
                                    <div>
                                        <Container>
                                            <Row >
                                                <Col>
                                                    <div class="form-group">
                                                        <div
                                                            style={{ textAlign: 'center' }} >
                                                            <Select
                                                                placeholder={`-CHOOSE STAGE-`}
                                                                isMulti
                                                                value={this.state.stage_filter_selected}
                                                                onChange={this.handleChangeStage}
                                                                options={this.state.stage_filter_list}
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <div
                                                class="form-group"
                                                style={{ marginBottom: 10, marginTop: 10 }}
                                            ></div>
                                            <Row >
                                                <Col>
                                                    <div class="form-group">
                                                        <div
                                                            style={{ textAlign: 'center' }} >
                                                            <Select
                                                                placeholder={`-CHOOSE STATUS-`}
                                                                isMulti
                                                                value={this.state.status_filter_selected}
                                                                onChange={this.handleChangeStatus}
                                                                options={this.state.status_filter_list}
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <div
                                                class="form-group"
                                                style={{ marginBottom: 10, marginTop: 10 }}
                                            ></div>
                                            <p>
                                                Search With Text
                                            </p>
                                            <Row >
                                                <Col>
                                                    <div class="form-group">
                                                        <div
                                                            style={{ textAlign: 'center' }} >
                                                            <input
                                                                type="text"
                                                                className="form-control font-weight-bold"
                                                                value={this.state.search_text_selected}
                                                                maxLength="35"
                                                                onChange={(event)=>{this.handleTextChange(event, 'search_text_selected')}}
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <div
                                                class="form-group"
                                                style={{ marginBottom: 10, marginTop: 10 }}
                                            ></div>

                                        </Container>
                                    </div>
                                )}
                            </div>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="primary"
                                onClick={() =>
                                    this.resetFilter()
                                }
                            >
                                Reset Filter
                            </Button>
                            {`      `}
                            <Button
                                variant="secondary"
                                onClick={this.applyFiltersAndCloseModal}
                            >
                                Done
                            </Button>
                        </Modal.Footer>
                    </Modal>

                </div>
            </>
        )

    }

}

const mapStateToProps = state => ({
    loading: state.verify.loading,
    client_result: state.verify.client_result
});

// const mapStateToProps = ({ bank }) => {
//     const { loading, client_result } = bank;
//     return {
//         loading,
//         client_result
//     }
// };

export default connect(mapStateToProps, {
    loadClientList, saveBankAccount, addBankAccount, elogPhotoUpload, fatcaUpload
})(ClientList);