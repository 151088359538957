import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";
import Popup from '../popup';
import { Fab, Action } from 'react-tiny-fab';

const relation = [
  { category: 'm', value: 'Wife', label: 'Wife' },
  { category: 'm', value: 'Husband', label: 'Husband' },
  { category: 'm', value: 'Son', label: 'Son' },
  { category: 'm', value: 'Daughter', label: 'Daughter' },
  { category: 'g', value: 'Mother', label: 'Mother' },
  { category: 'g', value: 'Father', label: 'Father' },
  { category: 'g', value: 'Brother', label: 'Brother' },
  { category: 'g', value: 'Sister', label: 'Sister' },
  { category: 'g', value: 'Friend', label: 'Friend' },
  { category: 'g', value: 'Relative', label: 'Relative' },
]

const identityproof = [
  // { value: 'aadhaar', label: 'AADHAAR' },
  { value: 'pan', label: 'PAN' },
  /*
  { value: 'passport', label: 'PASSPORT' },
  { value: 'voter_id', label: 'VOTER ID' },
  { value: 'driving_license', label: 'DRIVING LICENSE' }
  */

]

const incomerange = [
  { value: 'Below 1 Lakh', label: 'Below 1 Lakh' },
  { value: '1-5 Lakhs', label: '1-5 Lakh' },
  { value: '5-10 Lakhs', label: '5-10 Lakhs' },
  { value: '10-25 Lakhs', label: '10-25 Lakhs' },
  { value: 'Agriculturist', label: 'Agriculturist' },
  { value: '25 lakhs - 1 Crore', label: '25 lakhs - 1 Crore' },
  { value: 'More than 1 Crore', label: 'More than 1 Crore' },
  { value: 'Above 5 Crore', label: 'Above 5 Crore' },


]

const NomineeDetails = ({ prevStep, nextStep, handleSelectInputChange,
  handleInputChange, handleObjectInputChange, handleFileChange, handleAadhaarFileChange,
  handlePopupChange, handleAadhaarPopupChange, detectFileChange, values, errors, handleSelectChange, handleRadioChange, loading }) => {

  const Continue = e => {
    e.preventDefault();
    nextStep();
  }

  const Previous = e => {
    e.preventDefault();
    prevStep();
  }

  
  return (
    <>
      <Header1 />
      <Sidebar />
      <div id="navbar-personalinfo-container">
        <div id="navbar" >
          <div className="card" style={{ backgroundColor: '#f8f8fb' }}>
            <div className="row">
              <h5 style={{ textAlign: 'center', marginTop: 5 }}> Your Nominee Information</h5>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: 20 }}>
        <div className="container">
          <div class="row">
            <div class="col-xl-12">

              <div className="card" >
                <div className="card-body">

                  <Form>
                    <div class="row">
                      <div class="col">
                        <Form.Group className="mb-3" controlId="formBasicsourcewealth">
                          <Form.Label className="text-dark">Relationship</Form.Label>
                          <Select
                            options={
                              (values['marital_status'] || '') === 's' ?
                                relation.filter(el => { return el['category'] !== 'm' }) : relation
                            }
                            isSearchable={false}
                            onChange={handleSelectInputChange('relationship', 'nominee_information')}
                            value={relation.find(obj => obj.value === values['nominee_information']["relationship"] || '')}
                            isDisabled={values['is_bse_nominee_verified']}
                          />
                          <Form.Text className="text-danger">
                            {errors && errors["nominee_relationship"]}
                          </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label className="text-dark">Proof Of Identify
                            <p style={{ fontWeight: 'normal' }}>
                              ( File format should be png or jpeg)
                            </p>
                          </Form.Label>

                          <Select options={identityproof} onChange={handleSelectInputChange('poi', 'nominee_information')}
                            value={identityproof.find(obj => obj.value === values['nominee_information']["poi"] || '')}
                            isSearchable={false}
                            isDisabled={values['is_bse_nominee_verified']}
                          />
                          {/* <p>
                            For Aadhaar front & back side required in single file
                          </p> */}

                          <Form.Text className="text-danger">
                            {errors && errors["nominee_poi"]}
                          </Form.Text>

                          <Form.Text className="text-danger">
                            {errors && errors["nominee_validated"]}
                          </Form.Text>

                        </Form.Group>

                      </div>

                    </div>

                    <div class="row">
                      <div class="col">

                        <Form.Group className="mb-3" controlId="formGridAddress2">
                          <Form.Label className="text-dark">Name (Auto populated from id proof)
                          <br/>
                          </Form.Label>
                          <input type="text" className="form-control"
                            value={values['nominee_information']['name'] || ''}
                            readOnly={true}
                            maxLength="25"
                            onChange={
                              handleObjectInputChange('nominee_information', 'name', /^[a-zA-Z\s]*$/)}
                          />

                          <Form.Text className="text-danger">
                            {errors && errors["nominee_name"]}
                          </Form.Text>
                        </Form.Group>

                      </div>
                    </div>

                    <div class="row">

                      <div class="col">

                        {
                          (values['nominee_information']["poi"] || '').length > 2 &&
                          <div>
                            {values['is_bse_nominee_verified'] &&
                              <h6>{values['nominee_information']["poi"].toUpperCase()} of Nominee
                              </h6>
                            }
                            
                            <div>
                              {!values['is_bse_nominee_verified'] &&
                                <div>
                                <Form.Label>Kindly upload
                                  {values['nominee_information']["poi"].toLowerCase() === "aadhaar" ?
                                    ' front part of ' : ' '}
                                  {values['nominee_information']["poi"]}
                                </Form.Label>
                                
                                <Form.Control type="file" 
                                  // value={values['file_nominee']} 
                                  onClick = {(event) => 
                                    { const { target = {} } = event || {}; target.value = "";}}
                                  onChange={handleFileChange('file_nominee',
                                  values['nominee_information']["poi"], 'nominee',
                                  { is_update: true, name: 'nominee_information', data: values['nominee_information'] })}
                                />
                                </div>
                              }
                              {(values['nominee_information']["poi"] || '').length > 2 &&                                  
                                values['src_file_nominee'] && values['src_file_nominee'] != 'error' &&
                                <div>
                                  <img src={values['src_file_nominee']} style={{ width: 250, height: 250 }} />
                                </div>
                              }
                              {!values['is_bse_nominee_verified'] && values['nominee_information'] &&
                                values['nominee_information']["poi"].toLowerCase() === "aadhaar" &&
                                values['file_nominee'] !== null &&
                                <div>
                                  <Form.Label>Kindly upload back part of {values['nominee_information']["poi"]}
                                  </Form.Label>
                                  <Form.Control type="file" 
                                    // value={values['file_nominee_1']} 
                                    onClick = {(event) => 
                                      { const { target = {} } = event || {}; target.value = "";}}
                                    onChange={handleFileChange('file_nominee_1',
                                    values['nominee_information']["poi"], 'nominee',
                                    { is_update: true, name: 'nominee_information', data: values['nominee_information'] })}
                                  />
                                </div>
                              }
                              
                              {(values['nominee_information']["poi"] || '').toLowerCase() === 'aadhaar' &&
                                values['src_file_nominee_1'] && values['src_file_nominee_1'] != 'error' &&
                                <div>
                                  <img src={values['src_file_nominee_1']} style={{ width: 250, height: 250 }} />
                                </div>
                              }
                            </div>

                            {/* { //(values['nominee_information']["poi"] || '').toLowerCase() !== 'aadhaar' &&
                              values['src_file_nominee'] && values['src_file_nominee'] != 'error' &&
                              values['nominee_information'] &&
                              values['nominee_information']['id_information'] &&
                              (values['nominee_information']["poi"] || '').toLowerCase() ===
                              (
                                (values['nominee_information']['id_information']["id_type"] || '').toLowerCase() ||
                                (values['nominee_information']['id_information']['id_information']["id_type"] || '').toLowerCase()
                              ) &&
                              <div>
                                <img src={values['src_file_nominee']} style={{ width: 250, height: 250 }} />
                              </div>
                            } */}
                            
                          </div>
                        }

                        {/* <p>Nominee address is assumed based on uploaded id proof</p> */}
                        {!values['src_file_nominee'] &&
                          values['nominee_information'] && values['nominee_information']['id_type'] &&
                          <div className="text-center">
                            {Object.keys(values['nominee_information']).forEach((el, i) =>
                              <div key={i} >
                                <h5 className="text-dark">{el} {' : '}
                                  <label className="text-success">
                                    {values['nominee_information'][el]}
                                  </label>
                                </h5>
                              </div>
                            )
                            }
                          </div>
                        }

                      </div>
                      {
                        values['src_file_nominee'] &&
                        values['nominee_information']['is_minor'] &&
                        <div class="col">
                          <div>
                            {values['is_bse_nominee_verified'] &&
                              <h6>Pan card of Guardian
                              </h6>
                            }
                            {!values['is_bse_nominee_verified'] &&
                              <div >
                                <Form.Label>Kindly upload pan card of guardian.
                                </Form.Label>
                                <p>( Format shall be png, jpeg, jpg)</p>
                                <Form.Control type="file" onChange={handleFileChange('file_nominee_guardian',
                                  'pan', 'nominee_guardian',
                                  { is_update: true, name: 'nominee_information', data: values['nominee_information'] })} />
                              </div>
                            }
                            {
                              values['src_file_nominee_guardian'] &&
                              <div >
                                <img src={values['src_file_nominee_guardian']} style={{ width: 250, height: 250 }} />
                              </div>
                            }
                          </div>

                          {/* {!values['src_file_nominee'] &&
                              values['nominee_information'] && values['nominee_information']['id_type'] &&
                              <div className="text-center">
                                {Object.keys(values['nominee_information']).forEach((el, i) =>
                                  <div key={i} >
                                    <h5 className="text-dark">{el} {' : '}
                                      <label className="text-success">
                                        {values['nominee_information'][el]}
                                      </label>
                                    </h5>
                                  </div>
                                )
                                }
                              </div>
                            } */}

                        </div>
                      }
                    </div>

                    {values['file_nominee_show_popup'] && values['file_nominee_popup_messagetext'] && <Popup
                      content={<>
                        <p>
                          {values['file_nominee_popup_messagetext']}
                        </p>
                      </>}
                      handleClose={handlePopupChange('file_nominee_show_popup')}
                    />}

                    {/* {this.state
                      .show_popup_aadhaar_confirmation && (
                        <Modal
                          show={
                            this.state
                              .show_popup_aadhaar_confirmation
                          }
                          onHide={() =>
                            this.handleAadhaarPopupChange('show_popup_aadhaar_confirmation', false)
                          }
                          size="lg"
                          aria-labelledby="contained-modal-title-vcenter"
                          centered
                        >
                          <Modal.Header>Aadhaar Confirmation (Front & Back Side)</Modal.Header>
                          <Modal.Body>
                            <p>Aadhaar Front Side</p>
                            <div >
                              <img src={values['src_file_nominee']} style={{ width: 250, height: 250 }} />
                            </div>
                            <p>Aadhaar Back Side</p>
                            <div>
                              <img src={values['src_file_nominee_1']} style={{ width: 250, height: 250 }} />
                            </div>
                          </Modal.Body>
                          <Modal.Footer>
                          <Button
                              variant="primary"
                              onClick={() =>
                                this.handleAadhaarPopupChange('show_popup_aadhaar_confirmation', false)
                              }
                            >
                              Confirm
                            </Button>
                            <Button
                              variant="primary"
                              onClick={() =>
                                this.handleAadhaarPopupChange('show_popup_aadhaar_confirmation', false)
                              }
                            >
                              Close
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      )} */}
                  </Form>

                  {loading &&
                    <Spinner animation="border" variant="dark"
                      style={{ position: "fixed", top: "50%", left: "50%" }} />
                  }

                </div>
              </div>
              {/* <div className="text-center mb-4">
                <Button
                  onClick={Previous}
                  type="submit"
                  variant="secondary"
                  color="primary"
                  style={{ 'width': '120px' }}
                >
                  Previous
                </Button>
                <span style={{ marginRight: 70 }}>
                  {values['nominee_information']['is_validated']}
                </span>
                <Button
                  disabled={
                    loading ||
                    !values['nominee_information'] ||
                    !values['src_file_nominee'] ||
                    (values['src_file_nominee'] || '') === 'error' ||

                    (
                      values['nominee_information']['is_minor'] &&
                      !values['src_file_nominee_guardian'] ||
                      (values['src_file_nominee_guardian'] || '') === 'error'
                    ) ||
                    (values['nominee_information']['relationship'] || '').length < 2 ||
                    (values['nominee_information']['name'] || '').length < 4 ||
                    (values['nominee_information']['poi'] || '').length < 3 ||
                    ((values['nominee_information']['poi'] || '').toLowerCase() !==
                      ((values['nominee_information']['id_information']['id_type'] || '').toLowerCase()
                        || (values['nominee_information']['id_information']['id_information']['id_type'] || '').toLowerCase()
                      )
                      && values['nominee_information']['is_validated'])         
                  }
                  onClick={Continue}
                  type="submit"
                  variant="success"
                  color="primary"
                  style={{ 'width': '120px' }}
                >
                  Next
                </Button>
              </div> */}
              <div style={{ height: 35 }}></div>

              <Fab
                style={{ bottom: 22, left: -15, transform: 'scale(0.8)' }}
                alwaysShowTitle={true}
                mainButtonStyles={{
                  backgroundColor: "#d45950"
                }}

                disabled={
                  loading ||
                  !values['nominee_information'] ||
                  !values['src_file_nominee'] ||
                  (values['src_file_nominee'] || '') === 'error' ||

                  (
                    values['nominee_information']['is_minor'] &&
                    !values['src_file_nominee_guardian'] ||
                    (values['src_file_nominee_guardian'] || '') === 'error'
                  ) ||
                  (values['nominee_information']['relationship'] || '').length < 2 ||
                  (values['nominee_information']['name'] || '').length < 4 ||
                  (values['nominee_information']['poi'] || '').length < 3 ||
                  ((values['nominee_information']['poi'] || '').toLowerCase() !==
                    ((values['nominee_information']['id_information']['id_type'] || '').toLowerCase()
                      || (values['nominee_information']['id_information']['id_information']['id_type'] || '').toLowerCase()
                    )
                    && values['nominee_information']['is_validated'])
                  // || ((values['nominee_information']["poi"] || '').toLowerCase() === 'aadhaar' &&
                  // (
                  //   (!values['src_file_nominee'] || (values['src_file_nominee'] || '') === 'error')
                  //   ||
                  //   (!values['src_file_nominee_1'] || (values['src_file_nominee_1'] || '') === 'error')
                  // ))                    
                }
                icon={<i className="fa fa-arrow-left  fa-lg" ></i>}
                onClick={Previous}
              >
              </Fab>


              {
                (
                  (
                    (values['nominee_information']['id_no'] || ``).length > 7 ||
                    (values['nominee_information']['id'] || ``).length > 7 || 
                     (values['nominee_information']['id_information']['id_no'] || ``).length > 7
                  ) &&
                  (
                   (values['nominee_information']["poi"] || '').toLowerCase() !== 'aadhaar' ||
                   ( 
                    (values['nominee_information']["poi"] || '').toLowerCase() === 'aadhaar' &&
                    values['nominee_information']['id_information'] &&
                    (values['nominee_information']['id_information']['address'] || ``).length >= 10
                   )
                  )
                ) &&
                <Fab
                  style={{ bottom: 22, right: -18, transform: 'scale(0.8)' }}
                  alwaysShowTitle={true}
                  mainButtonStyles={{
                    backgroundColor: "#2ea34d"
                  }}
                  disabled={loading}
                  icon={<i className="fa fa-arrow-right  fa-lg" ></i>}
                  onClick={Continue}
                >
                </Fab>
              }

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NomineeDetails;