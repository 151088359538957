import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Form, Row, Col, Button, Label, Spinner } from "react-bootstrap";
import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";
import Select from 'react-select';

import { Fab, Action } from 'react-tiny-fab';
const PersonalDetails = ({
  nextStep,
  handleChange,
  values,
  handleRadioChange,
  handleDropdownChange,
  handleObjectInputChange,
  errors,
  loading,
}) => {
  // for continue event listener

  // handleInputValidation('address_information',
  //   [{ value: values['address_information']['pincode'], pattern: /^[0-9\s]*$/}]
  // );

  const Continue = (e) => {
    e.preventDefault();
    nextStep();
  };
  return (
    <>
      <Header1 />
      <Sidebar />
      <div id="navbar-personalinfo-container">
        <div id="navbar" >
          <div className="card" style={{ backgroundColor: '#f8f8fb' }}>
            <div className="row">
              <h5 style={{ textAlign: 'center', marginTop: 5 }}> Your Detailed Information</h5>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: 20 }}>
        <div className="container">
          <div class="row">
            <div class="col-xl-12">
              {/* <h5 className="text-center">Your Information</h5> */}
              <div className="card">
                <div className="card-body">
                <Form>
                  <fieldset>
                    <Form.Group as={Row} >
                      <Col>
                        <Form.Label className="text-dark">
                          Marital Status*
                        </Form.Label>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} >
                      <Col>
                        <Form.Check
                          type="radio"
                          label="Single"
                          name="marital_status"
                          id="s"
                          value="s"
                          checked={values.marital_status === "s"}
                          onChange={handleRadioChange("marital_status")}
                          disabled={values['is_bse_nominee_verified']}
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          type="radio"
                          label="Married"
                          name="marital_status"
                          id="m"
                          value="m"
                          checked={values.marital_status === "m"}
                          onChange={handleRadioChange("marital_status")}
                          disabled={values['is_bse_nominee_verified']}
                        />
                        <Form.Text className="text-danger">
                          {errors && errors["marital_status"]}
                        </Form.Text>
                      </Col>
                    </Form.Group>

                    <br />
                    {/* <Form.Group as={Row} className="mb-3">
                          <Col>
                            <Form.Label className="text-dark">Email*</Form.Label>

                            <Form.Control
                              placeholder=""
                              onChange={handleChange("email")}
                              disabled
                              defaultValue={values.email}
                            />
                            <Form.Text className="text-danger">
                              {errors.errors && errors.errors["email"]}
                            </Form.Text>
                          </Col>
                        </Form.Group> */}

                  </fieldset>

                  <Form.Group as={Row} className="mb-3" controlId="formGridAddress2">
                    <Col sm={12}>
                      <Form.Label className="text-dark">Communication Address*</Form.Label>
                      {`  `}(You can edit)
                      <textarea
                        className="form-control"
                        style={{
                          width: 290,
                          height: 120
                        }}
                        value={values["address_information"]["address"] || ""}
                        onChange={handleObjectInputChange(
                          "address_information",
                          "address"
                        )}
                        maxLength="130"
                        defaultValue={
                          values["address_information"]["address"] || ""
                        }
                        readOnly={values['is_bse_nominee_verified']}
                      />
                    </Col>
                  </Form.Group>


                  <Form.Group as={Row} controlId="formGridCity">
                    <Col sm={4}>
                      <Form.Label className="text-dark">City*</Form.Label>
                      <input
                        type="text"
                        className="form-control"
                        value={values["address_information"]["city"] || ""}
                        maxLength="25"
                        onChange={handleObjectInputChange(
                          "address_information",
                          "city",
                          /^[a-zA-Z\s]*$/
                        )}
                        readOnly={values['is_bse_nominee_verified']}
                      />
                      <Form.Text className="text-danger">
                        {errors && errors["city"]}
                      </Form.Text>
                    </Col>
                  </Form.Group>
                  <br />
                  <Form.Group as={Row} controlId="formGridState">
                    <Col sm={4}>
                      <Form.Label className="text-dark">State*</Form.Label>
                   
                        <Select 
                        options={values['master_list']['state_list']}
                          onChange={handleDropdownChange(
                            "address_information",
                            "statecode",
                            /^[a-zA-Z\s]*$/
                          )}
                          value={(values['master_list']['state_list'] || []).find(obj => obj.value.toLowerCase() === values["address_information"]["statecode"].toLowerCase())}
                          isDisabled={values['is_bse_nominee_verified']}
                          isSearchable={false}
                        />
                      <Form.Text className="text-danger">
                        {errors && errors["state"]}
                      </Form.Text>
                    </Col>
                  </Form.Group>
                  <br />
                  <Form.Group as={Row} controlId="formGridPincode">
                    <Col sm={4}>
                      <Form.Label className="text-dark">Pincode*</Form.Label>
                      <input
                        type="tel"
                        className="form-control"
                        pattern="[0-9]*"
                        style={{ letterSpacing: 3 }}
                        value={values["address_information"]["pincode"] || ""}
                        maxLength="7"
                        onChange={handleObjectInputChange(
                          "address_information",
                          "pincode",
                          /^[0-9\s]*$/
                        )}
                        readOnly={values['is_bse_nominee_verified']}
                      />
                      <Form.Text className="text-danger">
                        {errors && errors["pincode"]}
                      </Form.Text>
                    </Col>
                  </Form.Group>


                  <Form.Group className="mb-3"></Form.Group>

                  {/* <div className="text-center mb-4">
                    <Button
                      onClick={Continue}
                      // disabled={values['err_address_information']}
                      type="submit"
                      variant="success"
                      color="primary"
                      style={{ width: "120px" }}
                      disabled={loading}
                    >
                      Next
                    </Button>
                  </div> */}
                </Form>
                <Fab
                  style={{ bottom: 22, right: -18, transform: 'scale(0.8)' }}
                  // event="hover"
                  alwaysShowTitle={true}
                  mainButtonStyles={{
                    backgroundColor: "#2ea34d"
                  }}
                  disabled={loading}
                  icon={<i className="fa fa-arrow-right  fa-lg" ></i>}
                  onClick={Continue}
                >
                </Fab>
                {loading && (
                  <Spinner
                    animation="border"
                    variant="dark"
                    style={{ position: "fixed", top: "50%", left: "50%" }}
                  />
                )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalDetails;
