import React from "react";
import { Link } from "react-router-dom";
import Header1 from "../layout/header1";
import Bottom from "../element/bottom";
import { connect } from "react-redux";

class Landing extends React.Component {
  constructor(props) {

    super(props);
    this.state = {
      // isloading: false,
    };

  }

  componentDidMount() {

    if (localStorage.getItem('token')) 
    {
      this.props.history.push({
        pathname: '/portfolio/',
        state: {}
      });
    }
    else 
    { }

  }

  render() {

    return (
      <>
        <Header1 />
        <div className="intro" id="intro" data-scroll-index="0" style={{ marginTop: 120 }}>
          <div className="container">
          
            <div className="row align-items-center justify-content-between">
            
              <div className="col-xl-6 col-md-6">
                <div className="intro-content pb-5">
                  
                  <h2>
                    Buy and Sell <br />
                    Mutual Funds seamlessly
                  </h2>
                  <p>
                    Fast and secure way to purchase or exchange more than 1500+
                    Mutual Funds
                  </p>
                  <div className="intro-btn">
                    <div className="row">
                      <div className="col-6">
                        <Link class=" btn btn-primary" to={'/landing'}>
                          <img src={require("../../images/android.svg")} alt="" />
                        </Link>
                      </div>
                      <div className="col-6">
                        <Link class="btn btn-primary"  to={'/landing'}>
                          <img src={require("../../images/apple.svg")} alt="" />
                        </Link>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-5 col-md-6 py-md-5">
                        <div >
                          <img src={require('../../images/background/scbg.png')} alt="Freedom Blog" width='350' />
                        </div>
                      </div>
                    </div>
                    <div className="bottom-logo">

                      <p>Mutual Fund investments are subject to market risks.
                        Please read all scheme related documents carefully before investing.
                        Past performance is not an indicator of future returns.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        {/* <div class="intro section-padding position-relative" id="intro">
        <div class="container">
          <div class="row align-items-center justify-content-between">
            <div class="col-xl-7 col-md-6">
              <div class="intro-content">
                <h2>
                  Buy and sell <br />
                  Mutual Funds seamlessly
                </h2>
                <p>
                  Fast and secure way to purchase or exchange more than 1500+
                  Mutual Funds
                </p>
       
              </div>
            </div>
            <div class="col-xl-5 col-md-6 py-md-5">
             
                  <div>
        <div>
          <img src = {require('../../images/background/scbg.jpg')} alt = "Freedom Blog" width='500' />
        </div>
          <div class="textcenter">
             <h3 class="align-items-center"> Invest On the Go </h3>

          </div>
      </div>
                  </div>
                </div>
              </div>
            </div>
      

      <div class="market section-padding page-section" id="market">
        <div class="container">
          <div class="row py-lg-5 justify-content-center">
            <div class="col-xl-7">
              <div class="section_heading">
                <span>Explore</span>
                <h3>The India's Leading Mutual funds</h3>
                <p>
                  Trade Aditya birla, HDFC Bank, Axis Bank and hundreds of other
                  Mutual funds in minutes.
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12">
              <div class="card">
                <div class="card-body">
                  <div class="market-table">
                    <div class="table-responsive">
                      <table class="table mb-0 table-responsive-sm">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Price</th>
                            <th>Change</th>
                            <th>Chart</th>
                            <th>Trade</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td class="coin_icon">
                              <span>
                                {" "}
                                <b>Hdfc Bank Mutual Fund</b>
                              </span>
                            </td>

                            <td>12</td>
                            <td>
                              <span class="text-success">+1.13%</span>
                            </td>
                            <td>
                              {" "}
                              <span class="sparkline8"></span>
                            </td>
                            <td>
                              <Link to="/signin" class="btn btn-success">
                                Buy
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td class="coin_icon">
                              <span>
                                {" "}
                                <b>Aditya Birla</b>
                              </span>
                            </td>

                            <td>85</td>
                            <td>
                              <span class="text-success">+1.13%</span>
                            </td>
                            <td>
                              {" "}
                              <span class="sparkline8"></span>
                            </td>
                            <td>
                              <Link to="/signin" class="btn btn-success">
                                Buy
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td class="coin_icon">
                              <span>
                                {" "}
                                <b>Axis bank</b>
                              </span>
                            </td>

                            <td>22</td>
                            <td>
                              <span class="text-success">+1.13%</span>
                            </td>
                            <td>
                              {" "}
                              <span class="sparkline8"></span>
                            </td>
                            <td>
                              <Link to="/signin" class="btn btn-success">
                                Buy
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td class="coin_icon">
                              <span>
                                {" "}
                                <b>LIC Mutual Fund</b>
                              </span>
                            </td>

                            <td>55</td>
                            <td>
                              <span class="text-danger">-0.47%</span>
                            </td>
                            <td>
                              {" "}
                              <span class="sparkline8"></span>
                            </td>
                            <td>
                              <Link to="/signin" class="btn btn-success">
                                Buy
                              </Link>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="features section-padding" id="features">
        <div class="container">
          <div class="row py-lg-5 justify-content-center">
            <div class="col-xl-7">
              <div class="section_heading">
                <span>Features</span>
                <h3>Bullbox partnered with BSE Star MF</h3>
                <p>Here are a few reasons why you should choose bullbox</p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="features-content">
                <span>
                  <i class="fa fa-shield"></i>
                </span>
                <h4>Secure storage</h4>
                <p>
                  We store the vast majority of the Mutual Funds,Bonds,ETFS in secure
                 storage.
                </p>
                
              </div>
            </div>
            <div class="col-md-4">
              <div class="features-content">
                <span>
                  <i class="fa fa-cubes"></i>
                </span>
                <h4>Buy and Sell hassle free</h4>
                <p style={{marginBottom: 55}}>
                  Buy and Sell your Mutual funds with out worrying.
                </p>
               
              </div>
            </div>
            <div class="col-md-4">
              <div class="features-content">
                <span>
                  <i class="fa fa-life-ring"></i>
                </span>
                <h4>Industry best practices</h4>
                <p>
                  bullbox supports a variety of the most popular Mutual funds and other investments like Bonds,ETF's etc
                </p>
                
              </div>
            </div>
          </div>
        </div>
      </div> */}

        {/* <div class="portfolio section-padding" id="portfolio">
                <div class="container">
                    <div class="row py-lg-5 justify-content-center">
                        <div class="col-xl-7">
                            <div class="section_heading">
                                <span>Join Us</span>
                                <h3>Create your Mutual Fund portfolio today</h3>
                                <p>bullbox  has a variety of features that make it the best place to start trading</p>
                            </div>
                        </div>
                    </div>
                    <div class="row align-items-center justify-content-center">
                        <div class="col-xl-7 col-md-6">
                            <div class="portfolio_img">
                                <img src={require('../../images/portfolio.jpg')} alt="" class="img-fluid" />
                            </div>
                        </div>
                    </div>
                    <div class="row portfolio_list">
                        <div class="col-xl-6 col-md-6">
                            <div class="media">
                                <span class="port-icon"> <i class="la la-chart-bar"></i></span>
                                <div class="media-body">
                                    <h4>Manage your portfolio</h4>
                                    <p>Buy and sell popular digital currencies, keep track of them in the one place.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-md-6">
                            <div class="media">
                                <span class="port-icon"> <i class="la la-calendar-check"></i></span>
                                <div class="media-body">
                                    <h4>Recurring buys</h4>
                                    <p>Invest in bullbox ocurrency slowly over time by scheduling buys daily, weekly, or
                                    monthly.
                                </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-md-6">
                            <div class="media">
                                <span class="port-icon"> <i class="la la-lock"></i></span>
                                <div class="media-body">
                                    <h4>Vault protection</h4>
                                    <p>For added security, store your funds in a vault with time delayed withdrawals.
                                </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-md-6">
                            <div class="media">
                                <span class="port-icon"> <i class="la la-mobile"></i></span>
                                <div class="media-body">
                                    <h4>Mobile apps</h4>
                                    <p>Stay on top of the markets with the bullbox  app for <Link href="#">Android</Link> or
                                    <Link href="#">iOS</Link>.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

        {/* <div class="testimonial section-padding" id="testimonial">
        <div class="container">
          <div class="row py-lg-5 justify-content-center">
            <div class="col-xl-7">
              <div class="section_heading">
                <span>What's Say</span>
                <h3>Trusted by 2 million customers</h3>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-xl-8 col-md-11">
              <div class="testimonial-content">
                <div class="row align-items-center no-gutters">
                  <div class="col-xl-6 col-md-6">
                    <div class="customer-img">
                      <img
                        class="img-fluid"
                        src={require("../../images/testimonial/1.jpg")}
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="col-xl-6 col-md-6">
                    <div class="customer-review px-4">
                      <img
                        class="img-fluid"
                        src={require("../../images/brand/2.webp")}
                        alt=""
                      />
                      <p>
                        Integrating bullbox services into Trezor Wallet's
                        exchange has been a great success for all parties,
                        especially the users.
                      </p>
                      <div class="customer-info">
                        <h5>Mr John Doe</h5>
                        <p>CEO, Example Company</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

        {/* <div class="contact-form section-padding" id="contact">
        <div class="container">
          <div class="row py-lg-5 justify-content-center">
            <div class="col-xl-7">
              <div class="section_heading">
                <span>Ask Question</span>
                <h3>Let us hear from you directly!</h3>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-4 col-md-4 col-sm-4">
              <div class="info-list">
                <h4 class="mb-3">Contact</h4>
                <ul>
                  <li>
                    <i class="fa fa-phone"></i> (+91)9xxxxxxxxxx
                  </li>
                  <li>
                    <i class="fa fa-envelope"></i> support@bullbox.in
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-xl-8 col-md-8 col-sm-8">
              <form method="post" name="myform" class="contact_validate">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="contactName">Full name</label>
                      <input
                        type="text"
                        class="form-control"
                        id="contactName"
                        placeholder="Full name"
                        name="firstname"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="contactEmail">Email</label>
                      <input
                        type="email"
                        class="form-control"
                        name="email"
                        placeholder="hello@domain.com"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <textarea
                        class="form-control p-3"
                        name="message"
                        rows="5"
                        placeholder="Tell us what we can help you with!"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <button type="submit" class="btn btn-primary px-4">
                  Send message
                </button>
              </form>
            </div>
          </div>
        </div>
      </div> */}

        <Bottom />

      </>
    );
  }
}

// Landing.propTypes = {
//   // login: PropTypes.func.isRequired,
//   isAuthenticated: PropTypes.bool,
  
// };

const mapStateToProps = (state) => ({
  login_result: state.auth.login_result,
  loading: state.master.loading,
});

export default connect(mapStateToProps, {  })(Landing);;
